/* .product-content-five {
    height: 550px;
}


@media (max-width: 991.98px){
    .product-content-five {
        height: 550px;
    }
} */

/* @media (min-width: 768px){
    
    .product-content-five {
        height: fit-content;
    }
} */
.product-five-title{
    margin-bottom: 0;
}
.product-five {
    border-radius: 12px;
    border: 0.5px solid #392C7D;
    padding: 20px;
}

.product-img-card {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 80px; */
    width: 100px;
    border-radius: 12px;
    padding: 8px;
    /* margin: 20px 20px 0 20px; */
}

.heading {
    height: 110px;
}

.product-img-card img {
    width: fit-content;
}

.product-content-five {
    text-align: justify;
}

/* 
.product-content-five {
    height: 300px;
}
@media (max-width: 992px) {
    .product-content-five {
        height: 280px;
    }

}
@media (max-width: 768px) {
    .product-content-five {
        height: fit-content;
    }

}

@media (max-width: 576px) {
    .product-content-five {
        height: fit-content;
    }

} */