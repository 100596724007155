@media (max-width: 426px) {
    .manuu-logo .manuu-logo-1 {
      display: none;
    }
  
    .manuu-logo .manuu-logo-2 {
      display: block !important;
    }
  }

.fixed{
    position: fixed;
    width: 100% !important;
    left: 0px !important;
    right: 0px !important;
}

.header-fixed{
    /* background-color: rgb(239, 239, 239) !important; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}