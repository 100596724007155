/*
Author       : Dreamguys
Template Name: Dreams LMS - Bootstrap Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Bootstrap Classes
3. Header
4. Mobile Menu
5. Footer
6. Home One Main
7. Home Two Main
8. Home Three Main
9. Home Five Main
10. Login
11. Register Step
12. Courses
13. Pricing Plan
14. Notifications
15. Wishlists
16. Course Details
17. Help Center
18. Job Category
19. Instructor
20. Instructor List
21. Terms and Conditions
22. Instructor Profile
23. Instructor Profile Edit
24. Students
25. Students List
26. Students Profile
27. Settings - Students Payments
28. Settings - Students Invoices
29. Settings - Students Referral
30. Setting - Support Tickets
31. Setting - Support View Tickets
32. Setting - Instructor My Courses
33. Instructor - Dashboard
34. ck - Editer
35. Student Dashboard
36. Deposit Instructor
37. Deposit Payment Method
38. Error
39. All Courses
40. Search
41. Blog
42. Blog Grid
43. Blog Modern
44. Mycourse
45. Message
46. Cart
47. Ceckout
48. Student Profile
49. Instructor Profile
50. Add Course
51. Invoice
52. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700;800;900&display=swap');

html {
    height: 100%;
}
body {
	background-color: #fff;
	color: #22100D;
    font-family: 'Inter', sans-serif;
	font-size: 16px;
    height: 100%;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
	color: #22100D;
	font-weight: 500;
}
a {
    color: #22100D ;
    text-decoration: none ;
}
a:hover {
	color: #F66962 ;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button:focus {
	outline: 0;
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.btn.focus, .btn:focus {
	box-shadow: inherit;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-group {
    margin-bottom: 1rem;
}
.form-control {
    border-color: #dce0eb;
    color: #26292c;
	font-size: 15px;
    min-height: 42px;
	padding: 6px 15px;
}
.table .form-control {
	font-size: 14px;
	min-height: 38px;
}
.form-control::-webkit-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::-moz-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control:-ms-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::-ms-input-placeholder {
	color: #6e82a3;
	font-size: 14px;
}
.form-control::placeholder {
	color: #6e82a3;
	font-size: 14px;
}

/*-----------------
	Aos Animation
-----------------------*/

@-webkit-keyframes up-down {
	0% {
	  transform: translateY(10px);
	}
  
	100% {
	  transform: translateY(-10px);
	}
  }
  
  @keyframes up-down {
	0% {
	  transform: translateY(10px);
	}
  
	100% {
	  transform: translateY(-10px);
	}
  }
  
/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-block {
	width: 100%;
}
.btn-link {
	text-decoration: none;
}
.btn-link:hover {
	text-decoration: underline;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #26292c;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #1e88e5 ;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #1879cd;
}
.bg-success,
.badge-success {
	background-color: #00e65b;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #00cc52;
}
.bg-info,
.badge-info {
	background-color: #009efb;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e;
}
.bg-danger,
.badge-danger {
	background-color: #ff0100;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #F66962 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #ff0100 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #FFD600 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 ;
}
.text-black {
	color: #0B0B0B ;
}
.text-dark {
	color: #000 ;
}
.btn-primary {
	background-color: #FF875A ;
	border: 1px solid #FF875A ;
}
.bg-blue {
	background: #392C7D !important;
}
.bg-yellow {
	background: #FFB500 !important;
}
.bg-green {
	background: #21B477 !important;
}
.bg-light-green {
	background: #6CC04A !important;
}
.bg-orange {
	background: #FF602E !important;
}
.text-green {	
	color: #21B477 !important;
}
.text-blue {	
	color: #392C7D;
}
.text-orange {	
	color: #FF602E;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #fc7f50;
	border: 1px solid #fc7f50;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #fc7f50;
	border: 1px solid #fc7f50;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #fc7f50;
	border-color: #fc7f50;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #1e88e5;
    border-color: #1e88e5;
    color: #fff;
}
.btn-secondary {
    color: #fff;
    background: #FF875A !important;
	border: 1px solid #FF875A !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
	background-color: #fc7f50;
	border: 1px solid #fc7f50;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #00e65b;
    border-color: #00e65b;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #ff0100;
	border: 1px solid #ff0100;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:hover {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #1e88e5;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:hover {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #ff0100;
	border-color: #ff0100;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.pagination > li > a, 
.pagination > li > span {
	color: #1e88e5;
}
.page-link:hover {
	color: #1e88e5;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #1e88e5;
	border-color: #1e88e5;
}
.dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: inherit;
	font-size: 14px;
    transform-origin: left top 0;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #f7f7f7;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.card {
    border: 1px solid #e9ecef;
    margin-bottom: 1.875rem;
    border-radius: 0;
}
.card-body {
	padding: 1.5rem;
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, 
.bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #1e88e5;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #1e88e5;
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}

/*-----------------
	3. Header
-----------------------*/

.header-nav {
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border: 0;
    border-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    position: relative;
    height: 70px;
    padding: 0 20px;
    margin-bottom: 0;
    transition-duration: 0.4s;
    margin-top: 20px;
}
.header-page .header-nav { 
	background-color: #FFF;
}
.main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.main-nav > li.active > a {
	color: #F66962;
}
.main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #26292c;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 1px solid #f0f0f0;
}
.main-nav > li .submenu > li.has-submenu > a::after {
    display: none;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.main-nav > li .submenu li {
    position: relative;
}
.main-nav li a {
	display: block;
    font-size: 15px;
    font-weight: 500;
	color: #26292c;
}
.header-two .main-nav li a {
	color: #324FA2;
}
.header-two .main-nav li a {
	color: #324FA2;
}
.header-two .main-nav .has-submenu> a {
	position: relative;
}
.header-two .main-nav .has-submenu.menu-effect > a::after {
	content: "";
	height: 3px;
	width: 0px;
	background-color: #FF602E;
	position: absolute;
	bottom: 20px;
    left: 0;
	transition: all 0.5s;
	border-radius: 5px;
}
.header-two .main-nav .has-submenu.menu-effect:hover > a::after {
	width: 20px;
}
.header-two .main-nav .has-submenu.active > a::after {
	width: 20px;
}
.main-nav li.login-link {
	display: none;
}
.logo {
    display: inline-block;
    margin-right: 30px;
    width: 160px;
}
.header-navbar-rht {
    margin: 0;
	margin-left: 0;
	padding: 0;
}
.header-navbar-rht >  li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    padding-right: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	position: relative;
}
.header-navbar-rht li:last-child {
	padding-right:0px;
}
.header-navbar-rht > li > a {
	font-size: 16px;
	font-weight: 500;
	color: #26292c;
}
.header-navbar-rht li .dropdown-menu {
	border: 0;
	box-shadow: 0px 0px 28px 15px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
}
.header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
	visibility: visible;
	opacity: 1;
	margin-top: 0;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
.header-navbar-rht li .dropdown-menu {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	display: block;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
}
.header-navbar-rht .dropdown-toggle::after {
	display: none;
}
.header-navbar-rht li .dropdown-menu .dropdown-item {
	border-top: 1px solid transparent;
	padding: 10px 15px;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
	border-top: 0;
	border-radius: 5px 5px 0 0;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
	border-radius: 0 0 5px 5px;
}
.header-navbar-rht li a.header-login:hover {
    background-color: #f6697b;
    border-color: #f6697b;
    color: #fff;
}
.header-navbar-rht li a.header-login {
    border-radius: 50px;
    text-align: center;
    font-size: 16px;
    color: #392C7D;
    text-transform: capitalize;
    font-weight: 500;
	min-width: 150px;
	border: 3px solid #B4A7F5;
	padding: 10px 15px;
}
.header-navbar-rht li a.header-sign {
	min-width: 150px;
	padding: 14px 15px;
	background: rgb(241 241 241 / 42%);
	border-radius: 46.9159px;
	text-align: center;
}
.header-navbar-rht li a.header-sign:hover {
	background-color: #f6697b;
    border-color: #f6697b;
    color: #fff;
}
.add-header-bg .header-navbar-rht li a.header-sign {
	background: #b4a7f5;
	border: 3px solid #B4A7F5;
	color: #fff;
	padding: 10px 15px;
}
.add-header-bg .header-navbar-rht li a.header-sign:hover {
	background: #fff;
	color: #22100D;
	border: 3px solid #B4A7F5;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #757575;
	border-right: 2px solid #757575;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
	margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
    display: inline-block;
    position: relative;
}
.user-img > img {
	height: 50px;
    object-fit: cover;
    width: 50px;
    border-radius: 50%;
    border: 3px solid #E0E0E0;
}
.user-header .avatar img {
    width: 50px;
    height: 50px;
	border: 3px solid #E0E0E0;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.header-navbar-rht .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover, 
.header-navbar-rht .dropdown-menu .dropdown-item:hover i {
    color: #FF875A;
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
	font-size: 15px;
    margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 14px;
	line-height: 70px;
	padding: 0 10px;
}
.header-fixed {
    left: 0;
    /* position: fixed; */
    right: 0;
    top: 0;
	z-index: 999;
    transition: all ease-in 0.2s;
}
.header-active{
    background-color: white;
    height: 80px;
}
.header {
	background-color: #fff;
}
.users .dropdown-item i {
	color: #FF875A;
}
.dropdown-item.night-mode {
	justify-content: space-between;
}
.dropdown-item.night-mode i {
	color: #685F78;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #EEF1FE;
	text-align: center;
}
.topnav-dropdown-header {
	font-size: 14px;
	height: 40px;
	line-height: 40px;
	padding-left: 15px;
	padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.notifications .noti-content {
    height: 300px;
    width: 420px;
    overflow-y: auto;
    padding-right: 0;
    position: relative;
}
.notifications .noti-content::-webkit-scrollbar {
	width: 6px;
    background: #fff;
	height: 10px;
}
.notifications .noti-content::-webkit-scrollbar-track {
	border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb {
	background: #8A8A95; 
	border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb:hover {
	background: #1B2559; 
}
.notifications .media .avatar > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li {
	margin-top: 0;
	border-bottom: 1px solid #E4E8EE;
	box-shadow: 0px 1px 0px #E4E8EE;
	display: block;
	padding: 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}
.notifications .notification-message h6 {
	font-weight: 600;
	font-size: 14px;
	color: #000 !important;
	margin-bottom: 15px;
}
.notifications .notification-message h6 span {
	font-weight: 500;
}
.notifications .notification-message p {
	font-weight: 500;
	font-size: 14px;
	color: #565758;
	margin-bottom: 0;
}
.notifications .notification-message .btn {
	font-weight: 500;
	font-size: 14px;
	padding: 4px 8px;
	margin-bottom: 8px;
	border-radius: 4px;
}
.notifications .notification-message .noti-details {
	color: #1A1F36;
	position: relative;
	border-left: 4px solid #DDDEE1;
	padding-left: 8px;
	margin-bottom: 8px;
}
.notifications .notification-message .btn-accept {
	background: #E95744;
	border: 1px solid #E95744;
	color: #FFF;
    margin-right: 5px;
}
.notifications .notification-message .btn-reject {
	background: #FFFFFF;
	border: 1px solid #DDDEE1;
	color: #3C4257;
}
.topnav-dropdown-header .notification-title {
	color: #1A1F36;
	display: block;
	float: left;
	font-size: 14px;
	font-weight: 500;
}
.topnav-dropdown-header .clear-noti {
	color: #1A1F36;
	float: right;
	font-size: 14px;
	font-weight: 500;
}
.topnav-dropdown-header .clear-noti i {	
    font-weight: 500;
}
.notification-title select {
	background: #515669;
	border-radius: 4px;
    color: #FFF;
    margin-left: 5px;
}
.notification-title select:focus-visible {
	outline: 0;
}
.wishes-list {
	padding: 15px !important;	
	font-family: 'Poppins', sans-serif;
}
.wish-header a {
	font-size: 12px;
	text-decoration: underline;
}
.wish-nav .wishes-list {
	padding-right: 0 !important;
}
.wish-nav .wish-content ul li {
	margin-right: 15px;
}
.wish-content {
    height: 350px;
    width: 415px;
    overflow-y: auto;
    position: relative;
    padding-right: 0;
}
.wish-content::-webkit-scrollbar {
	width: 6px;
    background: #fff;
	height: 10px;
}
.wish-content::-webkit-scrollbar-track {
	border-radius: 4px;
}
.wish-content::-webkit-scrollbar-thumb {
	background: #8A8A95; 
	border-radius: 4px;
}
.wish-content::-webkit-scrollbar-thumb:hover {
	background: #1B2559; 
}
.wish-content .media .avatar  img {
    width: 84px;
	height: 63px;
    border-radius: 5px;
    margin-right: 10px;
}
.wish-content ul {
	list-style: none;
	padding: 0;
	margin: 0 0 20px;
}
.wish-content ul li {
	padding: 15px 0;
	border-bottom: 1px solid #DFDFE0;
}
.wish-nav .wish-content ul {
	margin: 0;
}
.wish-nav .wish-content ul li:first-child {
	padding-top: 0;
}
.wish-nav .wish-content ul li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}
.wish-content ul li .media {
	display: flex;
	justify-content: space-between;
}
.wish-content h6 {
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	margin-bottom: 2px;	
}
.wish-content h6 a {
	color: #000;
	font-size: 14px;
}
.wish-content h6 a:hover {
	color: #FF5364;
}
.wish-content .media-body {
	overflow: hidden;
}
.media-wide {
	width: 75%;
}
.wish-content p {
	color: #685F78;
	font-size: 12px;
	margin-bottom: 2px;
}
.wish-content h5 {
	color: #F66962;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 0;
}
.wish-content h5 span {
	color: #777777;
	font-size: 10px;
	font-weight: 400;
	margin-bottom: 0;
}
.wish-nav .remove-btn a {
	margin-top: 10px;
}
.remove-btn a {
	background: #FFFFFF;
	border: 1px solid #FF5364;
	padding: 5px 10px;
	border-radius: 0;
	font-weight: 500;
	font-size: 12px;
}
.remove-btn a:hover {
	background: #FF5364;
	color: #FFF;
}
.total-item {
	text-align: right;
}
.total-item h6 {
	font-size: 14px;
	color: #000000;
	margin-bottom: 10px;
}
.total-item h5 {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}
.night-mode .check-on .form-check-input {
    min-height: 20px;
    min-width: 38px;
}
.night-mode .form-switch .form-check-input:focus {
	 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.night-mode .form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff5364'/%3e%3c/svg%3e") !important;
}
.night-mode .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
	background-color: #E0E0E0;
	border-color: #E0E0E0;
}

/*-----------------
	4. Mobile Menu
-----------------------*/

.sidebar-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1040;
}
.menu-opened .main-menu-wrapper {
    transform: translateX(0);
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
    height: 60px;
    padding:  0 20px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    border-bottom: 1px solid #f0f0f0;
	display: none;
}
.menu-logo img {
    width: 130px;
}
.menu-close {
	font-size: 18px;
}
.header-five .menu-close {
	color: #fff;
}
.bar-icon {
    display: inline-block;
    width: 31px;
}
.bar-icon span {
    background-color: #f66962;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
}
.bar-icon span:nth-child(2) {
    width: 16px;
}
.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}
#mobile_btn {
	display: none;
	margin-right: 30px;
}
html.menu-opened body {
    overflow: hidden;
}

/*-----------------
	5. Footer
-----------------------*/

.footer {
    background-color: #fff;
	position: relative;
}
.footer-top {
	padding-top: 60px;
	position: relative;
	z-index: 9;
	font-size: 14px;
}
.footer-top .footer-about-content {
	max-width: 315px;
}
.footer-bottom {
	position: relative;
	z-index: 9;
}
.footer-title {
    color: #0A142F;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 26px;
    text-transform: capitalize;
}
.footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer-widget .footer-logo {
	margin-bottom: 15px;
}
.footer-widget .footer-logo img {
    width: 160px;
    max-width: 100%;
}
.footer-widget .footer-about-content p {
	color: #000000;
}
.footer-widget .footer-about-content p:last-child {
	margin-bottom: 0;
}
.footer-menu ul li {
    margin-bottom: 20px;
    position: relative;
}
.footer-menu ul li:last-child {
	margin-bottom: 0;
}
.footer-menu ul li a {
	color: #000;
	transition: all 0.4s ease 0s;
}
.footer-menu ul li a i {
	margin-right: 5px;
}
.footer-widget.footer-menu ul li a:hover {
	color: #F66962;
}
.footer-contact-info {
	color: #26292c;
	margin-top: 20px;
}
.footer-contact-info img {
	margin-right: 12px;
}
.footer-contact-info .footer-address {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.footer-contact-info .footer-address span {
	margin-right: 20px;
}
.footer-contact-info .footer-address {
	margin-bottom: 20px;
}
.footer-contact-info .footer-address p {
	margin-bottom: 0px;
}
.footer-contact-info p {
	color: #000;
}
.footer-contact-info p i {
	margin-right: 15px;
}
.footer-bottom .copyright {
    padding: 40px 0;
}
.footer-bottom {
	font-size: 14px;
}
.footer-bottom .copyright-text p {
	color: #000000;
	text-align: right;
}
.footer-bottom .copyright-text p a:hover {
	color: #F66962;
}
.privacy-policy ul {
	display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.privacy-policy ul li {
	padding: 0px 10px;
	border-right: 1px solid #B2B1FC;
	list-style: none;
}
.privacy-policy ul li a {
	color: #000;
}
.privacy-policy ul li a:hover {
	color: #F66962;
}
.privacy-policy ul li:last-child {
	border-right: 0;
}
.privacy-policy ul li:first-child {
	padding-left: 0;
}
.news-letter .form-control {
	border: 0;
	padding: 0;
}
.news-letter form {
	border-bottom: 1px solid #e9ecef;
}
.footer-menu-two .footer-title {
	color: #392C7D;
}
.footer-contact-two .footer-title {
	color: #392C7D;
}
.footer-menu-two {
	font-weight: 500;
	font-size: 15px;
	color: #4F4F4F;
}
.footer-two .footer-contact-widget p {
	font-weight: 500;
	color: #4F4F4F;
}
.footer-two .privacy-policy ul li a {
	color: #292929;
}
.footer-two .privacy-policy ul li a:hover {
	color: #FF602E;
}

/*-----------------
	6. Home
-----------------------*/

.home-slide {
	position: relative;
    background-image: url("../img/banner.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
	min-height: 1000px;
}
.section-header.section-head-one .sub-title {
	color: #777777;
	font-size: 18px;
	font-weight: normal;
	max-width: 760px;
}
.section-header.section-head-one h2 {
	color: #0B0B0B;
}
.home-slide-text {
	line-height: 36px;
}
.home-slide-text h5 {
	color: #685F78;
	font-size: 20px;
	margin-bottom: 25px;
}
.home-slide-text h1 {
	font-weight: 700;
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 1.2;
    max-width: 500px;
}
.home-slide-text p {
    font-weight: 500;
    font-size: 18px;
    color: #685F78;
    margin-bottom: 48px;
}
.home-slide-text h1 span {
	color: #6255A4;
}
.girl-slide-img img {
	width: 100%;
}
.location-search .form-control {
	padding-right: 160px;
}
.home-slide .search-box .search-btn {
	width: 150px;
    -ms-flex: 0 0 150px;
    flex: 0 0 130px;
    border-radius: 5px;
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 0px;
}
.bg-search a {
	font-size: 14px;
    color: #fff;
}
.bg-search {
	background-color: #6255a4;
    border: 1px solid #6255a4;
	color: #fff;
}
.bg-search:hover {
	background-color: #54469f;
    border: 1px solid #54469f;
	color: #fff;
}
.home-slide-face .trust-user p {
    font-size: 20px;
    color: #685F78;
    font-weight: 500;
    margin: 30px 0px 10px;
}
.banner-content {
    margin-bottom: 25px;
}
.rate-head h2 {
	margin-right: 40px;
	color: #000000;
	font-size: 48px;
	font-weight: 700;
	margin-bottom: 0;
}
.trust-rating .rating {
	margin-bottom: 0;
}
.trust-rating .rating i {
	padding: 0px 9px;
}
.rating h2 {
	color: #000000;
	font-size: 48px;
	font-weight: 700;
	margin-bottom: 0;
}
.banner-content .form-inner {
    padding: 12px;
    background-color: #fff;
    border-radius: 35px;
    width: 100%;
	border: 0;
	max-width: 740px;
}
.banner-content .input-group {
	background: #fff;
    border-radius: 130px;
	padding-left: 12px;
}
.banner-content .input-group .search-icon {
	display: flex;
	align-items: center;
	color: #F66962;
}
.banner-content .input-group .form-control::placeholder {
	color: #a2a2a2;
}
.banner-content .input-group .input-group-prepend {
	margin-right: 0;
}
.banner-content .form-control {
	background: #fff;
    border-radius: 40px;
	border: 0;
	height: 44px;
	color: #000;
    font-size: 16px;
	margin-right: 10px;
}
.drop-detail .form-select { 
	border: 0;
	border-radius: 50px;
	background-color: #FFDEDA;
	font-size: 14px;
}
.drop-detail .form-select:focus {
	box-shadow: none;
}
.drop-detail {
    width: 174px;
	/* display: flex; */
	margin: 0px 17px;
	border-radius: 50px !important;
	border: 0;
	height: 44px;
}
.banner-content .select2-container {
	border-radius: 50px;
    background-color: #FFDEDA;
	z-index: unset;
}
.banner-content .select2-container .select2-selection--single .select2-selection__rendered {
	font-size: 14px;
	padding-left: 15px;
}
.banner-content .select2-container .select2-selection--single {
	border: 0;
    align-items: center;
    display: flex;
	background: transparent;
	height: 44px;
}
.banner-content .select2-container--default.select2-container--open.select2-container {
    border-radius: 3px;
}
.select2-dropdown {
	border: 1px solid #ffdeda;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color:  #22100D;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
	transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
	margin-top: -5px;
	margin-left: -11px;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #1B2559;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: 1px;
}
.select2-results__option {
	font-size: 14px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #FFDEDA;
	color:  #22100D;
	font-size: 14px;
}
.banner-content .select2-container--default .select2-selection--single .select2-selection__arrow {
	top: auto;
}
.banner-content .sub-btn {
	background: #f66962;
    border-radius: 50px !important;
    font-weight: bold;
    border: 1px solid #f66962;
    margin: 0px;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 15px;
    color: #fff;
    width: 44px;
}
.banner-content .sub-btn:hover, 
.banner-content .sub-btn:focus {
    background: #fc7f50;
    border-color: #fc7f50;
}
.banner-content .form-control.catecory-mentor {
	background: #FFDEDA;
	margin: 0px 10px;
	font-size: 14px;
	color: #22100D;
}
.section-header {
    margin-bottom: 18px;
    position: relative;
	display: flex;
}
.section-sub-head h2 {
    font-size: 36px;
    margin-bottom: 0;
    font-weight: 700;
	color: #0B0B0B;
	letter-spacing: 0.9px;
}
.section-sub-head span {
    color: #F66962;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 18px;
    display: block;
    letter-spacing: 0.9px;
}
.section-text {
    max-width: 700px;
    color: #685F78;
    font-size: 16px;
    font-weight: 500;
}
.all-btn {
	margin-left: auto;
}
.all-category .btn-primary {
	color: #392C7D;
	border: 3px solid #B4A7F5;
	backdrop-filter: blur(151.39px);
	border-radius: 46.9159px;
	background: transparent;
	border-radius: 46.9159px;
	min-width: 150px;
	padding: 10px 15px;
	font-weight: 500;
}
.all-category .btn-primary:hover {
	background-color: #917cf6;
    border-color: #917cf6;
    color: #fff;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #dedfe0;
}
.home-slide .rating i.filled {
    color: #FFB54A;
}
.online-course {
	border-radius: 20px;
	padding: 25px;
	position: relative;
	background: #fff;
}
.course-inner-content {
    margin-left: 20px;
}
.course-radius {
	border-radius: 20px;
	width: 100%;
	border: 1px solid #e9ecef;
}
.blur-border.course-radius {
	padding: 1px;
	border-radius: 20px;
}
.course-full-width {
	width: 100%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}
.course-full-width:hover {
	top: -10px;
	transition: top ease 0.5s;
}
.online-course h4 {
	margin-bottom: 0;
	font-size: 20px;
    font-weight: 600;
}
.course-inner-content h4 {
	margin-bottom: 8px;
	font-weight: 700;
	color: #000;
}
.course-inner-content p {
	color: #000000;
	font-size: 16px;
	margin-bottom: 0;
}
.student-course {
	position: relative;
	margin-top: -60px;
}
.how-it-works {
	background: #fff;
	padding: 80px 0;
	position: relative;
}
.feature-box {
    position: relative;
	z-index: 9;
	width: 100%;
	margin-bottom: 25px;
	background: #FFFFFF;
	border: 1px solid #e9ecef;
	box-shadow: -11.729px -11.729px 32px rgba(255, 255, 255, 0.15);
	border-radius: 10px;
}
.feature-bg {
    padding: 25px;
	width: 100%;
	border-radius: 10px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-bg:hover {
	background: #413655;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-bg:hover p,
.feature-bg:hover .feature-text {
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-bg p {
	color: #685F78;
	font-size: 14px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-header {
    margin-bottom: 10px;
}
.feature-icon {
    margin-right: 15px;
    font-size: 30px;
    width: 105px;
    border-radius: 100px;
    height: 105px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    color: #fff;
    margin: 0 auto;
}
.mentoring-course .owl-stage {
	padding-top: 55px;
}
.feature-heading {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}
.feature-text {
	font-size: 18px;
	font-weight: 700;
	padding-top: 25px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    max-width: 130px;
    margin: 0 auto 25px;
}
.feature-bg p {
	margin-bottom: 0px;
}
.mentoring-course {
	margin-top: 40px;
}
.owl-theme .owl-nav {
	display: none;
}
.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0px 5px;
    background: #FFDEDA !important;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.owl-theme .owl-dots .owl-dot.active span, 
.owl-theme .owl-dots .owl-dot:hover span {
    background: #F66962 !important;
	width: 50px;
    height: 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.owl-theme .owl-dots, .owl-theme .owl-nav {
	margin-top: 26px;
}
.new-course {
	padding: 80px 0px 56px;
	background-image: url(../img/banner.png);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
	position: relative;
}
.new-course:before {
	content: "";
	position: absolute;
	background-image: url(../img/course-bg.png);
	width: 100%;
	height: 100%;
	top: 0;
	background-repeat: no-repeat;
}
.course-feature {
	margin-top: 57px;
}
.product .rating i {
	font-size: 13px;
}
.course-box {
    margin-bottom: 25px;
	border-radius: 10px;
}
.product {
    border-radius: 10px;
    position: relative;
	padding: 20px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	background: #fff;
	backdrop-filter: blur(17px);
	-webkit-backdrop-filter: blur(17px);
}
.product:hover {
	background: #413655;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.product:hover .course-info p,
.product:hover .course-name p,
.product:hover .btn-primary,
.product:hover .course-name h4 a,
.product:hover .product-content h3 a,
.product:hover .course-share i {
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.product:hover .course-name h4 a:hover, .product:hover .product-content h3 a:hover {
	color: #F66962;
}
.product:hover .rating-img img,
.product:hover .course-view img {
	filter: invert(1) brightness(100);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.product-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 10px 10px 10px 10px;
}
.product-img img {
    width: 100%;
    border-radius: 4px 4px 0 0;
	transform: translateZ(0);
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.product-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.product-content {
	padding-top: 18px;
	display: inline-block;
	width: 100%;
}
.product-content h3 {
	font-size: 18px;
	color: #000000;
	font-weight: 500;
	line-height: 1.3;
}
.product-content h3 a:hover {
	color: #F66962;
}
.product-content .instructor-text {
	max-width: 320px;
}
.product .rating {
    margin-left: auto;
    margin-bottom: 15px;
    width: auto;
}
.course-info {
	margin: 16px 0px 18px;
}
.course-info .course-view img,
.course-info .rating-img img {
	width: 18px !important;
}
.course-info p {
	margin-bottom: 0;
	color: #685F78;
	font-size: 16px;
	margin-left: 12px;
}
.course-view {
	margin-left: auto;
}
.course-group {
	margin-bottom: 17px;
}
.course-group-img img {
	max-width: 50px;
	height: 50px;
    border-radius: 25px;
	margin-right: 10px;
}
.course-share {
	margin-left: auto;
}
.color-active {
	color: red;
	font-weight: 700;
}
.course-share i {
	color: #FF5364;
	font-size: 20px;
}
.course-share a:hover i {
	font-weight: 700;
}
.course-name h4 {
	font-size: 18px;
	margin-bottom: 5px;
	font-weight: 600;
}
.course-name p {
	margin-bottom: 0;
	font-size: 14px;
	color: #685F78;
}
.average-rating span {
	color: #FFB54A;
}
.master-skill {
	padding: 80px 0px 0px;
}
.blur-border {
	padding: 3px;
	border-radius: 10px;
}
.rotate-box {
	position: relative;
	transform: rotate(30deg);
}
.certified-group {
	border-radius: 20px;
	margin-bottom: 24px;
	position: relative;
    top: 0;
    transition: top ease 0.5s;
	width: 100%;
	border: 1px solid #e9ecef;
}
.certified-group:hover {
	top: -10px;
}
.certified-img  {
	background: #fff;
	border-radius: 10px;
	width: 72px;
	height: 72px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.get-certified {
	background: #fff;
	padding: 20px;
	border-radius: 20px;
}
.get-certified p {
	margin-bottom: 0;
	color: #685F78;
	font-size: 14px;
	margin-left: 24px;
	font-weight: 500;
}
.career-group {
	margin: 60px 0px ;
}
.trend-course {
	padding: 80px 0px;
	background-image: url(../img/bg-banner.png);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
}
.trending-course {
    position: relative;
	margin-top: 57px;
}
.trend-box {
	background: transparent;
	border: 1px solid #e9ecef;
	padding: 0;
}
.price {
	background: #fff;
    position: absolute;
    bottom: 20px;
	right: 20px;
	min-width: 149px;
	padding: 14px 15px;
	border-radius: 10px;
}
.price.combo {
	background: #fff;
    position: absolute;
    bottom: 20px;
	right: 20px;
	min-width: 149px;
	padding: 14px 15px;
	border-radius: 10px;
}
.price.combo {
	min-width: 80px;
    padding:12px 12px;
}
.price.combo h3 {
	color: #159F46;
	font-weight: 700;
	font-size: 24px;
}
.price h3 span { 
	text-decoration: line-through;
	font-size: 14px;
	color: #777777;
}
.price h3 {
	font-size: 24px;
	color: #F66962;
	font-weight: 700;
	margin-bottom: 0;
}
.feature-instructors {
	padding-top: 80px;
}
.feature-head {
	margin: auto;
}
.feature-head h2 {
	margin-bottom: 25px;
}
.instructors-widget {
	background: #FFFFFF;
    border: 1px solid #e9ecef;
    box-shadow: 0 5px 13px 6px rgb(212 211 254 / 23%);
    border-radius: 10px;
	margin-bottom: 24px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.instructors-course {
	margin-top: 57px;
}
.instructors-img {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: 10px 10px 0 0;
	padding-bottom: 0;
}
.instructors-img img {
	transform: translateZ(0);
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.instructors-widget:hover .instructors-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.instructors-content { 
	padding: 20px;
}
.instructors-content h5 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 0;
}
.instructors-content p {
	margin-bottom: 0;
	color: #685F78;
	font-size: 16px;
	margin: 16px 0px 18px;
}
.student-count i {
	display: flex;
	align-items: center;
	color: #FF5364;
	margin-right: 17px;
}
.student-count .yellow {
	display: flex;
	align-items: center;
	color: #FFB54A;
	margin-right: 17px;
}
.student-count .violet {
	display: flex;
	align-items: center;
	color: #392C7D;
	margin-right: 17px;
}
.student-count .orange {
	display: flex;
	align-items: center;
	color: #FF875A;
	margin-right: 17px;
}
.student-count span {
	color: #685F78;
	font-size: 14px;
}
.lead-companies {
	padding: 0px 0px 56px;
}
.lead-companies .owl-carousel .owl-stage {
	display: flex;
	display: -webkit-flex;
	-webkit-align-items: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
.owl-carousel .owl-item .lead-img img {
	width: auto;
}
.lead-group {
	padding: 30px 0px 24px;
}
.trust-lead .get-certified {
	padding: 20px;
}
.trust-lead .certified-img img {
	top: inherit;
	left: inherit;
}
.share-knowledge {
	padding: 60px 0px;
	background-image: url(../img/bg-banner-01.png);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
}
.join-mentor h2 {
	color: #1F1F1F;
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 0px;
}
.join-mentor p {
	font-size: 18px;
	color: #685F78;
	margin: 30px 0px;
}
.course-list {
	padding: 0;
	margin-bottom: 35px;
}
.course-list li {
	list-style: none;
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 20px;
}
.course-list li i {
	color: #F66962;
	margin-right: 15px;
}
.latest-blog {
	padding: 80px 0px 63px;
	background-image: url(../img/bg-banner-02.png);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
}
.blogs-slide {
	margin-top: 57px;
}
.blogs-slide .blog-widget {
	border: 1px solid #e9ecef;
	margin-bottom: 24px;
	padding: 0;
}
.blog-widget .instructors-img {
	padding: 0;
}
.enroll-group {
	background: linear-gradient(90deg, rgba(161, 196, 253, 0.79) 0%, rgba(194, 233, 251, 0.36) 100%);
	border: 1px solid rgba(255, 222, 218, 0.38);
	border-radius: 20px;
	padding: 30px;
	margin: 80px 0px;
}
.enroll-img {
	background: #fff;
	border-radius: 10px;
	padding: 10px;
}
.course-count {
	margin-left: 25px;
	color: #000000;
}
.course-count p {
	margin-bottom: 0;
	font-size: 18px;
}
.course-count h3 {
	font-weight: 700;
	font-size: 36px;
	margin-bottom: 15px;
}
.icon-group {
	padding-top: 17px;
}
.enroll-group .total-course {
	margin-bottom: 0px;
}
.icon-group .total-course {
	width: 70px;
	height: 70px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	margin-bottom: 24px;
}
.icon-group .total-course {
	position: relative;
    top: 0;
    transition: top ease 0.5s;
	cursor: pointer;
}
.icon-group .total-course:hover {
    top: -10px;
}
.total-course {
	margin-bottom: 24px;
}
.user-love {
	padding: 80px 0px;
	background-image: url(../img/user-love.jpg);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
	min-height: 500px;
}
.white-header span,
.white-header h2 {
	color: #fff;
}
.become-instructors {
	padding: 327px 0px 100px;
}
.student-mentor {
	background: #FFDEDA;
	padding: 30px;
	border-radius: 20px;
	color: #000000;
	position: relative;
}
.student-mentor h4 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 18px;
}
.top-instructors p {
	font-size: 18px;
	margin-bottom: 0;
	margin-bottom: 40px;
}
.yellow-mentor {
	background: #FFE88F;
}
.testimonial-four {
	background: #fff;
	position: relative;
}
.review {
	position: absolute;
	width: 100%;
	bottom: -240px;
    z-index: 25;
}
.mentor-testimonial {
	z-index: 9;
}
.testimonial-four .slick-list {
    border: 14px solid #ededed;
	max-width: 70%;
    margin: auto;
	border-radius: 45px;
	background: linear-gradient(142.13deg, rgba(255, 255, 255, 0.28) 1.8%, rgba(255, 255, 255, 0.31) 99.75%);
	backdrop-filter: blur(151.39px);
}
.testimonial-four .testimonial-two-head {
    max-width: 90%;
	border: 0;
	background:none;
}
.testimonial-bg-left img {
	-webkit-transform: translate(-50% , -50%);
    -ms-transform: translate(-50% , -50%);
    transform: translate(-50% , 50%);
    position: absolute;
    top: 50%;
    left: 174px;
}
.testimonial-bg-left, 
.testimonial-bg-right, 
.testimonial-bg-top {
	position: relative;
}
.testimonial-bg-right img{
	-webkit-transform: translate(-50% , -50%);
    -ms-transform: translate(-50% , -50%);
    transform: translate(-50% , 50%);
    position: absolute;
    top: 50%;
    right: -125px;
}
.testimonial-bg-top img {
	-webkit-transform: translate(-50% , -50%);
    -ms-transform: translate(-50% , -50%);
    transform: translate(-50% , -50%);
    position: absolute;
    top: 50px;
    left: 38%;
}
.testimonial-four .h-four {
	padding-bottom: 140px;
}
.testimonial-four .slick-slide img {
	transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
}
.fount-about-img img {
	margin: auto;
	border-radius: 50px;
	width: 80px;
}
.fount-about-img span {
	color: #6E6C83;
	font-size: 18px;
	font-weight: 300;
}
.fount-about-img h3 {
	font-size: 24px;
	color: #201F2E;
	margin: 17px 0px 10px;
}
.testimonial-four.review.testimonial-four-saying{
    margin-right: 25px;
}
.testimonial-four .testimonial-two-head p {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
	font-style: italic;
	margin: 37px 0px 43px;
    max-width: 695px;
    text-align: center;
}
.testi-quotes {
	position: relative;
}
.testi-quotes img {
	position: absolute;
	left: 205px;
    z-index: 99;
    top: -31px;
}
.testimonial-four .slick-prev, 
.testimonial-four .slick-next {
	top: 62%;
}
.testimonial-four .slick-prev {
	left: 20%;
	z-index: 99;
	background: #fff;
	width: 60px;
	height: 60px;
	border-radius: 0;
}
.testimonial-four .slick-next {
	right: 22%;
	background: #fff;
	width: 60px;
	height: 60px;
	border-radius: 0;
}
.testimonial-four .slick-prev:hover, 
.testimonial-four .slick-next:hover, 
.testimonial-four .swiper-button-prev:hover, 
.testimonial-four .swiper-button-next:hover {
    color: #fff;
	background: #f66962;
    opacity: 1;
}
.testimonial-four .slick-prev:hover:before, 
.testimonial-four .slick-prev:focus:before, 
.testimonial-four .slick-next:hover:before, 
.testimonial-four .slick-next:focus:before, 
.testimonial-four .swiper-button-prev:hover:before, 
.testimonial-four .swiper-button-next:hover:before {
    color: #fff;
    opacity: 1;
}
.testimonial-four .slick-next:focus, 
.testimonial-four .slick-prev:focus {
    background: #f66962;
}
.testimonial-four .slick-prev:before {
    font-family: "Font Awesome 5 Free";
    content:  "\f060";
	color: #392C7D;
	font-weight: 600;
}
[dir='rtl'] .testimonial-four .slick-prev:before {
    content: '→';
}
.testimonial-four .slick-next:before {
	font-family: "Font Awesome 5 Free";
    content:  "\f061";
	color: #392C7D;
	font-weight: 600;
}
[dir='rtl'] .testimonial-four .slick-next:before {
    content: '←';
}
.testimonial-all {
	padding: 40px;
	position: relative;
	background-image: linear-gradient(#57504d, #f2f3f6);
}
.testi-right {
	position: relative;
}
.testi-right img {
	position: absolute;
    right: -60px;
    top: 0px;
}
.four-testimonial-founder h3 {
	margin: 17px 0px 10px;
	font-size: 24px;
	font-weight: 700;
	color: #201F2E;
}
.four-testimonial-founder span {
	font-size: 18px;
	color: #685F78;
}
.mentor-img img {
	position: absolute;
    bottom: 0;
    right: 0;
}

/*-----------------
	7. Home Two Main
-----------------------*/

.home-two .add-header-bg {
	/* box-shadow: 0px 4px 15px hsl(220deg 23% 97%); */
    transition-duration: 0.4s;
    background: #fff;
    padding-top: 0;
    margin: 0;
}
.home-two .banner-content .input-group {
    background: #fff;
    padding-left: 0;
    align-items: center;
}
.home-two-slide {
    position: relative;
    background-image: url(../img/bg/home-left.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.home-two-slide .home-slide-face {
    padding: 230px 0 230px;
}
.home-two-slide .drop-detail {
    width: 170px;
    display: flex;
    margin: 0;
    border-radius: 50px !important;
    border: 0;
    height: 44px;
}
.home-two-slide .banner-content .form-inner {
    padding: 10px;
    background-color: #fff;
    border-radius: 35px;
    width: 100%;
    border: 0;
    max-width: 740px;
}
.home-two .banner-content .input-group>:not(:first-child) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #F4F4F4;
}
.home-two .sub-btn {
    background: #FF602E;
    border-radius: 5px !important;
    width: 221px;
    padding: 15px;
}
.home-two .sub-btn:hover {
    background: #392C7D;
    border: 1px solid #392C7D;
    border-radius: 5px !important;
}
.home-two .banner-content .form-inner {
    background: #FFFFFF;
    border: 1px solid #7A9EDD;
    box-shadow: 0px 4px 34px rgb(196 196 196 / 25%);
    border-radius: 10px;
}
.home-two .home-slide-text h1 {
	color: #324FA2;
    max-width: 817px
}
.home-two .home-slide-text h5 {
    font-style: italic;
    font-weight: 600;
    color: #FF602E;
}
.home-two .trust-user-two p {
    color: #324FA2;
    font-weight: 500;
    font-size: 16px;
}
.trust-user-two .rating-two {
    color: #21B477;
	font-size: 15px;
	font-weight: 700;
}
.trust-user-two .rating-two i {
	font-size: 14px;
}
.home-two .banner-content .form-inner {
    max-width: 856px;
    width: 100%;
}
.home-two .banner-content .select2-container 
.select2-selection--single {
    border: 0;
    align-items: center;
    display: flex;
    background: #ffffff;
    height: 44px;
    border-right: 1px solid #f5f5f5;
    border-radius: 0;
}
.home-two .trust-user p {
    margin: 30px 0px 10px;
    font-weight: 500;
    font-size: 16px;
    color: #324FA2;
}
.home-two .shapes-one {
    position: absolute;
    right: -12rem;
    top: 0;
}
.home-two .shapes-two {
    position: absolute;
    right: 7rem;
    bottom: 0;
    top: 26rem;
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.home-two .shapes-four {
    position: absolute;
	width: 220px;
    left: -5rem;
    bottom: -1rem;
}
.home-two .shapes-middle {
    position: absolute;
    left: 50rem;
    top: 18rem;
    bottom: 0;
}
.about-section {
	padding: 60px 0 60px;
}
.home-two .trust-user .rating .filled {
    color: #21B477;
	font-size: 16px;
}
.home-two .trust-user .rating span {
    color: #21B477;
	font-size: 16px;
}
.text-navy {
	color: #324FA2 !important;
}
.login-head {
	border: 1px solid #392C7D;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #392C7D;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    min-width: 165px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.login-head:hover {
	background-color: #ffffff;
	border-color: #392C7D;
    color: #392C7D !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.signin-head {
    border: 1px solid #FF602E;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #FF602E;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    min-width: 165px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.signin-head:hover {
	background-color: #ffffff;
	border-color: #FF602E;
    color: #FF602E !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.discover-btn:hover {
    background-color: #ffffff;
	border-color: #392C7D;
    color: #392C7D !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.about-section .tagline {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    color: #FF602E;
}
.home-two .about-section li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0;
}
.home-two .about-section li .list-icon {
    text-transform: capitalize;
}
.home-two .about-section li .list-icon img {
    width: 20px;
    margin-right: 10px;
}
.discover-btn {
    font-size: 16px;
    display: inline-block;
    border: 1px solid #FF602E;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #FF602E;
    border-radius: 5px;
    text-align: center;
    padding: 12px 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.about-section .stylist-gallery .image-1 {
    position: relative;
}
.about-section .stylist-gallery .single-image {
    margin-bottom: 30px;
}
.about-count .course-img img {
    width: 80px;
}
.about-count {
    text-align: center;
}
.about-image.count-one h4 {
    color: #21B477;
    margin-top: 20px;
}
.about-image.count-two h4 {
    color: #FF602E;
    margin-top: 20px;
}
.about-image.count-three h4 {
    color: #FFB500;
    margin-top: 20px;
}
.about-image.count-four h4 {
    color: #392C7D;
    margin-top: 20px;
}
.count-content-three p {
    color: #5C5C5C;
    font-weight: 500;
    font-size: 20px;
}
.count-content-three h4 {
    font-size: 28px;
    font-weight: 700;
}
.header-two-title {
    margin-bottom: 50px;
}
.header-two-title .tagline {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    color: #FF602E;
}
.header-two-title h2 {
    margin-bottom: 24px;
    font-weight: 700;
    color: #4F4F4F;
}
.header-two-title p {
    font-weight: 400;
    color: #5C5C5C;
}
.home-two .header-two-text {
    max-width: 700px;
    color: #685F78;
    font-size: 16px;
    font-weight: 500;
	margin: auto;
}
.home-two .about-image {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    height: 300px;
    -webkit-transition: all 1.5s;
    transition: all 1.5s;
    margin-bottom: 24px;
}
.home-two .about-image:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    background: #fefefe;
    box-shadow: none;
}
.about-section .count-four {
    margin-top: -30px;
}
.about-section .count-two {
    margin-top: -33px;
}
.patter-one-main .patter-one {
    position: absolute;
    left: -5rem;
    bottom: 18rem;
}
.topcategory-sec {
    padding: 60px 0px 60px;
    background: #FBFCFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.topcategory-sec .categories-item {
    background: #FFFFFF; 
    border: 1px solid #E0EBFF;
	box-shadow: 0px 4px 34px #E0EBFF;
    padding: 30px;
	margin-bottom: 24px;
    border-radius: 10px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.topcategory-sec .categories-item p {
    margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.topcategory-sec .categories-content h3 {
    font-weight: 700;
    font-size: 20px;
    color: #392C7D;
    margin-top: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.topcategory-sec .categories-icon {
    margin-bottom: 20px;
}
.home-two .categories-icon img {
    width: 53px;
}
.top-category-group {
    margin-bottom: 42px;
}
.categories-item:hover{
    background: #413655;
    cursor: pointer;
    border: 1px solid #413655;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.categories-item:hover .categories-content h3 {
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.categories-item:hover .categories-content p{
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.home-two .about-section .about-text {
    margin-bottom: 50px;
}
.price-text {
    background: #21B477;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    display: flex;
    left: 15px;
    justify-content: center;
    min-width: 123px;
    padding: 8px 15px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.price-text h4 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 0;
}
.featured-details {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    margin-bottom: 25px;
}
.course-price h3 {
    font-weight: 700;
    font-size: 20px;
    color: #21B477;
    margin-bottom: 0;
    -webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.course-price h3 span {
    text-decoration: line-through;
    color: #FF602E;
    line-height: 0;
    margin-left: 4px;
}
.featured-courses-sec {
    padding: 60px 0px 60px;
    position: relative;
}
.featured-courses-two {
    margin-bottom: 50px;
}
.featured-info {
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.featured-details .image-info img {
    width: 60px;
    height: 60px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    z-index: 99999;
}
.featured-details-two .image-info img {
    width: 60px;
    height: 60px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
}
.featured-details-two {
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
	margin-bottom: 24px;
	width: 100%;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover {
    background: #392C7D;
    border: 1px solid #392C7D;
    box-shadow: 0px 4px 34px #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-details-content span {
    font-weight: 500;
    font-size: 14px;
    color: #5C5C5C;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-details-content span {
    font-weight: 400;
    font-size: 14px;
    color: #A5A5A5;
    line-height: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover .course-details-content span {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover .course-details-content p {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover .hours-time-two {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.product-img-two img {
    width: 100%;
    border-radius: 4px 4px 0 0;
	transform: translateZ(0);
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.featured-details-two:hover .featured-info-two span {
    color: #FF602E !important;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover .product-img-two img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.product-img-two {
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 10px 10px;
}
.course-details-content .image-info {
    position: absolute;
    top: -2.5rem;
    right: 1.5rem;
}
.featured-details-two .product--two-img {
	z-index: -1;
}
.course-details-content {
    position: relative;
    padding: 20px 10px 10px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two .name-text h3 a {
    font-weight: 700;
    font-size: 20px;
    color: #324FA2;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two:hover .name-text h3 a {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details-two .name-text {
    font-size: 14px;
}
.featured-details:hover .course-details-content h3 a {
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details:hover .course-details-content span {
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.featured-details:hover .course-details-content span {
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.growup-section {
    padding: 80px 0 0px;
	background-image: url(../img/bg/bg-3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    position: relative;
	width: 100%;
}
.growup-skills-img {
    text-align: center;
}
.home-two-shapes {
    position: absolute;
    right: 1rem;
    bottom: 8rem;
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.growup-section .growup-images {
    position: relative;
    padding: 0 70px 75px 0;
}
.growup-section .growup-left-img img {
    border: 6px solid #FFFFFF;
    border-radius: 10px;
	max-width: 340px;
}
.growup-section .growup-right-img img {
    border: 6px solid #FFFFFF;
    max-width: 340px;
}
.growup-section .growup-right-img {
    position: absolute;
    bottom: 0;
    top: 15rem;
    right: 10rem;
    filter: drop-shadow(0px 4px 14px rgba(202, 202, 202, 0.25));
    border-radius: 10px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.growup-left-img {
    display: flex;
    filter: drop-shadow(0px 4px 14px rgba(202, 202, 202, 0.25));
}
.growup-section .section-sub-head {
    margin-top: 100px;
}
.growup-section .feature-head h2 {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 32px;
    color: #324FA2;
}
.master-skills-sec {
    padding: 60px 0 60px;
}
.master-skills-sec .join-mentor {
    margin-top: 50px;
	padding: 50px;
	text-align: right;
}
.master-skills-info {
    margin-bottom: 60px;
}
.award-winning {
    margin-top: 70px;
}
.master-skills-sec .join-mentor h2 {
    font-weight: 700;
    font-size: 36px !important;
    text-align: right;
    color: #5C5C5C !important;
}
.winning-img img {
    border-radius: 10px;
}
.home-two .join-title-one h2 {
    font-weight: 700;
    font-size: 36px;
    text-align: right;
    margin-bottom: 25px;
    color: #5C5C5C;
}
.home-two .join-title-one {
    max-width: 515px;
}
.winning-two-one img {
    max-width: 636px;
}
.winning-two-two img {
    max-width: 636px;
    margin: auto;
}
.winning-two-three img {
    max-width: 636px;
}
.join-title-middle {
    max-width: 480px;
    margin: auto;
}
.home-two .join-title-one p {
    font-weight: 400;
	font-size: 16px;
	text-align: right;
	color: #292929;
	margin-bottom: 0;
}
.home-two .join-title-middle h2 {
    font-weight: 700;
    font-size: 36px;
    text-align: left;
    margin-bottom: 25px;
    color: #5C5C5C;
}
.home-two .join-title-middle p {
    font-weight: 400;
	font-size: 16px;
	text-align: left;
	color: #292929;
	margin-bottom: 0;
}
.pattern-layer-two{
    position: absolute;
    left: 31rem;
    top: -3.5rem;
    z-index: -1;
    width: 188px;
    height: 188px;
    background-repeat: no-repeat;
}
.pattern-layer-three {
    position: absolute;
    left: -5rem;
    top: -5.2rem;
    z-index: -1;
}
.join-mentor-img {
    position: relative;
    margin-bottom: 50px;
}
.joing-icon-award .joing-icon-one {
    position: absolute;
    bottom: 50px;
    left: -50px;
}
.joing-icon-award img {
    width: 100px;
    height: 100px;
}
.joing-icon-award .joing-icon-two {
    position: absolute;
    bottom: 75px;
    right: -35px;
}
.joing-icon-award .joing-icon-three {
    position: absolute;
    bottom: 50px;
    left: -40px;
}
.master-skills-sec .pattern-imgone {
    background: #FFB500;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 15px;
    display: inline-block;
    justify-content: center;
}
.master-skills-sec .pattern-icon-one {
    position: absolute;
    margin-top: -9rem;
    margin-left: -3rem;
}
.master-skills-sec .pattern-imgtwo {
    background: #21B477;
	border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 15px;
    display: inline-block;
    justify-content: center;
}
.master-skills-sec .pattern-icon-two {
    position: absolute;
    margin-top: -8rem;
    margin-left: 37rem;
}
.master-skills-sec .pattern-imgthree {
    background: #FF602E;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 15px;
    display: inline-block;
    justify-content: center;
}
.master-skills-sec .pattern-icon-three {
    position: absolute;
    margin-top: -9rem;
    margin-left: -3rem;
}
.home-two .testimonial-sec {
    background-image: url(../img/bg/map.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 60px 0 60px;
    width: 100%;
}
.home-two .testimonial-two-img {
    padding: 50px;
}
.trending-course-sec .feature-head {
    margin-bottom: 70px;
}
.testimonial-sec .testimonial-head-two h6 {
	color: #5C5C5C !important;
}
.testimonial-head-two h2 {
    font-size: 32px;
    color: #4F4F4F;
    font-weight: 700;
	margin-bottom: 25px;
}
.testimonial-head-two span {
    color: #FF602E;
}
.course-count-two h4 {
	font-weight: 500;
	color: #000;
}
.course-count-two {
    margin-left: 0 !important;
}
.course-count-two h5 {
    font-weight: 700;
    color: #5C5C5C;
    text-transform: uppercase;
    margin-bottom: 0;
}
.course-count-two p {
	color: #000000;
	font-size: 16px;
	margin-bottom: 0;
}
.course-details-content .filled {
    font-size: 12px;
}
.course-details-content p {
    font-size: 14px;
	margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.new-course-bg {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
	margin-top: 20px;
}
.new-course-bg:hover {
    background: #e0ebff2e;
    -webkit-transform: translateY(-5px);
    transform: translateY(-10px );
}
.home-two .trending-course-sec {
    padding: 60px 0 60px;
}
.home-two .trending-course-main {
    margin-bottom: 40px;
}
.trending-course-sec .trending-list {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    margin-top: 20px;
}
.trending-two-rating {
    margin-bottom: 10px;
}
.time-hours-two {
    display: flex;
    align-items: center;
}
.time-hours-two {
    display: flex;
    align-items: center;
}
.trending-two-rating span {
    font-weight: 400;
    font-size: 14px;
    color: #A5A5A5;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-courses-group:hover .trending-two-rating span {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-two-rating i {
    color: #FFB54A;
    font-size: 10px;
}
.featured-info-two h3 a {
    font-weight: 700;
    font-size: 20px;
    color: #324FA2;
    display: flex;
	margin-bottom: 10px;
}
.time-hours-two p {
    margin-bottom: 0;
    font-size: 14px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.time-hours-two span i {
    color: #22100D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.rating-price-two p {
    font-weight: 500;
    font-size: 14px;
    color: #FF602E;
	margin-bottom: 0;
}
.course-price-two h3 {
    font-weight: 700;
    font-size: 20px;
    color: #21B477;
	margin-bottom: 0;
}
.course-column-img {
    -ms-flex: 35%;
    flex: 35%;
    max-width: 35%;
}
.course-content-column {
    -ms-flex: 65%;
    flex: 65%;
    max-width: 65%;
}
.course-img-two {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.course-img-two img {    
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 5px;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.trending-courses-group:hover .product-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.trending-courses-group:hover {
    background: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-courses-group:hover .time-hours-two p  {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-courses-group:hover .time-hours-two span i {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-courses-group:hover .featured-info-two h3 a {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-content-column {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
}
.trending-courses-group {
    margin-bottom: 24px;
    padding: 8px;
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-courses-group:hover .course-img-two img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.trending-courses-two {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.trending-course-sec .trending-list .product-img {
    z-index: 1;
    max-width: 190px;
}
.trending-price {
    background: #21B477;
    border-radius: 4px;
    position: absolute;
    top: 15px;
    text-align: center;
    left: 15px;
    min-width: 75px;
    padding: 5px 15px;
}
.trending-price h4 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 0;
}
.trending-course-sec .trending-list:hover {
    background: #392C7D;
	cursor: pointer;
}
.trending-course-sec .course-details-content {
    position: relative;
    margin-top: 0;
    padding: 10px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.featured-info-two {
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 16px;
    padding-bottom: 16px !important;
}
.hours-time-two {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #5C5C5C;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.feature-instructors-sec .featured-border {
    position: absolute;
    top: 10px;
    left: 10px;
}
.feature-instructors-sec .featured-img {
    background: #fff;
	box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.25);
    border-radius: 50%;
    padding: 10px;
    align-items: center;
    display: flex;
    text-align: center;
}
.feature-instructors-sec .featured-img img {
    width: 20px;
	height: 20px;
}
.feature-instructors-sec .instructors-widget p {
    font-weight: 400;
    font-size: 16px;
    color: #324FA2;
    margin: 16px 0px 0px;
}
.feature-instructors-sec .course-info {
    border-bottom: 1px solid #E0EBFF;
	padding: 0;
    margin: 0;
}
.feature-instructors-sec .student-count {
    padding: 20px 0 0;
}
.feature-instructors-sec .student-count i {
    color: #FF602E;
}
.feature-instructors-sec .student-count span {
    color: #FF602E;
}
.course-details-two {
    position: relative;
    padding: 0 20px 20px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.knowledge-sec {
    width: 100%;
    background: #fbfcff;
}
.knowledge-sec .joing-content {
    margin-left: 20px;
}
.knowledge-sec .joing-group {
    padding: 30px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.joing-section-text {
    font-weight: 400;
    font-size: 16px;
    color: #292929;
    margin-bottom: 47px;
}
.knowledge-sec .section-title h2 {
    font-weight: 700;
    font-size: 32px;
    color: #5C5C5C;
    margin-bottom: 25px;
    max-width: 520px;
}
.knowledge-sec .featured-img-1 {
    width: 100%;
    float: left;
    /* background-image: url(../img/joing-us.png); */
    height: 777px;
    background-size: cover;
    background-position: center center;
}
.knowledge-sec .joing-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.knowledge-sec .joing-list .joing-header {
    display: flex;
    display: -ms-flexbox;
    margin-bottom: 47px;
}
.knowledge-sec .joing-list .joing-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
	padding: 0px;
    width: 70px;
    height: 70px;
}
.knowledge-sec .joing-list .joing-title {
    font-weight: 700;
    font-size: 16px;
    color: #5C5C5C;
}
.joing-list .joing-para p {
    font-weight: 400;
    font-size: 14px;
    color: #292929;
	margin-bottom: 0;
}
.feature-instructors-sec {
    background: #ffffff;
    padding: 60px 0 60px;
}
.feature-instructors-sec .section-header {
    margin-bottom: 70px;
}
.real-reviews-sec {
    background: #FBFCFF;
    padding: 60px 0 60px;
}
.featured-instructor-two {
    margin-bottom: 50px;
}
.pattern-review {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 0px;
    width: 70px;
    height: 70px;
}
.home-two-theme 
.section-sub-head span {
    color: #FF602E;
    font-weight: 600;
    font-size: 20px;
    font-style: italic;
    padding-bottom: 10px;
    display: block;
    letter-spacing: 0.9px;
}
.home-two-theme .tagline {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    color: #FF602E;
}
.home-two-theme .feature-head h2 {
    margin-bottom: 10px;
	font-size: 32px;
	color: #4F4F4F;
}
.growup-section .section-sub-head h2 {
    color: #324FA2;
}
.real-reviews-group {
    padding: 70px 0 70px;
}
/* owl carousel */

.slide-nav-8 button.owl-next,
.slide-nav-8 button.owl-prev {
	width: 44px;
	height: 44px;
	background: transparent;
	text-shadow: none;
	top: 0px;
	margin-top: 0;
	cursor: pointer;
	border: 0;
	color: #392C7D;
	margin-bottom: 0px;
	border-radius: 50px;
	transform: rotate(0deg);
	font-size: 24px;
}
.slide-nav-8 button.owl-next:hover,
.slide-nav-8 button.owl-prev:hover {
	color: #FF602E;
}
.real-reviews .owl-nav button.owl-next span,
.real-reviews .owl-nav button.owl-prev span {
	font-size: 34px;
	line-height: 25px;
	transform: rotate(0deg);
	display: flex;
	padding-left: 6px;
	padding-bottom: 3px;
}
.real-reviews .owl-nav button.owl-next {
	margin-left: 10px;
}
.real-reviews-grid .review-content h3 {
    font-size: 36px;
    color: #292929;
    font-weight: 500;
    margin-bottom: 25px;
}
.real-reviews-grid .review-info h3 {
    font-weight: 700;
    font-size: 18px;
    color: #292929;
}
.real-reviews-grid .review-info h5 {
	font-weight: 400;
	font-size: 14px;
	color: #5C5C5C;
}
.real-reviews-grid .review-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #5C5C5C;
}
.award-winning-two {
    margin-bottom: 50px;
}
.latest-blog-sec {
    background: #ffffff;
    padding: 60px 0 60px;
}
.latest-blog-widget 
.latest-blog-img img {
    border-radius: 5px;
}
.latest-blog-widget 
.latest-blog-content .span-name {
    background: #21B477;
    border-radius: 4px;
    color: #ffffff;
    padding: 6px;
}
.latest-blog-widget {
    position: relative;
}
.event-blog-main .latest-blog-img img {
    border-radius: 5px;
	width: 100%;
}
.event-blog-main {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}
.blog-img-three img {
    width: 100%;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-img-three {
    position: relative;
    overflow: hidden;
}
.blog-img-three:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.latest-blog-img:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.105) 0%, rgba(0, 0, 0, 0.7) 100%);
    content: "";
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.latest-blog-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 20px 20px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-student-count {
    color: #ffffff;
}
.latest-blog-img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}
.event-blog-main:hover .latest-blog-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.latest-blog-img img {
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.event-blog-main:hover .event-content-title h5 a {
	color: #FF602E;
}
.footer-two {
    background: linear-gradient(180.9deg, #FBFCFF 7.44%, #F3EDFF 115.77%);
}
.footer-two .footer-menu ul li a:before {
    display: block;
    content: "\f054";
    float: left;
    margin-right: 10px;
    color: #292929;
    transition: all 0.5s ease-in-out;
    font-family: 'FontAwesome';
}
.footer-two .footer-top .social-icon ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-two .social-icon ul li {
    margin-right: 15px;
}
.footer-two .social-icon ul li a {
    color: #392C7D;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    width: 35px;
    height: 35px;
    background: transparent;
    border: 1px solid #392C7D;
    border-radius: 50%;
}
.footer-two .social-icon ul li a:hover {
    color: #ffffff;
    background: #392C7D;
}
.footer-two .footer-bottom .copyright-text p {
    color: #292929;
    text-align: left;
	font-weight: 400;
	font-size: 14px;
}
.footer-two .social-icon {
    margin-top: 20px;
}
.footer-two .footer-contact-widget h6 {
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
}
.footer-two .privacy-policy {
    text-align: right;
}
.footer-two .footer-bottom .copyright {
    border-top: 1px solid #E0EBFF !important;
	padding: 30px 0 !important
}
.footer-two .footer-top {
    padding: 60px 0 60px !important;
}

/*-----------------
	8. Home Three Main
-----------------------*/

.header-nav-three {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	border: 0;
	border-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    position: relative;
	height: 70px;
	padding: 0 20px;
	margin-bottom: 0;
	transition-duration: 0.4s;
}
.header-page .header-nav-three { 
	background-color: #FFF;
}
.header-three .main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.header-three .main-nav > li.active > a {
	color: #7B1FFE;
    font-weight: 600;
}
.header-three .main-nav li .submenu a:hover {
    color: #7B1FFE;
    letter-spacing: 0.5px;
    padding-left: 20px;
}
.header-three .main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #26292c;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 1px solid #f0f0f0;
}
.header-three .main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.header-three .main-nav > li .submenu li {
    position: relative;
}
.header-three .main-nav li a {
	display: block;
    font-size: 15px;
    font-weight: 500;
	color: #000;
}
.header-three .main-nav li a:hover {
	color: #7B1FFE;
}
.header-three .main-nav li.login-link {
	display: none;
}
.logo {
    display: inline-block;
    margin-right: 30px;
    width: 160px;
}
.header-navbar-rht {
    margin: 0;
	margin-left: 0;
	padding: 0;
}
.header-navbar-rht li:last-child {
	padding-right:0px;
}
.header-navbar-rht li .dropdown-menu {
	border: 0;
	box-shadow: 0px 0px 28px 15px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
}
.header-navbar-rht li .dropdown-menu {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	display: block;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
}
.header-navbar-rht .dropdown-toggle::after {
	display: none;
}
.header-navbar-rht li .dropdown-menu .dropdown-item {
	border-top: 1px solid transparent;
	padding: 10px 15px;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
	border-top: 0;
	border-radius: 5px 5px 0 0;
}
.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
	border-radius: 0 0 5px 5px;
}
.header-navbar-rht li a.header-login:hover {
    background-color: #f6697b;
    border-color: #f6697b;
    color: #fff;
}
.header-navbar-rht li a.header-login {
    border-radius: 50px;
    text-align: center;
    font-size: 16px;
    color: #392C7D;
    text-transform: capitalize;
    font-weight: 500;
	min-width: 150px;
	border: 3px solid #B4A7F5;
	padding: 10px 15px;
}
.header-navbar-rht li a.header-sign {
	min-width: 150px;
	padding: 14px 15px;
	background: rgb(241 241 241 / 42%);
	border-radius: 46.9159px;
	text-align: center;
}
.header-navbar-rht li a.header-sign:hover {
	background-color: #f6697b;
    border-color: #f6697b;
    color: #fff;
}
.add-header-bg .header-navbar-rht li a.header-sign {
	background: #b4a7f5;
	border: 3px solid #B4A7F5;
	color: #fff;
	padding: 10px 15px;
}
.add-header-bg .header-navbar-rht li a.header-sign:hover {
	background: #fff;
	color: #22100D;
	border: 3px solid #B4A7F5;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #757575;
	border-right: 2px solid #757575;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
	margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	line-height: 58px;
	padding: 0 15px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
    display: inline-block;
    position: relative;
}
.user-img > img {
	height: 50px;
    object-fit: cover;
    width: 50px;
    border-radius: 50%;
    border: 3px solid #E0E0E0;
}
.user-header .avatar img {
    width: 50px;
    height: 50px;
	border: 3px solid #E0E0E0;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.header-navbar-rht .dropdown-menu {
    min-width: 200px;
    padding: 0;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover, 
.header-navbar-rht .dropdown-menu .dropdown-item:hover i {
    color: #FF875A;
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
	font-size: 15px;
    margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 14px;
	line-height: 70px;
	padding: 0 10px;
}
.header-fixed {
    left: 0;
    /* position: fixed; */
    right: 0;
    top: 0;
	z-index: 999;
}
.header {
	background-color: #fff;
}
.add-header-bg {
	/* box-shadow: 0px 4px 15px rgb(0 0 0 / 8%); */
    transition-duration: 0.4s;
	background: #fff;
	padding-top: 0;
	margin: 0;
}
.users .dropdown-item i {
	color: #FF875A;
}
.dropdown-item.night-mode {
	justify-content: space-between;
}
.dropdown-item.night-mode i {
	color: #685F78;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.notifications .noti-content {
    height: 300px;
    width: 420px;
    overflow-y: auto;
    padding-right: 0;
    position: relative;
}
.notifications .noti-content::-webkit-scrollbar {
	width: 6px;
    background: #fff;
	height: 10px;
}
.notifications .noti-content::-webkit-scrollbar-track {
	border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb {
	background: #8A8A95; 
	border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb:hover {
	background: #1B2559; 
}
.notifications .media .avatar > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li {
	margin-top: 0;
	border-bottom: 1px solid #E4E8EE;
	box-shadow: 0px 1px 0px #E4E8EE;
	display: block;
	padding: 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li .list-item {
	border: 0;
	padding: 0;
	position: relative;
}
.notifications .notification-message h6 {
	font-weight: 600;
	font-size: 14px;
	color: #1A1F36;
	margin-bottom: 15px;
}
.notifications .notification-message h6 span {
	font-weight: 500;
}
.notifications .notification-message p {
	font-weight: 500;
	font-size: 14px;
	color: #565758;
	margin-bottom: 0;
}
.notifications .notification-message .btn {
	font-weight: 500;
	font-size: 14px;
	padding: 4px 8px;
	margin-bottom: 8px;
	border-radius: 4px;
}
.notifications .notification-message .noti-details {
	color: #1A1F36;
	position: relative;
	border-left: 4px solid #DDDEE1;
	padding-left: 8px;
	margin-bottom: 8px;
}
.notifications .notification-message .btn-accept {
	background: #E95744;
	border: 1px solid #E95744;
	color: #FFF;
}
.notifications .notification-message .btn-reject {
	background: #FFFFFF;
	border: 1px solid #DDDEE1;
	color: #3C4257;
}
.topnav-dropdown-header .notification-title {
	color: #1A1F36;
	display: block;
	float: left;
	font-size: 14px;
	font-weight: 500;
}
.topnav-dropdown-header .clear-noti {
	color: #1A1F36;
	float: right;
	font-size: 14px;
	font-weight: 500;
}
.topnav-dropdown-header .clear-noti i {	
    font-weight: 500;
}
.notification-title select {
	background: #515669;
	border-radius: 4px;
    color: #FFF;
}
.notification-title select:focus-visible {
	outline: 0;
}
.wishes-list {
	padding: 15px !important;	
	font-family: 'Poppins', sans-serif;
}
.wish-header a {
	font-size: 12px;
	text-decoration: underline;
}
.wish-nav .wishes-list {
	padding-right: 0 !important;
}
.wish-nav .wish-content ul li {
	margin-right: 15px;
}
.wish-content {
    height: 350px;
    width: 415px;
    overflow-y: auto;
    position: relative;
    padding-right: 0;
}
.wish-content::-webkit-scrollbar {
	width: 6px;
    background: #fff;
	height: 10px;
}
.wish-content::-webkit-scrollbar-track {
	border-radius: 4px;
}
.wish-content::-webkit-scrollbar-thumb {
	background: #8A8A95; 
	border-radius: 4px;
}
.wish-content::-webkit-scrollbar-thumb:hover {
	background: #1B2559; 
}
.wish-content .media .avatar  img {
    width: 84px;
	height: 63px;
    border-radius: 5px;
    margin-right: 10px;
}
.wish-content ul {
	list-style: none;
	padding: 0;
	margin: 0 0 20px;
}
.wish-content ul li {
	padding: 15px 0;
	border-bottom: 1px solid #DFDFE0;
}
.wish-nav .wish-content ul {
	margin: 0;
}
.wish-nav .wish-content ul li:first-child {
	padding-top: 0;
}
.wish-nav .wish-content ul li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}
.wish-content ul li .media {
	display: flex;
	justify-content: space-between;
}
.wish-content h6 {
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	margin-bottom: 2px;	
}
.wish-content h6 a {
	color: #000;
	font-size: 14px;
}
.wish-content h6 a:hover {
	color: #FF5364;
}
.wish-content .media-body {
	overflow: hidden;
}
.media-wide {
	width: 75%;
}
.wish-content p {
	color: #685F78;
	font-size: 12px;
	margin-bottom: 2px;
}
.wish-content h5 {
	color: #F66962;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 0;
}
.wish-content h5 span {
	color: #777777;
	font-size: 10px;
	font-weight: 400;
	margin-bottom: 0;
}
.wish-nav .remove-btn a {
	margin-top: 10px;
}
.remove-btn a {
	background: #FFFFFF;
	border: 1px solid #FF5364;
	padding: 5px 10px;
	border-radius: 0;
	font-weight: 500;
	font-size: 12px;
}
.remove-btn a:hover {
	background: #FF5364;
	color: #FFF;
}
.total-item {
	text-align: right;
}
.total-item h6 {
	font-size: 14px;
	color: #000000;
	margin-bottom: 10px;
}
.total-item h5 {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}
.night-mode .check-on .form-check-input {
    min-height: 20px;
    width: 38px;
}
.night-mode .form-switch .form-check-input:focus {
	 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.night-mode .form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff5364'/%3e%3c/svg%3e") !important;
}
.night-mode .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
	background-color: #E0E0E0;
	border-color: #E0E0E0;
}

/*-----------------
	8. Home Three
-----------------------*/

.home-three .header-navbar-rht > li {
    padding-right: 6px;
}
.home-three .banner-three-content .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 0;
    background-image: url(../img/icon-three/building.svg);
    background-repeat: no-repeat;
    background-position: left 0 center;
    padding-left: 40px;
    align-items: stretch;
    width: 100%;
}
.home-three .bar-icon span {
    background-color: #7B1FFE;
}
.badge-green {
    background: #0DD3A3;
}
.badge-yellow {
    background: #FED700;
}
.text-green {
    color: #0DD3A3;
}
.text-yellow {
    color: #FED700;
}
.text-info {
    color: #1CBEEF;
}
.main-nav .has-submenu-three.active > a {
    color: #232158;
}
.home-three-slide {
    position: relative;
    /* background-image: url(../img/slider/home-three-banner.jpg); */
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}
.home-three .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #7B1FFE;
    color: #ffffff;
    font-size: 14px;
}
.home-three .select2-dropdown {
    border: 1px solid #e5e5e5;
}
.home-three-slide-face {
    padding: 189px 0 189px;
}
.home-three-slide-text h1 {
    font-weight: 700;
    font-size: 36px;
	letter-spacing: 0.02em;
}
.home-three-slide-face h1 span {
    color: #7B1FFE;
}
.home-three .drop-detail-three {
    width: 164px;
    display: flex;
    margin: 0px 17px;
    border-radius: 50px !important;
    border: 0;
    height: 44px;
}
.home-three-slide-text h5 {
    background: #7B1FFE;
    border-radius: 30px;
    display: inline-flex;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #FFFFFF;
	margin-bottom: 15px;
}
.home-three-slide-text p {
    margin-bottom: 23px;
}
.home-three-slide .girl-slide-img img {
    width: 500px;
    margin-top: 104px;
}
.home-three .header-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    position: relative;
    height: 85px;
    padding: 0 30px;
	margin-top: 0;
    margin-bottom: 0;
}

.home-three header input {
	outline: none;
}
.home-three header input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
}
.home-three header input::-webkit-search-decoration,
.home-three header input::-webkit-search-cancel-button {
	display: none; 
}
.home-three header input[type=search] {
    padding: 10px 10px 10px 31px;
    width: 0;
    background: url(../img/icon-three/search-icon.svg) no-repeat 9px center;
    border: transparent;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    margin: auto;
	cursor: pointer;
}
.home-three header input[type=search]:focus {
    width: 130px;
    background-color: #fff;
    border-color: #717171;
    -webkit-box-shadow: 0 0 2px #dee2e6;
    -moz-box-shadow: 0 0 2px rgba(109,207,246,.5);
    box-shadow: 0 0 2px #dee2e6;
}
.banner-three-content .form-inner-three {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(216 216 216 / 25%);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 0;
    max-width: 100%;
}
.banner-three-content .select2-container .select2-selection--single {
    border: 0;
    align-items: center;
    text-align: center;
    font-size: 14px;
    display: flex;
    background: #f7f7f7f7;
    height: 56px;
}
.banner-three-content .form-control {
    background: #ffffff;
    border: 0;
	padding: 0;
    height: 56px;
    color: #000;
    font-size: 18px;
    margin-right: 10px;
}
.banner-three-content .input-group .search-icon {
    display: flex;
    align-items: center;
    color: #F66962;
}
.form-three-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: red!important;
    color: #ffffff;
    font-size: 14px;
}
.banner-three-content .select2-container--default 
.select2-selection--single .select2-selection__arrow {
    top: auto;
}
.banner-three-content .select2-container--default 
.select2-results__option--highlighted[aria-selected] {
    background-color: #7B1FFE;
    color: #585858;
    font-size: 14px;
}
.banner-three-content .select2-dropdown {
    border: 1px solid #ffdeda;
}
.btn-three-primary {
	background-color: #7B1FFE;
    border: 1px solid #7B1FFE;
}
.banner-three-content .sub-btn {
    background: #7B1FFE;
    border-radius: 10px;
    border: 1px solid #7B1FFE;
    margin: 0px;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 15px;
    color: #fff;
    width: 60px;
	border-right: 1px solid #F4F4F4 !important;
	border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.banner-three-content .sub-btn:hover {
    background: #1CBEEF;
    border-radius: 10px;
    border: 1px solid #1CBEEF;
    margin: 0px;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 15px;
    color: #fff;
    width: 60px;
	border-right: 1px solid #F4F4F4 !important;
	border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.banner-three-content .input-group>:not(:nth-child(2)) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 14px;
}
.banner-three-content .form-inner-three .input-group::placeholder {
    font-weight: 400;
	font-size: 14px;
	color: #2E2E2E;
}
.banner-three-content .input-group .search-icon {
    display: flex;
    align-items: center;
    color: #DEDEDE;
}
body.home-three ::placeholder {
	color: #2E2E2E;
}  
body.home-three :-ms-input-placeholder { 
	color: #2E2E2E;
}
.email-success-popup {
    background-color: red !important;
    border: none !important;
    border: 0;
}
.email-success-popup:focus{
    outline: none !important;
}
.email-success-popup,
.login-three-head {
	border: 1px solid #1CBEEF !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #1CBEEF !important;
	border-radius: 10px;
    text-align: center;
    padding: 10px 45px;
    min-width: 150px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff ;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.login-three-head:hover {
    background-color: #1CBEEF !important;
    border-color: #1CBEEF !important;
    color: #1CBEEF !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.signin-three-head {
    border: 1px solid #FED700 !important;
    font-weight: 700 !important;
    color: #0D0D0D !important;
    background: #FED700 !important;
	border-radius: 10px;
    text-align: center;
    padding: 10px 45px;
    min-width: 150px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.signin-three-head:hover {
    background-color: #1CBEEF !important;
    border-color: #1CBEEF !important;
    color: #1CBEEF !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.home-three-course .online-course {
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 4px 14px rgb(231 231 231 / 25%);
    border-radius: 10px;
}
.course-details-three {
    border: 1px solid #F4F4F4;
	box-shadow: 0px 4px 14px rgba(231, 231, 231, 0.25);
    border-radius: 10px;
    padding: 10px;
    background: #ffffff;
    width: 100%;
    height: 133px;
    display: -ms-flexbox;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.course-details-three:hover {
    top: -10px;
    border: 1px solid #F4F4F4;
	box-shadow: 0px 44px 34px rgba(204, 204, 204, 0.25);
	-webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.course-count-three {
    margin-left: 25px;
    color: #000000;
    display: flex;
    display: -ms-flexbox;
	align-items: center;
}
.course-content-three {
    margin-left: 5px;
}
.course-details-three .course-content-three h4 {
    font-weight: 700;
    font-size: 24px;
}
.course-details-three .course-content-three p {
    color: #454242;
    margin-bottom: 0;
    font-size: 18px;
}
.course-count-three .course-img img {
    width: 92px;
}
.master-three-images {
    position: relative;
    max-width: 513px;
    margin: auto;
}
.master-three-left {
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.master-skill-three {
    padding: 60px 0 60px;
	position: relative;
}
.home-three-content p {
    margin-bottom: 0;
}
.home-three-content {
    margin-bottom: 40px;
}
.master-three-vector {
    position: absolute;
    right: 0;
    top: 25rem;
	bottom: 0;
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.master-three-right {
    position: absolute;
    bottom: 0;
    top: 10rem;
    right: 9.9rem;
}
.vector-layer-one {
    position: absolute;
    left: 0;
    top: 4rem;
    width: 505px;
    z-index: -1;
    background-repeat: no-repeat;
}
.vector-layer-two {
    position: absolute;
    right: 12rem;
    top: 5.3rem;
    width: 92px;
    height: 83px;
    background-repeat: no-repeat;
}
.skils-icon {
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 4px 14px rgb(231 231 231 / 25%);
    align-items: center;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 0px;
    width: 60px;
    height: 60px;
}
.skils-icon-item {
	position: relative;
    top: 0;
    margin-bottom: 40px;
    transition: top ease 0.5s;
}
.skils-icon-item:hover {
    top: -10px;
    transition: top ease 0.5s;
}
.home-three .categories-icon img {
    width: 44px !important;
    height: 44px !important;
}
.favourite-course-sec .mentoring-course .owl-stage {
    padding-top: 0;
}
.home-three-head h2 {
    font-weight: 700;
    padding-bottom: 20px;
	margin-bottom: 0px;
    position: relative;
}
.home-three-head h2:after {
    content: "";
    position: absolute;
    display: block;
    width: 70px;
    height: 4px;
    background: linear-gradient(89.96deg, #7B1FFE 2.92%, #FED700 50.8%, #1CBEEF 99.71%);
    left: 0;
    right: 0;
    bottom: 0;
}
.home-three .see-all a {
    font-weight: 600;
    font-size: 20px;
    color: #7B1FFE;
    float: right;
}
.home-three .see-all a:hover {
	color: #1CBEEF;
}
.home-three .see-all-icon {
    margin-left: 10px;
}
.home-three-favourite:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 230px;
    background: #F4F4FF;    
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
}
.home-three .home-three-head {
    margin-bottom: 48px;
}
.home-three-favourite {
    padding-bottom: 40px;
    position: relative;
}
.home-three-favourite .categories-content h3 {
    font-weight: 600;
    font-size: 16px;
    color: #2F2F2F;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.home-three-favourite .mentoring-course .owl-stage {
    padding-top: 0px;
}
.categories-icon {
    margin-bottom: 12px;
}
.favourite-box {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 4px 14px rgb(231 231 231 / 25%);
    border-radius: 10px;
    display: flex;
    padding: 20px 20px 0px;
    align-items: center;
    height: 252px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.favourite-box:hover {
    background: #7B1FFE;
    cursor: pointer;
    border: 1px solid #7B1FFE;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.favourite-box:hover .categories-content h3 {
	color: #ffffff; 
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;   
}
.favourite-box:hover .instructors-info p {
	color: #ffffff; 
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;   
}
.favourite-box .instructors-info p {
	font-weight: 500;
    font-size: 14px;
    color: #717171;
    margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;   
}
.home-three .owl-theme .owl-dots .owl-dot.active span, 
.home-three .owl-theme .owl-dots .owl-dot:hover .home-three span {
	width: 8px;
	height: 8px;
    background: #7B1FFE !important;
    box-shadow: 0px 0px 0px 3px #7b1ffe;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-three-trending .owl-theme .owl-dots .owl-dot.active span, 
.home-three-trending .owl-theme .owl-dots .owl-dot:hover .home-three-trending span {
	width: 8px;
	height: 8px;
    background: #1A1E21 !important;
    box-shadow: 0px 0px 0px 3px #1A1E21;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-three-courses {
    padding: 60px 0 60px;
	background: #FBFBFF;
}
.tablist-three .nav-tab.active {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.tablist-three .nav-tab:hover {
    color: #7B1FFE;
}
.tablist-three .nav-tab {
    font-weight: 500;
    font-size: 18px;
    color: #717171;
}
.course-three-img img {
    position: relative;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.course-box-three:hover .course-three-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
    overflow: hidden;
    position: relative;
}
.course-three-img {
    position: relative;
    overflow: hidden;
    padding: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;    
}
.course-three-text {
    margin-top: 30px;
	margin-bottom: 20px;
}
.course-three-item:hover .course-three-text h3 {
	color: #7B1FFE;
}
.course-three-item:hover .course-three-text p {
	color: #1CBEEF;
}
.course-three-item:hover .students-three-counts p {
	color: #0DD3A3;
}
.course-three-item:hover .course-price-three h3 {
	color: #1CBEEF;
}
.heart-three {
    position: absolute;
    top: 1rem;
    display: inline-block;
    right: 1rem;
    bottom: 0;
}
.heart-three .fa-heart {
    width: 31px;
    background-color: #fff;
    height: 31px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    color: #000000;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-three:hover .course-three-text p {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-three-text p {
    font-weight: 500;
    font-size: 14px;
    color: #7B1FFE;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-three-text h3 {
    font-weight: 600;
	font-size: 18px;
	color: #142E42;
	margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-three:hover .course-three-text h3 {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.students-three-counts img {
    width: 20px;
}
.students-three-counts p {
    font-weight: 400;
    font-size: 14px;
    color: #717171;
    margin-left: 12px;
	margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-three:hover .students-three-counts p {
    color: #0DD3A3;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.students-three-counts {
    margin-bottom: 20px;
}
.course-price-three h3 {
    color: #7B1FFE;
    font-weight: 600;
    font-size: 20px;
	margin-bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-three:hover .course-price-three h3 {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-three:hover .price-three-time {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.group-three-img {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%,-50%);
}
.price-three-time {
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.tablist-three {
    margin-bottom: 40px !important;
}
.course-price-three span {
    text-decoration: line-through;
    font-size: 14px;
    color: #DEDEDE;
    line-height: 0;
}
.student-counts-info {
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: 20px;
}
.home-three .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 0px 5px;
    background: #DEDEDE !important;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.group-three-img img {
    width: 51px;
    height: 51px;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
}
.course-three-item {
    position: relative;
    overflow: hidden;
    padding: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-three-content {
    position: relative;
    padding: 10px 20px 20px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.course-box-three {
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: -3px 5px 16px rgb(0 0 0 / 7%);
    border-radius: 10px;
    margin-bottom: 24px;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.course-box-three:hover .course-three-content {
    background-color: #7B1FFE;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.home-three-transform {
    padding: 60px 0 60px;
    background-color: #7B1FFE;
    background-repeat: no-repeat;
    background-position: top right;
}
.home-three-transform .cta-content h2 {
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}
.home-three-transform .cta-content p {
    font-weight: 500;
	font-size: 22px;
	color: #FFFFFF;
}
.btn-action {
    background: #FED700;
    border-radius: 10px;
    padding: 12px 30px;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #0D0D0D;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn.btn-action {
    background: #FED700;
    border-radius: 10px;
    padding: 12px 30px;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #0D0D0D;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-action:hover {
	background-color: #1CBEEF;
    border-color: #1CBEEF;
    color: #1CBEEF !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.course-box-three:hover .heart-three .fa-heart {
    background-color: #0DD3A3;
    color: #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.transform-button-three {
    text-align: end;
}
.instructors-list  {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.instructors-list li a img {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50px;
    border: 3px solid #FFFFFF;
}
.instructors-list li {
    margin-left: -20px;
}
.more-set a {
    width: 35px;
    height: 35px;
    background: #1A1E21;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 12px;
    color: #ffffff;
    line-height: 0;
}
.favourite-box .course-info {
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.instructors-info {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}
.accelerate-cloud-three {
    background: #071136;
    padding: 60px 0 60px;
	position: relative;
}
.award-three-content-one {
    max-width: 515px;
}
.award-three-content-two {
    max-width: 515px;
    margin: auto;
}
.award-three-images-one {
    max-width: 515px;
    margin: auto auto 56px;
}
.award-three-images-three {
    max-width: 515px;
    margin: auto;
}
.award-three-images-two {
    max-width: 515px;
}
.award-three-images img {
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.award-win-icon img {
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.accelerate-three-business {
    max-width: 625px;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
}
.accelerate-cloud-three .home-three-head h1 {
    width: 656px;
    font-weight: 700;
    color: #FFFFFF;
    font-size: 28px;
}
.accelerate-one {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.home-three .accelerate-two {
    position: absolute;
    right: 0;
    bottom: 50rem;
}
.shapes-three-left .accelerate-three {
    position: absolute;
    left: 0;
    top: 18rem;
}
.shapes-three-left .accelerate-four {
    position: absolute;
    left: 0;
    bottom: 8rem;
}
.shapes-three-left .accelerate-five {
    position: absolute;
    left: 0;
    bottom: 10rem;
}
.accelerate-one img {
    width: 200px;
}
.award-win-icon {
    margin-bottom: 20px;
}
.award-three-content-one h2 {
    font-weight: 600;
    font-size: 36px;
    color: #FED700;
    margin-bottom: 20px;
}
.award-three-content-one p {
	font-weight: 500;
	font-size: 16px;
	color: #FFFFFF;
	margin-bottom: 0;
}
.award-three-content-two p {
	font-weight: 500;
	font-size: 16px;
	color: #FFFFFF;
	margin-bottom: 0;
}
.award-list .award-icon {
    margin-right: 15px;
}
.award-one {
    border-bottom: 1px solid #162A73;
}
.award-list-info {
    margin-bottom: 10px;
}
.award-list-content {
    margin-bottom: 25px;
}
.award-list {
    margin-bottom: 20px;
}
.learn-anything h2 {
    font-weight: 600;
    font-size: 36px;
    color: #1CBEEF;
    margin-bottom: 20px;
}
.learn-anything {
    padding: 46px 0 50px;
    border-bottom: 1px solid #162A73;
}
.learn-anything .award-three-images img {
    text-align: left;
}
.development-carrer {
    padding-top: 56px;
}
.home-three-goals {
    padding: 60px 0 60px;
    background-color: #ffffff;
}
.development-carrer h2 {
    font-weight: 600;
    font-size: 36px;
    color: #0DD3A3;
}
.acheive-goals h2 {
    font-weight: 700;
    font-size: 32px;
    color: #1A1E21;
}
.acheive-goals h2:hover {
    color: #7B1FFE;
}
.acheive-goals span {
    font-weight: 700;
    font-size: 32px;
    color: #000000;
}
.acheive-goals p {
    font-weight: 500;
    font-size: 16px;
    color: #717171;
    text-align: left;
}
.acheive-goals {
    position: relative;
    z-index: 998;
}
.acheive-goals-content h4 {
    margin-bottom: 20px;
    text-align: left;
}
.acheive-elips-one {
    position: absolute;
    right: 10px;
    z-index: -1;
    top: -18px;
}
.acheive-goals-main {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
	transition-timing-function: ease-in-out;
}
.acheive-goals-main h2 {
    font-weight: 700;
    font-size: 32px;
    color: #1A1E21;
}
.acheive-goals {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
	transition-timing-function: ease-in-out;
    max-width: 167px;
    margin: auto;
}
.acheive-goals:hover {
    top: -15px;
    transition: top ease 0.8s;
	transform: translateZ(0);
	transition-timing-function: ease-in-out;
}
.acheive-elips-three {
    position: absolute;
    right: 80px;
    z-index: -1;
    top: -18px;
}
.acheive-elips-two {
    position: absolute;
    right: 38px;
    z-index: -1;
    top: -18px;
}
.acheive-goals-icon {
    position: absolute;
    top: 0;
    right: 7rem;
}
.acheive-goals-icon img {
	width: 64px;
	height: 64px;
}
.home-three-trending {
    padding: 60px 0 60px;
	background: #FBFBFF;
}
.trending-bg-one {
    background: #FFE57A;
    border: 1px solid #F4F4F4;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.trending-three-item:hover {
	box-shadow: 0px 24px 20px rgb(204 204 204 / 25%);
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border-radius: 10px;
}
.trending-bg-two {
    background: #69FFDB;
    border: 1px solid #F4F4F4;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.trending-bg-three {
    background: #EBE3FF;
    border: 1px solid #F4F4F4;
    border-radius: 10px 10px 0 0;
    padding: 20px;
}
.trending-three-item {
    position: relative;
    top: 0;
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    cursor: pointer;
    margin-bottom: 40px;
}
.trending-bg-four {
    background: #ABEEFF;
    border: 1px solid #F4F4F4;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.course-title-one {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 10px;
	margin-bottom: 25px;
    display: inline-flex;
	position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.trending-three-item:hover .course-title-one {
	background-color: #1CBEEF;
    border-color: #7B1FFE;
    color: #7B1FFE !important;
    box-shadow: inset 0 50px 0 0 #1CBEEF;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.course-title-one a:hover {
	color: #FFFFFF;
}
.course-title-one a {
    font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: #FFFFFF;
}
.trending-three-text h3 {
    font-weight: 600;
    font-size: 18px;
    color: #142E42;
    margin-bottom: 10px;
}
.trending-three-text p {
	font-weight: 400;
	font-size: 14px;	
	color: #0D0D0D;
	margin-bottom: 20px;
}
.trending-three-counts p {
    margin-left: 10px;
	font-size: 14px;
	margin-bottom: 0;
}
.price-three-time span {
    font-size: 14px;
}
.trending-three-counts img {
    width: 20px !important;
}
.trending-content-footer {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 10px 10px;
}
.trending-footer-img img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50px;
    border: 2px solid #7B1FFE;
}
.trending-price-three h3 {
    font-weight: 600;
    font-size: 20px;
    color: #0D0D0D;
	margin-bottom: 0;
}
.trending-price-three span {
    text-decoration: line-through;
    font-size: 14px;
    color: #DEDEDE;
}

/* Gallery Three */
.content-three-overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 0;
    background: rgba(123, 31, 254, 0.9);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.gallery-three-img-item:hover .content-three-overlay {
    height: 100%;
    bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.gallery-three {
    padding: 60px 0 60px;
	background: #ffffff;
}
.gallery-three .gallery-three-img-item {
	max-width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.gallery-three .content-three-details h6 {
	font-weight: 700;
	font-size: 16px;
	color: #FFFFFF;
	margin-bottom: 0;
}
.gallery-three .service-link i {
    font-size: 25px;
    color: #ffffff;
}  
.gallery-three .content-three-details p {
	font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
}
.gallery-img img {
	width: 100%;
}
.gallery-img-item-one img {
	min-height: 235px;
}
.content-three-image {
    width: 100%;
}
.content-three-text {
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-three-text h6 {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}
.content-three-text p {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}
.content-three-arrows {
    color: #ffffff;
    font-size: 18px;
}
.content-three-arrows:hover i {
    color: #1CBEEF;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.content-three-arrows i {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.content-three-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-three-become {
    background: #FED700;
    padding: 60px 0 60px;
}
.become-content-three h2 {
    font-weight: 700;
    font-size: 32px;
    color: #000000;
}
.become-content-three p {
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    margin-bottom: 0;
}
.become-button-three {
    text-align: end;
}
.home-three  .swiper-slide {
    flex-shrink: unset;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.home-three .become-content h2 {
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
}
.home-three .become-content {
    margin-bottom: 50px;
    width: 400px;
    position: relative;
    z-index: 11111;
}
.home-three .become-content h4 {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}
.home-three .become-content p {
    color: #ffffff;
	font-size: 16px;
}
.home-three .testimonial-item-five {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(100 100 100 / 25%);
    border-radius: 10px;
	background-position: center;
	background-size: cover;
	width: 455px;
}
.home-three .testimonial-quote {
    padding: 20px;
}
.home-three .testimonial-ratings .rating {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.home-three .testimonial-ratings .rating  span {
    font-weight: 400;
    font-size: 14px;
    color: #736969;
	margin-left: 5px;
}
.home-three .testimonial-content {
    padding: 20px;
}
.home-three .testimonial-item-five .testimonial-users {
	display: flex;
	align-items: center;
	padding: 20px;
}
.home-three .testimonial-item-five .testimonial-users .imgbx {
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 20px;
}
.home-three .testimonial-item-five .testimonial-users .imgbx img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.home-three .testimonial-item-five .testimonial-users h6 {
    font-weight: 600;
    font-size: 16px;
    color: #0D0D0D;
}
.home-three .testimonial-item-five .testimonial-users .imgbx img span {
	font-weight: 400;
	font-size: 14px;
	color: #717171;
}
.home-three .testimonial-content p {
    font-weight: 600;
    font-size: 16px;
    color: #2E2E2E;
	margin-bottom: 0;
}
.home-three .testimonial-ratings {
    border-bottom: 1px solid #E7E7E7;
	font-size: 14px;
    padding: 30px;
}
.home-three .testimonial-ratings .rating p {
	margin-left: 8px;
	margin-bottom: 0;
}
.home-three .testimonial-item-five .testimonial-users p {
    font-weight: 400;
    font-size: 14px;
    color: #717171;
	margin-bottom: 0;
}
.testimonial-pattern .pattern-left {
    position: absolute;
    left: 0;
    top: 5rem;
}
.testimonial-pattern .pattern-right {
    position: absolute;
    right: 0;
    bottom: 5rem;
}
.testimonial-bottom-nav {
    display: flex;
    float: right;
    margin-right: 60px;
}
.testimonial-next-pre {
    background: #ffffff;
    border-radius: 50px !important;
    font-size: 16px;
    padding: 10px 15px;
    color: #071136;
    cursor: pointer;
    margin-right: 20px;
}
.testimonial-next-pre:hover {
    background: #1CBEEF;
    color: #ffffff;
}
.become-content h2 {
    font-weight: 700;
    font-size: 32px;
    color: #000000;
}
.become-content p {
    font-weight: 500;
	font-size: 22px;
	color: #000000;
}
.btn-become {
    border: 1px solid #1CBEEF;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #1CBEEF;
    border-radius: 10px;
    text-align: center;
    padding: 15px 35px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn.btn-become {
    border: 1px solid #1CBEEF;
    font-weight: 700 !important;
    color: #ffffff !important;
    background: #1CBEEF;
    border-radius: 10px;
    text-align: center;
    padding: 15px 35px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-become:hover {
	background-color: #7B1FFE;
    border-color: #7B1FFE;
    color: #7B1FFE !important;
    box-shadow: inset 0 55px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.lead-companies-three {
    padding: 60px 0 60px;
}
.testimonial-three {
    padding: 60px 0 60px;
    background: url(../img/bg/map.png), #071136;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    width: 100%;
}
.testimonial-three-content .swiper-wrapper {
    margin-bottom: 20px;
}
.slide:not(.slick-active) {
    cursor: pointer;
}
.box-form-newsletter form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box-form-newsletter form .input-newsletter {
    width: 70%;
    border: 0;
    background-image: url(../img/icon-three/email.svg);
    background-repeat: no-repeat;
    background-position: left 17px center;
    padding: 10px 10px 10px 50px;
}
.custom-select:focus, :focus, [contenteditable].form-control:focus, 
[type=email].form-control:focus, [type=password].form-control:focus, 
[type=tel].form-control:focus, [type=text].form-control:focus, 
button:focus, input.form-control:focus, input[type=email]:focus, 
input[type=number]:focus, input[type=password]:focus, 
input[type=text]:focus, select:focus, textarea.form-control:focus, 
textarea:focus {
    outline: none!important;
    box-shadow: none;
}
.home-three .btn-default {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    background: #7B1FFE;
    border-radius: 5px;
    min-width: 159px;
    height: 44px;
    padding: 10px;
	overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.home-three .btn-default:hover {
	background-color: #ffffff;
    border-color: #1CBEEF;
    color: #ffffff !important;
    box-shadow: inset 0 50px 0 0 #1CBEEF;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.latest-blog-three {
    background: #ffffff;
    padding: 60px 0 60px;
}
.event-three-title h5 {
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.event-three-title p {
    font-weight: 400;
	font-size: 14px;
	color: #DEDEDE;
}
.event-blog-three {
    position: relative;
    overflow: hidden;    
    margin-bottom: 24px;
}
.event-blog-three:hover .blog-img-three img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.event-blog-three:hover .event-span-three .span-name-three {
    background: #7B1FFE !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.event-blog-three:hover .event-three-title h5 {
    color: #1CBEEF;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.latest-blog-three .section-header-title {
	padding-bottom: 48px;
}
.span-name-three {
    padding: 5px 25px;
    border-radius: 4px;
    display: inline-flex;
    margin-bottom: 10px;
    color: #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.blog-widget-three 
.blog-img-three img {
    border-radius: 5px;
}
.blog-widget-three 
.blog-content-three .span-name-three {
    background: #21B477;
    border-radius: 4px;
    color: #ffffff;
    padding: 6px;
}
.blog-widget-three {
    position: relative;
}
.event-main-three .blog-img-three img {
    border-radius: 5px;
	width: 100%;
}
.event-main-three {
    position: relative;
    overflow: hidden;
}
.blog-img-three:before {
    background: linear-gradient(0.26deg, #071136 0.25%, rgba(0, 0, 0, 0) 86.87%);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-content-three {
    position: absolute;
    bottom: -10%;
    left: 50%;
    z-index: 1;
    width: 100%;
    padding: 15px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.event-blog-main .span-name {
    background: #21B477;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    min-width: 90px;
    margin-bottom: 10px;
    padding: 5px;
    display: inline-flex;
}
.event-content-title h5 a {
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
}
.blog-student-count {
    color: #ffffff;
}
.event-blog-main:hover .event-content-title h5 a {
	color: #FF602E;
}
.box-form-newsletter {
    background: #fff;
    max-width: 635px;
    border-radius: 5px;
    padding: 10px;
}
.footer-three {
    padding: 60px 0 60px;
    background: #071136;
    background-image: url(../img/bg/bg-footer.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
}
.footer-menu-three ul li {
    margin-bottom: 10px;
    position: relative;
}
.footer-menu-three ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer-menu-three ul li a {
    color: #ffffff !important;
    font-weight: 500;
    font-size: 14px;
	margin-left: 20px;
}
.footer-menu-three ul li a:hover {
    color: #FED700;
    padding-left: 10px;
}
.footer-three .newsletter-title h6 {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 18px;
}
.footer-three-logo {
    margin-bottom: 20px;
}
.footer-three-about {
    max-width: 627px;
}
.footer-three-about p {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 50px;
}
.footer-three-right {
    margin-left: 100px;
}
.footer-three-title {
    font-weight: 600;
    color: #FFFFFF;
	margin-bottom: 20px;
}
.footer-three-top-content {
    border-bottom: 1px solid #2E2566 !important;
    padding-bottom: 60px;
}
.footer-three-top {
    margin-bottom: 60px;
}
.privacy-policy-three ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.privacy-policy-three ul li {
    padding: 0px 10px;
    border-right: 1px solid #B2B1FC;
    list-style: none;
    height: 10px;
    align-items: center;
    display: flex;
}
.privacy-policy-three ul li:last-child {
    border-right: 0;
}
.footer-menu-three ul li a:before {
	position: absolute;
    top: 7px;
    display: block;
    content: "\f111";
    float: left;
    margin-right: 10px;
	font-size: 8px;
    color: #FED700;
    transition: all 0.5s ease-in-out;
    font-family: 'FontAwesome';
}
.social-icon-three ul {	
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	list-style: none;
	padding: 0;
	margin-left: 20px;
	align-items: center;
    margin-bottom: 0;
}
.social-icon-three {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin-bottom: 36px;
    align-items: center;
}
.privacy-policy-three {
    margin-bottom: 36px;
}
.social-icon-three ul li {
    margin-right: 15px;
}
.social-icon-three ul li .feather-facebook-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    background: #066AC9;
    border-radius: 50%;
}
.social-icon-three ul li .feather-facebook-icon:hover {
	background: #7B1FFE;
}
.social-icon-three ul li .feather-twitter-icon:hover {
	background: #7B1FFE;
}
.social-icon-three ul li .feather-linkedin-icon:hover {
	background: #7B1FFE;
}
.social-icon-three ul li .feather-youtube-icon:hover {
	background: #7B1FFE;
}
.social-icon-three ul li .feather-twitter-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    background: #2BB7E5;
    border-radius: 50%;
}
.social-icon-three ul li .feather-linkedin-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    background: #066AC9;
    border-radius: 50%;
}
.social-icon-three ul li .feather-youtube-icon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    background: #FF2D20;
    border-radius: 50%;
}
.social-icon-three h6 {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.copyright-three {
    text-align: center;
}
.privacy-policy-three ul li a {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF !important;
}
.privacy-policy-three ul li a:hover {
    color: #FED700;
}
.copyright-text-three p {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.header-four .main-nav .has-submenu.active > a {
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #EE009C;
}
.header-four .main-nav li a {
    display: block;    
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
}
.header-four .main-nav li .submenu a:hover {
    color: #EE009C;
    letter-spacing: 0.5px;
    padding-left: 20px;
}
.list-data {
    list-style: none;
    display: inline-block;
    margin: 0 25px 0 0;
}
.landing-menu li a i {
    font-weight: 600;
    font-size: 16px;
    padding-left: 7px;
}
.right-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.right-header-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    transition: all 0.3s ease;
}
.right-header-button a .active {
    color:#F58E06;
    padding-right: 20px;
}
.buy-template-btn {
    background: #392C7D;
    border-radius: 32px;
    color: #fff !important;
    font-weight: 700;
    padding: 12px 40px;
    font-size: 16px;
    transition: all 0.6s;
}
.signup-four {
    background: #EE009C;
    padding: 12px 40px;
    color: #fff !important;
    border-radius: 32px;
    border: 1px solid #EE009C;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.signup-four:hover {
    background-color: #ffffff;
    border: 1px solid #EE009C;
    color: #EE009C !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.buy-template-btn:hover {
    background:#EE009C;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #EE009C;
    transition: 0.6s all;
}
.landing-menu li a {
    font-weight: 600;
    font-size: 16px;
    color: #392C7D;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.landing-menu li a:hover {
    color: #EE009C;
}
.landing-menu ul li .landing-page-list::before {
    content: "";
    position: absolute;
    height: 4px;
    bottom: 23px;
    -webkit-transition: all 0.5 ease;
    transition: all 0.5s ease;
    width: 0px;
}
.landing-menu ul li:hover .landing-page-list::before {
    content: "";
    position: absolute;
    bottom: 23px;
    -webkit-transition: all 0.5 ease;
    transition: all 0.5s ease;
    background-color: #2066e7;
}
.sub-menu {
    display: none;
    background: white;
    position: absolute;
}
.sub-menu li {
    display: block;
    padding: 5px 0px;
    border-top: 1px solid #f0f0f0;
}
.sub-menu li a {
    padding: 15px 20px;
}
.landing-menu ul li:hover .sub-menu {
    display: block;
    z-index: 20;
}
.main-nav {
    display: flex;
    justify-content: center;
}
.sub-head-wraper {
    background-color:#F3F2F7;
}
.landing-menu ul {
    padding-left: 0;
    margin: 0;
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 10px;
}
.dropdown:hover .dropdown-menu .dropdown-item{
    display: block;
    margin-top: 10px;
}
.home-four .select2-container--default 
.select2-results__option--highlighted[aria-selected] {
    background-color: #EE009C;
    color: #ffffff;
    font-size: 14px;
}
.home-four .select2-dropdown {
    border: 1px solid #EE009C;
}
  
/*-----------------
	Banner Four
 -----------------------*/

.banner-section-four {
    position: relative;
    background-image: url(../img/banner-background-img-1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.banner-heading-four {
    padding: 159px 0 159px;
}
.vector-four .vector-four-one {
    position: absolute;
    right: 0;
    top: 0;
}
.vector-four .vector-four-two {
    position: absolute;
    left: 0;
    top: 0;
}
.banner-heading-four h3 {
    font-weight: 500;
    font-size: 20px;
    color: #EE009C;
}
.banner-heading-four h2 {
    font-weight: 700;
    font-size: 45px;
    color: #392C7D;
    padding-bottom: 35px;
}
.banner-heading-four h5 {
    font-weight: 400;
    font-size: 16px;
    color: #392C7D;
    margin: 0;
    padding-bottom: 40px;
}
.search-box-four { 
    background: #FFFFFF;
    border: 1px solid #EE009C;
    box-shadow: 0px 16px 106px rgba(196, 196, 196, 0.16);
    border-radius: 40px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search-box-four .select2-container {
    width: 196px !important;
    background: #E8E6F0;
    border-radius: 90px;
    border: none;
    padding: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #392C7D ;
    cursor: pointer;
}
.search-box-four .select2-container--default .select2-selection--single {
    border: none;
    background: #E8E6F0;
    color: #392C7D;
}
.search-box-four .select2-container--default .select2-selection--single 
.select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 22px;
    right: 10px;
    width: 20px;
}
.search-box-four .select2-container .select2-selection--single 
.select2-selection__rendered {
    display: block;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.search-box-four .select2-container--default .select2-selection--single 
.select2-selection__rendered {
    color: #392C7D;
}
.search-box-four input {
    border: none;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    color: #392C7D;
    width: 400px;
}
.search-box-four input:focus {
    outline: none;
}
.search-icon-four {
    width: 60px;
    height: 60px;
    background: #EE009C;
    border-radius: 32px;
    border: 1px solid #EE009C;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}
.search-box-four a:hover div {
    background: #fff;
    box-shadow: 50px 0 0 0 #fff inset;
}
.search-box-four a:hover i {
    color: #EE009C;
}
.search-icon-four i {
    color: #fff;
}
.banner-img-four{
    position: relative;
}
.banner-img-sec{
    position: absolute;
    left: -65px;
    top: 35px;
}
.banner-img-third {
    position: absolute;
    right: -95px;
    top: 50px;
}
.banner-img-fourth {
    position: absolute;
    right: -5rem;
    top: 10rem;
}
.banner-img-sec, .banner-img-third, .banner-img-fourth, .banner-imgs .banner-img-two, .banner-imgs .banner-img-three {
    padding: 33px 0px 0px 33px;
    -webkit-animation: float 2s ease-in-out infinite;
    animation: float 2s ease-in-out infinite;
}
.user-registration .student-img img,.master-skill-img-two,.master-skill-img-three {
    padding: 33px 0px 0px 33px;
    -webkit-animation: float 2s ease-in-out infinite;
    animation: float 2s ease-in-out infinite;
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
50% {
		transform: translatey(-15px);
	}
	100% {
		transform: translatey(0px);
	}
}

/*-----------------
	Course Categorie Four
-----------------------*/

.course{
    border-right: none;
}
.circle {
    background: #E8E6F0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 95px;
}
.multiple-courses-text span {
    font-weight: 700;
    font-size: 24px;
    color: #392C7D;
}
.multiple-courses-text h5 {
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
    margin: 0;
}
.course-categories {
    padding: 60px 0 60px;
}
.course-categories .section-heading {
    padding: 70px 0 50px 0;
}
.section-heading h2 {
    font-weight: 700;
    font-size: 32px;
    color: #392C7D;
}
.section-heading h5 {
    font-weight: 500;
    font-size: 16px;
    color: #5C5C5C;
    margin: 0;
}
.development-course {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px rgba(57, 44, 125, 0.02);
    border-radius: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 135px;
    gap: 20px;
}
.main-development {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.course-name span {
    font-weight: 500;
    font-size: 18px;
    color: #5C5C5C;
}
.favourite-course .owl-nav {
    position: absolute;
    top: -110px;
    right: 20px;
    display: flex;
    gap: 20px;
}
.home-four .owl-nav .owl-prev i, .home-four .owl-nav .owl-next i{
    width: 50px;
    height: 50px;
    background: #C4C4C4;
    border-radius: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff
}
.home-four .owl-nav .owl-prev i:hover, .home-four .owl-nav .owl-next i:hover {
    background: #392C7D;
}

 /*-----------------
	Our Courses Four
-----------------------*/

.our-courses {
    background: #F3F2F7;
    padding: 80px 0;
}
.our-courses .section-heading{
    padding-bottom: 50px;
}
.our-courses .section-heading h5 {
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
    margin-bottom: 0;
}
.card-body-group {
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: -3px 5px 16px rgb(0 0 0 / 7%);
    border-radius: 15px;
    margin-bottom: 20px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover {
    position: relative;
    overflow: hidden;
    background: #EE009C;
    box-shadow: -3px 5px 16px rgba(0, 0, 0, 0.07);
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.rated {
    background: rgba(57, 44, 125, 0.5);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 93.54px;
    height: 39px;
    position: absolute;
    top: 16px;
    left: 20px;
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    transition: 0.7s all;
}
.heart {
    position: absolute;
    top: 16px;
    right: 20px;
    color: #392C7D;
    font-size: 20px;
    cursor: pointer;
}
.our-courses .card-body {
    padding: 0 1rem;
}
.our-courses .card .card-img:hover .rated {
    background: #EE009C;
}
.our-courses .card .card-img img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    min-height: 260px;
}
.developer-img {
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}
.developer-img img {
    width: 34px;
    height: 35px;
}
.web-developer-name {
    display: flex;
    align-items: center;
    gap: 10px;
}
.web-developer-name a {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover .web-developer-name a {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.our-courses .card:hover .php-course a {
     color:#EE009C;
     -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.developer-img img {
    border-radius: 50px;
}
.web-developer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.course-caste span {
    font-weight: 600;
    font-size: 22px;
    color: #EE009C;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover .course-caste span {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group .php-course {
    padding: 20px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.php-course a {
    font-weight: 500;
    font-size: 20px;
    color: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover .php-course a  {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-lesson-and-duration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.course-lesson {
    display: flex;
    align-items: center;
    gap: 10px;
}
.book-img {
    width: 38px;
    height: 38px;
    background: rgba(57, 44, 125, 0.2);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content:center;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover .book-img {
    background: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.book-img i {
    color:#392C7D
}
.course-lesson span {
    font-weight: 400;
    font-size: 14px;
    color: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-body-group:hover .course-lesson span {
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.card-button .buy-now-btn {
    display: flex;
}
.card-button .buy-now-btn a {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    background: #EE009C;
    border-radius: 32px;
    padding: 15px 50px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.card-button .buy-now-btn a:hover {
    background: #392C7D;
    box-shadow: inset 0 50px 0 0 #392C7D;
}
.home-four .our-courses-slider .owl-nav {
    position: absolute;
    top: -110px;
    right: 20px;
    display: flex;
    gap: 20px;
}
.heart-red {
    color:red;
}

 /*---------------------------------
	Skills To Drive Your Career Four
------------------------------------*/

.career-skills {
     padding: 80px 0;
}
.skills-info .section-heading p {
    font-weight: 400;
    font-size: 17px;
    color: #5C5C5C;
    padding-bottom: 20px;
}
.skills-info .section-heading h2 {
    padding-bottom: 20px;
    margin: 0;
    max-width:375px
}
.skills-info ul li span{
    color: #EE009C;
    padding-right: 10px;
}
.skills-info ul {
    padding-left: 0;
    margin: 0;
}
.skills-info ul li {
    list-style-type: none;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 10px;
    color: #5C5C5C;
}
.skills-info ul li:last-child {
    padding: 0;
}
.multiple-informations {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    padding: 10px 0 30px 0;
}
.learning-process span {
    font-weight: 700;
    font-size: 24px;
    color: #5C5C5C;
}
.learning-process h5 {
    font-size: 16px;
    color: #5C5C5C;
    font-weight: 400;
}
.explore-button {
    display: flex;
    align-items: center;
 }
 .skills-info .section-heading p{
    margin-bottom: 0;
 }
 .explore-button .explore-btn {
    display: flex;
 }
 .explore-button .explore-btn a {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    background: #EE009C;
    border-radius: 32px;
    padding: 15px 50px;
    transition: 0.7s all;
 }
 .explore-button .explore-btn a:hover {
    background: #392C7D;
    box-shadow: inset 0 50px 0 0 #392C7D;
 }
 .skills-img {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 /*------------------------
	Feature Instructor Four
-----------------------------*/

.card-img {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}
.card-img img:hover {
    transform: scale(1.15);
}
.card-img img {
    transition: 0.7s all;
}

.feature-instructor {
    padding: 80px 0;
}
 .feature-instructor .section-heading h5 {
    color: #392C7D;
 }
 .instructor-info-four {
    text-align: center;
 }
 .instructor-info-four h3 {
    font-weight: 500;
    font-size: 20px;
    color: #392C7D;
 }
 .instructor-info-four h3:hover {
    color:#EE009C;
 }
 .instructor-info-four h4 {
    font-weight: 400;
    font-size: 16px;
    color: #525252;
    padding: 5px 0 20px;
    margin-bottom: 0;
 }
 .instructor-info-four h5 {
    font-weight: 600;
    font-size: 16px;
    color: #EE009C;
 }
 .feature-instructor .card {
    background: #FFFFFF;
    box-shadow: 2px 8px 20px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    border:1px solid #fff ;
    max-width: 100%;
 }
 .feature-instructor .section-heading {
    padding-bottom: 50px;
 }
 .feature-instructor-slider .owl-nav {
    position: absolute;
    top: -110px;
    right: 20px;
    display: flex;
    gap: 20px;
}

 /*------------------------
    Different Companies Four
-----------------------------*/

.different-companies {
    background: #F3F2F7;
    padding: 80px 0;
}
.different-companies .section-heading {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;

}
.different-companies .section-heading h2 {
    max-width: 540px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}
.leading-univercities .item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.leading-univercities .owl-theme .owl-dots, .owl-theme .owl-nav {
    margin-top: 0 !important;
}

 /*-----------------
	Testimonial Four
-----------------------*/

.testimonial {
    padding: 80px 0;
    background-image: url(../img/testimonial-background-img-1.png), url(../img/testimonial-background-img-2.png);
    background-repeat: no-repeat;
    background-position: left 10% top 40%, right 10% bottom 30%;
}
.testimonial .section-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.testimonial .section-heading h5 {
    color: #392C7D;
    margin: 0;
}
.testimonial-img img {
    border-radius: 50%;
    padding: 50px 0;
}
.testimonial-name h2 {
    font-weight: 700;
    font-size: 32px;
    color: #392C7D;
}
.testimonial-name h5 {
    font-weight: 500;
    font-size: 16px;
    color: #392C7D;
    margin: 0;
}
.testimonial-name p{
    max-width: 789px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;
    padding: 50px 0;
}
.founder-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.founder-info .testimonial-name {
    text-align: center;
}
.testimonial-slider .owl-dots {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.testimonial-slider .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: #C4C4C4;
    border-radius: 53px;
}
.testimonial-slider .owl-dots .active {
    background:  #392C7D;
}
.testimonial-img img {
    width: 201px;
    height: 301px;
}

 /*------------------------
	Latest Blogs Four
-----------------------------*/

.latest-blog-four {
    background: #F3F2F7;
    padding: 60px 0 60px;
}
.latest-blog .section-heading {
    padding-bottom: 50px;
}
.blog-info .course-name {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(57, 44, 125, 0.1);
}
.blog-info .course-info {
    border-bottom: none;
    margin-bottom: 15px;
    padding-bottom: 0;
}
.blog-info .course-name h5 {
    font-weight: 700;
    font-size: 14px;
    color: #EE009C;
    transition: 0.5s all;
    margin: 0;
}
.blog-info .course-info a {
    font-weight: 700;
    font-size: 20px;
    color: #392C7D;
}
.blog-info .course-info a:hover {
    color: #EE009C;
}
.blog-info .course-date {
    display: flex;
    align-items: center;
    gap: 10px;
}
.latest-blog .card {
    margin-bottom: 0;
}
.latest-blog-slider .card .card-body {
    padding-bottom: 0;
}
.latest-blog-slider .card {
    border:none;
    background: #FFFFFF;
    box-shadow: -3px 5px 16px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    margin-bottom: 20px;
    max-width: 100%;
}
.latest-blog-slider .card .card-img {
    border-radius: 30px;
}
 .latest-blog-slider .card .card-img img {
    border-radius: 30px;
    min-height: 292px;
 }
.home-four .latest-blog-slider .owl-nav {
    position: absolute;
    top: -110px;
    right: 20px;
    display: flex;
    gap: 20px;
}

 /*------------------------------------
	Unlimited Courses and Labs Four
------------------------------------*/

.education-details {
    background: #D9D9D9;
    padding: 50px 0;
}
.unlimited-courses-labs {
    padding: 80px 0;
}
.unlimited-courses-labs .section-heading {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
.unlimited-courses-labs .section-heading h2{
    text-align: center;
    max-width: 550px;
} 
.courses-labs-logo {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.courses-labs-logo .owl-theme .owl-dots, .owl-theme .owl-nav {
    margin-top: 0 !important;
}
.leading-univercities.owl-carousel.owl-theme.owl-loaded.owl-drag {
    margin-bottom: 0;
    display: flex;
}
 /*---------------------------
	Education Details Four
-------------------------------*/

.education-details-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.education-details-box .card-button {
    display: block;
    padding-bottom: 0;
}
.education-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.education-info h2 {
    font-weight: 700;
    font-size: 34px;
    color: #FFFFFF;
}
.education-info p {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    max-width: 350px;
    text-align: center;
}
.education-img img {
    border-radius: 25px;
    transition: 0.5s;
}
.education-img {
    position: relative;
    overflow: hidden;
    border-radius: 25px;
}

 /*-------------------
	News Letter Four
-------------------------*/

.news-letter-four {
    background: #392C7D;
    padding: 50px 0;
}
.news-letter .section-heading {
    padding-bottom: 30px;
}
.news-letter .section-heading h2 {
    margin-bottom: 0;
}
.news-letter .section-heading h2 {
    color: #fff;
}
.news-letter-four .section-heading h2 {
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
    padding-bottom: 30px;
}
.request-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.request-box .send-btn a {
    padding: 15px 70px;
    background: #EE009C;
    border: 1px solid #EE009C;
    border-radius: 32px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    transition: 0.5s all;
}
.request-box .send-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.request-box .name-field input {
    border: none;
    background: #392C7D;
    border-bottom: 2px solid rgba(255, 255, 255, 0.33);
    padding: 0 0 17px 0;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    width: 400px;
}
.request-box .email-field input {
    border: none;
    border-bottom-color: currentcolor;
    background: #392C7D;
    border-bottom: 2px solid rgba(255, 255, 255, 0.33);
    padding: 0 0 17px 0;
    color: #fff;
    width: 500px;
}
.request-box input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
}
.request-box input:focus {
    outline: none;
}.request-box .send-btn a:hover {
    background: #FFF;
    box-shadow: inset 0 50px 0 0 #FFF;
    color: #EE009C;
    border: 1px solid #EE009C;
}

 /*-------------------
	Footer Four
-------------------------*/

.footer-four {
    background: #F3F2F7;
    padding: 50px 0;
    background-image: url(../img/footer-background-img.png);
    background-repeat: no-repeat;
    background-position: right bottom;
}
.footer-main-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 60px;
}
.footer-main-info h2 {
    font-weight: 700;
    font-size: 20px;
    color: #392C7D;
    margin: 0;
    padding-bottom: 25px;
}
.footer-main-info ul {
    margin: 0;
    padding: 0;
}
.footer-four .footer ul li a {
    font-weight: 400;
    font-size: 16px;
    color: #392C7D;
}
.footer-main-info ul li {
    list-style-type: none;
    padding-bottom: 10px;
}
.footer-main-info ul li:last-child {
    padding-bottom: 0;
}
.address p {
    font-weight: 400;
    font-size: 16px;
    color: #392C7D;
    max-width: 250px;
}
.footer-logo p {
    max-width: 385px;
    font-weight: 400;
    font-size: 16px;
    color: #392C7D;
    padding: 70px 0 0 0;
}
.social-media-links {
    display: inline-flex;
}
.social-media-links a {
    margin-left: 17px;
}
.social-media-icon {
    width: 47px;
    height: 47px;
    background: rgba(57, 44, 125, 0.1);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-media-icon i {
    color:#392C7D;
    font-size: 22px;
    transition: 0.5s;
}
.social-media-links a div {
    transition: 0.5s all;
}
.social-media-links a div:hover {
    background: #fff;
    box-shadow: inset 50px 0 0 0 #fff;
}
.social-media-links a div:hover i {
    color: #EE009C;
    transform:rotatey(360deg)
}
.more-information {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.more-information li {
    position: relative;
    margin-left: 70px;
}
.footer-four .footer-bottom ul {
    margin: 0;
    padding: 0;
}
.footer-four ul li a:hover {
    color:#EE009C;
}
.footer-bottom .more-information li {
    list-style-type: none;
}
.footer-four ul li a {
    font-weight: 400;
    font-size: 16px;
    color: #392C7D;
}

/*-----------------
	9 Home Four Main
-----------------------*/

.header-navbar-five {
    margin: 0;
    margin-left: 0;
    padding: 0;
}
.header-five .main-nav .has-submenu.active > a {
    font-weight: 700;
    font-size: 16px;
    color: #FFD600 !important;
}
.header-five .main-nav li .submenu a:hover {
    color: #FFD600;
    letter-spacing: 0.5px;
    padding-left: 20px;
}
.header-five .main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 14px;
    color: #000000 !important;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    width: 100%;
    border-top: 1px solid #f0f0f0;
}
.header-navbar-five > li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-right: 46px;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
}
.header-navbar-five > li .login-five {
    color: #FFD600 !important;
    font-weight: 700;
    font-size: 16px;
}
.header-navbar-five > li .login-five:hover {
    color: #1CBEEF !important;
}
.header-navbar-five > li .signup-five {
    font-weight: 500;
    font-size: 16px;
    border-radius: 32px;
    padding: 10px 40px;
    background-color: #FFD600;
    border-color: #FFD600;
    color: #000000;
}
.header-navbar-five > li .signup-five:hover {
    background-color: #1CBEEF;
    border-color: #1CBEEF;
    color: #ffffff !important;
}
.home-slide-five {
    position: relative;
	background: #2D0160;
}
.header-five .main-nav li a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff !important;
}
.header-five .main-nav li a:hover {
	color: #ffd600;
}
.header-five .add-header-bg {
    /* box-shadow: 0px 4px 15px rgb(0 0 0 / 8%); */
    transition-duration: 0.4s;
    background: #2D0160;
    padding-top: 0;
    margin: 0;
}
.vector-shapes-five {
    position: absolute;
    left: 2.5rem;
    top: 14.5rem;
}
.home-slide-five-face {
    padding: 174px 0 56px;
}
.home-slide-five .banner-slider-img {
    position: relative;
}
.home-slide-five-text {
    max-width: 415px;
	width: 100%;
}
.slider-five-one {
    position: absolute;
    top: 9.2rem;
}
.slider-five-one img {
    width: 351px;
    max-width: 100%;
}
.slider-five-two img {
    width: 351px;
    max-width: 100%;
}
.slider-five-two {
    animation: up-down 5s ease-in-out infinite alternate-reverse both;
}
.home-slide-five-text h5 {
    font-weight: 500;
    font-size: 16px;
    color: #FFD600;
}
.home-slide-five-text h1 {
    font-weight: 700;
    font-size: 36px;
    color: #FFFFFF;
	margin-bottom: 38px;
}
.home-slide-five-text p {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
	margin-bottom: 38px;
}
.banner-content-five {
    margin-bottom: 116px;
}
.banner-content-five .form-inner-five {
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #FFD600;
    box-shadow: 0px 16px 106px rgb(196 196 196 / 16%);
    border-radius: 40px;
    width: 100%;
    max-width: 585px;
}
.banner-content-five .form-inner-five .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.drop-detail-five {
    background: #FFD600;
    border-radius: 90px;
    display: flex;
    border: 0;
    border-top-right-radius: 90px !important;
    border-bottom-right-radius: 90px !important;
    max-width: 147px;
    width: 100%;
}
.banner-content-five .select2-container--default 
.select2-selection--single .select2-selection__arrow b {
    margin-top: -2px;
    margin-left: -8px;
}
.banner-content-five .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	margin-top: 2px;
}
.banner-content-five .select2-container 
.select2-selection--single {
    border: 0;
    align-items: center;
    display: flex;
    background: transparent;
    justify-content: center;
    height: 50px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.home-five .select2-dropdown {
    border: 1px solid #FFD600;
}
.home-five .select2-container--default 
.select2-results__option--highlighted[aria-selected] {
    background-color: #FFD600;
    color: #000000;
    font-size: 14px;
}
.banner-content-five .sub-btn {
	background: #FFD600;
    border: 1px solid #FFD600;
    box-shadow: inset 0 0 0 0 #ffffff;
    border-radius: 32px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border: 1px solid #FFD600;
    border-radius: 32px;
    margin: 0px;
    line-height: 0;
    padding: 15px 15px;
    color: #000000;
    border-top-left-radius: 32px !important;
    border-bottom-left-radius: 32px !important;
}
.banner-content-five .sub-btn:hover {
	color: #0071DC;
    border: 1px solid #0071DC;
    background: #0071DC;
    box-shadow: inset 0 50px 0 0 #1CBEEF;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.banner-content-five .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    padding-left: 0;
}
.banner-content-five .input-group>.form-control, .input-group>.form-select {
    position: relative;
    width: auto;
    margin-left: 0px !important;
    border: 1px solid transparent !important
}
.banner-content-five .select2-container--default 
.select2-selection--single .select2-selection__arrow {
    height: auto;
    position: absolute;
    top: 23px;
    right: 0;
    width: 50px;
}

.banner-content-five .form-inner-five ::-webkit-input-placeholder { 
    font-size: 14px;
}
  .banner-content-five .form-inner-five ::-moz-placeholder { 
    font-size: 14px;
}
.banner-content-five .form-inner-five :-ms-input-placeholder { 
    font-size: 14px;
}
.banner-content-five .form-inner-five :-moz-placeholder { 
    font-size: 14px;
}
.review-user-five .review-users-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.review-user-five .review-users-list li {
    margin-right: -20px;
}
.review-user-five .review-users-list li a img {
	width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #440093;
}
.review-rating-five p {
	display: inline-block;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
	margin-bottom: 0;
}
.review-rating-five {
    margin-left: 39px;
}
.rating-star .filled {
    color: #FFB54A;
}
.rate-head-five h2 {
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.rate-head-five p {
	font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 10px;
	margin-bottom: 0;
}
.review-user-five {
    margin-bottom: 25px;
}
.review-five-group .course-count {
    margin-left: 0;
}
/*-----------------
	Leading Section Five
-----------------------*/

.leading-section-five {
	position: relative;
    padding: 60px 0 60px;
    background: #F6F7FB;
}
.leading-five-content h2 {
    font-weight: 700;
    font-size: 32px;
    color: #2D0160;
}
.leading-five-content p {
	color: #515151;
    font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.leading-section-five .owl-carousel 
.owl-item .lead-img img {
    width: 147px;
}
.course-section-five .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 5px;
    margin: 0px 5px;
    background: #D9D9D9;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-five .owl-theme .owl-dots .owl-dot.active span, 
.home-five .owl-theme .owl-dots .owl-dot:hover span {
    background: #FFD600 !important;
    width: 50px;
    height: 5px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-five .owl-theme .owl-dots .owl-dot span {
    width: 50px;
    height: 5px;
    margin: 5px 7px;
    background: #D6D6D6 !important;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}
.course-section-five {
    background: #ffffff;
    padding: 60px 0 60px;
    position: relative;
    z-index: 999;
}
.course-section-five .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 30px;
}
.course-five-item {
    background: #F6F7FB;
    border-radius: 10px;
	border: 1px solid transparent;
}
.course-five-item:hover {
    background: #FFFFFF;
    border: 1px solid #FFD600;
    border-radius: 10px;
	transition-duration: 0s;
}
.course-five-grid {
    padding: 30px;
}
.course-info-five h3 {
    font-weight: 700;
    font-size: 20px;
    color: #2D0160;
}
.course-info-five p {
    font-weight: 400;
	font-size: 16px;
	color: #515151;
	margin-bottom: 39px;
}
.course-info-btn .btn-five {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    width: 30px;
    height: 30px;
    background: #2D0160;
    border-radius: 50%;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-info-btn i {
    color: #ffffff;
	font-size: 12px;
}
.course-icon-five {
    margin-bottom: 39px;
	text-align: center;
}
.course-icon-five img {
    background: #ffffff;
    padding: 20px;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
}
.course-info-five {
    margin-bottom: 20px;
	text-align: center;
}
.course-info-btn {
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.header-five-title h2 {
    font-weight: 700;
    font-size: 32px;
    color: #392C7D;
}
.header-five-title {
    margin-bottom: 50px;
}
.course-section-five .owl-carousel .owl-item img {
    display: inline-flex;
    width: auto;
}
.icon-five-border {
	position: relative;
    background: rgba(251, 15, 0, 0.2);
    border-radius: 75px 30px 75px 30px;
    width: 90px;
    margin: auto auto 20px;
}
.course-five-item:hover .course-info-five h3 {
	font-weight: 700;
	font-size: 20px;
	color: #FFD600;
}
.course-five-item:hover .course-info-five h3 {
	font-weight: 700;
	font-size: 20px;
	color: #FFD600;
}
.course-five-item:hover .course-info-btn .btn-five  {
	background-color: #FFD600;
}
.course-five-item:hover .icon-five-border  {
	background-color: #FFD600;
}
.counter-section-five {
    background: #2D0160;
    padding: 60px 0 60px;
}
.count-content-five h4 {
	font-weight: 700;
	font-size: 32px;
	color: #FFD600;
}
.count-content-five p {
	font-weight: 700;
	font-size: 20px;
	color: #ffffff;
	margin-bottom: 0;
	
}
.count-five {
    border-right: 1px solid #D9D9D9;
}
.count-five-0 {
    border-right: 0;
}
.featured-section-five {
    padding: 80px 0 65px;
	background: #F6F7FB;
}
.tablist-five {
	padding-bottom: 18px;
    border-bottom: 1px solid #EFF2F5;
    display: block;
    margin-bottom: 40px;
    text-align: center;
}
.tablist-five .nav-tab {
    margin-right: 40px;
    font-weight: 500;
    font-size: 18px;
    color: #515151;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s; 
}
.tablist-five .nav-tab.active {
    color: #2D0160;
	border-bottom: 4px solid #2D0160;
	padding-bottom: 18px;
}
.tablist-five .nav-tab:hover {
    color: #FFD600;
    border-bottom: 4px solid #FFD600;
    padding-bottom: 18px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s; 
}
/*-----------------
	Featured Courses Five
-----------------------*/

.course-box-five {
	position: relative;   
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: -3px 5px 16px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin-bottom: 24px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.product-content-five {
    padding: 20px;
}
.joing-course-ovelay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    background: rgba(45, 1, 96, 0.63);
    border-radius: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-five:hover .joing-course-ovelay {
    height: 100%;
    bottom: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-box-five:hover .heart-five .fa-heart {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    color: #ff0000;
    stroke: #000;
    stroke-width: 30px;
    z-index: 2;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.product-img-five {
	position: relative;
    overflow: hidden;
    padding: 0;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.heart-five {
    position: absolute;
    top: 1rem;
    display: inline-block;
    left: 1rem;
    bottom: 0;
}
.heart-five .fa-heart {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.course-group-img-five img {
    max-width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
}
.course-group-img-five h6 {
    font-weight: 500;
	font-size: 16px;
	color: #515151;
	margin-bottom: 0;
}

.product-content-five .price-five-group p {
    font-weight: 500;
    font-size: 16px;
    color: #2D0160;
    margin-bottom: 0;
}
.course-share-five .rating {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.course-share-five .rating {
	font-size: 12px;
}
.product-content-five h3 a {
    font-weight: 600;
    font-size: 18px;
    color: #515151;
}
.product-five-title {
    display: flex;
    display: -ms-flexbox;
    margin-bottom: 20px;
}
.info-five-middle {
    border-bottom: 1px solid #F1F1F1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
}
.price-five-group {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
}
.course-view-five {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
}
.info-five-middle .rating-img {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
}
.course-info-five p {
    margin-bottom: 0;
    color: #685F78;
    font-size: 16px;
    margin-left: 5px;
}
.course-group-five {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.course-group-img-five {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.course-info-five .course-view-five i {
    color: #FFD600;
}
.course-info-five .rating-img i {
	color: #FFD600;
}
.product-content-five .price-five-group h3 {
    font-weight: 500;
    font-size: 20px;
    color: #FB0F00;
    margin-bottom: 0;
}
.info-five-middle .course-view-five p {
    font-weight: 500;
    font-size: 16px;
    color: #2D0160;
    margin-bottom: 0;
} 
.info-five-middle .rating-img span, .info-five-middle .rating-img p{
    margin-bottom: 0;
}
.joing-course-middle {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    background-color: #0071DC;
    border-radius: 6px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.joing-course-btn {
    font-weight: 500 !important;
    background: #FFD600;
    border: 1px solid #FFD600;    
    text-align: center;
    padding: 10px 30px;
    position: relative;
    box-shadow: inset 0 0 0 0 #ffffff;
    border-radius: 32px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 32px;
}
.joing-course-btn:hover {
    color: #2D0160;
    border: 1px solid #2D0160;
    background: #ffffff;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

/*-----------------
	Master Skills Five
-----------------------*/

.master-section-five {
    position: relative;
    background: #ffffff;
    padding: 60px 0 60px;
}
.master-section-five .header-five-title h2 {
    max-width: 374px;
    width: 100%;
}
.master-five-vector {
    position: absolute;
    left: 2rem;
}
.skill-five-icon img {	
    padding: 5px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
}
.career-five-content {
    margin-bottom: 40px;
}
.career-five-content p {
    font-weight: 400;
    font-size: 16px;
    color: #515151;
}
.skill-five-icon {
    margin-bottom: 10px;
}
.skill-five-item {
    margin-bottom: 38px;
}
.learn-more-five {
    border: 1px solid #FED700;
    font-weight: 500 !important;
    color: #0D0D0D !important;
    background: #FED700;
    border-radius: 32px;
	font-size: 16px;
    text-align: center;
    padding: 12px 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.learn-more-five:hover {
    background-color: #1CBEEF;
    border-color: #1CBEEF;
    color: #1CBEEF !important;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.skill-five-content h3 {
    font-weight: 600;
    font-size: 20px;
    color: #515151;
}
.skill-five-content p {
    font-weight: 400;
    font-size: 16px;
    color: #515151;
	margin-bottom: 0;
}

/*-----------------
	Experienced Course
-----------------------*/

.experienced-course-five {
    background: #2D0160;
    padding: 60px 0 60px;
	position: relative;
}
.instructor-vector-left {
    position: absolute;
    left: 0rem;
    top: 0.7rem;
}
.header-five-title-inner {
	margin-bottom: 30px;
}
.header-five-title .ex-five-title {
    max-width: 374px;
    color: #FFDB20;
}
.career-five-content .ex-five-content {
    max-width: 550px;
    color: #ffffff;
}
.experienced-five-sub {
    padding-left: 50px;
}
.instructor-vector-right {
    position: absolute;
    right: 2rem;
    bottom: 1.7rem;
}
.developer-five-list li img {
    max-width: 92px;
    height: 92px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 0;
    border: 5px solid transparent;
}
.developer-five-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.developer-five-list {
    max-width: 550px;
    margin: auto;
}
.experienced-five-group {
    position: relative;
}
.developer-five-list .column-img {
    display: flex;
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
	margin-bottom: 20px;
}
.developer-profile-five {
    cursor: pointer;
    position: relative;
    cursor: pointer;
    position: relative;
    text-align: center;
    width: 230px;
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;
}
.developer-profile-five .profile-five-ovelay {
    background: rgba(255, 255, 255, 0.85);
    border: 5px solid #FFD600;
    border-radius: 60px 60px 60px 0px;
    bottom: 100%;
    display: block;
    text-align: center;
    left: 70px;
    bottom: 80px;
    opacity: 0;
    z-index: 2;
    padding: 10px;
    position: absolute;
    min-width: 213px;
    max-width: 100%;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(14px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgb(0 0 0 / 28%);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgb(0 0 0 / 28%);
}
.developer-profile-five:hover .profile-five-ovelay {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}
.developer-profile-five:hover .developer-image img{
    border: 5px solid #FFD600;
}
.profile-five-ovelay h5 {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
}
.profile-five-ovelay p {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-bottom: 0;
}

/*-----------------
	Share your knowledge
-----------------------*/

.knowledge-list-five ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.knowledge-list-group {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.knowledge-list-group img {
    width: 24px;
	margin-right: 10px;
}
.knowledge-list-group p {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;	
	color: #515151;
	margin-bottom: 0;	
}
.knowledge-list-five ul li {
    margin-bottom: 18px;
}
.knowledge-list-five {
    margin-bottom: 48px;
}
.joing-count-five img {
	animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.share-knowledge-five {
    padding: 60px 0 60px;
	background: #ffffff;
}
.share-knowledge-five .header-five-title {
	max-width: 559px;
}
.share-knowledge-five .career-five-content {
    max-width: 550px;
}
.joing-count-five {
    position: relative;
}
.joing-count-five-one {
    text-align: center;
    position: absolute;
    left: 0rem;
    top: 6.5rem;
    z-index: 2;
}
.joing-count-five-two {
    text-align: center;
    position: absolute;
    left: -4rem;
    bottom: 3rem;
    z-index: 2;
}
.joing-count-five-three {
	text-align: center;
    position: absolute;
    right: 5rem;
    top: 2rem;
    z-index: 2;
}
.joing-count-number {
    font-weight: 700;
    font-size: 32px;
    color: #2D0160;
}
.joing-count-text {
    font-weight: 500;
    font-size: 22px;
    color: #2D0160;
	margin-bottom: 0;
}

/*-----------------
	Achieve your Goals 
-----------------------*/

.goals-section-five {
    background: #FFD600;
    padding: 60px 0 60px;
}
.goals-content-five h4 {
    font-weight: 700;
    font-size: 32px;
    color: #2D0160;
    display: flex;
    justify-content: center;
}
.goals-content-five p {
    font-weight: 500;
	font-size: 16px;
	color: #515151;
}
.goals-count-five {
    border-left: 1px solid #FFF09F;
}

/*-----------------
	Transform Access
-----------------------*/

.transform-section-five {
    padding: 60px 0 60px;
}
.transform-access-content .career-five-content {
    max-width: 550px;
}
.transform-count-five-one {
    position: absolute;
    top: 0;
    left: -2rem;
    text-align: center;
}
.home-five .course-count h3 {
    margin-bottom: 0;
}
.transform-count-five-two {
    position: absolute;
    top: 3.5rem;
    right: 6.5rem;
    text-align: center;
}
.transform-count-five-three {
    position: absolute;
    bottom: 3.5rem;
    right: 3.5rem;
    text-align: center;
}

/*-----------------
	Testimonial
-----------------------*/

.testimonial-section-five {
    background: #2D0160;
    padding: 60px 0 60px;
}
.testimonial-slider-five {
    max-width: 1000px;
	width: 100%;
    margin: auto;
}
.testimonial-item {
    display: flex;
	align-items: center;
	position: relative;
}
.testimonial-content-five {
	position: relative;
    background: #ffffff;
    border-radius: 10px;
	left: 35px;
    padding: 40px;
    width: 757px;
	height: 278px;
	z-index: 2;
}
.testimonial-item:before {
    content: "";
    background: #FFD600;
    border-radius: 35px;
    width: 62px;
    height: 168px;
    left: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.testimonial-text p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #0A033C;
}
.testimonial-users h5 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.002em;
    color: #000000;
}
.testimonial-users p {
    font-weight: 400;
	font-size: 16px;
	color: #000000;
	margin-bottom: 0;
}
.testimonial-section-five .slick-prev, 
.testimonial-section-five .slick-next {
	z-index: 99;
    background: #FFFFFF;
    box-shadow: 0px 4px 60px rgb(41 44 124 / 15%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.testimonial-section-five .slick-prev {
    left: -80px;
}
.testimonial-section-five .slick-next {
    right: -80px;
}

.testimonial-section-five .slick-prev:hover, 
.testimonial-section-five .slick-next:hover, 
.testimonial-section-five .swiper-button-prev:hover, 
.testimonial-section-five .swiper-button-next:hover {
    color: #000;
	background: #FFD600;
    opacity: 1;
}
.testimonial-section-five .slick-prev:hover:before, 
.testimonial-section-five .slick-prev:focus:before, 
.testimonial-section-five .slick-next:hover:before, 
.testimonial-section-five .slick-next:focus:before, 
.testimonial-section-five .swiper-button-prev:hover:before, 
.testimonial-section-five .swiper-button-next:hover:before {
    color: #fff;
    opacity: 1;
}
.testimonial-section-five .slick-next:focus, 
.testimonial-section-five .slick-prev:focus {
    background: #FFD600;
}
.testimonial-section-five .slick-prev:before {
    font-family: "Font Awesome 5 Free";
    content:  "\f054";
	color: #392C7D;
	font-size: 16px;
	font-weight: 900;
}
[dir='rtl'] .testimonial-section-five .slick-prev:before {
    content: '→';
}
.testimonial-section-five .slick-next:before {
	font-family: "Font Awesome 5 Free";
    content:  "\f053";
	color: #392C7D;
	font-size: 16px;
	font-weight: 900;
}
[dir='rtl'] .testimonial-section-five .slick-next:before {
    content: '←';
}

/*-----------------
	Latest Blogs
-----------------------*/

.blogs-section-five {
    padding: 60px 0 60px;
}
.blog-five-item {
    position: relative;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: -3px 5px 16px rgb(0 0 0 / 7%);
    border-radius: 10px;
    margin-bottom: 20px;
	transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}
.blog-box-content {
    padding: 30px;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-five-header {
    border-bottom: 1px solid rgba(57, 44, 125, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.blog-five-footer h3 {
    display: flex;
	font-weight: 600;
	font-size: 20px;
	color: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    margin-bottom: 0;
}
.blogs-section-five .owl-theme .owl-dots, .owl-theme .owl-nav {
    margin-top: 20px;
    margin-bottom: 0;
}
.blog-five-header .blog-five-text p {
	font-weight: 600;
	font-size: 16px;
	color: #515151;
	margin-bottom: 0;    
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-five-item:hover .blog-five-text p {
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    color: #ffffff;
} 
.blog-five-item:hover .blog-five-year i {
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    color: #ffffff;
} 
.blog-five-year span {
	font-weight: 400;
	font-size: 14px;
	color: #515151;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-five-item:hover .blog-five-year span {
	color: #ffffff;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-five-year i {
	font-weight: 400;
	font-size: 14px;
	color: #392C7D;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}
.blog-five-item .product-img-five img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-five-item:hover .product-img-five img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-five-item:hover .blog-box-content {
    background-color: #2D0160;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
    
}
.blog-five-item:hover .blog-five-footer h3 a {
	color: #ffffff !important;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
}

/*-----------------
	Footer Five
-----------------------*/

.footer-five {
    position: relative;
    background: #2D0160;
	padding: 60px 0 30px;
}
.footer-top-five {
	position: relative;
	z-index: 9;
	font-size: 14px;
    margin-bottom: 60px;
}
.footer-title-five {
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}
.footer-five-left {
    position: absolute;
    top: 0;
    left: 3rem;
}
.footer-five-right {
    position: absolute;
    top: 0;
    right: 3rem;
}
.footer-five-right {
    position: absolute;
    top: 7rem;
    right: 2rem;
}
.footer-top-five .footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer-top-five .footer-address span {
    font-weight: 400;
    font-size: 16px;
    color: #FFD600;
}
.footer-top-five .footer-contact-info p {
    font-weight: 400;
	font-size: 14px;
	color: #FFFFFF;
}
.footer-top-five .footer-contact-info .phone-icon {
	font-weight: 400;
    font-size: 16px;
    color: #FFD600;
}
.footer-top-five .footer-contact-info .phone-icon i {
	transform: rotate(320deg);
}
.footer-menu-five ul li a:before {
	content: "";
	background: #FFD600;
	border-radius: 8px;	
    width: 15px;
    height: 3px;
    left: -40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.footer-menu-five ul li a {
    font-weight: 400;
	font-size: 16px;
	color: #FFFFFF;
    transition: all 0.4s ease 0s;
}
.footer .footer-menu-five ul li a {
    color: #fff !important;
    font-size: 14px;
    margin-left: 40px;
    position: relative;
    transition: all 0.4s ease 0s;
}
.footer .footer-menu-five ul li a:hover {
    color: #FFD600;
    padding-left: 5px;
}
.footer-about-five p {
    font-weight: 400;
    letter-spacing: 0.346154px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.footer-news-five .form-control {
    background: #39007A;
    border: 1px solid #C5C5D2;
    border-radius: 35px;
    min-height: 50px;
    font-size: 16px;
    color: #FFFFFF;
}
.footer-news-five .btn {
    border-radius: 32px;
    position: absolute;
    right: 0;
    top: 0px;
    width: 144px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-news-five .btn-one {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 10px 30px;
    text-align: center;
    background: #FFD600;
    border: 1px solid #FFD600;
    box-shadow: inset 0 0 0 0 #ffffff;
    border-radius: 32px;
    min-width: 110px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.btn-one:hover {
    color: #0071DC;
    border: 1px solid #0071DC;
    background: #ffffff;
    box-shadow: inset 0 50px 0 0 #ffffff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.footer-news-five .form-group {
    position: relative;
}
.social-icon-five {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin-bottom: 0px;
    align-items: center;
    float: right;
}
.social-icon-five ul li {
	margin-right: 20px;
}
.social-icon-five ul li:last-child {
	margin-right: 0;
}
.social-icon-five a {
	width: 42px;
    height: 42px;
    font-size: 16px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid #FFF;
}
.social-icon-five a:hover {
	color: #FFF;
	background: transparent;
}
.social-icon-five .twitter-icon {
	background: #2BB7E5;
}
.social-icon-five .linked-icon {
	background: #066AC9;
}
.social-icon-five .facebook-icon {
	background: #066AC9;
}
.social-icon-five .youtube-icon {
	background: #FF2D20;
}
.footer-news-five .form-group ::placeholder { 
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}  
.footer-news-five .form-group :-ms-input-placeholder { 
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}  
.footer-news-five .form-group::-ms-input-placeholder { 
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}
.social-icon-three ul li a {
    width: 42px;
    height: 42px;
}
.copyright-five {
    border-top: 1px solid #392c7d;
    padding: 40px 0 0;
}
.footer-news-five {
    margin-bottom: 30px;
}
.copyright-five .copyright-text p {
    color: #cec9e8;
    text-align: center;
    margin-bottom: 0;
}

.right-header-center {
    justify-content: center;
}
.right-header-center .landing-menu ul li a {
    color:#585858;
}
.right-header-center .landing-menu ul li a:hover {
    color: #421E76;
}
.right-header-button .login-btn,.right-header-button .register-btn {
    background: #FFFFFF;
    border-radius: 60px;
    padding: 15px 50px;
    transition: 0.5s all;
}
.right-header-button .login-btn i {
    padding-right: 5px;
}
.right-header-button .login-btn:hover, .right-header-button .register-btn:hover {
    background: #F58E06;
    color: #fff;
    box-shadow: 0 50px 0 0 inset #F58E06;
}
.right-header-button a :hover i {
    color:#fff;
}
.sign-btn-six {
    padding: 12px 30px;
    background: #F58E06;
    border-radius: 60px;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF !important;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.sign-btn-six:hover {
    background: #421E76;
    color: #000000;
    text-transform: initial;
    color: #ffffff;
    box-shadow: inset 0 0 0 0 #421E76;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.login-btn-six {
    background: #FFFFFF;
    border-radius: 60px;
    padding: 12px 35px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.login-btn-six:hover {
    background: #421E76;
    color: #ffffff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: initial;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}   

 /*-------------------
    Banner Six
-------------------------*/

.banner-sections-six {
    position: relative;
    background-image: url(../img/home-six-banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.banner-slide-six {
    padding: 124px 0 0;
}
.banner-small-circles .small-circle-one {
    position: absolute;
    top: 20px;
    left: 335px;
}
.home-six header input[type=search] {
    padding: 10px 10px 10px 30px;
    width: 0;
    background: url(../img/icon/home-search-six.svg) no-repeat 9px center;
    border: transparent;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    margin: auto;
    cursor: pointer;
}
.home-six header input[type=search]:focus {
    width: 150px;
    background-color: #fff;
    border-color: #717171;
    -webkit-box-shadow: 0 0 2px #dee2e6;
    -moz-box-shadow: 0 0 2px rgba(109,207,246,.5);
    box-shadow: 0 0 2px #dee2e6;
}
.banner-content-six .select2-container .select2-selection--single {
    border: 0;
    align-items: center;
    display: flex;
    background: #FFFAF3;
    justify-content: center;
    height: 45px;
    border-radius: 80px;
    font-weight: 400;
    border-bottom-left-radius: 80px !important;
    border-bottom-right-radius: 80px !important;
    font-size: 14px;
    color: #585858;
}
.banner-content-six .form-inner-six {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 40px;
    width: 100%;
    max-width: 585px;
}
.banner-content-six .sub-btn {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border: 1px solid transparent;
    margin: 0px;
    background: #2BB7E5;
    border-radius: 115px;
    padding: 12px 60px;
    color: #ffffff;
    border-top-left-radius: 115px !important;
    border-bottom-left-radius: 115px !important;
}
.banner-content-six .form-inner-six .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.drop-detail-six {
    max-width: 147px;
    width: 100%;
}
.banner-content-six .input-group>.form-control, .input-group>.form-select {
    position: relative;
    width: auto;
    margin-left: 0px !important;
    border: 1px solid transparent !important;
}
.banner-content-six .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #22100D;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 0;
    margin-left: -11px;
}
.banner-small-circles .small-circle-two {
    position: absolute;
    top: 260px;
    left: 935px;
}
.banner-small-circles .small-circle-three {
    position: absolute;
    right: 60px;
    bottom: 75px;
}
.banner-small-circles .small-circle-four {
    position: absolute;
    left: -30px;
    bottom: 160px;
}
.banner-sections-six .section-heading h2 {
    color:#421E76;
    max-width: 470px;
    padding: 15px 0;
    margin: 0;
}
.banner-sections-six .section-heading p {
    font-weight: 400;
    font-size: 24px;
    color: #585858;
    max-width: 520px;
    margin: 0;
    padding-bottom: 15px;
}
.banner-sections-six .section-heading h2 span {
    color:#F58E06
}
.banner-sections-six .section-heading .search-box {
    border: none;
    color:#585858;
    max-width: 750px;
}
.banner-sections-six .search-box .select2-container {
    width: 180px !important;
    background: #FFFAF3;
    border-radius: 80px;
    border: none;
    padding: 20px;
    font-weight: 400;
    font-size: 16px;
    color:#585858;
    cursor: pointer;
}
.banner-sections-six .search-box .select2-container--default .select2-selection--single {
    border: none;
    background: #FFFAF3;
}
.banner-sections-six .search-box .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 22px;
    right: 20px;
    width: 20px;
}
.banner-sections-six .search-box .select2-container--default .select2-selection--single .select2-selection__rendered {
    color:#585858;
    font-size: 14px;
}
.banner-sections-six .section-heading .search-box .search-icon {
    background: #2BB7E5;
    border-radius: 115px;
    border: none;
    padding: 20px 80px;
    border: 1px solid #2BB7;
}
.banner-sections-six .section-heading .search-box .search-icon:hover {
    box-shadow: 200px 0 0 0 inset#fff;
    background: #fff;
    border: 1px solid #2BB7E5;
}
.banner-sections-six .section-heading .search-box .search-icon:hover i {
    color:#2BB7E5
}
.banner-sections-six .section-heading .search-box input {
    color:#585858;
    min-width: 335px;
}
.banner-sections-six .banner-imgs .small-box {
    width: 90px;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(119, 185, 143, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-sections-six .banner-imgs {
    position: relative;
}
.banner-sections-six .banner-imgs .react-icon {
    position: absolute;
    top: 200px;
}
.banner-sections-six .banner-imgs .figma-icon {
    position: absolute;
    bottom: 50px;
    left: -30px;
}
.banner-sections-six .banner-imgs .banner-img-two {
    position: absolute;
    top: 250px;
    right: -120px;
}
.banner-sections-six .banner-imgs .banner-img-three {
    position: absolute;
    bottom: 48px;
    right: 35px;
}
.banner-imgs .react-icon img{
    animation: rotation 4s infinite linear;
}
  
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
  
 /*-------------------
    Top Category
 -------------------------*/
  
.top-category-six {
    padding: 80px 0;
}
.multiple-websites {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:20px;
}
.section-headings {
    position: relative;
}
.leading-univercities .item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.section-headings::after {
    width: 40px;
    height: 2px;
    background: #F58E06;
    content: "";
    position: absolute;
    top:40px;
}
.section-headings h2{
    padding-bottom: 20px;
    margin: 0;
    font-weight: 700;
    font-size: 28px;
    color: #171717;
}
.top-category-six .section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
}
.top-category-six .section-headings::after {
    top: 90px;
}
.top-category-six .section-headings p {
    max-width: 530px;
    font-weight: 600;
    font-size: 16px;
    color: #7B7B7B;
}
.course-development-slider {
    position: relative;
}
.course-development {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #F7FBFD;
    box-shadow: 0px 4px 24px rgba(223, 223, 223, 0.25);
    border-radius: 6px;
}
.top-category-list .item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.top-category-list .item img {
    filter: contrast(0.0);
}
.top-category-slider .instructor {
    background: #FFF6EA;
    border-radius: 64px;
    padding: 5px 17px;
    color: #F58E06;
}
.top-category-slider .course-development-name h2 {
    font-size: 16px;
    font-weight: 600;
    color: #2F2F2F;
    width: 100px;
    text-align: center;
    margin: 0;
}
.top-category-slider .owl-nav .owl-prev i, .top-category-slider .owl-nav .owl-next i{
    width: 41px;
    height: 41px;
    background: #ffffff;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 4px 34px 10px rgba(237, 237, 237, 0.25);
    border-radius: 25px;
    color: #F58E06;
    transition: 0.5s all;
}
.top-category-slider .owl-nav .owl-prev i, .owl-nav .owl-next i {
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-category-slider .owl-nav .owl-prev i:hover, .top-category-slider .owl-nav .owl-next i:hover {
    background: #F58E06;
    color: #FFFFFF;
}
.course-development-img img {
    height: 37px;
}
.top-category-slider .owl-nav .owl-prev {
    position: absolute;
    top:70px;
    left: -20px;
}
.top-category-slider .owl-nav .owl-next {
    position: absolute;
    top:70px;
    right: -20px;
}
  
/*-------------------------------------
    Professionals Around The World
-----------------------------------------*/
  
.trained-by-experts {
    background: #FFFBF7;
    padding: 80px 0;
    position: relative;
    z-index: 1;
}
.trained-by-experts .section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}
.trained-by-experts .section-headings p {
    max-width: 530px;
    font-weight: 600;
    font-size: 16px;
    color: #7B7B7B;
}
.online-courses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: #FFFFFF;
    border: 1px solid #F7FBFD;
    box-shadow: 0px 4px 24px rgba(223, 223, 223, 0.25);
    border-radius: 6px;
    height: 330px;
    transition: 0.5s all;
    position: relative;
}
.online-courses-img {
    background: rgba(112, 194, 23, 0.25);
    border-radius: 90px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.online-courses-name {
    text-align: center;
}
.online-courses-name span {
    font-weight: 700;
    font-size: 28px;
    color: #70C217;
}
.online-courses-name h5 {
    font-weight: 700;
    font-size: 16px;
    color: #5C5C5C;
    margin: 0;
}
.online-courses-text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #2E2E2E;
    max-width: 240px;
}
.expert-tutors .online-courses-img {
    background: #FFE3BD;
}
.certified-courses .online-courses-img {
    background: rgba(26, 188, 254, 0.26);
}
.online-students .online-courses-img {
    background: #DDDEFF;
}
.expert-tutors span {
    color: #F58E06;
}
.certified-courses span {
    color:  #2BB7E5
}
.online-students  span {
    color: #4C51CE;
}
.online-courses::after {
    width: 125px;
    height: 132px;
    background: #80CA2F;
    border-radius: 10px;
    content: "";
    position: absolute;
    z-index: -1;
    right: -4px;
    bottom: -4px;
}
.online-courses:hover:after {
    z-index: -1;
}
.online-courses:hover {
    box-shadow: 0px 4px 24px rgb(125 125 125 / 25%);
}
.online-courses:hover::after {
    background: #F58E06;
}
.expert-tutors::after {
    background: #F58E06;
}
.certified-courses::after {
    background: #2BB7E5;
}
.online-students::after {
    background: #4C51CE;
}
  
/*-------------------
    Find Best Course
-------------------------*/
  
.best-course-section {
    padding: 80px 0;
    position: relative;
}
.best-course-section .small-icons .arrow-img {
    position: absolute;
    top: 300px;
    left: 0;
}
.best-course-section .small-icons .hat-img {
    position: absolute;
    right: 50px;
    bottom: 150px;
}
.best-course-section .section-small-imgs .hat-img{
    position: absolute;
    right: 80px;
    top: 70px;
}
.best-course-section .section-small-imgs .vector-img{
    position: absolute;
    left: -18px;
    bottom: 400px;
}
.select-category  .select2-container {
    width: 245px !important;
    height: 71.5px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 10px rgba(242, 242, 242, 0.25);
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.select-category .select2-container--default .select2-selection--single {
    border: none;
}
.select-category .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 22px;
    right: 20px;
    width: 20px;
}
.best-course-heading {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    gap: 250px;
}
.best-course-heading .section-headings p{
    max-width: 600px;
    font-weight: 600;
    font-size: 16px;
    color: #7B7B7B;
}
.business-management {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 14px 44px rgba(230, 230, 230, 0.25);
    border-radius: 10px;
    padding: 20px;
}
.business-course-heading h5 {
    font-weight: 700;
    font-size: 14px;
    color: #1ABCFE;
    margin: 0;
}
.business-course-heading {
    background: rgba(26, 188, 254, 0.04);
    padding: 10px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.creative-art .business-course-heading {
    background: rgba(204, 0, 0, 0.06);
}
.personal-learning .business-course-heading {
    background: rgba(10, 207, 131, 0.03);
}
.creative-art .business-course-heading h5 {
    color: #F58E06;
}
.personal-learning .business-course-heading h5 {
    color: #85D1A0;
}
.business-management .business-img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}
.business-management .business-img img {
    border-radius: 5px;
    transition: 0.5s all;
}
.business-management .business-img img:hover {
    border-radius: 5px;
    transform: scale(1.14);
}  
.best-course-instructor-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}
.best-course-text h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #171717;
    max-width: 305px;
    padding: 15px 0;
}
.business-management .text-two {
    max-width: 275px;
}
.best-course-info {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-bottom: 18px;
    position: relative;
    border-bottom: 3px solid rgba(98, 85, 164, 0.03);
}
.best-course-info::after {
    content: "|";
    position: absolute;
    color: #C4C4C4;
    left: 123px;
}
.best-course-info span {
    font-weight: 400;
    font-size: 16px;
    color: #736969;
}
.best-course-info .lesson-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}
.instructor-img {
    display: flex;
    align-items: center;
    gap: 10px;
}
.best-course-instructor-info .instructor-img .instructor-name a {
    font-weight: 600;
    font-size: 16px;
    color: #585858;
}
.best-course-instructor-info .instructor-img .instructor-name h5 {
    font-weight: 400;
    font-size: 14px;
    color: #7B7B7B;
    margin: 0;
}
.best-course-instructor-info .course-cost {
    font-weight: 700;
    font-size: 24px;
    color: #F58E06;
}
.best-course-slider .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: #FFEACF;
    border-radius: 40px;
}
.best-course-slider .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
}
.best-course-slider .owl-dots .active {
    width: 38px;
    height: 8px;
    background: #421E76;
    border-radius: 40px;
}
.best-course-slider .owl-nav .owl-prev i, .best-course-slider .owl-nav .owl-next i {
    width: 60px;
    height: 60px;
    background: #2BB7E5;
    box-shadow: 0px 4px 10px rgba(242, 242, 242, 0.25);
    border-radius: 120px;
    transition: 0.5s all;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.best-course-slider .owl-nav {
    position: absolute;
    top: -130px;
    right: 0;
    display: flex;
    gap: 10px;
}
.best-course-slider .owl-nav .owl-prev i:hover, .best-course-slider .owl-nav .owl-next i:hover {
    background: #421E76;
}
.best-course-instructor-info .instructor-name a:hover {
    color: #F58E06;
}
.best-course-text h3 a:hover {
    color: #F58E06;
}
.feature-instructor-card .instructor-info a:hover {
    color: #F58E06;
}
  
/*-------------------------
    Master Skills Career
--------------------------------*/
  
.master-skills-section {
    background: #FCFCFC;
    padding: 80px 0 50px 0;
    position: relative;
}
.master-skills-section .small-circles .dotted-img {
    position: absolute;
    left: 300px;
    top:50px
}
.master-skills-section .small-circles .small-circle-one {
    position: absolute;
    right: 300px;
    top: 60px;
}
.master-skills-section .small-circles .small-circle-two {
    position: absolute;
    right: 40px;
     bottom: 50px;
}
.master-skills-section .small-circles .small-circle-three {
    position: absolute;
    left: -30px;
    bottom: 160px;
}
.skills-main-info .section-headings {
    padding-top: 60px;
    padding-bottom: 50px;
}
.skills-main-info .section-headings::after {
    top:105px;
}
.skills-main-info .section-headings p {
    padding-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #2E2E2E;
}
.skill-rectangle-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(219, 219, 219, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 30px;
    margin-bottom: 20px;
}
.skill-rectangle-box span {
    font-weight: 600;
    font-size: 16px;
    color: #393939;
}
.skill-rectangle-box span b {
    color:#421E76;
    font-weight: 600;
    font-size: 16px;
}
.master-skill-imgs {
    position: relative;
}
.master-skill-img-two {
    position: absolute;
    top: 15px;
     right: 45px;
}
.master-skill-img-three {
    position: absolute;
    right: 50px;
    bottom: 128px;
}
  
/*-------------------------
        Feature Instructor Two
--------------------------------*/
  
.feature-instructor-two {
    background: #FFFBF7;
    padding: 80px 0;
}
.feature-instructor-two .section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}
.feature-instructor-two .section-headings p {
    max-width: 560px;
    font-weight: 600;
    font-size: 16px;
    color: #7B7B7B;
    text-align: center
}
.feature-instructor-card {
    background: #FFFFFF;
    box-shadow: 0px 14px 44px rgba(230, 230, 230, 0.25);
    border-radius: 10px;
    padding: 15px 15px 30px 15px;
    position: relative;
    margin-bottom: 10px;
}
.feature-instructor-img {
    background: #F7FBFD;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    height: 250px;
    align-items: flex-end;
    position: relative;
    overflow: hidden;      
    cursor: pointer;
}
.feature-instructor-img:hover img {
    transform: scale(1.1);
}
.feature-instructor-img img {
      transition: 0.5s all;
}
.instructor-rated {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(230, 230, 230, 0.25);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 33px;
    position: absolute;
    top: 245px;
}
.instructor-rated i {
    color: #FAAE42;;
}
.feature-instructor-two-slider .instructor-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feature-instructor-two-slider .instructor-info a {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}
.feature-instructor-two-slider .instructor-info h5 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #066AC9;
    padding-top: 15px;
}
.feature-instructor-two-slider .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: #FFEACF;
    border-radius: 40px;
}
.feature-instructor-two-slider .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
}
.feature-instructor-two-slider .owl-dots .active {
    width: 38px;
    height: 8px;
    background: #421E76;
    border-radius: 40px;
}
      
/*-----------------------------
    Accelerate Cloud Success
-------------------------------------*/
  
.accelerate-cloud-section {
    background: #FCFCFC;
    border-radius: 11px;
    padding: 100px 0;
    position: relative;
    z-index: 1;
}
.accelerate-cloud-section .section-headings::after {
    top: 72px;
}
.accelerate-cloud-section .section-headings p {
    font-weight: 400;
    font-size: 20px;
    color: #7B7B7B;
    padding-bottom: 20px;
}
.cloud-technologies {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.cloud-technologies .technology-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(219, 219, 219, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 12px;
    width: 300px;
}
.cloud-technologies .technology-item span {
    width: 65px;
    height: 65px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cloud-technologies .technology-item p {
    max-width: 200px;
    font-weight: 400;
    font-size: 16px;
    color: #393939;
}
.cloud-technology-img .male-img {
    position: absolute;
    right: 0;
    bottom: 0;
}
.cloud-technology-img .vector-green-img {
    position: absolute;
    z-index: -1;
    top: 145px;
    right: 55px;
}
.cloud-technology-img .cloud-icon {
    position: absolute;
    right: 555px;
    top: 100px;
}
.cloud-technology-img .aero-icon {
    position: absolute;
    left: 70px;
    bottom: 150px;
}
  
/*-----------------------------
    Student Section
-------------------------------------*/
  
.student-section {
    background: #421E76;
    padding-top: 80px;
    position: relative;
}
.student-section .section-small-imgs .vector-img-one {
    position: absolute;
    right: 150px;
    top: 100px;
}
.student-section .section-small-imgs .vector-img-two {
    position: absolute;
    top: 250px;
    left: -30px;
}
.user-registration-right {
    padding-left: 78px;
}
.student-section .section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.student-section .section-headings p {
    text-align: center;
    max-width: 850px;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    padding-bottom: 80px;
}
.student-section .section-headings h2 {
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}
.students-enrolled .student-icon {
    background: #F58E06;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.multiple-students-informations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
}
.students-enrolled {
    display: flex;
    align-items: center;
    gap: 10px;
}
.students-enrolled .total-students span {
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}
.students-enrolled .total-students h5 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
}
.total-course .student-icon{
    background: #4C51CE;
}
.worldwide-student .student-icon{
    background: #2BB7E5;
}
.student-bottom-section {
    background: linear-gradient(
      to right,
      #4C51CE 0%,
      #4C51CE 50%,
      #F58E06 50%,
      #F58E06 100%
    );padding: 70px 0;
  
}
.registration-info a {
    background: #F58E06;
    border:1px solid #F58E06;
    border-radius: 60px;
    padding: 15px 50px;
    font-weight: 700;
    font-size: 16px;
    color:#fff;
    transition: 0.5s all;
}
.registration-info a:hover {
    border:1px solid #F58E06; 
    background: #fff;
    color: #F58E06;
    box-shadow: 0 70px 0 0 inset#fff;
}
.registration-info h2 {
    font-weight: 700;
    font-size: 36px;
    color: #FFFFFF;
    max-width: 295px;
    margin: 0;
    
}
.registration-info p {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    max-width: 410px;
    margin: 0;
}
.student-register-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-registration {
    display: flex;
    align-items: center;
    gap: 100px;
  }
  .registration-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .registration-info .start-course {
    background: #4C51CE;
    border:1px solid #4C51CE;
  }
  .registration-info .start-course:hover {
    border:1px solid #4C51CE; 
    background: #fff;
    color: #4C51CE;
    box-shadow: 0 70px 0 0 inset#fff;
  }
  
   /*-------------------
    Impressive Section
  -------------------------*/
  
  .impressive-section {
    padding: 80px 0;
  }
  .impressive-section .section-headings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
  .impressive-section .section-headings p {
    font-weight: 400;
    font-size: 16px;
    color: #5C5C5C;
  }
  .impressive-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(191, 191, 191, 0.25);
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .impressive-card-head {
    padding: 17px;
    border-bottom: 1px solid #E7E7E7;
  }
  .impressive-card .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .impressive-card .impressive-developer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .impressive-card .star-rating h4 {
    font-weight: 600;
    font-size: 20px;
    color: #421E76;
  }
  .impressive-card .star-rating i {
    color:#FAAE42;
  }
  .impressive-card .star-rating span {
    font-weight: 400;
    font-size: 14px;
    color: #736969;
    padding-left: 8px;
  }
  .impressive-card .star-rating :nth-child(6) {
    color:#E7E7E7
  }
  .impressive-developer .instructor-name a {
    font-weight: 600;
    font-size: 16px;
  }
  .impressive-developer .instructor-name h5 {
    font-weight: 400;
    font-size: 14px;
    color: #7B7B7B;
    margin: 0;
  }
  .impressive-card p {
    font-weight: 400;
    font-size: 14px;
    color: #2E2E2E;
    margin: 0;
    padding: 30px 0;
  }
  .impressive-developer {
    padding: 17px 0 17px 23px;
  }
  .impressive-section-slider .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: #FFEACF;
    border-radius: 40px;
  }
  .impressive-section-slider .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
  }
  .impressive-section-slider .owl-dots .active {
      width: 38px;
      height: 8px;
      background: #421E76;
      border-radius: 40px;
    }
  
   /*-------------------
    Latest News and Events
  -------------------------*/
  
  .latest-news-events {
      background: #FCFCFC;
      border-radius: 11px;
      padding: 80px 0;
      position: relative;
    }
    .latest-news-events .section-small-imgs .hat-img {
      position: absolute;
      top: 100px;
      left: 50px;
    }
    .latest-news-events .section-small-imgs .vector-img{
      position: absolute;
      right: 0;
      bottom: 130px;
    }
  .latest-news-events .section-headings {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
    }
  .latest-news-events .section-headings p {
      font-weight: 400;
      font-size: 16px;
      color: #5C5C5C;
      max-width: 620px;
      text-align: center;
    }
    .news-events-card {
      background: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(169, 169, 169, 0.25);
      border-radius: 0px 0px 10px 10px;
      margin-bottom: 10px;
      position: relative;
    }
    .news-card-body {
      padding: 27px;
    }
    .news-card-body h5 {
      font-weight: 600;
      font-size: 16px;
      color: #70C217;
      margin: 0;
    }
    .news-events-card .business h5 {
      color: #2BB7E5;
    }
    .news-events-card .development h5 {
      color: #F58E06; 
}
.news-card-body h2 {
    font-weight: 700;
    font-size: 20px;
    color: #2F2F2F;
    margin: 0;
    padding: 15px 0;
}
.news-card-body p{
    font-weight: 400;
    font-size: 16px;
    color: #5C5C5C;
    margin: 0;
}
.news-card-img .date {
    background: #4C51CE;
    border-radius: 4px;
    width: 85px;
    height: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 15px;
    transition: 0.5s all;
}
.news-card-img .date h5 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}
.news-card-img .date span {
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
}
.news-card-img .rounded-img {
    position: absolute;
    z-index: 1;
    top: 290px;
    right: 50px;
    transition: 0.5s all;
}
.latest-news-events-slider .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: #FFEACF;
    border-radius: 40px;
}
.latest-news-events-slider .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
}
.latest-news-events-slider .owl-dots .active {
    width: 38px;
    height: 8px;
    background: #421E76;
    border-radius: 40px;
}
  
/*--------------------------------------------
    Unlimited Access Courses and Labs Two
 -----------------------------------------------*/
.unlimited-courses-labs-two {
    padding: 80px 0;
    position: relative;
}
.unlimited-courses-labs-two .section-small-imgs .small-circle-one {
    position: absolute;
    right: 320px;
    top: 20px;
}
.unlimited-courses-labs-two .section-small-imgs .small-circle-two {
    position: absolute;
    right: 80px;
    bottom: 140px;
}
.unlimited-courses-labs-two .section-small-imgs .small-circle-three {
    position: absolute;
    left: -50px;
    bottom: 160px;
}
.unlimited-courses-labs-two .section-heading h2 {
    text-align: center;
    width: 600px;
    font-weight: 700;
    font-size: 32px;
    color: #171717;
    margin: 0;
}
.unlimited-courses-labs-two .section-heading {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.unlimited-courses-labs-two .lab-items-one {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
}
.unlimited-courses-labs-two .lab-items-two {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.unlimited-courses-labs-two .lab-item {
    background: #FFFFFF;
    border: 1px solid rgba(225, 225, 225, 0.25);
    box-shadow: 0px 4px 24px rgba(190, 190, 190, 0.25);
    border-radius: 6px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unlimited-courses-labs-two .multiple-labs {
    padding-bottom: 80px;
    border-bottom: 1px solid #E7E7E7;
}
.trusted-customer .section-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}
.trusted-customer .section-heading h2 {
    font-weight: 500;
    font-size: 32px;
    color: #171717;
}
.trusted-customer .section-heading h5 {
    font-weight: 700;
    font-size: 16px;
    color: #F58E06;
    margin-bottom: 0.5rem;
}
.trusted-customer .different-company .company-logo {
    width: 198px;
    height: 75px;
    background: #FFFFFF;
    border: 1px solid rgba(225, 225, 225, 0.25);
    box-shadow: 0px 4px 24px rgba(190, 190, 190, 0.25);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.trusted-customer .different-company {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.trusted-customer {
    padding-top: 80px;
}

   /*-------------------
    Footer Six
  -------------------------*/
  
  .footer-six {
    background: #421E76;
    padding: 80px 0;
  }
  .footer-top-six {
    padding-bottom: 60px;
    border-bottom: 1px solid #52219A;
  }
  .footer-six .footer-top-six h2 {
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin: 0;
    max-width: 400px;
    padding-bottom: 30px;
  }
  .footer-six .footer-top-six h5 {
    font-weight: 400;
    font-size: 20px;
    color: #F58E06;
    padding: 30px 0 20px 0;
    margin: 0;
  }
  .footer-top-six .email-address-six {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 106px;
    display: flex;
    justify-content: space-between;
    width: 525px;
    height: 75px;
    align-items: center;
    padding: 10px;
  }
  .footer-top-six .email-address-six input {
    border: none;
    font-weight: 400;
    font-size: 16px;
  }
  .footer-top-six .email-address-six input:focus {
    outline: none;
  }
  .footer-top-six .subscribe-btn-six button {
    background: #F58E06;
    border-radius: 106px;
    border: none;
    padding: 15px 40px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    transition: 0.5s all;
}
.footer-top-six .subscribe-btn-six button:hover {
    background: #52219A;
    box-shadow: 0 50px 0 0 inset#52219A;
    color:#F58E06;
}
.goto-website {
    display: flex;
    align-items: center;
    gap:10px;
    padding-bottom: 50px;
}
.goto-website .site-name {
    display: flex;
    flex-direction: column;
}
.goto-website .site-name span {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}
.goto-website .site-name a {
    font-weight: 200;
    font-size: 14px;
    color: #cccc;
}
.goto-website .envelop {
    width: 52px;
    height: 52px;
    background: #2BB7E5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.goto-website .envelop i {
    color:#fff;
    font-size: 20px;
}
.footer-six-info {
    display: flex;
    justify-content: space-between;
}
.footer-six-info h3 {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    padding-bottom: 25px;
    margin: 0;
}
.footer-six-info ul {
    margin: 0;
    padding: 0;
}
.footer-six-info ul li a {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    padding-bottom: 10px;
}
.footer-six-info ul li a:hover {
    color:#F58E06
}
.footer-contact-info-six p {
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
}
.footer-contact-info-six .address-info-six {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
}
.footer-contact-info-six .address-info-six i {
    color: #fff;
    font-size: 20px;
}
.footer-six-info ul li {
    padding-bottom: 10px;
    list-style: none;
}
.footer-six-info ul li:last-child{
    padding-bottom: 0;
}
.social-media-links .link {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-bottom-six .social-media-links {
    float: right;
}
.footer-bottom-six {
    padding-top: 30px;
}
.footer-bottom-six p {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
}
.social-media-links .facebook {
    background: #066AC9;
}
.social-media-links .twitter {
    background: #2BB7E5;
}
.social-media-links .linkedin {
    background: #066AC9;
}
.social-media-links .youtube {
    background: #FF2D20;
}
.social-media-links .link i {
    color: #fff;
    transition: 0.5s all;
}
.social-media-links .link:hover i {
    color: #F58E06
}
  
/*-----------------
	10. Login
-----------------------*/

.login-bg {
	background-image: url('../img/login-bg.png');
	background-size: cover;
	top: 0;
    left: 0;	
	position: fixed;
	min-height: 100vh;
	display: flex;
    align-items: center;
}
.login-banner {
    max-width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.welcome-login {
	padding: 20px 20px 0px 20px;
}
.mentor-course h2 {
	font-size: 30px;
	color: #000000;
	font-weight: 500;
	margin: 40px 0px 20px;
}
.mentor-course p {
	font-size: 14px;
	color: #22100D;
	margin-bottom: 0;
	max-width: 570px;
	margin: auto;
}
.login-banner img {
	max-width: 430px;
}
.login-slide.owl-theme .owl-dots .owl-dot.active span, 
.login-slide.owl-theme .owl-dots .owl-dot:hover span {
    background: #FFB54A !important;
}
.login-slide.owl-theme .owl-dots .owl-dot span {
	background: #FF875A;
}
.login-slide.owl-theme .owl-dots, 
.login-slide.owl-theme .owl-nav {
    margin-top: 40px;
}
.login-wrap-bg {
	position: absolute;
	right: 0;
	padding: 0;
}
.login-wrapper {
	max-width: 100%;
	height: 100vh;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	justify-content: center;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.login-wrapper .loginbox {
	max-width: 100%;
	width: 100%;
	font-weight: 600;
	padding: 100px;
}
.login-wrapper .loginbox .img-logo {
	margin-bottom: 54px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	align-items: center;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.login-wrapper .loginbox .img-logo img {
	width: 150px;
}
.back-home {
	margin-left: auto;
}
.back-home a {
	text-decoration: underline !important;
	font-weight: 400;
    color: #000;
}
.back-home a:hover{
    color: #F66962;
}
.login-wrapper .loginbox h1 {
	font-weight: 500;
	font-size: 30px;
	margin-bottom: 27px;
	color: #000;
}
.account-subtitle {
	color: #273656;
	font-size: 18px;
	margin-bottom: 55px;
}
.login-wrapper .loginbox label {
	margin-bottom: 15px;
	width: 100%;
	color: #685F78;
	font-weight: 500;
}
.toggle-password {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #cccccc;
	cursor: pointer;
}
.remember-me .form-check-input {
	border: 1px solid #FFDEDA;
	border-radius: 0;
}
.remember-me a {
	color: #F66962;
}
.pass-group {
    position: relative;
}
.google-bg {
	background: rgba(255, 222, 218, 0.31);
	padding: 50px;
	width: 100%;
}
.sign-google ul {
	padding: 0;
	margin:0;
	display: inline-flex;
}
.sign-google ul li {
	list-style: none;
}
.sign-google ul li a {
	padding: 0px 20px;
	color: #000;
	font-weight: 600;
	display: inline-block;
}
.sign-google ul li a,
.google-bg span a,
.google-bg p,
.back-home a {
	font-size: 14px;
}
.google-bg p a {
	color: #F66962;
}
.sign-google ul li a img {
	margin-right: 10px;
}
.sign-google ul li:first-child a {
	border-right: 1px solid #000000
}
.google-bg span a {
	color: #22100D;
	font-weight: 400;
}
.sign-google {
	margin: 32px 0px;
}
.loginbox .form-control {
	border-color:rgba(255, 222, 218, 0.71);
	border-radius: 5px;
	min-height: 50px;
}
.loginbox .form-control:focus {
	border: 1px solid #EB9481;
}
.loginbox .form-control::placeholder {
	color: #a2a2a2;
}
.toggle-password.feather-eye {
    margin-right: 1px;
	color: #6e82a3;
}
.toggle-password.feather-eye-off {
	color: #F66962;
}
.pass-checked {
	position: absolute;
    right: 40px;
    top: 55%;
    transform: translateY(-50%);
    color: #4CAF50;
	opacity: 0;
}
.pass-checked.active {
	opacity: 1;
}
.forgot-link  {
	color: #392C7D;
	font-size: 14px;
}
.forgot {
	margin-bottom: 28px;
}
.remember-me label {
	font-size: 14px;
}
.remember-me {
	margin-bottom: 30px;
}
.btn.btn-start {
	background: #FF875A;
	border-radius: 5px;
	padding: 12px 15px;
    font-weight: 700;
	color: #FFF;
    border: none;
}
.btn.btn-start:hover {
	background: #f57b4d !important;
	color: #FFF;
}
.login-wrapper .loginbox .dont-have {
	color: #273656;
	margin: 27px 0 0;
	font-size: 20px;
}
.login-wrapper .loginbox .dont-have a {
	color: #FF6575;
}
.login-wrapper .loginbox .dont-have a:hover {
	text-decoration: underline;
}
.reset-password p {
	font-size: 16px;
	color: #685F78;
	margin-bottom: 20px;
	font-weight: 500;
}
.log-wrap .login-wrapper {
	flex-direction: column;
	flex-wrap: unset;
	justify-content: unset;
}
.log-wrap .google-bg {
	margin-top: auto;
}
.custom_check {
    color: #685F78 !important;
    display: inline-block;
    position: relative;
    font-size: 14px !important;
    margin-bottom: 15px;
    padding-left: 25px;
    cursor: pointer;
	font-weight: 400 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;
	font-family: 'Noto Sans', sans-serif;
}
.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
    background-color: #FF5364;
    border-color: #FF5364;
}
.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #FF5364;
    background-color: #fff;
    border-radius: 2px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.custom_check .checkmark::after {
    content: "✔";
    font-family: 'feather' !important;
    font-weight: 900;
    position: absolute;
    display: none;
    left: 2px;
    top: -2px;
    color: #ffffff;
    font-size: 9px;
}
.custom_check input:checked ~ .checkmark:after {
    display: block;
}

/*-----------------
	11. Register Step
-----------------------*/

.register-step .login-banner img {
    max-width: 350px;
}
.login-wrapper .register-box {
    padding: 40px;
}
.login-wrapper .register-box .img-logo {
	margin-bottom: 17px;
}
.profile-box {
	background: #FAFAFA;
	border-radius: 10px;
	padding: 20px 20px 4px 20px;
	height: 100%;
}
.profile-box h3 {
	font-size: 15px;
	color: #000;
	text-align: center;
	margin: 24px 0px;
}
.personal-detail {
	background: #FFFFFF;
	box-shadow: 0px 0px 7px 10px rgba(227, 227, 227, 0.16);
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 16px;
	position: relative;
}
.personal-text h4 {
	font-size: 15px;
	font-weight: 500;
	color: #000;
	margin-bottom: 3px;
}
.personal-text p {
	font-size: 12px;
	color: #000;
	font-weight: 400;
}
.personal-detail span {
	background: rgba(224, 224, 224, 0.42);
    width: 30px;
    height: 30px;
	font-size: 14px;
	color: #000;
	margin-right: 10px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.personal-detail .active-color {
	background: #159F46;
	color: #fff;
}
.personal-form {
	background: #FFFFFF;
	border: 1px solid #c7c7c740;
	border-radius: 10px;
	padding: 20px;
	height: 100%;
	position: relative;
}
.personal-form h4 {
	font-size: 20px;
	color: #000000;
	margin-bottom: 28px;
}
.personal-form .form-select {
	border-color: rgba(255, 222, 218, 0.71);
	min-height: 40px;
	font-size: 14px;
	color: #C4C4C4;
}
.personal-form .form-select:focus {
	box-shadow: none;
}
.personal-form .form-group {
    margin-bottom: 15px;
}
.next-btn .btn-start {
	font-weight: 700;
	min-width: 163px;
	padding: 12px 15px;
}
.form-check-input:checked {
    background-color: #FF5364;
    border-color: #FF5364;
}
.form-check-input:focus {
	box-shadow: none;
	border-color: #FF5364;
}
.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    background-color: #ff5364;
    border: none;
}
.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ff5364'/%3e%3c/svg%3e");
}
.check-on .form-check-input {
	min-height: 30px;
    min-width: 70px;
	margin: 0;
	cursor: pointer;
}
.check-on {
	display: flex;
	align-items: center;
	padding: 0;
	margin: 10px 0px 30px;
}
.loginbox .check-on label {
	margin-bottom: 0;
	margin-left: 15px;
	font-size: 14px;
	color: #000000;
	font-weight: 400;
}
.btn.btn-back {
	background: #777777;
	border-radius: 5px;
	padding: 12px 15px;
    font-weight: 700;
	color: #FFF;
	margin-right: 25px;
}
.btn.btn-back:hover {
	background: #746e6e;
	color: #FFF;
}
.back-btn .btn-back {
    font-weight: 700;
    min-width: 163px;
    padding: 12px 15px;
}
.google-account {
	margin-bottom: 20px;
}
.account-list h4 {
	font-size: 16px;
	color: #000 !important;
	font-weight: 400;
	margin-bottom: 20px;
}
.account-list p {
	color: #685F78 !important;
	font-size: 14px;
}
.account-btn {
	margin-top: 10px;
}
.account-link,
.google-link {
	margin-top: 17px;
}
.account-link .btn.btn-primary {
	min-width: 243px;
	background-color: #777777 !important;
	border: 1px solid #777777 !important;
	font-size: 14px;
	padding: 7px 15px;
    color: #ffffff !important;
}
.account-link .btn.btn-primary:hover {
	opacity: 0.8;
}
.google-link .btn-primary {
	min-width: 191px;
	background-color: #fff !important;
	border: 1px solid #C4C4C4 !important;
	padding: 7px 15px;
	font-size: 14px;
	color: #263238 !important;
}
.google-link .btn-primary:hover {
	background-color: #777777 !important;
	border: 1px solid #777777 !important;
	color: #fff !important;
}
.your-done p {
	font-size: 14px;
	color: #685F78 !important;
}
.your-done p a {
	color: #F66962;
}
.work-done {
	position: absolute;
	bottom: 20px;
}
.password-strength {
	display: flex;
	margin-top: 15px;
}
.password-strength span {
	width: 100%;
    height: 5px;
    background: #E3E3E3 !important;
    margin-right: 10px;
	border-radius: 5px;
}
.password-strength span:last-child {
	margin-right: 0;
}
.weak-pass .password-strength span {
	background: #FF0000;
}
.reset-account p {
	font-size: 14px;
	color: #22100D;
	margin-top: 39px;
}
.reset-account p a {
    color: #F66962;
}
.verification-code .form-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.verification-code .form-control {
	min-height: 70px;
    width: 70px;
	text-align: center;
	margin-right: 35px;
	margin-bottom: 20px;
}
.send-code a {
	color: #392C7D;
	font-size: 14px;
	text-decoration: underline;
}
.send-code {
	margin: 7px 0px 40px;
}
.active-bar:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 40px;
	background: #159F46;
	left: 6px;
}
.progress{
    width: 110px;
    height: 110px;
    line-height: 110px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}
.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 8px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress .progress-right{
    right: 0;
}
.progress .progress-right .progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}
.progress .progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #ffffff;
    font-size: 20px;
    color: #159f46;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}
.progress.blue .progress-bar{
    border-color: #159f46;
}
.progress.blue .progress-left .progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
}
@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg);
    }
}
@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
.circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
}
.circle-bar > div canvas {
    width: 110px !important;
    height: 110px !important;
}
.circle-bar > div p {
    left: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin: 0 auto;
    transform: translate(-50%, -50%);
	font-weight: 700;
	font-size: 24px;
	color: #159F46;
}
.circle-bar > div p span {
	font-weight: 500;
	font-size: 14px;
	color: #000000;
	display: block;
}
#passwordInfo, #passwordInfos {
  font-size: 12px;
  font-weight: 400;
  margin-top: 15px;
}
.poor-active span.active#poor, 
.poor-active span.active#poors {
	background: #FF0000;
}
.avg-active span.active#poor, .avg-active span.active#weak,
.avg-active span.active#poors, .avg-active span.active#weaks {
	background: #FFB54A;
}
.strong-active span.active#poor, .strong-active span.active#weak, .strong-active span.active#strong,
.strong-active span.active#poors, .strong-active span.active#weaks, .strong-active span.active#strongs {
	background: #1D9CFD;
}
.heavy-active span.active#poor, .heavy-active span.active#weak, .heavy-active span.active#strong, .heavy-active span.active#heavy,
.heavy-active span.active#poors, .heavy-active span.active#weaks, .heavy-active span.active#strongs, .heavy-active span.active#heavys {
	background: #159F46;
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
	display: none;
}

/*-----------------
	12. Courses
-----------------------*/

.header-page,
.header-page .header-nav {
	height: 95px;
}
.header-page .scroll-sticky {
    margin-top: 0px;
}
.header-page .add-header-bg .header-border {
    border-bottom: 0;
}
.header-border {
	border-bottom: 1px solid #DFDFE0 !important;
	padding-top:12px;
	padding-bottom:12px;
}
.breadcrumb-bar {
    background: #FFF;
    padding: 25px 0;
}
.breadcrumb-list {
	display: flex;
	align-items: center;
    justify-content: space-between;
}
.breadcrumb-bar .breadcrumb-title {
    color: #000;
    font-size: 35px;
    font-weight: 700;
	margin-bottom: 0;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 16px;
    margin-bottom: 0;
    padding: 0;
}
.page-breadcrumb ol li a {
    color: #22100D;
	font-weight: 500;
}
.page-breadcrumb ol li.active {
    color: #22100D;
	font-weight: 500;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.page-breadcrumb ol li a:hover {
	color: #FF5364;
}
.page-breadcrumb ol li.active:hover {
	color: #22100D;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.breadcrumb-item+.breadcrumb-item {
    padding-right: 16px;
	font-weight: 500;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	color: #22100D;
	font-size: 11px;
	content: "\f054";
	font-weight: 600;
	line-height: 2.4;
    font-family: "Font Awesome 5 Free";
	padding-right: 16px;
}
.course-content {
	background:#fafafa;
	padding: 80px 0px;
}
.course-design {
	border: 1px solid #e9ecef;
}
.course-design .product {
	background: #fff;
}
.course-design .product:hover {
	background: #fff;
}
.course-design .product:hover .course-info p, 
.course-design .product:hover .course-name p, 
.course-design .product:hover .course-name h4 a, 
.course-design .product:hover .btn-primary,
.course-design .product:hover .course-share i,
.course-design .product:hover .product-content h3 a {
    color: #000000;
}
.course-design .product:hover .rating-img img, 
.course-design .product:hover .course-view img {
    filter: none;
}
.course-design .product:hover {
	color: #000000;
}
.course-design .product:hover .course-share i {
    color: #FF5364;
}
.course-design .all-category .btn-primary:hover{
	color: #fff;
}
.course-design .course-info p {
	font-size: 15px;
}
.course-design .product {
	padding: 12px;
}
.course-design .price h3 {
    font-size: 20px;
}
.price {
    min-width: 140px;
    padding: 6px 15px;
}

/*-----------------
	13. Pricing Plan
-----------------------*/

.title-sec {
	margin-bottom: 30px;
}
.title-sec h5 {
	font-weight: 700;
	font-size: 18px;
	color: #F66962;
	margin-bottom: 18px;
}
.title-sec h2 {
	font-weight: 700;
	font-size: 36px;
	color: #0B0B0B;
	margin-bottom: 18px;
}
.title-sec p {
	font-weight: 500;
	color: #685F78;
	padding-bottom: 10px;
}
.plan-box {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	display: flex;
    justify-content: space-between;
	margin-bottom: 25px;
	border: 1px solid #e9ecef;
}
.plan-box:hover {
}
.plan-box h4 {
	color: #FF5364;
	margin-bottom: 16px;
	font-weight: 700;
}
.plan-box p {
	color: #685F78;
	font-size: 14px;
	margin-bottom: 16px;
}
.plan-box p:last-child {
	margin-bottom: 0;
}
.plan-box h3 {
	font-size: 36px;
	display: inline-flex;
}
.plan-box h3 span {
	font-size: 16px;
	position: relative;
    top: 7px;
}
.benefit-box {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 30px;
	border: 1px solid #e9ecef;
}
.benefit-box h5 {
	font-size: 18px;
	color: #0B0B0B;
	margin-bottom: 20px;
}
.benefit-box a {
	padding: 9px;
    font-weight: 700;
    font-size: 20px;
}
.benefit-box ul {
	padding-left: 15px;
	margin-bottom: 40px;
}
.benefit-box ul li {
	font-size: 14px;
	color: #685F78;
	margin-bottom: 10px;
}
.table>:not(:first-child) {
    border-top: 0;
}
.table-price tr {
	border-color: #FFDEDA;
}
.table-price thead tr {
	border-color: transparent;
}
.table-price tr.basic-cell {
	border-left: 1px solid transparent;
}
.table-price td {
	font-size: 18px;
	font-weight: 500;
	color: #685F78;
}
.table-price .self-cell {
	background: #FF5364;
	color: #FFF;
}
.table-price .business-cell {
	background: #392C7D;
	color: #FFF;
}
.enable-item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
	margin-bottom: 35px;
}
.enable-item .check-on .form-check-input {
    min-height: 28px;
    width: 53px;
}
.main-title {
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 40px;
}
.price-sec {
	margin-bottom: 70px;
}
.price-box {
	background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;
    border: 1px solid #e9ecef;
}
.price-box h4 {
	color: #FF5364;
	margin-bottom: 16px;
	font-weight: 700;
}
.price-box p {
	color: #685F78;
	font-size: 14px;
	margin-bottom: 20px;
}
.price-box h3 {
	font-size: 36px;
	display: inline-flex;
	margin-bottom: 20px;
}
.price-box h3 span {
	font-size: 16px;
	position: relative;
    top: 7px;
}
.price-box h5 {
	font-size: 18px;
	color: #0B0B0B;
	margin-bottom: 30px;
}
.btn.btn-free {
	padding: 12px;
    font-weight: 700;
	background: #FFFFFF;
	border: 1px solid #F66962;
	border-radius: 5px;
	color: #F66962;
	transition: 0.7s;
}
.price-box ul {
	padding-left: 15px;
	margin-bottom: 40px;
}
.price-box ul li {
	font-size: 14px;
	color: #685F78;
	margin-bottom: 10px;
}
.price-box:hover .btn.btn-free {
	border: 1px solid #F66962;
	background: #F66962;
	color: #FFF;
}
.recom-badge {
	background: #392C7D;
	border-radius: 5px;
	padding: 4px;
	font-size: 14px;
	color: #FFFFFF;
	display: inline-block;
}

/*-----------------
	14. Notifications
-----------------------*/

.notify-sec h5 {
	font-weight: 700;
	font-size: 18px;
	color: #F66962;
	margin-bottom: 38px;
}
.btn-mark {
	background: #FF5364;
	font-weight: 600;
	padding: 12px;
	color: #FFFFFF;
    border-radius: 0.25rem;
}
.btn-mark:hover {
	color: #FFF;
	background: #f94355;
}
.notify-item {
	background: #FFFFFF;
	backdrop-filter: blur(17px);
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 20px;
}
.notify-item img {
	width: 50px;
	height: 50px;
	border: 1px solid #E3E3E3;
	border-radius: 50%;
}
.notify-content {
	display: flex;
}
.notify-detail {
	margin-left: 10px;
}
.notify-detail h6 a {
	font-weight: 600;
	font-size: 18px;
    color: #000;
}
.notify-detail h6 a:hover{
    color: #F66962;
}
.notify-detail h6 span {
	font-weight: 500;
	font-size: 14px;
	color: #A5ACB8;
}
.notify-detail p {
	font-size: 14px;
	color: #685F78;
	margin-bottom: 0;
}
.notify-item a.btn {
	background: #FFFFFF;
	border: 1px solid #303030;
	border-radius: 5px;
	font-weight: 600;
	color: #303030;
	padding: 12px;
    min-width: 150px;
}
.notify-item a.btn:hover {
	background: #303030;
	color: #FFF;
}
.dropdown-menu.dropdown-menu-right {
    left: auto;
    right: 0;
    margin-top: 15px;
}

/*-----------------
	15. Wishlists
-----------------------*/

.wishlist-item {
	background: #FFFFFF;
	border: 1px solid rgb(199 199 199 / 25%);
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 20px;
}
.wishlist-item:last-child {
	margin-bottom: 0;
}
.wishlist-detail {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	width: 100%;
}
.wishlist-img {
	margin-right: 20px;
	position: relative;
}
.wishlist-img img {
	max-width: 205px;
	width: 100%;
	height: 154px;
	border-radius: 10px;
}
.wishlist-info {
	overflow: hidden;
}
.wishlist-info h5 {
	margin-bottom: 16px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.wishlist-info h5 a {
	color: #000000;
	font-family: 'Poppins', sans-serif;
}
.wishlist-info h5 a:hover {
	color: #F66962;
}
.wishlist-info .course-view {
	margin-left: 30px;
}
.wishlist-item .remove-btn {
	text-align: right;
}
.price-amt {
	background: #FFFFFF;
	border-radius: 10px;
	position: absolute;
    bottom: 12px;
    right: 12px;
	padding: 6px 12px;
}
.price-amt h4 {
	color: #F66962;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 0;
}
.wishlist-item .remove-btn a {
	font-size: 16px;
	padding: 4px 12px;
}
.wish-card .card-header {
    border-bottom: 1px solid #DFDFE0;	
	padding: 35px;
}
.wish-card .card-header h5 {
    margin-bottom: 0;
	color: #000;
}
.wish-card .card-body {
    padding: 35px;
}
.wish-card {
	border-radius: 10px;
}
.wish-card .card-header {
	background: transparent;
}

/*-----------------
	16. Course Details
-----------------------*/

.subs-title {
	font-size: 18px;
	font-weight: 700;
	color: #392C7D;
	margin-bottom: 20px;
}
.course-sec p {
	font-size: 14px;
}
.overview-sec h6 {
	font-weight: 600;
	font-size: 14px;
	color: #0B0B0B;
	margin-bottom: 20px;
}
.overview-sec ul {
	padding-left: 20px;
	margin: 0 0 20px;
}
.overview-sec ul li {
	font-size: 14px;
	margin-bottom: 6px;
}
.overview-sec-body {
	padding-bottom: 24px;
}
.instructor-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	align-items: center;
	border-bottom: 1px solid #C7C7C7;
	margin-bottom: 15px;
	flex-wrap: wrap;
}
.about-instructor {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.abt-instructor-img {
	margin-right: 10px;
}
.abt-instructor-img img {
	width: 50px;
	height: 50px;
	border: 1px solid #E3E3E3;
	border-radius: 50%;
}
.instructor-wrap .rating {
	width: unset;
	margin-bottom: 15px;
	font-size: 14px;
}
.instructor-detail h6,
.instructor-detail h5 {
    font-weight: 600;
    font-size: 18px;
	margin-bottom: 5px;
}
.instructor-detail p {
    font-size: 13px;
	color: #22100D;
	margin-bottom: 0;
}
.course-info {
	border-bottom: 1px solid #C7C7C7;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.cou-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	margin-right: 15px;
}
.cou-info p {
	font-size: 16px;
	font-weight: 500;
}
.instructor-sec ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.instructor-sec ul li {
	font-size: 14px;
}
.rev-info {
	font-style: italic;
}
.btn-reply {
	background: #FFFFFF;
    border: 1px solid #392C7D;
    border-radius: 50px;
    padding: 6px 22px;
    font-weight: 500;
    font-size: 14px;
    color: #392C7D;
}
.btn-reply:hover {
	background: #392C7D;
	color: #FFF;
}
.comment-sec .form-control {
	background: rgba(229, 229, 229, 0.42);
}
.submit-btn {
	font-weight: 500;
	color: #392C7D;
	border-radius: 50px;
	padding: 9px 15px;
	min-width: 150px;
	border: 4px solid transparent;
	background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(white, white), linear-gradient(to right, rgba(180, 167, 245, 1), rgba(57, 44, 125, 1));
}
.submit-btn:hover {
	background: #392C7D;
	color: #FFF;
}
.cou-title a {
	background: rgba(161, 196, 253, 0.12);
	border-radius: 5px;
	padding: 15px;
	font-weight: 600;
	font-size: 14px;
	color: #0B0B0B;
	position: relative;
	display: block;
	font-weight: 600;
	font-size: 14px;
	padding-right: 60px;
}
.cou-title a:hover{
    color:#0B0B0B;
}
.cou-title > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078" !important;
    position: absolute;
    top: 50%;
	transform: translateY(-50%);
    right: 15px;
    color: #000;
}
.cou-title > a:not(.collapsed):after {
    content: "\f077" !important;
}
.course-card ul {
	margin: 0;
	padding: 0;
}
.course-card ul li {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #C7C7C7;
	padding: 15px 0;
}
.course-card ul li:last-child {
	border: 0;
}
.course-card ul li p {
	font-size: 14px;
	margin-bottom: 0;
}
.course-card ul li a {
	font-size: 14px;
	text-decoration: underline !important;
    color: #000;
}
.course-card ul li span {
	font-size: 14px;
	margin-left: 30px;
}
.video-sec img {
	width: 100%;
}
.video-thumbnail {
	position: relative;
}
.play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	background: rgb(1 1 1 / 40%);
	width: 90px;
	height: 90px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	color: #FFF;
}
.play-icon i {
	font-size: 50px;
    padding-left: 8px;
}
.course-fee {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: center;
	flex-wrap: wrap;
}
.inner-banner {
	padding: 45px 0;
	background: url("../img/inner-banner.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}
.inner-banner:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.76);
}
.inner-banner .container {
	position: relative;
}
.inner-banner p, .inner-banner h5, .inner-banner h2, .inner-banner .average-rating {
	color: #FFF;
}
.inner-banner h2 {
	font-size: 30px;
	margin-bottom: 15px;
}
.inner-banner p {
	font-size: 14px;
}
.web-badge {
	background: #FFB54A;
	border-radius: 50px;
	padding: 6px 15px;
	font-weight: 500;
	font-size: 14px;
	color: #FFF;
}
.web-badge:hover {
	background: #e19b36;
}
.sidebar-sec {
	margin-top: -305px;
}
.course-sec .card {
	z-index: 2;
}
.vid-bg {
	position: relative;
	z-index: 1;
}
.video-details {
	margin-top: 25px;
}
.video-details h2 {
	font-weight: 700;
	font-size: 30px;
	/* color: #159F46; */
	color: #F66962; 
	font-family: 'Poppins', sans-serif;
	margin-bottom: 12px;
}
.video-details p {
	font-size: 14px;
	color: #777777;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 12px;
}
.video-details p span {
	text-decoration: line-through;
}
.btn.btn-enroll {
	background: #159F46;
	border: 1px solid #159F46;
	border-radius: 50px;
	font-weight: 500;
	font-size: 13px;
	color: #FFF;
	padding: 13px 20px;
}
.btn.btn-enroll:hover {
	color: #FFF;
	background: #0c993e;
}
.btn.btn-wish {
    background: #FFFFFF;
    border: 1px solid #F66962;
    border-radius: 50px;
    padding: 10px;
    color: #F66962;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 15px;
}
.btn.btn-wish:hover {
	background: #F66962;
	color: #FFF;
}
.include-sec ul, .feature-sec ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.include-sec ul li {
	font-size: 14px;
	margin-bottom: 15px;
}
.include-sec ul li:last-child, .feature-sec ul li:last-child {
	margin-bottom: 0;
}
.feature-sec ul li {
	font-size: 14px;
	padding: 10px 0;
	color: #39393B;
	border-bottom: 1px solid #e7e7e7;
}
.feature-sec ul li span {
	font-weight: 700;
}
.feature-sec ul li:last-child {
	border: 0;
	padding-bottom: 0;
}
.com-info {
	display: flex;
}
.com-info h2 {
	font-size: 30px;
	color: #000;
	margin-bottom: 15px;
}
.complete-sec .video-thumbnail {
	max-width: 350px;
	margin-left: 15px;
}
.no-video .card {
	background: #FFF;
}
.no-video .video-details {
	margin: 0;
}
.btn.btn-cart {
	background: #F66962;
	border: 1px solid #F66962;
	border-radius: 50px;
	font-weight: 500;
	font-size: 14px;
	color: #FFF;
	padding: 13px 20px;
}
.btn.btn-cart:hover {
	color: #FFF;
	background: #f4554e;
}
.overflow-links {
	margin: 0 0 10px;
	padding: 0;
	list-style: none;
}
.overflow-links li {	
	display: inline-block;
}
.overflow-links li a {
	border: 1px solid #392C7D;
	border-radius: 50px;
	min-width: 150px;
	padding: 8px 10px;
	font-weight: 500;
	font-size: 16px;
	color: #392C7D;
	display: inline-block;
	margin-bottom: 15px;
    text-align: center;
    margin-right: 5px;
}
.overflow-links li a:hover, .overflow-links li a.active {
	background: #9C8FDB;
	color: #FFF;	
	border: 4px solid transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(#9C8FDB, #9C8FDB), linear-gradient(to bottom, rgba(180, 167, 245, 1), rgba(57, 44, 125, 1));	
}
#Overview, #Course_Content, #Instructor, #Reviews  {
	scroll-margin-top: 80px;
}
.complete-sec .cou-info p {
	color: #000;
}
.br-1 {
	border-right: 1px solid rgb(199 199 199 / 25%);
}
.bb-1 {
	border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.com-course .feature-sec ul li {
	border-bottom: 0;
	padding: 0 0 15px;
}
.com-course .feature-sec ul li:last-child {
	padding: 0;
}
.complete-sec h2 {
	 font-size: 30px;
	 margin-bottom: 15px;
}
.course-sec .course-info, .inner-banner .course-info,
.instructor-wrap .about-instructor {
	flex-wrap: wrap;
}
.course-sec .card {
	border-radius: 10px;
}
.page-content {
	background: #fafafa;
	padding: 60px 0 60px;
}
.instructor-page-content {
	padding: 60px 0 30px;
}
.cat-title {
	margin-bottom: 15px;	
}
.cat-title h4 {
	font-size: 18px;
	font-weight: 700;
	color: #0B0B0B;
	margin-bottom: 0px;
	display: inline-block;
}
.cat-title span {
	float: right;
	margin-bottom: 15px;
}

/*-----------------
	17. Help Center
-----------------------*/

.help-sec {
	padding: 80px 0 50px;
	background: #FAFAFA;
}
.help-title {
	padding-bottom: 15px;
}
.help-title h1 {
	font-weight: 700;
	margin-bottom: 18px;
}
.help-title p {
	font-size: 18px;
	margin-bottom: 15px;
}
.faq-card {
	background: #FFFFFF;
	border: 1px solid #e9ecef;
	border-radius: 10px;	
	margin-bottom: 30px;
}
.faq-title {
	margin-bottom: 0;
}
.faq-title a {
	padding: 15px;
	font-weight: 600;
	font-size: 16px;
	color: #000;
	position: relative;
	display: block;
	padding-right: 60px;
}

.faq-title a:hover{
    color: #000;
}

.faq-title > a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: none;
    position: absolute;
    top: 50%;
	transform: translateY(-50%);
    right: 15px;
    color: #000;
}
.faq-title > a:not(.collapsed) {
	color: #FF5364;
}
.faq-title > a:not(.collapsed):after {
    content: "\f068";
	color: #FF5364;
}
.faq-detail {
	padding: 0 15px;
}
.faq-detail p {
	margin-bottom: 15px;
}
.support-sec {
	padding: 100px 0;
	background: url('../img/support-bg.png');
	background-size: cover;
}
.support-card {
	background: #FFF;
	border-radius: 10px;
	padding: 20px;
	position: relative;
}
.support-card p, .support-card h3 {
	margin-bottom: 15px;
}
.support-card .btn {
	min-width: 150px;
    font-weight: 700;
    padding: 12px 10px;
	border-radius: 5px;
	color: #FFF;
}
.btn-contact {
	background: #1D9CFD;
}
.btn-contact:hover {
	background: #0d92f7;
}
.btn-ticket {
	background: #58247E;
}
.btn-ticket:hover {
	background: #4d1377;
}
.support-img img {
	position: absolute;
	right: 0;
	bottom: 0;
}
.page-banner {
	padding: 88px 0;
	text-align: center;
	background: url('../img/ban-bg.png') #1565C0;
	background-repeat: no-repeat;
	background-size: cover;
}
.page-banner h1 {
	font-weight: 600;
	margin-bottom: 15px;
	color: #FFF;
}
.page-banner p {
	color: #FFF;
	margin-bottom: 0;
}
.sup-title {
	margin-bottom: 30px;
}
.support-wrap {
	background: #FFFFFF;
	border: 1px solid #e9ecef;
	border-radius: 10px;	
	padding: 30px;
}
.support-wrap h5 {
	color: #0B0B0B;
	font-weight: 700;
	text-align: center;
	margin-bottom: 34px;
}
.support-wrap label {
	color: #685F78;
	font-weight: 500;
	margin-bottom: 15px;
}
.btn-submit {
	background: #FF5364;
	border: 1px solid #FF5364;
	border-radius: 5px;
	color: #FFF;
}
.btn-submit:hover {
	background: #ff3448;
	color: #FFF;
}
.support-wrap .btn-submit {
	padding: 12px 10px;
	min-width: 200px;
}

/*-----------------
	18. Job Category
-----------------------*/

.category-box {
	background: #FFFFFF;
	box-shadow: 0px 0px 6px rgba(227, 227, 227, 0.85);
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.category-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.category-img {
	margin-right: 10px;
}
.category-title h5 {
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 0;
	color: #685F78;
}
.category-box span {
	width: 30px;
	height: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	background: #FF5364;
	border-radius: 50%;
	color: #FFF;
	font-weight: 500;
	font-size: 14px;
}
.category-tab ul {
	margin: 0 0 40px;
	padding: 0;
	list-style: none;
}
.category-tab ul li {
	display: inline-block;
	margin: 0 20px 10px 0;
}
.category-tab ul li a {
	background: #E0E0E0;
	color: #0B0B0B;
	padding: 10px 15px;
	display: inline-block;
	text-align: center;
	font-size: 24px;
    font-weight: 600;
}
.category-tab ul li a.active, .category-tab ul li a:hover  {
	background: #FF5364;
	color: #FFF;
}
.category-tab.tickets-tab-blk .nav-justified .nav-item {
	flex-grow: inherit;
}
.category-tab.tickets-tab-blk ul li a {
	font-size: 14px;
	padding: 10px 26px;
}
.category-tab.tickets-tab-blk ul {
    margin: 0 0 10px;
}

/*-----------------
	19. Instructor
-----------------------*/

.instructor-box {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 30px;
    border: 1px solid #e9ecef;
}
.instructor-img {
	position: relative;
	text-align: center;
}
.instructor-img img {
	max-width: 200px;
	width: 100%;
	border-radius: 50%;
}
.rating-count {
	position: absolute;
	top: 0;
	right: 0;
}
.instructor-content {
	padding-top: 15px;
	text-align: center;
}
.instructor-content h5 {
	color: #000;
	margin-bottom: 5px;
}
.instructor-content h6 {
	color: #685F78;
	margin-bottom: 15px;
}
.instructor-content p {
	margin-bottom: 0;
	color: #685F78;
	font-weight: 500;
}
.instructor-content .rating-img, .instructor-content .course-view {
	margin-bottom: 20px;
}
.instructor-content .rating {
	margin-bottom: 0;
}
.instruct-grid.instructor-box {
	padding: 0;
}
.instruct-grid .instructor-img img {
	border-radius: 10px;
	max-width: unset;
}
.instruct-grid .instructor-content {
	padding: 15px;
}
.instruct-grid .rating-count {
	right: 20px;
	top: 20px;
	color: #FF5364;
}

/*-----------------
	20. Instructor List
-----------------------*/

.instructor-list {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
    border: 1px solid #e9ecef;
}
.instructor-list .instructor-img img {
	width: 145px;
	border-radius: 7px 0px 0px 7px;
}
.instructor-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.instructor-info > div {
	width: auto;
	margin-right: 20px;
}
.instructor-info .course-view {
	margin-left: 0;
}
.instructor-list .instructor-content {
	width: 100%;
	text-align: left;
	padding: 0 0 0 25px;
}
.instructor-list .instructor-content .rating-img, .instructor-list .instructor-content .course-view {
    margin-bottom: 10px;
}
.instructor-list .instructor-content {
	position: relative;
}
.instructor-list .instructor-content .rating-count {
	right: 20px;
	top: 20px;
}
.instructor-badge .web-badge {
	display: inline-block;
	background: rgba(255, 222, 218, 0.36);
	border-radius: 5px;
	color: #FF875A;
	font-size: 14px;
	padding: 3px 10px;
	margin-top: 10px;
}
.instructor-badge .web-badge:hover {
	background: rgba(255, 222, 218, 0.66);
}
.review-sec .review-item:not(:last-child) {
	margin: 0 0 20px;
}
.edu-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
}
.edu-wrap:last-child {
	margin: 0;
}
.edu-name {
	margin-right: 15px;
}
.edu-name span {
	width: 40px;
	height: 40px;
	background: #FF5364;
	border-radius: 50%;
	color: #FFFFFF;
	font-size: 14px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.edu-detail h6 {
	font-weight: 700;
	color: #000;
	margin-bottom: 6px;
}
.edu-detail p.edu-duration {
	font-style: italic;
	margin-bottom: 6px;
}
.edu-detail p {
	margin-bottom: 0;
}

/*-----------------
	21. Terms and Conditions
-----------------------*/

.terms-text {
    margin-bottom: 20px;
}
.terms-text h3 {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 15px;
}
.terms-text h3 span, .terms-text p a {
	color: #F66962;
}
.terms-text h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}
.terms-text h5 {
	font-size: 16px;
    font-weight: 500;
	position: relative;
}
.terms-text p, .terms-text ul li {
    color: #685F78;
    font-size: 14px;
}

/*-----------------
	49. Instructor Profile
-----------------------*/

.page-banner.instructor-bg-blk {
    padding: 32px 0;
    text-align: center;
    /* background: url(../img/instructor/instructor-bg-banner.png) #1565C0; */
    background-repeat: no-repeat;
    background-size: cover;
}
.profile-info-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex-direction: column;
}
.profile-info-blk .profile-info-img {
	display: block;
	max-width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 5px solid #FFFFFF;
	margin-bottom: 8px;
}
.profile-info-blk h4 {
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 0px;
}
.profile-info-blk h4 a {
	font-size: 32px;
	font-weight: 600;
	color: #fff;
}
.profile-info-blk h4 a:hover {
	color: #F66962;
}
.profile-info-blk h4 span {
	background: #fff;
	padding: 2px 10px;
	font-size: 14px;
	border-radius: 5px;
	margin-left: 20px;
}
.profile-info-blk p {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}
.profile-info-social {
	margin-top: 8px;
	margin-bottom: 0px;
}
.profile-info-social li a {
	color: #ffffff;
}
.profile-info-social li a:hover {
	color: #F66962;
}
.profile-info-social li:not(:last-child) {
	margin-right: 14px;
}
.sidebar-tag-labels ul {
    display: flex;
	flex-wrap: wrap;
	padding: 0px;
	margin: 0px;
	gap: 10px;
}
.sidebar-tag-labels ul li a	{
	display: block;
	background: rgba(255, 222, 218, 0.36);
	padding: 5px 10px;
	border-radius: 5px;
	color: #FF875A;
}
.sidebar-tag-labels ul li a:hover {
	background: rgba(255, 222, 218, 0.66);
}
.rating-grp {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	justify-content: space-between;
}
.profile-overview-list {
	word-wrap: break-word;
	margin-top: 23px;
}
.profile-overview-list .list-grp-blk:not(:last-child) {
	margin-bottom: 23px;
}
.profile-overview-list .list-content-blk h5 {
	font-size: 15px;
	font-weight: bold;
	color:#22100D;
	margin-bottom: 2px;
}
.profile-overview-list .list-content-blk p {
	margin:0px;
	word-wrap: break-word;
}
.contact-info-list {
	margin-top: 23px;
}
.contact-info-list .edu-wrap:not(:last-child) {
	margin-bottom: 23px;
}

/*-----------------
	23. Instructor Profile Edit
-----------------------*/

.edit-blk {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.edit-blk h5{
	margin-bottom: 0px;
}
.edit-blk a	{
	width: 30px;
	height: 30px;
	background-color: #455A64;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.edit-blk a	i {
	color: #ffffff;
	font-size: 14px;
}

/*-----------------
	24. Students
-----------------------*/

.student-box {
	border: 1px solid #e9ecef;
	border-radius: 10px;
	padding: 20px;
	margin-bottom: 30px;
}
.student-img {
	position: relative;
	text-align: center;
}
.student-content {
	padding: 15px 0px;
	text-align: center;
}
.student-content h5 {
	color: #000;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 5px;
}
.student-content h6 {
	font-size: 14px;
	color: #685F78;
	margin-bottom: 15px;
}
.student-content p {
	color: #685F78;
	margin-bottom: 0px;
}
.student-box.student-grid {
	padding: 0px;
}
.student-grid .student-img img {
	border-radius: 10px;
    max-width: unset;
}

/*-----------------
	25. Students List
-----------------------*/

.student-list {
	border: 1px solid #e9ecef;
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
	padding: 20px;
}
.student-list .student-img img {
	max-width: 100%;
	border-radius: 7px;
}
.student-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.student-list .student-content {
    width: 100%;
    text-align: left;
    padding: 0 0 0 25px;
}
.student-info > div {
	width: auto;
	margin-right: 20px;
}
.student-content h6 {
	font-size: 14px;
	color: #685F78;
	margin-bottom: 15px;
}
.student-list .student-content .loc-blk {
    margin-bottom: 10px;
}
.student-list .student-content {
	position: relative;
}
.student-list .student-content p{
	margin: 0px;
}
.student-list .profile-info-social {
    margin: 0px;
}
.student-list .profile-info-social li:not(:last-child) {
    margin-right: 10px;
}
.student-list .profile-info-social li a {
    color: #000000;
	font-size: 18px;
}
.student-list .profile-info-social li a:hover {
    color: #F66962;
}

/*-----------------
	26. Students Profile
-----------------------*/

.page-banner.student-bg-blk {
    padding: 14px 0;
    text-align: center;
    /* background: url(../img/students/student-bg-banner.png) #FFB54A; */
    background-repeat: no-repeat;
    background-size: cover;
}

/*------------------------------
	27. Settings - Students Payments
-------------------------------*/

.settings-btn-grp {
	margin-bottom: 40px;
}

.payment-method-blk .payment-list:not(:last-child) {
	margin-bottom: 32px;
}
.settings-inner-blk {
	background-color: #ffffff;
	border-radius: 10px;
}
.hvr-sweep-to-right a {
	font-size: 15px;
	font-weight: 700;
	color: #FF5364;
	padding: 8px 20px;
	border: 1px solid transparent;
	background-origin: border-box;
	background-clip: padding-box, border-box;
	background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #392C7D, #FF5364);
	border-radius: 10px;
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	overflow: hidden;
	min-height: 41px;
}
.hvr-sweep-to-right a:hover {
	color: white;
	background: #FF5364;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.payment-list h5 {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 10px;
	color: #303030;
}
.payment-list p {
	font-size: 14px;
	font-weight: 400;
	color: #303030;
	margin-bottom: 0px;
}
.grp-action-style li:not(:last-child) {
	margin-right: 10px;
}
.btn-action-style {
	display: block;
	padding:3px 7px;
}
.btn-action-dark {
	min-width: 35px;
	background-color: #455A64;
	color: #ffffff;
}
.btn-action-primary:hover, .btn-action-dark:hover, .btn-action-method:hover, .btn-action-delete:hover {
	color: #ffffff;
}
.btn-action-dark i, .btn-action-method i, .btn-action-delete i {
	font-size: 14px;
}
.btn-action-primary {
	font-size: 14px;
	line-height: 22px;
	color: #ffffff;
	padding:4px 8px;
	text-transform: uppercase;
	background-color: #159F46;
}
.btn-action-method {
	min-width: 35px;
	display: block;
	background-color: #FF875A;
	color: #ffffff;
}
.btn-action-delete {
	min-width: 35px;
	background-color: #EB4334;
	color: #ffffff;
}
.modal-styles .modal-header, .modal-styles .modal-body {
	padding:23px;
}
.modal-styles .modal-header {
	border-color: rgba(199, 199, 199, 0.25);
}
.modal-styles .close {
	border-width: 0px;
	padding: 0;
	margin: 0px;
	font-size: 23px;
	background-color: transparent;
	color: #F12804;
}
.modal-styles .modal-title {
	color: #392C7D;
	font-size: 24px;
	font-weight: 700;
	text-transform: capitalize;
}
.modal-styles .btn-modal-style {
	display: block;
	padding: 10px 25px;
	color: #ffffff;
	border-radius: 5px;
	font-size: 16px;
	margin: 0px;
	font-weight: 600;
}
.modal-styles .btn-theme {
	background-color: #FF5364;
}
.modal-styles .btn-cancel {
	background-color: #455A64;
}
.modal-styles .btn-theme:hover, .modal-styles .btn-cancel:hover {
	opacity: 0.86;
	color:#ffffff;
}
.modal-styles .modal-footer .btn-modal-style:not(:last-child) {
	margin-right: 20px;
}
.modal-styles .form-control, .modal-styles .form-select {
    border-color: rgba(255, 222, 218, 0.71);
    border-radius: 5px;
    min-height: 40px;
}
.modal-styles .form-control::-webkit-input-placeholder {
	color: #a2a2a2;
}
.modal-styles .form-control::-moz-placeholder {
	color: #a2a2a2;
}
.modal-styles .form-control:-ms-input-placeholder {
	color: #a2a2a2;
}
.modal-styles .form-control::-ms-input-placeholder {
	color: #a2a2a2;
}
.modal-styles .form-control::placeholder {
	color: #a2a2a2;
}
.modal-styles .modal-footer {
	padding: 0px 23px 23px;
	border-top: 0px solid transparent;
}
/* .modal-styles.modal {
	z-index: 1050;
} */
.pop-modal .modal-backdrop {
	z-index: 1040;
}

/*----------------------------------
	28. Settings - Students Invoices
------------------------------------*/

.settings-inner-blk table thead tr {
	font-size: 15px;
	font-weight: 500;
	background-color: #F0F0F0;
	border-radius: 5px;
}
.settings-inner-blk .settings-invoice-blk table thead tr {
	text-transform: uppercase;
}
.settings-inner-blk table tbody tr:last-child {
	border: 1px solid transparent;
}
.settings-inner-blk table tbody tr td {
	padding: 1rem 0rem;
	font-size: 15px;
}
.settings-invoice-blk table tbody tr td .invoice-no {
	color: #F66962;
}
.settings-invoice-blk table tbody tr td .badge {
	font-size: 14px;
	padding: 7px 10px;
	font-weight: 400;
	border-radius: 5px;
	color: #ffffff;
}
.settings-invoice-blk table tbody tr td .badge.status-due {
	background: #E53935;
}
.settings-invoice-blk table tbody tr td .badge.status-completed {
	background: #159F46;
}
.settings-inner-blk table tbody tr td .btn-style {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin:0px auto;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	width: 38px;
	height: 38px;
	color: #685F78;
	border: 1px solid #F1F1F1;
	border-radius: 5px;
}
.settings-inner-blk table tbody tr td .btn-style i {
	font-size: 18px;
}
.settings-inner-blk table tbody tr {
	vertical-align: middle;
}
.settings-inner-blk table tbody tr td .btn-style:hover {
	background-color: #F66962;
	color: #ffffff;
}
.settings-inner-blk table tbody tr td .btn-style svg{
	width: 18px;
	height: 18px;
}
.table-nowrap tr th, .table-nowrap tr td {
	white-space: nowrap;
}

/*----------------------------------
	29. Settings - Students Referral
------------------------------------*/

.settings-top-widget .stat-info span {
	color: #685F78;
	font-size: 14px;
	font-weight: 600;
}
.settings-top-widget .stat-info h3 {
	font-size: 28px;
	font-weight: 600;
	margin:16px 0px;
}
.settings-top-widget .stat-info p {
	color: #303030;
	font-size: 14px;
	margin-bottom: 0px;
}
.settings-top-widget .card {
	border-radius: 10px;
}
.settings-top-widget .stat-info.net-earn h3 {
	color: #1D9CFD;
}
.settings-top-widget .stat-info.bal h3 {
	color: #159F46;
}
.settings-top-widget .stat-info.avg h3 {
	color: #FF5364;
}
.settings-top-widget .stat-info.refer h3 {
	color: #1D9CFD;
}
.link-box h3{
	color: #392C7D;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 16px;
}
.link-box p {
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 8px;
}
.link-box input {
	background: rgba(227, 227, 227, 0.41);
	border-color: rgba(255, 222, 218, 0.71);
    border-radius: 5px;
    min-height: 40px;
}
.link-box a {
	display: inline-block;
	background-color: #FF5364;
	border-radius: 5px;
	padding: 10px 25px;
	font-size: 15px;
	font-weight: 700;
	color: #ffffff;
}
.link-box a:hover, .link-box a:focus {
	opacity: 0.86;
}
.withdraw-box h4 {
	font-size: 16px;
	font-weight: 700;
	color: #303030;
	line-height: 26px;
}
.withdraw-box p {
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 16px;
}
.filter-grp {
	margin-bottom: 23px;
}
.filter-grp h3 {
	font-size: 24px;
	font-weight: 600;
	color: #392C7D;
	margin:	0px;
}
.filter-grp select {
	border-radius: 10px;
}
.filter-blk div:not(:last-child) {
	margin-right: 14px;
}
.settings-referral-blk .refer-avatar-blk p {
	margin: 0px;
}
.settings-top-widget .card {
	border-radius: 10px;
}
.settings-top-widget .card.withdraw-box {
	background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #392C7D, #FF5364);	
}

/*----------------------------
	30. Setting - Support Tickets
------------------------------*/

.settings-top-widget .stat-info.ttl-tickets h3 {
	color: #1D9CFD;
}
.settings-top-widget .stat-info.open-tickets h3 {
	color: #159F46;
}
.settings-top-widget .stat-info.close-tickets h3 {
	color: #FF5364;
}
.ticket-grp p {
	margin:5px 0px 0px;
}
.tiket-suport.filter-grp {
    margin-bottom: 40px;
}
.tiket-suport .ticket-btn-grp {
	display: inline-flex;
}
.ticket-btn-grp a {
    background: #392C7D;
    border: 1px solid #392C7D;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 13px 28px;
}
.ticket-btn-grp a:hover {
	opacity: 0.86;
}
.filter-tickets ul li:not(:last-child) {
	margin-right: 7px;
}
.filter-tickets ul li a {
	display: block;
	font-size: 14px;
	padding: 10px 30px;
	background: #E0E0E0;
}
.filter-tickets ul li a:hover, .filter-tickets ul li a:focus, .filter-tickets ul li a.active {
	color: #ffffff;
	background: #FF5364;
}
.settings-inner-blk table .link {
	color: #F66962;
}
.settings-inner-blk .info-high {
	background: #E53935;
}
.settings-inner-blk .info-medium {
	background: #FFB54A;
}
.settings-inner-blk .info-low {
	background: #159F46;
}
.settings-menu .top-menu-bar li:last-child {
	margin-bottom: 23px;
}
.new-ticket-blk h4{
	font-size: 24px;
	margin-bottom: 23px;
	color: #F66962;
}
.new-ticket-blk .form-control {
	border-color: rgba(255, 222, 218, 0.71);
	border-radius: 5px;
	min-height: 40px;
}
.new-ticket-blk .form-group {
    margin-bottom: 25px;
}
.new-ticket-blk .form-group label {
	font-weight: 500;
	color: #685F78;
	margin-bottom: 15px;
}
.settings-inner-blk .form-control::-webkit-input-placeholder {
	color: #a2a2a2;
}
.settings-inner-blk .form-control::-moz-placeholder {
	color: #a2a2a2;
}
.settings-inner-blk .form-control:-ms-input-placeholder {
	color: #a2a2a2;
}
.settings-inner-blk .form-control::-ms-input-placeholder {
	color: #a2a2a2;
}
.settings-inner-blk .form-control::placeholder {
	color: #a2a2a2;
}
.settings-inner-blk .form-select:focus {
	box-shadow: none;
}
.settings-inner-blk .country-select {
	border: 1px solid rgba(255, 222, 218, 0.71);
	color: #263238;
	height: 40px;
}
.submit-ticket {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	flex-wrap: wrap;
}
.submit-ticket .btn-primary {
	background: #1D9CFD;
	border: 1px solid #1D9CFD;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
	min-width: 171px;
	padding: 13px 15px;
	margin-right: 12px;
}
.submit-ticket .btn-primary:hover {
	background: #187ac5;
	border: 1px solid #187ac5;
}
.submit-ticket .btn-dark {
	background: #263238;
	border: 1px solid #263238;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
	min-width: 171px;
	padding: 13px 15px;
}
.submit-ticket .btn-dark:hover {
	background: #5f6568;
	border: 1px solid #5f6568;
	color: #fff;
}

/*----------------------------
	31. Setting - Support View Tickets
------------------------------*/

.bug-software-head h2 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 15px;
}
.bug-software-head p {
	color: #F66962;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0px;
}
.bdr-bottom-line {
	border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.ticket-profile .course-name h4 {
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 600;
}
.ticket-profile .course-group-img img {
    max-width: 40px;
    height: 40px;
}
.ticket-profile .course-group-img {
	margin-bottom: 25px;
}
.admin-msg-list {
	padding: 0;
	margin: 0;
}
.admin-msg-list li {
	list-style: none;
	padding-bottom: 20px;
}
.comman-space-ticket {
	padding: 30px;
}
.requested-img img {
	width: 30px;
	height: 30px;
	border-radius: 50px;
	margin-right: 5px;
}
.ticket-view-blk {
	padding: 15px 0px;
}
.ticket-view-blk h5 {
	color: #685F78;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
.requested-img h4 {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	color: #000;
}
.ticket-view-blk p {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
	color: #000;
}
.ticket-view-blk .btn-success {
	background: #159F46;
	border: 1px solid #159F46;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	padding: 2px 12px;
}
.ticket-view-blk .btn-danger {
	background: #CC454E;
	border: 1px solid #CC454E;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	padding: 2px 8px;
}
.ticket-view-blk h3 {
	color: #000000;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.ticket-close-btn {
	margin-top: 15px;
}
.ticket-close-btn .btn-danger {
	background: #CC454E;
	border: 1px solid #CC454E;
	border-radius: 5px;
	font-size: 15px;
	font-weight: 400;
	width: 100%;
	padding: 10px 15px;
}
.ticket-close-btn .btn-danger:hover {
	background: #ed0b1a;
	border: 1px solid #ed0b1a;
}
.btm-para-blk h2 {
	color: #fff;
	font-size: 36px;
	font-weight: 600;
	margin: 30px 0px;
}
.dropzone {
	background: #FBFBFF;
	border: 1px dashed rgba(255, 222, 218, 0.71);
	border-radius: 5px;
	padding: 10px;
	min-height: 70px;
	text-align: center;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.dropzone.dz-clickable .dz-message {
	display: none;
}
.dropzone p {
	color: #C4C4C4;
	margin-bottom: 0;
}
.accept-drag-file p {
	color: #685F78;
	font-weight: 500;
	margin-top: 15px;
}
.your-replay-head h2 {
	font-size: 24px;
	font-weight: 600;
	color: #392C7D;
}
.your-replay-head  {
	margin-bottom: 30px;
}
.your-replay-head p {
	margin-bottom: 0;
	color: #303030;
	font-size: 14px;
}

/*----------------------------------
	32. Setting - Instructor My Courses
------------------------------------*/

.section-wrapper {
	background: #fafafa;
    padding: 80px 0px;
}
.custom-btn-blk a:hover {
	opacity: 0.86;
}
.instructor-info-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.instructor-info-blk .instructor-info-img {
    display: block;
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 5px solid #FFFFFF;
    margin-right: 8px;
}
.instructor-info-blk .instructor-info-content {
	text-align: left;
}
.instructor-info-blk h4 {
	font-size: 32px;
	font-weight: 600;
	color: #ffffff;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
}
.instructor-info-blk h4 a{
	color: #ffffff;
}
.instructor-info-blk h4 span {
    background: #fff;
	color: #000000;
	font-weight: 400;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 8px;
}
.instructor-info-blk p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
	margin: 0;
}
.instructor-overview-list {
	background: rgba(10, 20, 47, 0.33);
	border-radius: 10px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	word-wrap: break-word;
	padding: 10px;
}
.instructor-overview-list .list-grp-blk:not(:last-child) {
	margin-right: 23px;
}
.instructor-overview-list .list-content-blk h5 {
	font-size: 15px;
	font-weight: bold;
	color:#ffffff;
	margin-bottom: 2px;
}
.instructor-overview-list .list-content-blk p {
	margin:0px;
	word-wrap: break-word;
}
.instructor-bg-blk .btm-para-blk {
	margin-top: 23px;
}
.custom-card {
	border: 4px solid transparent;
	background-origin: border-box;
	background-clip: padding-box, border-box;
	background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #E3E3E3, #FFDEDA);	
	border-radius: 10px;
}
.custom-card-header {
	padding:23px;
	border-bottom: 1px solid rgba(199, 199, 199, 0.23);
}
.custom-card-header h3 {
	font-size: 24px;
    font-weight: 600;
    color: #392C7D;
    margin: 0px;
}
.custom-card-header .custom-btn-blk a {
	background: #392C7D;
    border: 1px solid #392C7D;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    padding: 13px 28px;
}
.courses-grp .course-design {
	background: #ffffff;
	border: 1px solid rgba(199, 199, 199, 0.25);
}
.courses-grp .list-course .product-img {
    -ms-flex: 0 0 205px;
    flex: 0 0 205px;
    margin-right: 24px;
    width: 205px;
	position: relative;
}

/*----------------------------
	33. Instructor - Dashboard
------------------------------*/

.sell-course-head h3 {
	color: #392C7D;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0;
}
.sell-course-head {
	border-bottom: 1px solid rgba(199, 199, 199, 0.25);
}
.sell-group-img {
	width: 150px;
	height: 112px;
	margin-right: 20px;
}
.sell-table-group p {
	font-size: 20px;
	color: #000000;
	font-weight: 500;
	margin-bottom: 0;
}
.sell-tabel-info .course-info {
	margin: 7px 0px;
	width: 270px;
}
.sell-table-group .sell-tabel-info p {
	font-size: 16px;
}
.course-instruct-blk .badge {
	min-width: 79px;
	padding: 7px 10px;
}
.settings-inner-blk .info-inter {
    background: #1D9CFD;
}
.course-stip.progress-stip {
	height: 8px;
}
.instruct-search-blk .show-filter .form-control,
.instruct-search-blk .select-form .form-select {
    max-width: 222px;
	border-radius: 10px;
}
.instruct-search-blk .select-form .form-select {
	margin-left: auto;
	border: 1px solid #EB9481;
}
.instruct-search-blk .show-filter .form-control {
	border: 1px solid #EB9481;
}
.instructor-detail h5 {
	margin-bottom: 1px;
}
.choose-search-blk .select2-container--default .select2-selection--single,
.table-select-blk .select2-container--default .select2-selection--single,
.all-select-blk .select2-container--default .select2-selection--single {
    border: 1px solid #e9ecef;
	height: 40px;
	border-radius: 10px;
}
.choose-search-blk .select2-container--default .select2-selection--single .select2-selection__rendered,
.table-select-blk .select2-container--default .select2-selection--single .select2-selection__rendered,
.all-select-blk .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 40px;
	color: #263238;
    font-size: 14px;
}
.choose-search-blk .select2-container--default .select2-selection--single .select2-selection__arrow,
.table-select-blk .select2-container--default .select2-selection--single .select2-selection__arrow,
.all-select-blk .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 36px;
}
.choose-search-blk .form-group {
	width: 222px;
	margin-left: auto;
}
.choose-search-blk.show-filter .form-control {
    border: 1px solid #e9ecef;
	border-radius: 10px;
}
.table-select-blk .form-group {
	width: 127px;
}
.instruct-search-blk {
	margin-bottom: 25px;
}
.course-instruct-blk .table-nowrap tr th,
.course-instruct-blk .table-nowrap tr td {
    white-space: inherit;
}
.sell-tabel-info {
	max-width: 270px;
}
.sell-course-head p {
	color: #303030;
	margin-bottom: 0;
	margin-top: 15px;
}
.instruct-review-blk .rev-info {
    font-style: normal;
	font-size: 14px;
}
.instruct-review-blk .btn-reply {
    padding: 6px 11px;
}
.instruct-orders-info p {
	margin-bottom: 0;
	max-width: 280px;
}
.course-instruct-blk tr:last-child {
	border-color: #fff;
}
.student-meet-head .view-icons {
	margin-left: auto;
}
.student-search-form .form-group {
	width: 100%;
	margin-right: 20px;
}
.student-search-form .form-control {
	height: 50px;
}
.export-cvv-btn .btn-primary {
	background: #FF5364;
	border-radius: 5px;
	min-width: 125px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	padding: 13px 15px;
}
.student-search-form {
	margin-top: 25px;
}
.export-cvv-btn .btn-primary:hover {
	background: #e73a4b;
}
.tak-instruct-group .student-box {
	padding: 0;
}
.student-box-blks {
	padding: 20px;
}
.enrol-student-foot ul {
	padding: 0;
	margin: 0;
}
.enrol-student-foot ul li {
	padding: 0;
	border-top: 1px solid rgb(199 199 199 / 25%);
	list-style: none;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	padding: 15px;
	color: #303030;
	font-size: 14px;
}
.enrol-student-foot ul li .instruct-stip {
	margin-left: auto;
}
.instruct-stip .progress-stip {
	width: 69px;
    margin-left: auto;
}
.instruct-stip span {
	color: #303030;
	font-size: 14px;
	margin-right: 5px;
}
.student-box-blks .student-content h5 {
	font-weight: 600;
}
.student-box-blks .student-content p {
	font-size: 14px;
}
.student-box-blks .student-img img {
    max-width: 100px;
}
.message-text-img {
	width: 38px;
	height: 38px;
	background: #FFFFFF;
	border: 1px solid #F1F1F1;
	border-radius: 5px;
	position: absolute;
	right: 20px;
	z-index: 999;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.message-text-img:hover {
	background: #ff5364;
	border: 1px solid #ff5364;
	cursor: pointer;
}
.message-text-img:hover img {
	filter: invert(1) brightness(100);
}
.tak-instruct-group .student-box {
	border: 1px solid rgb(199 199 199 / 25%);
    background: rgb(255 255 255 / 25%);
	border-radius: 5px;
}
.instruct-check-list .form-check-input {
	width: 18px;
    height: 18px;
}
.withdraw-history-head h3 {
	margin-bottom: 20px;
}
.download-widra {
	border: 1px solid #e9ecef;
	border-radius: 10px;
	width: 40px;
	height: 40px;
	margin-left: auto;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}

/*----------------------------
	34. ck - Editer
------------------------------*/

.ck.ck-toolbar,
.ck.ck-editor__editable_inline {
	border: 0 !important;
}
.ck.ck-editor {
	border: 1px solid #e9ecef !important;
	border-radius: 5px !important;
	padding: 15px !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
	box-shadow: none !important;
}
.ck.ck-editor__editable {
	height: 100px !important;
}

/*----------------------------
	35. Student Dashboard
------------------------------*/

.img-deposit-ticket {
	margin-left: auto;
}
.settings-top-widget .stat-info.ttl-tickets .student-ticket-view h3 {
    color: #F66962;
	margin-top: 0;
}
.settings-top-widget .stat-info.open-tickets .student-ticket-view h3 {
    color: #392C7D;
	margin-top: 0;
}
.settings-top-widget .stat-info.close-tickets .student-ticket-view h3 {
    color: #159F46;
	margin-top: 0;
}
.settings-top-widget .stat-info .student-ticket-view p {
	margin-bottom: 15px;
	color: #685F78;
	font-weight: 600;
}
.student-ticket-view a {
	color: #392C7D;
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline;
}

/*-----------------
	36. Deposit Instructor
-----------------------*/

.instructor-bg-blk .student-group {
	padding-bottom: 20px;
    margin-bottom: 20px;
}
.settings-top-widget .stat-info.medium-tickets h3 {
    color: #FFB54A;
	margin-top: 0;
}
.settings-top-widget .stat-info.blue-tickets h3 {
    color: #1D9CFD;
	margin-top: 0;
}
.student-deposit-blk .stat-info {
	width: 100%;
}

/*-----------------
	37. Deposit Payment Method
-----------------------*/

.radio-with-img {
	display: block;
}
.radio-with-img p.radio-deposit-item {
	display: inline-flex;
	width: 180px;
	height: 132px;
	box-sizing: border-box;
	border: none;
	margin-right: 25px;
	margin-bottom: 30px;
    align-items: baseline;
}
.radio-with-img p.radio-deposit-item label {
	display: block;
	height: 100%;
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid rgba(255, 222, 218, 0.71);
	color: #000;
	cursor: pointer;
	opacity: .8;
	transition: none;
	font-size: 13px;
	padding-top: 25px;
	text-align: center;
	margin: 0 ;
	font-size: 14px;
    font-weight: 400;
}
.radio-with-img p.radio-deposit-item label:hover, 
.radio-with-img p.radio-deposit-item label:focus, 
.radio-with-img p.radio-deposit-item label:active {
	background: rgba(21, 159, 70, 0.08);
	border: 1px solid #159F46;
	color: #000;
	margin: 0;
}
.radio-with-img p.radio-deposit-item label::after, 
.radio-with-img p.radio-deposit-item label:after, 
.radio-with-img p.radio-deposit-item label::before, 
.radio-with-img p.radio-deposit-item label:before {
	opacity: 0;
	width: 0;
	height: 0;
	margin: 0;
}
.radio-with-img p.radio-deposit-item label img {
	display: block;
	font-size: 50px;
	margin: auto;
	margin-bottom: 10px;
}
.radio-with-img p.radio-deposit-item input[type="radio"] {
	opacity: 0;
	width: 0;
	height: 0;
}
.radio-with-img p.radio-deposit-item input[type="radio"]:active ~ label {
	opacity: 1;
}
.radio-with-img p.radio-deposit-item input[type="radio"]:checked ~ label {
	opacity: 1;
	background: rgba(21, 159, 70, 0.08);
	border: 1px solid #159F46;
	color: #000;
}
.radio-with-img p.radio-deposit-item input[type="radio"]:hover, 
.radio-with-img p.radio-deposit-item input[type="radio"]:focus, 
.radio-with-img p.radio-deposit-item input[type="radio"]:active {
	margin: 0;
}
.radio-with-img p.radio-deposit-item input[type="radio"] + label:before, 
.radio-with-img p.radio-deposit-item input[type="radio"] + label:after {
	margin: 0;
}

/*-----------------
	38. Error
-----------------------*/

.error-page {
    align-items: center;
    color: #1f1f1f;
    /* display: flex; */
	background-image: url(../img/error-bg1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
	min-height: unset;
	padding-top: 25px;
}
.error-box {
    margin: 0 auto;
    max-width: 570px;
    padding: 20px 0;
    text-align: center;
    width: 100%;
}
.error-logo {
	margin-bottom: 50px;
}
.error-logo img {
	width: 150px;
}
.error-box h3 {
    color: #FA5560;
	font-weight: 600;
    font-size: 36px;
	margin-top: 20px;
}
.error-box p {
    margin-bottom: 30px;
	color: #343A40;
	font-size: 14px;
}
.error-box .btn-primary {
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    min-width: 158px;
    padding: 9px 15px;
	background: #FF5364;
    border: none;
}
.error-box .btn-primary:hover,
.error-box .btn-primary:active:not(:disabled):not(.disabled) {
	background: #e72f41;
}
.come-soon-box h5 {
	font-size: 20px;
	margin: 50px 0px 25px;
}
.error-box h4 {
	font-size: 32px;
	margin-bottom: 0;
}
.error-box h6 {
	font-size: 16px;
	margin: 35px 0px;
}
.social-icon-soon ul {
	padding: 0;
	margin: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.social-icon-soon ul li i {
	font-size: 24px;
}
.social-icon-soon ul li {
	list-style: none;
}
.social-icon-soon ul li:not(:last-child) {
	margin-right: 15px;
}
.social-icon-soon ul li .face-book {
	color: #1877F2;
}
.social-icon-soon ul li .twit-ter {
	color: #1DA1F2;
}
.social-icon-soon ul li .insta-gram {
	color: #000100;
}
.social-icon-soon ul li .linked-in {
	color: #0A66C2;
}
.social-icon-soon ul li i:hover {
	color: #ff5364;
}
.subscribe-soon .btn-danger {
	background: #FF5364;
	border: 1px solid #FF5364;
	min-width: 127px;
	font-size: 16px;
}
.subscribe-soon .btn-danger:hover {
	background: #e63333;
	border: 1px solid #e63333;
}
.subscribe-soon .form-control {
	height: 48px;
}
.countdown-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	margin-bottom: 15px;
}
.error-box .big-text {
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    margin: 0;
	margin-bottom: 15px;
	padding-bottom: 15px;
	color: #fff;
	border-bottom: 1px solid #F2F2F2;
}
.countdown-el {
    text-align: center;
	background: rgba(0, 0, 0, 0.4);
	min-width: 140px;
	padding: 38px 20px;
	border-radius: 10px;
	color: #fff;
}
.countdown-el:not(:last-child) {
	margin-right: 15px;
}
.countdown-el span {
    font-size: 26px;
}

/*-----------------
	39. All Courses
-----------------------*/

.search-filter .filter-widget h4 {
	color: #0B0B0B;
	font-size: 18px;
	font-weight: 700;
}
.search-filter .filter-widget label {
	font-size: 14px;
}
.search-filter .filter-widget .custom_check input {
	margin-right: 10px;
}
.inner-banner h5 a {
	color: #fff;
}
.inner-banner h5 a:hover {
	color: #F66962;
}
.view-icons {
    display: inline-flex;
}
.view-icons a {
	align-items: center;
	background-color: #fff;
	border: 1px solid #D2DCEC;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	color: #000;
	display: flex;
	font-size: 20px;
	justify-content: center;
	text-align: center;
	margin-right: 10px;
	width: 40px;
	height: 40px;
}
.view-icons a:hover {
	border-color: #FF6575;
	color: #FF6575;
}
.view-icons a.active {
    background-color: #FF6575;
	border-color: #FF6575;
	color: #fff !important;
}
.show-result h4 {
	font-size: 16px;
	color: #22100D;
	margin-bottom: 0;
}
.clear-text {
	margin-left: auto;
}
.clear-text p,
.clear-filter h4 {
	margin-bottom: 0;
}
.clear-text p {
	color: #000;
	font-size: 14px;
	font-weight: 500;
}
.clear-filter {
	margin-bottom: 43px;
    padding: 8px 0px;
}
.clear-filter h4 {
	font-size: 20px;
	color: #333333;
}
.clear-filter h4{
	display: flex;
    align-items: center;justify-content: center;
}
.clear-filter h4 i {
	margin-right: 9px;
}
.clear-filter h4 svg {
	margin-right: 9px;
    vertical-align: middle;
    line-height: 1;
}
.card.search-filter,
.card.post-widget {
    margin-bottom: 20px;
    border-radius: 10px;
}
.showing-list {
	margin-bottom: 44px;
}
.course-design .product:hover .product-content h3 a:hover,
.course-design .product:hover .course-name h4 a:hover {
    color: #F66962;
}
.course-share .active-heart i {
    color: #FF5364;
	font-weight: 900;
}
.course-design .price .free-color {
	color: #159F46;
}
.course-design .price {
    min-width: auto;
	padding: 8px 15px;
	text-align:center;
}
.lms-page li .page-link {
	margin-right: 15px;
	border: 1px solid #FFDEDA;
	border-radius: 5px;
	width: 40px;
	height: 40px;
	color: #000000 !important;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.lms-page {
	margin-bottom: 0;
	margin-top: 20px;
}
.lms-pagination {
	margin-bottom: 20px;
}
.lms-page li .page-link:hover { 
	background-color: #FF5364 !important;
	color: #fff !important;
}
.lms-page .page-item.active .page-link {
    background-color: #FF5364 !important;
    border-color: #FF5364 !important;
}
.page-item:first-child .page-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.show-filter .form-control {
	border-color: rgba(255, 222, 218, 0.71);
    min-height: 40px;
    font-size: 14px;
	padding-left: 30px;
}
.show-filter .form-control::placeholder {
	color: #a2a2a2;
}
.search-filter .card-body {
	padding: 20px;
}
.show-filter .search-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	position: relative;
}
.show-filter .search-group i {
	position: absolute;
	left: 9px;
	color: #F66962;
}
.categories-head i {
	margin-left: auto;
	margin-bottom: 15px;
}
.course-design .product {
	width: 100%;
}
.course-design {
	width: 100%;
}
.list-course .product {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}
.list-course .product-img {
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    margin-right: 24px;
    width: 240px;
	position: relative;
}
.head-course-title {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.head-course-title .title {
	margin-bottom: 0;
	margin-right: 20px;
}
.list-course .course-view {
    margin-left: 30px;
}
.list-course .product-content {
	padding-top: 0;
}
.rating i {
    color: #777777;
}
.rating i.filled {
    color: #FFB800;
}
.product-content h3 a,
.course-name h4 a {
    color: #000000;
}
.product-content-title {
	max-width: 320px;
}

/*-----------------
	40. Search
-----------------------*/

.custom_one .checkmark {
	border-radius: 50px;
}
.filter-widget {
	margin-bottom: 20px;
}
.filter-widget h4 {
	font-size: 1rem;
	margin-bottom: 15px;
}
.filter-widget .custom_check {
	line-height: 18px;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 60px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 92px;
}
.post-info h4 {
	font-size: 15px;
	margin: 0 0 5px;
}
.post-info h4 a {
	color: #000000;
	font-size: 14px;
}
.post-info h4 a:hover {
	color: #f66962;
}
.post-info p {
	color: #df5f5e;
	font-size: 14px;
	margin: 0;
	font-weight: 500;
}
.free-color p {
	color: #159F46;
}
.post-info p i {
	color: #1e88e5;
	font-size: 14px;
	margin-right: 4px;
}
.latest-head h4 {
	color: #0B0B0B;
	font-size: 18px;
}
.select-form .form-select {
    border-color: rgba(255, 222, 218, 0.71);
    min-height: 40px;
    font-size: 14px;
    color: #22100D;
}
.select-form .form-select:focus {
    box-shadow: none;
}

/*-----------------
	41. Blog
-----------------------*/

.blog {
	border-radius: 5px;
	position: relative;
	margin-bottom: 25px;
}
.section-blogs .blog {
	border: 1px solid #f0f0f0;
	box-shadow: 0 0 13px rgba(0,0,0,0.10);
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 20px;
	border-radius: 5px 5px 0 0
}
.blog-content {
	position: relative;
}
.blog-title {
	font-size: 24px;
	margin: 0 0 20px;
	font-weight: 600;
	line-height: 1.3;
}
.blog-title a {
	color: #000;
}
.blog-title a:hover {
	color: #F66962;
}
.blog-title:hover{
    color: #9043fe;
}
.blog-content p {
	margin: 0 0 20px;
	font-size: 14px;
	color: #685F78;
	font-weight: 400;
}
.blog-content h4 {
	font-size: 16px;
	color: #000;
	font-weight: 600;
}
.post-author img {
    border-radius: 100%;
    width: 30px;
    margin-right: 5px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #1e88e5;
    text-decoration: underline;
}
.blog-info {
    color: #26292c;
    font-size: 14px;
    margin-bottom: 20px;
}
.blog-info a {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
}
.blog-info a:hover {
	color: #F66962
}
.entry-meta {
	color: #26292c;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #26292c;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #1e88e5;
	border-color: transparent;
	color: #ffffff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	padding: 0px 20px;
	border-left: 1px solid #C4C4C4;
	color: #000;
	font-weight: 400;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.post-left ul li:first-child {
	border:0;
	padding-left: 0;
}
.post-left ul li img {
	margin-right: 9px;
}
.post-author a {
	display: flex;
	align-items: center;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}
.latest-posts li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}
.post-thumb {
	border-radius: 4px;
	width: 80px;
	float: left;
	overflow: hidden;
}
.post-thumb a img {
	border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-widget .post-info {
	margin-left: 95px;
}
.blog-widget .post-info h4 {
	font-size: 14px;
	margin: 0 0 5px;
	font-weight: 600;
}
.blog-widget .post-info h4 a {
	color: #22100D;
}
.blog-widget .post-info h4 a:hover {
	color: #F66962;
}
.blog-widget .post-info p {
	color: #000000;
	font-size: 12px;
	margin: 0;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #22100D;
    font-size: 14px;
    font-weight: 500;
}
.category-widget .categories li a:hover {
	color: #F66962;
}
.category-widget .categories > li > a > i {
	color: #F66962;
	font-size: 18px;
	margin-right: 19px;
}
.tags-card {
	padding-bottom: 5px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.tags li {
	float: left;
}
.tag {
	background-color: #FFDEDA;
	border-radius: 5px;
	color: #22100D;
	padding: 5px 10px;
	position: relative;
	margin: 0 15px 15px 0;
	font-size: 14px;
	border: 1px solid #FFDEDA;
	display: inline-block;
	font-weight: 500;
}
.tag:hover {
	background-color: #f5cbc5;
	border-color: #f5cbc5;
	color: #22100D;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    font-size: 14px;
    margin-bottom: 5px;
}
.blog-col {
	width: 100%;
}
.custom-edit-service h4 {
	padding-bottom: 15px;
	margin-bottom: 0;
}
.custom-edit-service label{
	font-size: 16px;
}
.custom-edit-service textarea.form-control {
	min-height: 200px;
}
.custom-edit-service .service-upload {
	border: 1px solid #dcdcdc;
    border-radius: .25rem;
    text-align: center;
    padding: 70px 0;
    margin-bottom: 30px;
    background-color: #fff;
    position: relative;
}
.custom-edit-service .service-upload i {
	font-size: 50px;
    color: #858585;
}
.custom-edit-service .service-upload span {
	font-size: 15px;
    color: #858585;
    margin-top: 14px;
    display: block;
}
.custom-edit-service .service-upload input[type="file"] {
	position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.custom-edit-service .upload-wrap {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.custom-edit-service .upload-wrap li {
	margin: 10px;
}
.custom-edit-service .upload-wrap li .upload-images {
	width: 100px;
}
.custom-edit-service .upload-wrap li img {
	max-width: 100%;
	height: auto;
}
.blog-read .btn-primary {
	background: #FF875A;
	border: 1px solid #FF875A;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 16px;
	color: #fff;
	text-decoration: none;
	padding: 9px 28px;
}
.blog-read .btn-primary:hover { 
	background: #fff !important; 
	border: 1px solid #FF875A !important;
	color: #FF875A;
}
.blog-widget {
	padding: 20px;
	border: 1px solid #e9ecef;
    margin-bottom: 20px;
    border-radius: 10px;
}
.tags-card {
	padding-bottom: 5px;
}
.blog-widget .card-body {
	padding: 0px;
}
.blog-widget .card-header {
    border-bottom: 0px;
	padding: 0px;
	margin-bottom: 20px;
}
.blog-search .input-group .btn-primary {
	background: #FF5364;
	border: 1px solid #FF5364;
}
.blog-search .input-group .form-control {
	background: rgba(229, 229, 229, 0.42);
	border: 0;
}
.blog-search .input-group .form-control::placeholder {
	color: #a2a2a2;
	font-size: 14px;
}
.blog-widget .card-header .card-title {
   font-size: 18px;
   color: #0B0B0B;
   font-weight: 700;
}
.post-widget .post-info p img {
	margin-right: 6px;
}
.load-more .btn-primary {
	background: #FF5364;
	border: 1px solid #FF5364;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 700;
	padding: 9px 48px;
	margin-top: 25px;
}
.load-more .btn-primary:hover {
	background: #ed4051;
	border: 1px solid #ed4051;
}

/*-----------------
	42. Blog Grid
-----------------------*/

.blog-grid-box {
	background: #fff;
    padding: 15px;
    margin: 0px 10px;
    margin-top: -20px;
    position: relative;
	border-radius: 5px;
}
.blog-grid-box .post-left ul li,
.grid-modern .post-left ul li {
    padding: 0px 5px;
}
.blog-grid-box .post-left ul li:first-child,
.grid-modern .post-left ul li:first-child {
	padding-left: 0;
}
.grid-blog .blog-image {
	margin-bottom: 0;
}
.grid-blog {
	margin-bottom: 20px;
}

/*-----------------
	43. Blog Modern
-----------------------*/

.grid-modern .blog-image {
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 25px;
}
.blog-modern-box {
	position: absolute;
	bottom: 0;
	padding: 20px;
}
.blog-modern-box .blog-title a {
    font-size: 20px;
	color: #fff;
}
.blog-modern-box .blog-title a:hover {
	color: #ff5364;
}
.blog-modern-box .post-left ul li {
	color: #fff;
	font-size: 12px;
}

/*-----------------
	44. Mycourse
-----------------------*/

.mycourse-student {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.student-filter {
	margin-left: auto;
}
.show-filter .mycourse-student .form-control {
	max-width: 222px;
}
.student-filter .select-form .form-select {
    min-width: 222px;
}
.student-group .course-share {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	align-items: center;
	justify-content: center;
}
.course-student-header {
	background: #645b73;
	padding: 25px 0px;
}
.student-group .course-group-img img {
	max-width: 100px;
    height: 100px;
    border-radius: 10px;
	border: 5px solid #FFFFFF;
}
.student-group:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: 0;
	background: #fff;
	opacity: 0.25;
}
.student-group {
	position: relative;
}
.student-group .course-name h4 {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.student-group .course-name h4 a {
	font-size: 32px;
	font-weight: 600;
	color: #fff;
}
.student-group .course-name h4 a:hover {
	color: #F66962;
}
.student-group .course-name h4 span {
	background: #fff;
	padding: 2px 10px;
	font-size: 14px;
	border-radius: 10px;
	margin-left: 20px;
}
.student-group .course-name p {
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}
.student-group .course-share .btn-primary {
	background-color: #FF5364;
    border: 1px solid #FF5364;
	font-size: 14px;
	font-weight: 600;
	padding: 13px 26px;
}
.student-group .course-group-img img {
	margin-right: 25px;
}
.my-student-list ul {
	display: flex;
	padding: 0;
	margin: 0;
}
.my-student-list ul li {
	list-style: none;
	margin-right: 20px;
}
.my-student-list ul li a {
	color: #fff;
	padding: 0px 10px;
	padding-bottom: 10px;
}
.my-student-list ul li .active {
	border-bottom: 4px solid #fff;
}
.my-student-list ul li a:hover {
	border-bottom: 4px solid #fff;
}
.student-group .course-group {
	padding-bottom: 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rating-student {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.product .rating-student .rating {
	margin-left: 0;
}
.edit-rate {
	margin-left: auto;
	cursor: pointer;
}
.progress-stip {
	display: flex;
    height: 20px;
    overflow: hidden;
    background-color: #e9ecef;
    border-radius: 50px;
}
.active-stip {
	width: 35%;
}
.progress-stip .bg-success {
    background-color: #159f46 !important;
}
.start-leason .btn-primary {
	background: #392C7D;
	border: 1px solid #C4C4C4;
	border-radius: 5px;
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	padding: 13px 15px;
}
.course-design .product:hover .start-leason .btn-primary {
    color: #fff;
}
.edit-rate a {
	font-size: 14px;
}
.student-percent p {
	font-size: 14px;
	margin: 20px 0px;
}
.start-leason .btn-primary:hover {
    background: #3b2999;
    border: 1px solid #3b2999;
}
.student-widget {
	border: 1px solid #e9ecef;
	border-radius: 10px;
}
.message-student-widget {
	margin-bottom: 20px;
}
.student-widget-group {
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	padding-bottom: 0;
}
.group-btn .btn-primary {
	width: 100%;
	padding: 13px 15px;
}
.course-design .product:hover .group-btn .btn-primary {
    color: #fff;
}
.publish-btn {
	font-size: 14px;
	font-weight: 600;
	background: #FF725E;
	border: 1px solid #C4C4C4;
	border-radius: 5px;
}
.publish-btn:hover {
	background: #ed5843;
	border: 1px solid #ed5843;
}
.cancel-btn {
	font-size: 14px;
	font-weight: 600;
	background: #777777;
	border: 1px solid #C4C4C4;
	border-radius: 5px;
}
.cancel-btn:hover {
	background: #5e5959;
	border: 1px solid #5e5959;
}
.publish-rate {
	display: none;
}
.purchase-widget .student-widget-group {
	padding: 25px;
}
.lesson-group .cou-title {
	margin-bottom: 0;
}
.lesson-group .cou-title span {
	float: right;
}
.lesson-group .cou-title a {
	background: transparent;
}
.lesson-group .course-card {
	background: #FFFFFF;
	border: 1px solid #E5E5E5;
	border-radius: 10px;
	margin-bottom: 10px;
}
.lesson-group .card-collapse {
	padding: 15px;
}
.lesson-group .course-card ul li {
	border-bottom: 0;
}
.play-intro {
	color: #F66962;
}
.lesson-percent {
	border-bottom: 1px solid #E5E5E5;
}
.lesson-percent p {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	font-size: 12px;
}
.lesson-percent p span {
	margin-left: auto;
}
.progress-stip .bg-success {
	border-radius: 50px;
}
.lesson-widget-group {
	background: #fff;
    padding: 20px;
    border-radius: 10px;
}
.lesson-introduction .tittle {
	color: #F66962;
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 20px;
}
.introduct-video img {
	width: 100%;
}

/*-----------------
	45. Message
-----------------------*/

.chat-page .content {
	padding: 110px 0 0 0;
}
.chat-page .footer {
	display: none;
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.chat-cont-left {
    border-right: 1px solid #f0f0f0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    left: 0;
	margin-right: 1%;
    max-width: 35%;
    position: relative;
    z-index: 4;
	background: #fff;
	border: 1px solid rgb(199 199 199 / 25%);
	border-radius: 5px;
	margin-bottom: 20px;
}
.chat-cont-left .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    color: #161c2d;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.chat-cont-left .chat-search {
    background-color: #fff;
    width: 100%;
}
.chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    bottom: 0;
    color: #666;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    right: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    border-radius: 5px;
}
.chat-cont-right .chat-scroll {
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
}
.chat-cont-left .chat-users-list {
    background-color: #fff;
	border-radius: 5px;
}
.chat-cont-left .chat-users-list a.media {
    background: #fff;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
	border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.badge-active {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #22100D;
	font-weight: 600;
	font-size: 16px;
    text-transform: capitalize;
	text-align: left;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #685F78;
    font-size: 14px;
    line-height: 24px;
	text-align: left;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-cont-right {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
	background: #fff;
	border: 1px solid rgb(199 199 199 / 25%);
	border-radius: 5px;
	margin-bottom: 20px;
	padding-top: 0;
}
.chat-cont-right .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background: #fff;
	border-bottom: 1px solid rgb(199 199 199 / 25%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-cont-right .chat-header .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
    color: #161c2d;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    height: 30px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    margin-left: 10px;
    width: 30px;
}
.chat-cont-right .chat-body {
    background-color: #fff;
}
.chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
	bottom: 0;
    position: absolute;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px;
	border-radius: 10px;
	display: inline-block;
	position: relative;
	margin-bottom: 20px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #303030;
    margin-bottom: 0;
}
.chat-cont-right .chat-body .media .media-body .msg-box .chat-msg-info {
	color: #685F78;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
	position: relative;
}
.chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #F5F5F5;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box .chat-msg-info {
	justify-content: start;
}
.chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
	position: relative;
}
.chat-cont-right .chat-body .media.sent .avatar {
    height: 30px;
    width: 30px;
    bottom: 0;
    right: 0;
    position: absolute;
}
.chat-cont-right .chat-body .media.sent .media-body {
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
    margin-left: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #FF875A;
	float: right;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #fff;
}
.chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-footer {
    background-color: #fff;
    padding: 10px;
    position: relative;
}
.chat-cont-right .chat-footer .input-group {
    width: 100%;
	background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
	padding: 5px;
}
.chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .btn, 
.chat-cont-right .chat-footer .input-group .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-cont-left .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff5837;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box h6 {
    font-size: 10px;
    margin-bottom: 0;
	color: #ff5b37;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box h6 {
	text-align: right;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
    clear: both;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: #f6f6f6;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: #919191;
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}
@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.bgg-yellow {
	background: #FF7A03;
	color: #fff;
    border-radius: 50%;
    width: 17px;
    line-height: 17px;
    padding: 0;
    height: 17px;
}
.avatar > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.chat-cont-right .chat-footer .input-group .btn-file i {
	font-size: 16px;
}
.add-compose {
	border-bottom: 1px solid rgb(199 199 199 / 25%);
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.add-compose i {
	margin-right: 10px;
}
.add-compose .btn-primary {
	color: #F66962;
	font-size: 14px;
	background: #FFFFFF;
	border: 1px solid #F66962;
	border-radius: 50px;
	padding: 13px 41px;
}
.add-compose .btn-primary:hover {
	background-color: #FF875A;
    border: 1px solid #FF875A;
	color: #fff;
}

/*-----------------
	46. Cart
-----------------------*/

.cart-remove .btn-primary {
	font-size: 16px;
	border: 1px solid #FF5364;
	background: #FFFFFF;
	color: #FF5364;
	font-weight: 500;
    border-radius: 0;
    padding: 4px 11px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.cart-remove .btn-primary:hover {
	font-size: 16px;
	border: 1px solid #FF5364;
	background: #FF5364;
	color: #fff;
}
.course-design .product:hover {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.course-design .product:hover .cart-remove .btn-primary {
    color: #fff;
	background: #FF5364;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.cart-widget .student-widget .course-design {
	border: 1px solid #e9ecef;
	border-radius: 5px;
	background: #fff;
	padding: 0;
}
.cart-widget .student-widget-group {
	padding: 0;
}
.cart-head {
	padding: 20px;
	border-bottom: 1px solid #e9ecef;
}
.cart-head h4 {
	margin-bottom: 0;
	font-size: 20px;
}
.cart-group,
.cart-total {
	padding: 20px;
}
.cart-total {
	border-top: 1px solid #e9ecef;
}
.check-outs .btn-primary {
	background: #FF5364;
	border: 1px solid #FF5364;
	width: 100%;
	color: #fff;
	padding: 12px 15px;
	font-size: 16px;
	font-weight: 700;
}
.check-outs .btn-primary:hover {
	background: #e14151;
	border: 1px solid #e14151;
}
.condinue-shop .btn-primary {
	background: #fff;
	border: 1px solid #FF5364;
	width: 100%;
	color: #FF5364;
	padding: 12px 15px;
	font-size: 16px;
	font-weight: 700;
}
.condinue-shop .btn-primary:hover {
	background: #FF5364;
	border: 1px solid #FF5364;
	color: #fff;
}
.cart-subtotal p {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	width: 100%;
	font-size: 20px;
	font-weight: 500;
	color: #000000;
}
.cart-subtotal span {
	margin-left: auto;
	font-size: 20px;
	font-weight: 600;
}

/*-----------------
	47. Ceckout
-----------------------*/

.checkout-form {
	padding: 20px;
}
.checkout-form label {
	color: #685F78;
    font-weight: 600;
	font-size: 16px;
}
.checkout-form .form-control,
.checkout-form .form-select {
	border-color: rgba(255, 222, 218, 0.71);
    border-radius: 5px;
    min-height: 40px;
}
.checkout-form .form-control:focus {
	border: 1px solid #EB9481;
}
.checkout-form .form-group {
	margin-bottom: 20px;
}
.checkout-form .form-select:focus {
	box-shadow: none;
}
.checkout-form .form-control::placeholder {
	font-size: 14px;
	color: #a2a2a2;
}
.checkout-widget .student-widget-group {
	padding: 0;
}
.ship-check .form-check-input {
	border-radius: 0;
	border-color: #FFDEDA;
	margin-right: 10px;
	font-size: 20px;
	margin-top: 0;
}
.ship-check {
	font-size: 14px;
	margin-bottom: 15px;
}
.pay-method {
	margin-top: 40px;
}
.payment-btn .btn-primary {
	background: #FF5364;
	border: 1px solid #FF5364;
	color: #fff;
	padding: 12px 15px;
	font-size: 16px;
	font-weight: 700;
	min-width: 600px;
}
.payment-btn .btn-primary:hover {
	background: #e14151;
	border: 1px solid #e14151;
}
.select-plan-group .student-widget-group {
	padding: 20px;
}
.plan-header {
	background: #392C7D;
}
.plan-header h4 {
	font-size: 24px;
	color: #fff;
	text-align: center;
	padding: 15px 15px;
    margin-bottom: 0;
}
.basic-plan h3 {
	font-size: 24px;
	color: #FF5364;
	margin-bottom: 15px;
}
.basic-plan h2 {
	font-size: 36px;
	color: #392C7D;
	font-weight:700;
	margin-bottom: 0;
}
.basic-plan h2 span {
	font-size: 18px;
}
.basic-plan p {
	font-size: 14px;
	margin-bottom: 15px;
}
.plan-header {
	margin-bottom: 20px;
}
.benifits-feature ul {
	padding: 0;
	margin: 0;
}
.benifits-feature ul li {
	list-style: none;
}
.wallet-method .custom_radio {
    color: #555;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.wallet-method .custom_radio .checkmark {
    height: 25px;
    width: 25px;
}
.wallet-method .custom_radio .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ffe6e1;
    border-radius: 50%;
}
.wallet-method .custom_radio input {
    position: absolute;
    opacity: 0;
}
.wallet-method .custom_radio input:checked ~ .checkmark:after {
    opacity: 1;
}
.wallet-method .custom_radio .checkmark:after {
    display: block;
    content: "";
    position: absolute;
    opacity: 0;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ff5b37;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.wallet-method {
	margin: 10px 0px;
}
.benifits-feature {
	margin: 30px 0px;
}
.benifits-feature h3 {
	font-size: 18px;
	color: #000;
	font-weight: 700;
	margin-bottom: 30px;
}
.benifits-feature ul li {
	font-size: 14px;
	color: #685F78;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}
.benifits-feature ul li i {
	font-size: 7px;
	margin-right: 10px;
}
.plan-change .btn-primary {
	font-size: 16px;
	font-weight: 700;
	color: #392C7D;
	background:#fff;
	border: 1px solid #392C7D;
	border-radius: 0px;
	width: 100%;
	padding: 12px 15px;
}
.plan-change .btn-primary:hover {
	background: #392C7D;
	border: 1px solid #392C7D;
	color: #fff;
}

/*-----------------
	48. Student Profile
-----------------------*/

.settings-widget {
	border: 1px solid #e9ecef;
    margin-bottom: 30px;
	border-radius: 10px;
}
.settings-menu {
	background: #fff;
	border-radius: 10px;
	padding: 15px;
}
.settings-menu h3 {
	font-size: 18px;
	color: #392C7D;
	font-weight: 600;
	margin: 5px 0px 20px;
}
.settings-menu ul {
	display: block;
	border: 0;
	padding: 0px;
	margin: 0;
	list-style: none;
}
.settings-menu ul li {
	border-top: 1px solid rgb(199 199 199 / 25%);
	position: relative;
}
.settings-menu ul li a {
    color: #303030;
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    border: 0 ;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    line-height: normal;
	padding: 20px;
}
.settings-menu ul li:hover, .settings-menu ul li:focus {
    color: #303030;
	background: rgba(255, 222, 218, 0.25);
}
.settings-menu ul li:hover:before {
	content: "";
	position: absolute;
	background: #FF5364;
	width: 2px;
	height: 40px;
	left: 9px;
	top: 11px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	opacity: 1;
}
.settings-menu ul li:before {
	opacity: 0;
	content: "";
	position: absolute;
	background: #FF5364;
	width: 2px;
	height: 40px;
	left: 9px;
	top: 11px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.settings-menu ul li:hover a {
	color: #303030;
}
.settings-menu ul .active {
	background: rgba(255, 222, 218, 0.25);
}
.settings-menu ul li i {
	color: #FF875A;
}
.settings-menu ul .active i {
	color: #FF875A;
}
.settings-menu ul li a i {
	margin-right: 15px;
	font-size: 20px;
}
.settings-menu ul .active:before {
	content: "";
	position: absolute;
	background: #FF5364;
	width: 2px;
	height: 40px;
	left: 9px;
	top: 11px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	opacity: 1;
}
.go-dashboard .btn-primary {
	background: #392C7D !important;
	border: 1px solid #392C7D !important;
	border-radius: 5px;
	font-size: 14px;
	color: #fff !important;
	padding: 13px 28px;
}
.go-dashboard .btn-primary:hover {
	opacity: 0.86;
}
.profile-bg img {
	width: 100%;
	border-radius: 5px 5px 0 0;
}
.profile-bg {
	position: relative;
}
.profile-bg h5 {
	position: absolute;
	background: #FFFFFF;
	border-radius: 10px;
	padding: 3px 10px;
	color: #000000;
	font-size: 14px;
	right: 20px;
	top: 20px;
	margin: 0;
}
.profile-img img {
	width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    bottom: -60px;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
	border: 5px solid #FFFFFF;
}
.profile-name h4 {
	font-size: 20px;
	color: #22100D;
	font-weight: 600;
	margin-bottom: 5px;
}
.profile-name p {
	color: #685F78;
	font-size: 14px;
	margin-bottom: 20px;
}
.profile-group {
	padding: 30px;
	padding-top: 70px;
}
.profile-heading {
	padding: 25px;
	border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.profile-heading h3 {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 15px;
	margin-top: 0;
}
.profile-heading p {
	margin-bottom: 0;
}
.profile-details .course-group {
	padding: 25px;
	border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.profile-share {
    margin-left: auto;
}
.profile-share .btn-success {
	background: transparent;
	color: #159F46 !important;
	border: 1px solid #159F46;
	margin-right: 10px;
	border-radius: 0;
	padding: 7px 22px;
	font-weight: 500;
	border-radius: 5px;
}
.profile-share .btn-success:hover {
	background: #159F46 !important;
	color: #fff !important;
	border: 1px solid #159F46;
}
.profile-share .btn-danger {
	background: transparent;
	color: #FF0000 !important;
	border-radius: 0;
	padding: 7px 22px;
	font-weight: 500;
	border-radius: 5px;
}
.profile-share .btn-danger:hover {
	background: #FF0000 !important;
	color: #fff !important;
	border: 1px solid #FF0000;
}
.profile-details .course-name h4 a {
    color: #392C7D !important;
	font-size: 18px;
	font-weight: 600;
}
.profile-details .course-name h4 a:hover {
	color: #F66962 !important;
}
.profile-details .course-name p {
	color: #303030 !important;
	font-size: 14px;
}
.profile-details .course-group-img img {
    max-width: 100px;
    height: 100px;
    border-radius: 50px;
}
.checkout-form.personal-address {
	padding: 25px;
	margin-bottom: 0;
}
.personal-address h4 {
	font-size: 20px;
	color: #392C7D;
	margin-bottom: 15px;
	font-weight: 600;
}
.personal-address p {
	font-size: 14px;
	color: #303030;
}
.country-select {
	color: #C4C4C4;
	font-size: 14px;
}
.update-profile {
	margin-top: 10px
}
.update-profile .btn-primary {
	font-size: 16px;
    padding: 7px 15px;
	font-weight: 700;
	border: 1px solid #ff875a;
	border-radius: 5px;
	min-width: 200px;
}
.update-profile .btn-primary:hover {
	background-color: #fff;
    border: 1px solid #fc7f50;
	color: #fc7f50;
}
.border-line {
    border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.personal-info-head p span {
	color: #F66962;
}
.save-social .btn-primary {
	min-width: 237px;
}
.secure-alert .check-on label {
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
}
.secure-alert .check-on {
	margin: 0px 0px 25px;
}
.un-subscribe {
	padding: 25px;
}
.un-subscribe .btn-danger {
	background: transparent;
	color: #FF0000 !important;
	padding: 10px 15px;
	font-size: 16px;
	font-weight: 500;
}
.un-subscribe .btn-danger:hover {
	background: #FF0000 !important;
	color: #fff !important;
}
.link-accounts.personal-form {
	border: 0;
	padding: 25px;
	border-radius: 0;
	background: transparent;
}
.comman-space {
	padding: 25px;
}
.edit-new-address {
	margin-bottom: 30px;
}
.edit-new-address label {
	font-size: 14px;
	color: #303030;
	font-weight: 400;
}
.wallet-radio-blk .custom_radio {
    margin-bottom: 0px;
}
.wallet-radio-blk .custom_radio input:checked ~ .checkmark {
    background-color: #ff5364;
}
.wallet-radio-blk .custom_radio .checkmark:after {
    background: #fff;
}
.wallet-radio-blk .custom_radio input:checked ~ .checkmark:after {
    opacity: 1;
}
.address-edits {
	margin-left: auto;
}
.edit-new-address .form-check-input{
	width: 20px;
	height: 20px;
}
.address-edits .edit-btn-blk {
	padding: 5px 7px;
	background-color: #455A64;
	border: 1px solid #455A64;
    border: 0;
	color: #fff;
	border-radius: 5px;
}
.add-new-address  {
	margin-bottom: 40px;
}
.grad-border .btn-primary {
	border: 1px solid transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(#FFF, #FFF), linear-gradient(to right, #392C7D, #FF5364);
    border-radius: 10px;
}
.grad-border .btn-default {
	background: rgba(227, 227, 227, 0.41);
	border-radius: 10px;
	color: #C7C7C7;
	min-width: 173px;
	padding: 7px 15px;
	cursor: not-allowed;
}
.add-new-address .btn-primary {
	font-size: 15px;
	font-weight: 700;
	color: #FF5364;
	padding: 8px 20px;
}
.add-new-address .btn-primary:hover:focus,
.active-change-plan .btn-primary:hover:focus,
.upgrade-now .btn-primary:hover:focus {
	color: #fff;
}
.upgrade-now .btn-primary {
	font-size: 15px;
	font-weight: 700;
	color: #FF5364;
	padding: 8px 16px;
}
.upgrade-now {
	margin-left: auto;
}
.active-btns .btn-success {
	background-color: #159F46;
	border: 1px solid #159F46;
	color: #fff;
	font-size: 14px;
	padding: 1px 11px;
}
.active-btns .btn-danger {
	color: #fff;
	font-size: 14px;
	padding: 1px 11px;
}
.subscribtion-active h5 {
	color: #303030;
	font-size: 16px;
	font-weight: 700;
	margin: 10px 0px;
}
.subscribtion-active p {
	color: #303030;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
}
.active-change-plan {
	margin-left: auto;
}
.active-change-plan .btn-primary {
	font-size: 15px;
	font-weight: 700;
	color: #FF5364;
	padding: 8px 39px;
}
.bill-widget {
	width: 100%;
	margin-bottom: 10px;
}
.monthly-subscribtion.comman-space {
	padding-bottom: 15px;
}
.monthly-group {
	margin-bottom: 30px;
}
.bill-name-group h5 {
	font-size: 14px;
	color: #303030;
	font-weight: 600;
	margin-bottom: 2px;
}
.bill-name-group p {
	font-size: 14px;
	color: #303030;
	font-weight: 400;
	margin-bottom: 0;
}
.plan-icons i {
	font-size: 18px;
}
.plan-icons {
	background: #FF875A;
    color: #fff;
    width: 44px;
    height: 44px;
	border-radius: 5px;
	margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}

/*-----------------
    47. Instructor Profile
-----------------------*/

.btn-dark {
    font-weight: 600;
    background: #392C7D;
    border: 1px solid #392C7D;
    color: #fff;
    border-radius: 5px;
    padding: 12px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.btn-dark:hover {
    background: #fff;
    border: 1px solid #392C7D;
    color: #392C7D;
}
.btn.btn-black {
    font-weight: 600;
    background: #263238;
    border: 1px solid #263238;
    color: #fff;
    border-radius: 5px;
    padding: 12px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.btn-black:hover {
    background: #fff;
    border: 1px solid #263238;
    color: #263238;
}
.btn.btn-success-dark {
   font-weight: 600;
    background: #159F46;
    border: 1px solid #159F46;
    color: #fff;
    border-radius: 5px;
    padding: 12px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center; 
}
.btn-success-dark:hover {
    background: #fff;
    border: 1px solid #159F46;
    color: #159F46;
}
.instructor-profile {
    text-align: left;
}
.instructor-profile-pic {
    max-width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 5px solid #FFFFFF;
    margin-right: 10px;
}
.instructor-profile-content h4 {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
}
.instructor-profile-content h4 a {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
}
.instructor-profile-content h4 a:hover {
	color: #F66962;
}
.instructor-profile-content h4 span {
    background: #fff;
    color: #000000;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 20px;
}
.instructor-profile-content p {
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
}
.instructor-profile-menu {
    background: rgba(10, 20, 47, 0.33);
    border-radius: 10px;
    padding: 15px 20px;
    text-align: left;
    max-width: 480px;
    margin-left: auto;
}
.instructor-profile-menu ul {
    justify-content: space-between;
}
.instructor-profile-menu-img {
    margin-right: 10px;
}
.instructor-profile-menu-content h4 {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 2px;
}
.instructor-profile-menu-content p {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
}
.instructor-profile-text {
    text-align: left;
    padding-top: 30px;
}
.instructor-profile-text p {
    font-size: 15px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-bottom: 0;
}
.instructor-card .card-header {
    background: transparent;
    border-bottom: 1px solid rgb(199 199 199 / 25%);
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.instructor-card .card-header h4 {
    font-weight: 600;
    font-size: 24px;
    color: #392C7D;
    margin-bottom: 0;
}
.instructor-card .btn {
    font-size: 14px;
    min-width: 170px;
    padding: 13px 15px;
}
.instructor-card .card-body {
    padding: 20px;
}
.instructor-grid {
    background: #FFFFFF;
    border: 1px solid rgb(199 199 199 / 25%);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.instructor-grid .product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    width: 100%;
}
.instructor-grid  .product-img {
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    margin-right: 0;
    width: 240px;
    border-radius: 10px;
}
.instructor-grid .instructor-product-content {
    margin-left: 15px;
}
.instructor-grid .instructor-product-content h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    max-width: 460px;
    line-height: 30px;
}
.instructor-grid .instructor-product-content h3 a {
    color: #000;
}
.instructor-grid .instructor-product-content h3 a:hover {
    color: #F66962;
}
.instructor-grid .course-view {
    margin-left: 30px;
}
.instructor-grid .course-info p {
    font-size: 16px;
    font-weight: 500;
}

/*-----------------
    50. Add Course
-----------------------*/

.add-course-header {
    padding-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
}
.add-course-header h2 {
    font-weight: 600;
    font-size: 32px;
    color: #000000;
    margin-bottom: 0;
}
.add-course-btns ul li {
    margin-right: 17px;
}
.add-course-btns ul li:last-child {
    margin-right: 0;
}
.add-course-btns .btn {
    font-weight: 600;
    font-size: 14px;
    padding: 13px 15px;
    min-width: 170px;
    display: inline-flex;
}
.multistep-form #first {
    display: block;
}
.multistep-form fieldset {
    display: none;
    width: 100%;
}
.widget-setcount {
    padding: 30px 30px 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(199 199 199 / 25%);
}
.widget-setcount ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}
.widget-setcount ul li {
    margin-right: 30px;
    position: relative;
}
.widget-setcount ul li:last-child {
    margin-right: 0;
}
.widget-setcount ul li p {
    font-weight: 500;
    color: #22100D;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: 20px;
    border-top: 4px solid #C1C9D2;
	min-width: 173px;
}
.widget-setcount ul span {
    border: 1px solid #8792A2;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B1B5B8;
    background: #fff;
}
.progress-activated span:before {
    content: "\f00c";
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    font-size: 12px;
    background: #159F46;
    color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.progress-active p {
    border-top: 5px solid #159F46 !important;
}
.progress-activated p {
    border-top: 5px solid #159F46 !important;
}
.progress-active span {
    color: #159F46 !important;
    border-color: #159F46 !important;
    position: relative;
    border: 5px solid #159f46 !important;
}
.add-course-inner-header {
    padding: 0 30px 30px;
}
.add-course-inner-header h4 {
    font-weight: 500;
    font-size: 24px;
    color: #F66962;
    margin-bottom: 0;
}
.add-course-form {
    padding: 0 30px 30px;
}
.add-course-info .form-group {
    margin-bottom: 20px;
}
.add-course-info .add-course-label {
    font-weight: 500;
    color: #685F78;
    margin-bottom: 10px;
}
.add-course-info .form-control {
    background: #FFFFFF;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    min-height: 40px;
}
.add-course-info .form-control:focus {
	border: 1px solid #e9ecef;
}
.add-course-info .select2-container--default .select2-selection--single {
    border: 1px solid #e9ecef;
}
.add-course-info .select2-container .select2-selection--single {
    height: 40px;
}
.add-course-info .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #263238;
    line-height: 40px;
    font-size: 14px;
}
.add-course-info .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px;
}
.add-course-info textarea.form-control {
    min-height: 150px;
}
.widget-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 30px 30px;
    border-top: 1px solid rgb(199 199 199 / 25%);
}
.widget-btn .btn {
    font-size: 14px;
    font-weight: 600;
    min-width: 170px;
    padding: 13px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.widget-btn .btn-info-light {
    color: #fff;
    background: #1D9CFD;
    border: 1px solid #1D9CFD;
    border-radius: 5px;
}
.widget-btn .btn-info-light:hover {
    color: #1D9CFD;
    background: #fff;
    border: 1px solid #1D9CFD;
}
.relative-form {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    color: #6e82a3;
    min-height: 40px;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.relative-form span {
    font-size: 14px;
}
.relative-file-upload {
    color: #fff;
    background: #F66962;
    border: 1px solid #F66962;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    min-width: 238px;
    text-align: center;
}
.relative-file-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block;
}
.add-image-box {
    min-height: 250px;
    background: #edede8;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 100%;
}
.add-image-box a {
    font-size: 200px;
    color: #a1a2a0;
}
.add-video-box {
    background: rgba(255, 222, 218, 0.71);
}
.add-video-box a {
    font-size: 40px;
    color: #FF5364;
}
.add-course-section {
    padding: 0 40px 40px;
}
.add-course-section .btn {
    font-weight: 600;
    font-size: 14px;
    color: #1D9CFD;
    background: #FFFFFF;
    border: 1px solid #1D9CFD;
    border-radius: 5px;
    min-width: 105px;
    padding: 7px 10px;
}
.add-course-section .btn:hover {
    color: #FFFFFF;
    background: #1D9CFD;
    border: 1px solid #1D9CFD;
}
.curriculum-grid {
    padding: 20px;
    margin-bottom: 30px;
    background: rgba(227, 227, 227, 0.41);
    border-radius: 10px;
}
.curriculum-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding-bottom: 20px;
}
.curriculum-head p {
    font-size: 16px;
    margin-bottom: 0;
    color: #000000;
}
.curriculum-head .btn {
    font-weight: 600;
    font-size: 14px;
    color: #1D9CFD;
    background: #FFFFFF;
    border: 1px solid #1D9CFD;
    border-radius: 5px;
    min-width: 105px;
    padding: 7px 10px;
}
.curriculum-head .btn:hover {
    color: #FFFFFF;
    background: #1D9CFD;
    border: 1px solid #1D9CFD;
}
.faq-grid {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 20px;
    margin-bottom: 16px;
}
.faq-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    position: relative;
}
.faq-header .faq-collapse {
    color: #000000;
    font-weight: 600;
    width: 100%;
    text-align: left;
    position: relative;
    display: inline-flex;
    align-items: center;
}
.faq-header .faq-collapse i {
    font-size: 20px;
    margin-right: 10px;
    color: #757575;
}
.faq-header .faq-collapse.collapsed:after {
    content: "\f078";
    font-family: 'Font Awesome 6 Free';
    font-weight: 600;
    font-size: 16px;
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
}
.faq-header .faq-collapse:after {
    content: "\f077";
    font-family: 'Font Awesome 6 Free';
    font-weight: 600;
    font-size: 16px;
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
}
.faq-right {
    position: absolute;
    right: 30px;
}
.faq-right a {
    font-size: 18px;
    color: #000000;
    margin-right: 8px;
}
.faq-right a:hover {
    color: #F66962;
}
.faq-body {
    padding-top: 20px;
    padding-left: 25px;
}
.add-article-btns .btn {
    font-weight: 600;
    font-size: 14px;
    color: #685F78;
    margin-right: 12px;
    background: #FFFFFF;
    border: 1px solid #685F78;
    border-radius: 5px;
    min-width: 130px;
    padding: 7px 8px;
}
.add-article-btns .btn:hover {
    color: #FFFFFF;
    background: #685F78;
    border: 1px solid #685F78;
}
.form-group-tagsinput .bootstrap-tagsinput {
    display: block;
    border-radius: 5px;
    color: #000;
    background: #FFFFFF;
    border: 1px solid #e9ecef;
    box-shadow: unset;
    min-height: 60px;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.form-group-tagsinput .bootstrap-tagsinput .tag {
    margin-right: 5px;
    margin-bottom: 5px;
    color: #263238;
    border: 1px solid #e9ecef;
    background: rgba(227, 227, 227, 0.41) !important;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 12px;
}
.form-group-tagsinput .bootstrap-tagsinput input {
    line-height: 42px;
    font-size: 14px;
}
.add-course-msg {
    text-align: center;
    padding-bottom: 30px;
}
.add-course-msg i {
    color: #159F46;
    font-size: 40px;
}
.add-course-msg h4 {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
}
.add-course-msg p {
    font-size: 16px;
    color: #000000;
    margin-bottom: 0;
}

/*-----------------
    Instructor Dashboard
-----------------------*/

.settings-menu .instructor-title {
    padding-top: 20px;
}
.instructor-card {
    border-radius: 10px;
    margin-bottom: 25px;
}
.instructor-inner h6 {
    font-weight: 600;
    font-size: 14px;
    color: #685F78;
    margin-bottom: 16px;
    text-transform: uppercase;
}
.instructor-inner h4 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 16px;
}
.instructor-inner .instructor-text-success {
    color: #159F46;
}
.instructor-inner .instructor-text-info {
    color: #1D9CFD;
}
.instructor-inner .instructor-text-warning {
    color: #FFB54A;
}
.instructor-inner p {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #303030;
    margin-bottom: 0;
}

/*-----------------
	51. Invoices
-----------------------*/

.invoice-list-blk .card {
	border-radius: 10px;
}
.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#161c2d;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align: right;
	font-weight: 500
}
.invoice-item .invoice-details strong {
	color:#161c2d
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 18px;
	color: #161c2d;
	font-weight: 600;
	margin-bottom: 8px;
	display: block
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #161c2d;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tr td,
.invoice-table-two tr td {
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tr td {
	text-align: right
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}

/*-----------------
	52. Responsive
-----------------------*/

@media (min-width: 992px) {
	.main-nav > li {
		margin-right: 15px;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav > li > a {
		line-height: 70px;
		padding: 0 !important;
	}
	.main-nav > li > a:hover {
		color: #F66962;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-left: 3px;
	}
	.main-nav li > ul {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 200px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
	}
	.main-nav li .submenu::before {
		border: 7px solid #fff;
		border-color: transparent transparent #fff #fff;
		box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
		content: "";
		left: 45px;
		position: absolute;
		top: 2px;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active > a {
		color: #F66962;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #F66962;
	}
	.header-one .main-nav .has-submenu.active .submenu li.active > a,
	.header-four .main-nav .has-submenu.active .submenu li.active > a {
		color: #6255A4;
	}
	.header-two .main-nav .has-submenu.active .submenu li.active > a {
		color: #F66962;
	}
	.header-three .main-nav .has-submenu.active .submenu li.active > a {
		color: #7B1FFE;
	}
    .header-five .main-nav .has-submenu.active .submenu li.active > a {
		color: #FFD600;
	}   
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li .submenu.first-submenu li a {
		border-top: 1px solid #f0f0f0
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav li .submenu a:hover {
		color: #F66962;
		letter-spacing: 0.5px;
		padding-left: 20px;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.profile-custom-list > li{
		width: 33.33%;
	}
	.custom-about p{
		width: 73%;
	}
}

@media (min-width: 576px) {
	.modal-styles .modal-dialog {
		max-width: 860px;
	}
	.growup-section .growup-right-img {
		margin-top: 20px;
		filter: drop-shadow(0px 4px 14px rgba(202, 202, 202, 0.25));
		border-radius: 10px;
		-webkit-border-radius: 5px;
		-khtml-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}
	.knowledge-sec .joing-list .joing-icon {
		flex-shrink: 0;
	}
}

@media (max-width: 992px) {
	.home-two .shapes-one {
		position: absolute;
		right: -3rem;
		top: 0;
		width: 500px;
	}
	.home-two .home-slide-text h1 {
		font-weight: 700;
		font-size: 45px;
		color: #324FA2;
		margin-bottom: 10px;
		max-width: 800px;
	}
	.home-two .shapes-four {
		position: absolute;
		left: -7rem;
		bottom: -2rem;
	}	
	.home-two .shapes-two {
		position: absolute;
		right: 4rem;
		bottom: 0;
		top: 36rem;
	}
    .knowledge-sec .featured-img-1 {
        width: 100%;
        float: left;
        height: 777px;
        background-size: cover;
        background-position: inherit;
    }
    .knowledge-sec .joing-list .joing-icon {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        text-align: center;
        border-radius: 10px;
        padding: 0px;
        width: 50px;
        height: 50px;
    }
    .knowledge-sec .joing-group {
        padding: 10px;
    }
	.featured-courses-sec {
		padding: 60px 0 60px;
		position: relative;
	}
	.growup-section .growup-right-img {
		position: absolute;
		bottom: 0;
		top: 11rem;
		right: 1rem;
	}
	.growup-section .section-sub-head {
		margin-top: 50px;
	}
	.new-course-bg .course-details-content h3 a {
		font-weight: 700;
		font-size: 16px;
		color: #324FA2;
		line-height: 20px !important;
		display: block;
		margin-top: 8px;
	}	
	.course-price h3 {
		font-weight: 700;
		font-size: 16px;
		color: #21B477;
        margin-bottom: 0;
	}
	.new-course-bg .rating {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;
	}
    .course-count-two h5 {
        font-size: 16px;
    }
	.trending-price h4 {
		font-size: 12px;
	}
    .home-three-head h1 {
        font-size: 25px;
    }
    .home-three .become-content h2 {
        font-size: 30px;
    }
	.home-two .shapes-middle {
		position: absolute;
		left: 43rem;
		top: 20rem;
		bottom: 0;
	}
    .award-three-content {
        float: left;
    }
	.login-three-head {
		padding: 5px 30px;
	}
    .learn-anything h2 {
        font-size: 25px;
    }
	.signin-three-head {
		padding: 5px 30px;
	}
	.home-three-slide .girl-slide-img img {
		width: 300px;
		margin-top: 140px;
	}
	.home-three-slide-face {
		padding: 60px 0 60px;
	}
	.master-three-left {
		margin-bottom: 30px;
	}
	.home-three-courses {
		padding: 60px 0 60px;
		background: #FBFBFF;
	}
	.home-three-transform {
		padding: 60px 0 60px;
	}
	.home-three-goals {
		padding: 60px 0 60px;
		background-color: #ffffff;
	}
	.accelerate-cloud-three {
		padding: 60px 0 60px;
	}
	.home-three-trending {
		padding: 60px 0 60px;
	}
	.gallery-three {
		padding: 60px 0 60px;
	}
	.home-three-become {
		padding: 60px 0 60px;
	}
	.lead-companies-three {
		padding: 60px 0 60px;
	}
	.latest-blog-three {
		background: #ffffff;
		padding: 60px 0 60px;
	}
	.event-three-title h5 {
		font-size: 15px;
	}
	.event-three-title p {
		font-weight: 400;
		font-size: 12px;
		color: #DEDEDE;
	}
	.span-name-three {
		padding: 0px 20px;
		font-size: 14px;
		border-radius: 4px;
		display: inline-flex;
		margin-bottom: 10px;
		color: #ffffff;
	}
	.gallery-three .content-three-details {
		position: absolute;
		padding: 7px;
	}
	.box-form-newsletter {
		margin-bottom: 25px;
	}
	.home-two .winning-two-two {
		margin-bottom: 30px;
	}
	.home-two .course-column-img {
		-ms-flex: 25%;
		flex: 25%;
		max-width: 25%;
	}
	.home-two .course-content-column {
		-ms-flex: 75%;
		flex: 75%;
		max-width: 75%;
	}
	.instructors-img img {
		width: 100%;
	}
    .home-two .home-slide-text h1 {
        font-weight: 700;
        font-size: 35px;
        color: #324FA2;
        margin-bottom: 25px;
        max-width: 100%;
    }
    .home-two .shapes-middle {
        display: none;
    }
    .home-two .shapes-one {
        display: none;
    }
    .home-two .shapes-four {
        display: none;
    }
    .home-two-slide .home-slide-face {
        padding: 120px 0 100px;
    }
    .login-head {
        padding: 5px 30px;
    }
    .signin-head {
        padding: 5px 20px;
    }
    .event-content-title h5 a {
        font-size: 20px;
    }
    .main-nav-four .has-submenu.active > a {
        color: #EE009C;
    }
    .request-box .name-field input {
        width: 100%;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    .buy-template-btn {
        padding: 8px 25px;
        font-size: 14px;
    }
    .signup-four {
        padding: 8px 25px;
        font-size: 14px;
    }
    .banner-section-four {
        text-align: center;
    }
    .banner-heading-four h2 {
        font-size: 33px;
    }
    .banner-img-third {
        position: absolute;
        right: 4rem;
        top: 1rem;
    }
    .banner-img-fourth {
        position: absolute;
        right: 2rem;
        top: 10rem;
    }
	.slider-five-one {
        position: absolute;
        top: 5.5rem;
    }
	.slider-five-one img {
		width: 100%;
		max-width: 550px;
	}
	.slider-five-two img {
		width: 100%;
		max-width: 550px;
		float: right;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 70px;
	}
	.header-navbar-five > li .signup-five {
		font-size: 14px;
		padding: 8px 30px;
	}
	.header-navbar-five > li .login-five {
		font-size: 14px;
	}
    .slider-five-two img {
        width: 220px !important;
        max-width: 100% !important;
        float: right;
    }
    .vector-shapes-five {
        display: none;
    }
    .instructor-vector-left {
        position: absolute;
        left: -1.6rem;
        top: 0.7rem;
    }
    .banner-content-five .sub-btn {
        padding: 15px 20px;
    }
    .banner-content-five .select2-container .select2-selection--single {
        height: 55px;
        font-size: 14px;
    }
    .home-five .course-count h3 {
        margin-bottom: 0;
        font-size: 30px;
    }
    .master-five-vector {
        display: none;
    }
    .footer-five-left {
        display: none;
    }
    .footer-five-right {
        display: none;
    }
    .best-course-slider .owl-nav {
        display: none;
    }
    .more-information li {
        margin-left: 20px;
    }
}

@media (min-width: 1200px) {
	.main-nav > li {
		margin-right: 30px;
	}
    .master-five-vector {
        position: absolute;
        left: 1rem;
        top: 3rem;
    }
    
}

@media (max-width: 1399.98px) {
	.register-step .login-banner img, 
	.login-banner img {
		max-width: 280px;
	}
    .instructor-vector-left {
        position: absolute;
        left: -2.5rem;
        top: 0.7rem;
    }
	.table-price td {
		font-size: 16px;
	}
	h1 {
		font-size: 32px;
	}
	.help-title p, .category-title h5 {
		font-size: 16px;
	}	
	.category-tab ul li a {
		font-size: 20px;
	}
	.error-box h3 {
		font-size: 30px;
	}
	.error-box-img {
		max-width: 400px;
		margin: auto;
	}
	.time-counts li {
		font-size: 16px;
		min-width: 85px;
	}
	.time-counts li span {
		font-size: 25px;
	}
	.error-logo {
		margin-bottom: 25px;
	}
	.error-box h4 {
		font-size: 24px;
	}
	.error-box h6 {
		font-size: 16px;
		margin: 20px 0px;
	}
	.time-counts {
		margin-bottom: 20px;
	}
	.come-soon-box h5 {
		font-size: 16px;
		margin: 25px 0px 25px;
	}
	.subscribe-soon .form-control {
		height: 42px;
	}
	.masonry-box .post-left li {
		font-size: 12px;
	}
	.masonry-box .blog-title {
		font-size: 20px;
	}
	.profile-img img {
		width: 120px;
		height: 120px;
		border: 3px solid #FFFFFF;
	}
	.account-settings .settings-menu h3 {
		font-size: 16px;
	}
	.account-settings .settings-menu ul li a {
		font-size: 15px;
	}
	.bill-name-group p {
		font-size: 12px;
	}
	.error-box .big-text {
		font-size: 26px;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
	.countdown-el {
		min-width: 100px;
		padding: 15px;
		color: #fff;
	}
	.countdown-el span {
		font-size: 18px;
	}
    .login-head {
        padding: 8px;
        min-width: 130px;
    }
    .signin-head {
        padding: 8px;
        min-width: 130px;
    }
    .home-two .sub-btn {
        width: 180px;
    }
	.home-two .shapes-one {
		position: absolute;
		right: -5rem;
		top: 0;
		max-width: 400px;
        width: 100%;
	}
    .home-two .shapes-middle {
        position: absolute;
        left: 46rem;
        top: 18rem;
        width: 115px;
        bottom: 0;
    }
    .home-two .home-slide-text h1 {
        font-size: 40px;
        margin-bottom: 25px;
        max-width: 100%;
    }
    .header-two-title {
        margin-bottom: 20px;
    }
    .count-content-three h4 {
        font-size: 25px;
    }
    .count-content-three p {
        font-size: 18px;
    }
	.home-two .shapes-four {
        position: absolute;
        left: -6rem;
        bottom: 0rem;
    }
	.course-details-content .featured-info {
		border-bottom: 1px solid #f7f7f7;
		margin-bottom: 0px !important;
		padding-bottom: 0px !important;
	}
	.acheive-elips-one {
		position: absolute;
		right: -1.0rem;
		z-index: -1;
		top: -1rem;
	}
	.home-two .course-column-img {
		-ms-flex: 40%;
		flex: 40%;
		max-width: 40%;
	}
	.home-two .course-content-column {
		-ms-flex: 60%;
		flex: 60%;
		max-width: 60%;
	}
    .joing-icon-award img {
        width: 70px;
        height: 70px;
    }
    .home-two .join-title-one {
        max-width: 500px;
    }
    .joing-icon-award .joing-icon-one {
        bottom: 50px;
        left: -30px;
    }
    .joing-icon-award .joing-icon-two {
        bottom: 60px;
        right: 0;
    }
    .joing-icon-award .joing-icon-three {
        bottom: 70px;
        left: -20px;
    }
    .join-mentor-img {
        margin-bottom: 25px;
    }
    .winning-two-one img {
        max-width: 525px;
    }
    .winning-two-two img {
        max-width: 525px;
        margin: auto;
    }
    .winning-two-three img {
        max-width: 525px;
    }
    .course-count-two h5 {
        font-size: 18px;
    }
    .knowledge-sec .joing-group {
        padding: 10px 10px 0;
    }
    .real-reviews-grid .review-content h3 {
        font-size: 30px;
    }
    .event-content-title h5 a {
        font-size: 20px;
    }
    .knowledge-sec .joing-list .joing-title {
        font-size: 16px;
    }
    .joing-section-text {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .joing-list .joing-para p {
        font-size: 14px;
    }
    .home-three header input[type=search]:focus {
        width: 125px;
    }
    .home-three header input[type=search] {
        padding: 8px 8px 8px 25px;
    }
    .login-three-head {
        padding: 8px 25px;
        min-width: 120px;
    }
    .signin-three-head {
        padding: 8px 25px;
        min-width: 120px;
    }
    .event-three-title h5 {
        font-size: 18px;
    }
    .banner-img-sec {
        position: absolute;
        left: -55px;
        top: 35px;
    }
    .banner-img-sec {
        position: absolute;
        left: -55px;
        top: 35px;
    }
    .banner-img-fourth {
        position: absolute;
        right: -6rem;
        top: 10rem;
    }
    .our-courses .card .card-img img {
        min-height: 223px;
    }
    .latest-blog .card .card-img img {
        min-height: 252px;
    } 
    .right-header-button .login-btn {
        padding: 15px 25px;
    }
    .right-header-button .register-btn {
        padding: 15px 25px;
    }
    .banner-sections-six .section-heading .search-box .search-icon {
        padding: 15px 60px;
        width: 100%;
        height: 100%;
    }
    .banner-sections-six .banner-imgs .react-icon {
        position: absolute;
        top: 200px;
        right: 410px;
    }
    .banner-sections-six .banner-imgs .banner-img-two {
        position: absolute;
        top: 250px;
        right: -35px;
        width: 300px;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        position: absolute;
        bottom: 50px;
        left: -50px;
    }
    .banner-sections-six .banner-imgs .banner-img-three {
        position: absolute;
        bottom: 28px;
        right: -32px;
        width: 300px;
    }
    .best-course-heading {
        gap: 90px;
    }
    .best-course-slider .owl-nav {
        top: -140px;
    }
    .top-category {
        padding: 40px 0;
    }
    .top-category .section-headings {
        padding: 30px 0;
    }
    .section-headings h2 {
        font-size: 24px;
    }
    .trained-by-experts .section-headings {
        padding-bottom: 30px;
    }
    .cloud-technology-img .cloud-icon {
        position: absolute;
        right: 575px;
        top: 255px;
    }
    .cloud-technology-img .vector-green-img {
        top: 335px;
    }
    .cloud-technologies .technology-item {
        min-width: 260px;
    }
    .banner-sections-six .section-heading .search-box input {
        color: #585858;
        min-width: 305px;
    }
    .top-category .section-headings::after {
        top: 70px;
    }
    .user-registration {
        display: flex;
        align-items: center;
        gap: 0;
    }
    .instructor-vector-right {
        position: absolute;
        right: -1.5rem;
        bottom: 1.7rem;
    }
	.slider-five-two img {
		width: 280px !important;
	}
    .home-five .course-count h3 {
        margin-bottom: 0;
        font-size: 30px;
    }
    .joing-count-five-one {
        position: absolute;
        left: -1rem;
        top: 6.5rem;
    }
    .joing-count-five-two {
        position: absolute;
        left: -6rem;
        bottom: 3rem;
    }
    .joing-count-five-three {
        text-align: center;
        position: absolute;
        right: 4rem;
        top: 2rem;
    }
    .transform-count-five-three {
        position: absolute;
        bottom: 3.5rem;
        right: -0.5rem;
        text-align: center;
    }
}

@media (max-width: 1299.98px) {
    .home-two .home-slide-text h1 {
        font-size: 40px;
    }
    .home-two .shapes-middle {
        position: absolute;
        left: 43rem;
        top: 18rem;
        bottom: 0;
    }
    .home-two .sub-btn {
        width: 150px;
    }
    .home-two-slide .banner-content .form-inner {
        padding: 8px;
    }
    .header-two-title h2 {
        font-size: 30px;
    }
    .topcategory-sec .categories-content h3 {
        font-size: 18px;
    }
    .header-navbar-five > li .signup-five {
        padding: 8px 40px;
    }
    .banner-content-five .select2-container .select2-selection--single {
        height: 55px;
        font-size: 14px;
    }
    .banner-content-five .form-inner-five {
        padding: 5px;
    }
}

@media (max-width: 1199.98px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.header-navbar-rht li.contact-item {
		display: none;
	}
	.map-right {
		height: 450px;
		position: static;
		min-height: inherit;
		margin-bottom: 30px;
		padding-right: 15px;
	}
	.header-one .main-nav > li {
		margin-right: 27px;
	}
	.home-slide-text h1 {
		font-size: 38px;
		margin-bottom: 20px;
	}
	.home-slide-text p {
		font-size: 18px;
	}
	.home-slide .search-box .search-btn {
		top: 7px;
		right: 7px;
		padding: 10px 15px;
	}
	.home-slide .search-box .search-btn {
		height: 46px;
	}
	.homeone-section-header h2 {
		font-size: 28px;
	}
	.homeone-section-header .sub-title, .category-tab ul li a {
		font-size: 16px;
	}
	.home-slide .search-box form {
		display: block;
	}
	.section-header h2 {
		font-size: 28px;
	}
	.section-header .sub-title {
		font-size: 14px;
	}
	.header-navbar-rht li a.header-login,
	.add-header-bg .header-navbar-rht li a.header-sign	{
		min-width: 110px;
		padding: 6px 15px;
		font-size: 14px;
	}
	.enroll-group .total-course {
		margin-bottom: 5px;
	}
	.header-navbar-rht li a.header-sign {
		min-width: 110px;
		padding: 8px 15px;
		font-size: 14px;
	}
	.main-nav li a {
		font-size: 14px;
	}
	.logo {
		width: 130px;
	}
	.google-bg {
		padding: 25px;
	}
	.sign-google ul li a {
		padding: 0px 10px;
	}
	.login-wrapper .loginbox h1 {
		font-size: 28px;
	}
	.login-banner img {
		max-width: 330px;
	}
	.mentor-course h2 {
		font-size: 28px;
		margin: 20px 0px 20px;
	}
	.title-sec h5, .title-sec p {
		font-size: 16px;
	}
	.title-sec h2, .main-title {
		font-size: 34px;
	}	
	.inner-banner h2, .video-details h2 {
		font-size: 26px;
	}
	.btn-wish {
		padding: 10px 0;
		font-size: 12px;
	}
	.inner-banner h5, .instructor-detail h5 {
		font-size: 18px;
	}
	.complete-sec h2 {
		font-size: 26px;
	}
	.btn-cart, .btn-enroll {
		font-size: 13px;
		padding: 11px 11px;
	}
	.play-icon {
		width: 60px;
		height: 60px;
	}
	.play-icon i {
		font-size: 24px;
		padding-left: 5px;
	}
	.support-img img {
		width: 160px;
	}
	.support-card h3 {
		font-size: 22px;
	}
	.faq-title a, .instructor-content p, .rating {
		font-size: 14px;
	}
	.instructor-info > div {
		margin-right: 10px;
	}
	.error-box h4 {
		font-size: 20px;
	}
	.error-box h6 {
		font-size: 15px;
		margin: 20px 0px;
	}
	.time-counts li span {
		font-size: 20px;
	}
	.time-counts li {
		font-size: 15px;
		min-width: 80px;
		padding: 15px;
	}
	.time-counts ul li:not(:last-child) {
		margin-right: 10px;
	}
	.come-soon-box h5 {
		font-size: 14px;
		margin: 25px 0px;
	}
	.social-icon-soon ul li i {
		font-size: 20px;
	}
	.error-box-img img {
		width: 270px;
	}
	.error-logo img {
		width: 120px;
	}
	.error-box {
		padding: 20px;
	}
	.progress-stip {
		display: flex;
		height: 15px;
		font-weight: 400;
	}
	.payment-btn .btn-primary {
		min-width: 100%;
	}
	.plan-header h4 {
		font-size: 20px;
		padding: 12px 15px;
	}
	.plan-change .btn-primary {
		font-size: 20px;
		padding: 10px 15px;
	}
	.world-img img {
		margin-bottom: 15px;
	}
	.link-accounts.personal-form {
		margin-top: 0;
	}
	.error-box .big-text {
		font-size: 20px;
		margin-bottom: 6px;
		padding-bottom: 10px;
	}
	.countdown-el {
		min-width: 90px;
	}
	.countdown-el span {
		font-size: 16px;
	}
    .login-head {
        padding: 5px 20px;
        min-width: 100px;
    }
    .signin-head {
        padding: 5px 20px;
        min-width: 100px;
    }
	.instructors-img img {
		width: 100%;
		transform: translateZ(0);
		transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	}
	.home-three-slide .girl-slide-img img {
		width: 400px;
        margin-top: 140px;
	}
    .home-three-transform .cta-content h2 {
        font-size: 25px;
    }
    .home-three-transform .cta-content p {
        font-size: 16px;
    }
    .btn-action {
        font-size: 14px;
    }
    .home-three-slide-text h1 {
        font-size: 30px;
    }
	.home-three-slide-face {
		padding: 100px 0 100px;
	}
	.master-three-left {
		margin-bottom: 30px;
	}
	.event-three-title h5 {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.span-name-three {
		padding: 2px 15px;
	}
	.blog-student-count {
		font-size: 16px;
	}
    .home-two .join-title-one {
        max-width: 430px;
    }
    .home-two .join-title-one h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    .home-two .join-title-middle h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    .winning-two-one img {
        max-width: 480px;
    }
    .winning-two-two img {
        max-width: 480px;
        margin: auto;
    }
    .winning-two-three img {
        max-width: 480px;
    }
    .join-title-middle {
        max-width: 425px;
        margin: auto;
    }
    .joing-icon-award .joing-icon-two {
        bottom: 60px;
        right: 2rem;
    }
    .master-three-images {
        max-width: 413px;
        margin: 0;
    }
    .acheive-elips-one {
        right: 2rem;
    }
    .acheive-elips-two {
        right: 3.5rem;
        top: -1rem;
    }
    .acheive-elips-three {
        right: 5.5rem;
        top: -1rem;
    }
    .award-three-content {
        max-width: 515px;
        width: 100%;
        float: left;
    }
    .acheive-goals-main h2 {
        font-size: 25px;
        margin-bottom: 50px;
        text-align: center;
    }
    .acheive-goals span {
        font-size: 25px;
    }
    .award-three-images-one {
        max-width: 415px;
        margin: auto auto 46px;
    }
    .learn-anything {
        padding: 36px 0 40px;
    }
    .accelerate-cloud-three .home-three-head h1 {
        width: 100%;
        font-size: 18px;
    }
    .course-three-text {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .home-three-trending {
        padding: 40px 0 40px;
        background: #FBFBFF;
    }
    .gallery-three {
        padding: 40px 0 40px;
    }
    .home-three-head h1 {
        font-size: 25px;
    }
    .home-three-become {
        padding: 40px 0 40px;
    }
    .lead-companies-three {
        padding: 40px 0 40px;
    }
    .home-three .become-content h2 {
        font-size: 28px;
    }
    .home-three .become-content h4 {
        font-size: 18px;
    }
    .accelerate-three-business {
        font-size: 16px;
    }
    .award-three-content-one h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    .award-three-content-one p {
        font-size: 14px;
    }
    .learn-anything h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    .award-win-icon img {
        width: 50px;
    }
    .award-three-content-two p {
        font-size: 14px;
    }
    .award-three-images-two {
        max-width: 415px;
        margin: auto;
    }
    .become-content-three h2 {
        font-size: 25px;
    }
    .become-content-three p {
        font-size: 16px;
    }
    .btn-become {
        font-size: 14px;
        padding: 12px 30px;
    }
    .home-three .btn-default {
        font-size: 12px;
        min-width: 125px;
        height: 44px;
        padding: 10px;
    }
    .accelerate-cloud-three {
        padding: 40px 0 40px;
    }
    .footer-three-right {
        margin-left: 70px;
    }
    .footer-three-about p {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .footer-three .newsletter-title h6 {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .box-form-newsletter form .input-newsletter {
        background-position: left 10px center;
        padding: 5px 5px 5px 45px;
    }
    .footer-three-top-content {
        padding-bottom: 20px;
    }
    .social-icon-three {
        margin-bottom: 30px;
    }
    .footer-three-top {
        margin-bottom: 30px;
    }
    .banner-img-fourth {
        position: absolute;
        right: 8rem;
        top: 10rem;
    }
    .banner-img-third {
        position: absolute;
        right: 12rem;
        top: 1rem;
    }
    .home-two .home-slide-text h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .home-two .shapes-middle {
        display: none;
    }
    .knowledge-sec .section-title h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .home-two-slide .home-slide-face {
        padding: 150px 0 150px;
    }
    .home-two .shapes-two {
        position: absolute;
        right: 7rem;
        bottom: 0;
        top: 19rem;
    }
    .home-two .sub-btn {
        width: 120px;
        padding: 8px;
    }
    .header-two-title h2 {
        font-size: 25px;
    }
    .event-content-title h5 a {
        font-size: 18px;
    }
    .event-blog-main .span-name {
        font-size: 14px;
    }
    .discover-btn {
        padding: 10px 35px;
        font-size: 14px;
    }
    .price-text h4 {
        font-size: 14px;
    }
    .price-text {
        min-width: 100px;
    }
    .joing-icon-award img {
        width: 60px;
        height: 60px
    }
    .featured-details-two .name-text h3 a {
        font-size: 18px;
    }
    .home-two .shapes-four {
        position: absolute;
        left: -5rem;
        bottom: 0rem;
    }
    .count-content-three h4 {
        font-size: 20px;
    }
    .count-content-three p {
        font-size: 16px;
    }
    .about-section {
        padding: 60px 0 60px;
    }
    .topcategory-sec {
        padding: 60px 0px 60px;
    }
    .home-two .trending-course-sec {
        padding: 60px 0 60px;
    }
    .featured-courses-sec {
        padding: 60px 0px 60px;
    }
    .feature-instructors-sec {
        padding: 60px 0 60px;
    }
    .real-reviews-sec {
        padding: 60px 0 60px;
    }
    .latest-blog-sec {
        padding: 60px 0 60px;
    }
    .footer-two .footer-top {
        padding: 60px 0 60px !important;
    }
    .home-two .course-column-img {
		-ms-flex: 30%;
		flex: 30%;
		max-width: 30%;
	}
	.home-two .course-content-column {
		-ms-flex: 70%;
		flex: 70%;
		max-width: 70%;
	}
    .real-reviews-grid .review-content h3 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .real-reviews-grid .review-content p {
        font-size: 14px;
    }
    .real-reviews-grid .review-info h3 {
        font-size: 16px;
    }
    .real-reviews-grid .review-info h5 {
        font-size: 12px;
    }
    .box-form-newsletter {
        margin-bottom: 40px;
        padding: 5px;
    }
    .knowledge-sec .joing-list .joing-icon {
        width: 50px;
        height: 50px;
    }
    .course-details-three .course-content-three h4 {
        font-size: 20px;
    }
    .course-details-three .course-content-three p {
        font-size: 14px;
    }
    .login-three-head {
        padding: 5px 15px;
        min-width: 100px;
    }
    .signin-three-head {
        padding: 5px 15px;
        min-width: 100px;
    }
    .login-three-head span {
        font-size: 14px;
    }
    .signin-three-head span {
        font-size: 14px;
    }
    .home-three header input[type=search] {
        padding: 5px 15px;
    }
    .award-three-content h2 {
        font-size: 25px;
    }
    .award-three-content h2 {
        font-weight: 600;
        font-size: 36px;
        color: #FED700;
        margin-bottom: 20px;
    }
    .home-three .home-three-head {
        margin-bottom: 38px;
    }
    .master-skill-three {
        padding: 40px 40px;
    }
    .home-three-courses {
        padding: 40px 0 40px;
    }
    .home-three-transform {
        padding: 40px 0 40px;
    }
    .home-three-goals {
        padding: 40px 0 40px;
    }
    .home-five .bar-icon span {
        background-color: #ffffff;
    }
    .home-five .menu-header {
        background-color: #2D0160;
        border-bottom: 1px solid #ffffff;
    }
    .home-five .main-nav > li {
        border-bottom: 1px solid #ffffff;
        margin-left: 0;
    }
    .home-five .main-menu-wrapper {
        background-color: #2D0160;
    }
    .home-five .main-nav ul {
        background-color: #520aa5;
    }
    .menu-close {
        font-size: 18px;
    }
	.slider-five-one {
        position: absolute;
        top: 5.8rem;
    }
	.slider-five-one img {
		width: 300px !important;
		max-width: 550px;
	}
	.slider-five-two img {
        max-width: 100%;
        float: right;
    }
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 50px;
	}
	.header-navbar-five > li .signup-five {
		font-size: 14px;
		padding: 8px 30px;
	}
	.header-navbar-five > li .login-five {
		font-size: 14px;
	}
    .banner-content-five .sub-btn {
        padding: 10px 20px;
    }
    .banner-content-five .form-inner-five .input-group {
        position: relative;
        display: flex;
        flex-wrap: revert;
        align-items: stretch;
        width: 100%;
    }
    .banner-content-five .select2-container .select2-selection--single {
        height: 50px;
    }
    .home-slide-five-text h1 {
        font-size: 25px;
        margin-bottom: 25px;
    }
    .banner-content-five .form-inner-five {
        padding: 5px;
    }
    .leading-section-five {
        padding: 40px 0 40px;
    }
    .learn-more-five {
        padding: 10px 15px;
    }
    .leading-five-content h2 {
        font-size: 25px;
    }
    .course-icon-five img {
        padding: 15px;
    }
    .course-icon-five {
        margin-bottom: 29px;
    }
    .course-section-five {
        padding: 50px 0 50px;
    }
    .course-info-five h3 {
        font-size: 18px;
    }
    .course-info-five p {
        font-size: 14px;
    }
    .counter-section-five {
        padding: 50px 0 50px;
    }
    .featured-section-five {
        padding: 50px 0 50px;
    }
    .tablist-five .nav-tab {
        font-size: 16px;
    }
    .product-img-five img {
        width: 100%;
    }
    .product-content-five h3 a {
        font-size: 14px;
    }
    .info-five-middle {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .product-content-five .price-five-group h3 {
        font-size: 16px;
    }
    .count-content-five h4 {
        font-size: 25px;
    }
    .count-content-five p {
        font-size: 18px;
    }
    .skill-five-content h3 {
        font-size: 18px;
    }
    .header-five-title h2 {
        font-size: 25px;
    }
    .goals-five-one .goals-content-five {
        border-left: 1px solid transparent;
    }
    .header-five-title {
        margin-bottom: 31px;
    }
    .master-section-five {
        padding: 50px 0 50px;
    }
    .experienced-course-five {
        padding: 50px 0 50px;
    }
    .joing-count-five-one {
        position: absolute;
        left: 2rem;
        top: 0.5rem;
    }
    .joing-count-five-two {
        position: absolute;
        left: -7rem;
        bottom: 3rem;
    }
    .joing-count-five-three {
        position: absolute;
        right: 2rem;
        top: 2rem;
        z-index: 2;
    }
    .goals-section-five {
        padding: 50px 0 50px;
    }
    .goals-content-five h4 {
        font-size: 25px;
    }
    .transform-section-five {
        padding: 50px 0 50px;
    }
    .transform-count-five-one {
        position: absolute;
        top: 0;
        left: 3.5rem;
    }
    .joing-count-five img {
        animation: up-down 0s ease-in-out infinite alternate-reverse both;
    }
    .joing-count-number {
        font-size: 25px;
    }
    .joing-count-text {
        font-size: 16px;
    }
    .transform-count-five-two {
        position: absolute;
        top: -0.5rem;
        right: 3.5rem;
    }
    .transform-count-five-three {
        position: absolute;
        bottom: -1.5rem;
        left: -18rem;
    }
    .testimonial-section-five .slick-prev {
        left: -8px;
    }
    .testimonial-section-five .slick-next {
        right: -8px;
    }
    .testimonial-section-five {
        padding: 50px 0 50px;
    }
    .testimonial-users h5 {
        font-size: 18px;
    }
    .leading-five-content p {
        font-size: 14px;
    }
    .blogs-section-five {
        padding: 50px 0 50px;
    }
    .blog-five-footer h3 a {
        font-size: 18px;
    }
    .blog-box-content {
        padding: 20px;
    }
    .footer.footer-five {
        padding: 50px 0 30px;
    }
    .copyright-five {
        padding: 20px 0 0;
    }
    .footer-title-five {
        font-size: 18px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .more-information li {
        margin-left: 20px;
    }
    .request-box .name-field input {
        width: 100%;
        margin-bottom: 40px;
    }
    .request-box .email-field input {
        width: 100%;
        margin-bottom: 40px;
    }
    .news-letter-four .section-heading h2 {
        font-size: 30px;
        padding-bottom: 30px;
    }
    .request-box {
        display: block;
        align-items: center;
        justify-content: space-between;
    }
    .request-box .send-btn {
        display: block;
        align-items: center;
        justify-content: center;
    }
    .footer-news-five .btn {
        width: 105px;
        height: 45px;
    }
    .footer-news-five .form-control {
        min-height: 45px;
    }
    .footer-about-five p {
        font-size: 14px;
    }
    .footer-top-five .footer-contact-info p {
        font-size: 14px;
    }
}

@media (max-width: 991.98px) {
    .main-nav li.login-link {
        display: block;
    }
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.main-nav ul {
		background-color: #7b1ffe;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #fff;
		margin-left: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #fff !important;
		font-size: 14px;
		font-weight: 500;
	}
	.header-two .main-nav li a {
		color: #fff;
	}
	.header-two .main-nav .has-submenu.menu-effect > a::after {
		display: none;
	}
	.main-nav > li.active > a,
	.main-nav .has-submenu.active .submenu li.active > a,
	.main-nav .has-submenu.active > a{
		color: #fff !important;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #fff;
		padding: 10px 15px 10px 35px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a {
		color: #1879cd;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #1879cd;
	}
	.header-five .main-nav > li .submenu li a {
		padding: 10px 20px;
	}
	.login-left {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		background-color: #7B1FFE;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
		top: 4px;
		position: relative;
	}
	.dropdown-menu.dropdown-menu-right {
		margin-top: 5px;
	}
	h1 {
		font-size: 28px;
	}
	h5 {
		font-size: 18px;
	}
	.header-nav {
		padding: 0;
	}
	.section-search {
		min-height: 330px;
	}
	.progress { 
		margin-bottom: 20px; 
	}
	.footer-widget {
		margin-bottom: 30px;
	}
	.container {
		max-width: 100% !important;
	}
	.home-slide .search-box form {
		display: block;
	}
	.testimonial-four .testimonial-all {
		background: none;
		min-height: 454px;
	}
	.new-course-four .course-mail .bg-course-subscribe {
		line-height: 2;
		height: 45px;
	}
	.home-slide-four .hometwo-slide-text h1 {
		font-size: 24px;
	}
	.home-slide-four {
		min-height: 420px;
	}
	.testimonial-four .testimonial-two-head p {
		font-size: 16px;
	}
	.side-four img, .side-four-right img {
		display: none;
	}
	.testimonial-four .testimonial-all {
		padding: 5px;
	}
	.testimonial-bg-top img {
		top: 16px;
		left: 38%;
		width: 231px;
	}
	.testimonial-bg-right img {
		top: 50%;
		right: -90px;
		width: 180px;
	}
	.testimonial-bg-left img {
		top: 50%;
		left: 130px;
		width: 180px;
	}
    .testi-right{
        margin-right: 1px;
    }
	.breadcrumb-bar {
		padding: 10px 0;
	}
	.course-key {
		position: relative;
		top: 0;
	}
	.home-slide-text h1 {
		font-size: 32px;
		margin-bottom: 10px;
	}
	.home-slide {
		min-height: 700px;
	}
	.trust-user p {
		font-size: 16px;
	}
	.rate-head h2,
	.rating h2	{
		font-size: 40px;
	}
	.course-radius {
		margin-bottom: 45px;
	}
	.course-widget {
		background: #fff;
		padding: 60px 20px 0px;
	}
	.student-course,
	.how-it-works,
	.new-course,
    .trend-course {
		padding: 60px 0px;
	}
	.master-skill {
		padding: 60px 0px 0px;
	}
	.section-header h2 {
		font-size: 30px;
	}
	.owl-theme .owl-dots, .owl-theme .owl-nav {
		margin-top: 0px;
	}
	.career-img {
		display: none;
	}
	.feature-instructors {
		padding-top: 60px;
	}
	.lead-group {
		padding: 0px 0px 40px;
	}
	.join-mentor h2 {
		font-size: 25px;
	}
	.join-mentor p {
		font-size: 16px;
		margin: 20px 0px;
	}
	.review {
		bottom: -190px;
	}
	.testimonial-four .slick-prev,
	.testimonial-four .slick-next	{
		width: 45px;
		height: 45px;
	}
	.testimonial-four .slick-prev:before,
	.testimonial-four .slick-next:before {
		font-size: 28px;
	}
	.testi-quotes img {
		width: 90px;
	}
	.become-instructors {
		padding: 240px 0px 60px;
	}
	.mentor-img img {
		position: relative;
		bottom: 0;
		right: 0;
	}
	.top-instructors p {
		margin-bottom: 0;
		font-size: 16px;
	}
	.latest-blog {
		padding: 60px 0px 30px;
	}
	.enroll-group {
		margin: 60px 0px;
	}
	.new-course:before {
		display: none;
	}
	.trending-course {
		background: transparent;
	}
	.testi-right img {
		position: absolute;
		right: 0;
	}
	.cube-instuctor:before,
	.yellow-mentor:before {
		display: none;
	}
	.enroll-img {
		width: 92px;
		height: 92px;
	}
	.total-course {
		margin-bottom: 24px;
	}
	.header-nav {
		height: 60px;
	}
	.icon-group .total-course {
		justify-content: center;
	}
	.home-slide-text h5,
	.home-slide-text p	{
		font-size: 16px;
		margin-bottom: 15px;
	}
	.section-text p {
		font-size: 16px;
	}
	.course-name h4,
	.product-content h3  {
		font-size: 16px;
	}
	.course-group-img  {
		align-items: center;
	}
	.course-info p, .average-rating, .category-tab ul li a {
		font-size: 14px;
	}
	.instructors-content h5 {
		font-size: 18px;
	}
	.instructors-content p {
		font-size: 16px;
		margin: 10px 0px 10px;
	}
	.course-list li {
		font-size: 18px;
		margin-bottom: 12px;
	}
	.testi-quotes img {
		left: 150px;
	}
	.student-mentor h4 {
		font-size: 22px;
		margin-bottom: 15px;
	}
	.icon-group .enroll-img  {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.login-wrapper .loginbox {
		padding: 50px;
	}
	.login-wrapper .loginbox h1 {
		font-size: 26px;
	}
	.sign-google ul li {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;
	}
	.sign-google ul li:first-child a {
		border-right: 0;
	}
	.sign-google ul {
		display: block;
	}
	.sign-google {
		margin: 25px 0px;
	}
	.mentor-course h2 {
		font-size: 22px;
		margin: 15px 0px 15px;
	}
	.login-slide.owl-theme .owl-dots, .login-slide.owl-theme .owl-nav {
		margin-top: 15px;
	}
	.login-banner img {
		max-width: 280px;
	}
	.login-wrapper .register-box {
		padding: 40px;
	}
	.personal-form {
		margin-top: 20px;
	}
	.title-sec h2, .main-title {
		font-size: 28px;
	}
	.header-page, .header-page .header-nav {
		height: 60px;
	}
	.header-border {
		padding: 4px 20px;
	}
	.breadcrumb-list {
	    padding: 0 10px;
	}
	.page-breadcrumb ol, .notify-item a.btn {
		font-size: 14px;
	}
	.wishlist-info h5, .wish-card .card-header h5 {
		font-size: 18px;
	}
	.price-amt h4, .notify-detail h6 {
		font-size: 16px;
	}
	.notify-detail h6 span {
		font-size: 12px;
	}
	.notify-item a.btn {
		min-width: 130px;
	}
	.inner-banner h2 {
		font-size: 24px;
	}
	.sidebar-sec {
		margin-top: 0;
	}
	.play-icon {
		width: 60px;
		height: 60px;
	}
	.play-icon i {
		font-size: 26px;
	}
	.complete-sec h2 {
		font-size: 24px; 
	}
	.instructor-detail h5 {
		font-size: 16px;
	}
	.com-info {
		flex-direction: column;
	}
	.complete-sec .video-thumbnail {
		margin: 20px auto 0;
	}
	.com-course .include-sec ul li:last-child {
		margin-bottom: 15px;
	}
	.com-course .feature-sec ul li:last-child {
		padding-bottom: 15px;
	}
	.nav-justified .nav-item {
		flex-grow: 0;
		flex-basis: unset;
	}
	.category-title h5, .support-wrap label, .faq-detail p, .support-card p {
		font-size: 14px;
	}
	.page-banner {
		padding: 50px 0;
	}
	.page-banner h1, .help-title h1 {
		font-size: 24px;
	}
	.terms-text h3 {
		font-size: 22px;
	}
	.support-card h3 {
		font-size: 20px;
	}
	.help-sec {
		padding: 40px 0 30px;
	}
	.support-sec {
		padding: 40px 0;
	}
	.page-content {
		padding: 50px 0 30px;
	}
	.sup-title {
		margin-bottom: 0px;
	}
	.instructor-content p {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.instructor-info .rating-img p, .instructor-info .course-view p {
		margin-bottom: 0;
	}
	.btn-action-primary {
		font-size: 12px;
	}
	.profile-details .table th {
		font-size: 12px;
	}
	.ticket-grp {
		flex-wrap: wrap;
	}
	.ticket-grp .ticket-btn-grp {
		display: inline-flex;
		margin-top: 10px;
	}
	.filter-tickets ul li {
		margin-bottom: 10px;
	}
	.radio-with-img p.radio-deposit-item {
		display: inline-flex;
		width: 155px;
	}
	.error-box h4 {
		font-size: 20px;
	}
	.error-box h6 {
		font-size: 15px;
		margin: 20px 0px;
	}
	.time-counts li span {
		font-size: 20px;
	}
	.time-counts li {
		font-size: 15px;
		min-width: 80px;
		padding: 15px;
	}
	.time-counts ul li:not(:last-child) {
		margin-right: 10px;
	}
	.come-soon-box h5 {
		font-size: 14px;
		margin: 25px 0px;
	}
	.social-icon-soon ul li i {
		font-size: 20px;
	}
	.error-box-img img {
		width: 240px;
	}
	.error-logo img {
		width: 120px;
	}
	.error-box {
		padding: 20px;
	}
	.course-content {
		padding: 60px 0px;
	}
	.show-filter {
		margin-top: 15px;
	}
	.lms-page {
		margin-bottom: 20px;
		margin-top: 0px;
	}
	.clear-filter {
		margin-bottom: 25px;
	}
	.course-design .price h3 {
		font-size: 18px;
	}
	.price h3 span {
		font-size: 12px;
	}
	.course-group-img img {
		max-width: 40px;
		height: 40px;
	}
	.view-icons a {
		font-size: 15px;
		width: 30px;
		height: 30px;
	}
	.breadcrumb-bar {
		background: #FFF;
		padding: 15px 0;
	}
	.blog-title {
		font-size: 20px;
	}
	.blog-read .btn-primary {
		font-size: 16px;
	}
	.post-left ul li {
		margin-bottom: 5px;
	}
	.blog-modern-box .blog-title a {
		font-size: 18px;
	}
	.student-group .course-group-img img {
		max-width: 80px;
		height: 80px;
		margin-right: 15px;
		border: 3px solid #FFFFFF;
	}
	.student-group .course-name h4 a {
		font-size: 24px;
	}
	.student-group .course-share .btn-primary {
		padding: 8px 20px;
	}
	.my-student-list ul li a {
		font-size: 14px;
		padding: 0px 6px;
		padding-bottom: 10px;
	}
	.progress-stip {
		height: 15px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
		font-size: 14px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		font-size: 12px;
	}
	.cart-subtotal p,
	.cart-subtotal span {
		font-size: 18px;
	}
	.check-outs .btn-primary,
	.condinue-shop .btn-primary {
		padding: 10px 15px;
		font-size: 18px;
	}
	.payment-btn .btn-primary {
		padding: 12px 15px;
		font-size: 18px;
		min-width: 550px;
	}
	.plan-header h4 {
		font-size: 22px;
		padding: 12px 15px;
	}
	.plan-change .btn-primary {
		font-size: 20px;
		padding: 12px 15px;
	}
	.profile-img img {
		width: 90px;
		height: 90px;
		bottom: -50px;
	}
	.profile-bg h5 {
		font-size: 12px;
	}
	.profile-name h4 {
		font-size: 18px;
	}
	.go-dashboard .btn-primary {
		padding: 9px 18px;
	}
	.account-settings .settings-menu ul li a {
		font-size: 14px;
	}
	.profile-heading h3 {
		font-size: 20px;
		margin-bottom: 6px;
	}
	.profile-heading {
		padding: 20px;
	}
	.profile-heading p {
		font-size: 15px;
	}
	.profile-details .course-group-img img {
		max-width: 80px;
		height: 80px;
	}
	.profile-details .course-group {
		flex-wrap: wrap;
	}
	.profile-details .course-group .course-group-img {
		margin-bottom: 15px;
	}
	.profile-share .btn-success,
	.profile-share .btn-danger	{
		padding: 4px 20px;
		font-size: 14px;
	}
	.checkout-form label {
		font-size: 15px;
	}
	.update-profile .btn-primary {
		font-size: 16px;
		min-width: 180px;
	}
	.checkout-form.personal-address,
	.profile-details .course-group,
	.profile-heading {
		padding: 15px;
	}
	.save-password {
		margin-top: 30px;
	}
	.world-img img {
		margin-bottom: 15px;
	}
	.link-accounts.personal-form {
		margin-top: 0;
	}
	.filter-grp.user-referred h3,
	.link-box h3{
		font-size: 20px;
	}
	.subscription-group {
		flex-wrap: wrap;
	}
	.subscription-group .subscription-name {
		margin-bottom: 15px;
	}
	.upgrade-now {
		margin-left: 0;
	}
	.instructor-profile-content h4 a {
        font-size: 26px;
    }
    .instructor-profile-content h4 span {
        margin-left: 0;
    }
    .instructor-profile {
        margin-bottom: 20px;
    }
    .instructor-profile-menu {
        max-width: 100%;
        margin-bottom: 20px;
    }
    .instructor-profile-text {
        padding-top: 0;
    }
    .add-course-header h2 {
        font-size: 26px;
    }
	.widget-setcount ul li p {
		min-width: 135px;
	}
	.invoice-table tr td, 
	.invoice-table-two tr td,
	.invoice-item .invoice-details	{
		font-weight: 500;
		font-size: 15px;
	}
	.about-section .count-two {
		margin-top: 0;
	}
	.about-section .count-four {
		margin-top: 0;
	}	
    .knowledge-sec .featured-img-1 {
        width: 100%;
        float: left;
        height: 777px;
        background-size: cover;
        background-position: inherit;
        margin-bottom: 25px;
    }
    .topcategory-sec {
        padding: 50px 0px 50px;
        background: #fbfcff;
    }
    .footer-two .footer-bottom .copyright {
        padding: 30px 0 0 !important;
    }
    .footer-two .footer-bottom {
        margin-bottom: 0;
    }
	.home-three .main-menu-wrapper {
		background-color: #7B1FFE !important;
	}
	.home-three .main-nav > li {
		border-bottom: 1px solid #E5E5E5;
		margin-left: 0;
	}
    .header-three .main-nav li.login-link {
        display: block;
    }
	.home-three .main-nav > li > a {
		color: #fff;
	}
	.home-three .main-nav ul {
		background-color: #9142ff;
	}
	.main-nav > li .submenu li a {
		color: #fff !important;
	}
	.home-three-slide .girl-slide-img img {
		display: none;
	}
    .home-three-slide {
        text-align: center;
        background-position: center;
        background-size: cover;
    }
    .home-three-slide-text h1 {
        font-size: 30px;
    }
    .home-three-slide-text p {
        margin-bottom: 23px;
        font-size: 16px;
    }
    .banner-three-content .select2-container .select2-selection--single {
        height: 50px;
    }
    .banner-three-content .sub-btn {
        font-size: 18px;
        line-height: 18px;
        padding: 10px 15px;
        width: 50px;
        height: 50px;
    }
    .login-three-head { 
        min-width: 120px;
    }
    .signin-three-head { 
        min-width: 120px;
    }
    .master-three-images {
        margin: auto 0;
    }
    .home-three-slide-text h5 {
        padding: 10px;
        font-size: 12px;
    }
    .home-three-transform .cta-content h2 {
        font-size: 25px;
    }
    .home-three-transform .cta-content p {
        font-size: 16px;
    }
    .btn-action {
        padding: 10px 20px;
        font-size: 14px;
    }
    .award-three-content {
        max-width: 515px;
        float: left;
    }
	.course-details-three {
		margin-bottom: 20px;
	}
	.home-three .student-course {
		position: relative;
		margin-top: 0;
	}
	.master-three-left {
		margin-bottom: 40px;
	}
	.home-three-transform {
		padding: 60px 0 60px;
	}
	.home-three-goals {
		padding: 60px 0 60px;
	}
	.acheive-elips-one {
		position: absolute;
		right: 0.5rem;
		z-index: -1;
		top: -1rem;
	}
	.acheive-elips-two {
		position: absolute;
		right: 1.5rem;
		z-index: -1;
		top: -1rem;
	}
	.acheive-elips-three {
		position: absolute;
		right: 3.8rem;
		z-index: -1;
		top: -1rem;
	}
	.acheive-goals h2 {
		text-align: center;
	}
	.acheive-goals {
		margin-bottom: 40px;
	}
	.home-three-trending {
		padding: 60px 0 60px;
	}
	.gallery-three {
		padding: 60px 0 60px;
	}
	.gallery-three .content-three-details {
		padding: 10px;
	}
	.home-three-become {
		background: #FED700;
		padding: 60px 0 60px;
	}
	.lead-companies-three {
		padding: 60px 0 60px;
	}
	.latest-blog-three {
		background: #ffffff;
		padding: 60px 0 60px;
	}
    .home-three .btn-default {
        font-size: 12px;
        min-width: 120px;
        height: 40px;
        padding: 8px;
    }
    .box-form-newsletter {
        padding: 5px;
    }
	.event-three-title h5 {
		font-size: 25px;
		margin-bottom: 15px;
	}
	.event-three-title p {
		font-weight: 400;
		font-size: 16px;
		color: #DEDEDE;
	}
    .footer-menu-three ul li a {
        font-size: 12px;
        margin-left: 18px;
    }
    .footer-three-title {
        font-size: 18px;
    }
    .footer-three-top {
        margin-bottom: 0;
    }
    .footer-three-bottom .copyright-three {
        padding-top: 40px;
    }
    .social-icon-three {
        margin-bottom: 26px;
    }
    .footer-three-logo {
        width: 180px;
    }
    .social-icon-three h6 {
        font-size: 18px;
    }
    .footer-three .newsletter-title h6 {
        font-size: 18px;
    }
    .footer-three-about p {
        font-size: 14px;
        margin-bottom: 30px;
    }
	.span-name-three {
		padding: 8px 30px;
		font-size: 14px;
		border-radius: 4px;
		display: inline-flex;
		margin-bottom: 10px;
		color: #ffffff;
	}
	.home-two .header-two-text {
		max-width: 700px;
		color: #685F78;
		font-size: 16px;
		font-weight: 500;
	}
	.home-two-shapes {
		position: absolute;
		right: 1rem;
		bottom: 18rem;
	}
	.winning-two-one {
		margin-bottom: 20px;
		text-align: center;
	}
	.winning-two-two {
		margin-bottom: 20px;
	}
	.winning-two-three {
		margin-bottom: 20px;
	}
	.joing-icon-award {
		display: none;
	}
	.growup-skills-img img {
		max-width: 100%;
	}
	.home-two-shapes img {
		display: none;
	}
	.home-two .join-title-one h2 {
		text-align: center;
        font-size: 24px;
        margin-bottom: 15px;
	}
    .joing-icon-award .joing-icon-two {
        bottom: 4rem;
        right: 29rem;
    }
    .winning-two-one img {
	    max-width: inherit;
	}
    .join-title-middle {
        margin: 0;
        max-width: inherit;
    }
	.home-two .join-title-one p {
		text-align: center;
        margin-bottom: 15px;
        font-size: 14px;
	}
	.home-two .join-title-middle h2 {
		text-align: center;
        font-size: 24px;
        margin-bottom: 15px;
	}
	.home-two .join-title-middle p {
		text-align: center;
        margin-bottom: 15px;
        font-size: 14px;
	}
	.winning-two-two {
		text-align: center;
	}
	.winning-two-two img {
		max-width: inherit;
	}
	.winning-two-three {
		text-align: center;
	}
	.winning-two-three img {
		max-width: inherit;
	}
    .joing-icon-award .joing-icon-three {
        bottom: 4rem;
        left: 29rem;
    }
    .master-skills-sec {
        padding: 50px 0 50px;
    }
	.home-two .testimonial-sec {
		padding: 50px 0 50px;
	}
	.home-two .course-column-img {
		-ms-flex: 25%;
		flex: 25%;
		max-width: 25%;
	}
	.home-two .course-content-column {
		-ms-flex: 75%;
		flex: 75%;
		max-width: 75%;
	}
	.home-two .instructors-img img {
		width: 100%;
	}
	.home-two .feature-instructors-sec {
		padding: 50px 0 50px;
	}
	.home-two .real-reviews-sec {
		padding: 50px 0 50px;
	}
	.real-reviews-group {
		margin-bottom: 0px;
		text-align: center;
	}
	.home-two .reviews-img {
		margin-bottom: 20px;
	}
	.home-two .owl-nav.slide-nav-8.nav-control {
		text-align: center;
		display: block;
		margin: auto;
	}
    .login-head {
        padding: 5px 30px;
    }
    .signin-head {
        padding: 5px 20px;
    }
    .home-two .sub-btn {
        background: #FF602E;
        border-radius: 5px !important;
        width: auto;
    }
    .header-navbar-rht > li > a {
        font-size: 14px;
    }
    .home-two .banner-content .sub-btn {
        background: #FF602E;
        border-radius: 5px !important;
        border: 1px solid #FF602E;
        margin: 0px;
        font-size: 16px;
        line-height: 18px;
        padding: 10px 35px;
        color: #fff;
    }
    .home-two .select2-container--default .select2-selection--single 
    .select2-selection__rendered {
        font-weight: 700;
        font-size: 14px !important;
        color: #5C5C5C;
    }
    .footer-three-top-content {
        padding-bottom: 20px;
    }
    .header-four .main-menu-wrapper {
        background-color: #EE009C;
    }
    .header-four .main-nav li a {
        color: #ffffff !important;
    }
    .header-four .main-nav ul {
        background-color: #e578bf;
    }
    .header-four .bar-icon span {
        background-color: #e578bf;
    }
    .banner-heading h2 {
        font-size: 33px;
        padding-bottom: 15px;
    }
    .vector-four {
        display: none;
    }
    .banner-img-third {
        position: absolute;
        right: 1rem;
        top: 50px;
    }
    .banner-img-fourth {
        position: absolute;
        right: 0;
        top: 151px;
    }
	.banner-section {
		padding-top: 30px;
		background-size: auto, 0;
		text-align: center;
	}
	.banner-heading h2 {
		font-size: 33px;
		padding-bottom: 15px;
	}
	.banner-heading h5 {
		padding-bottom: 20px;
	}
	.course-categories {
		padding: 40px 0;
		text-align: center;
	}
	.section-heading h2 {
		font-size: 28px;
	}
	.course-categories .section-heading {
		padding: 20px 0 40px;
	}
	.online-course {
		padding-bottom: 20px;
		border: none;
	}
	.our-courses .section-heading h5 {
		margin: 0;
	}
	.course-caste span {
		font-size: 21px;
	}
	.php-course a {
		font-size: 18px;
	}
	.card-button {
		padding: 0 0 15px 0;
	}
	.our-courses {
		padding: 40px 0;
	}
	.our-courses .section-heading {
		padding-bottom: 40px;
		text-align: center;
	}
	.career-skills {
		padding: 40px 0;
		text-align: center;
	}
	.skills-info .section-heading h2 {
		max-width: 100%;
	}
	.skills-info ul {
		text-align: left;
	}
	.skills-info {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.skills-img {
		padding-bottom: 30px;
	}
	.skill-img {
		padding-bottom: 15px;
	}
	.multiple-informations {
		padding: 0 0 20px 0;
		gap:20px;
	}
	.multiple-informations h5 {
		margin: 0;
	}
	.feature-instructor {
		padding: 40px 0;
	}
	.feature-instructor .section-heading {
		padding-bottom: 40px;
		text-align: center;
	}
	.feature-instructor .section-heading h5 {
		margin: 0;
	}
	.testimonial {
		padding: 40px 0;
		background-size: 300px, 300px;
	}
	.testimonial-img img {
		padding: 30px 0;
	}
	.testimonial-name p{
		padding: 30px 0;
	}
	.latest-blog .section-heading {
		padding-bottom: 40px;
		text-align: center;
	}
	.latest-blog .section-heading h5 {
		margin: 0;
	}
	.latest-blog {
		padding: 40px 0;
	}
	.blog-info .course-info a{
		font-size: 18px;
	}
	.unlimited-courses-labs {
		padding: 40px 0;
	}
	.unlimited-courses-labs .section-heading {
		padding-bottom: 40px;
	}
	.unlimited-courses-labs .section-heading h2 {
		margin: 0;
	}
	.education-details {
		padding: 40px 0 10px 0;
	}
	.education-details-box {
		padding-bottom: 30px;
	}
	.news-letter-four {
		padding: 40px 0;
		text-align: center;
	}
	.request-box .name-field input {
		width: 100%;
	}
	.request-box .email-field input {
		width: 100%;
	}
	.footer {
		padding: 40px 0;
		background-image: none;
	}
	.footer-bottom {
		text-align: center;
		margin-bottom: 40px;
	}
	.footer-logo p {
		padding: 40px 0 0 0;
	}
	.footer-main-info {
		flex-wrap: wrap;
		padding-bottom: 40px;
	}
	.footer-main-info h2 {
		padding-bottom: 15px;
	}
	.favourite-course .owl-nav, .our-courses-slider .owl-nav ,.feature-instructor-slider .owl-nav, .latest-blog-slider .owl-nav {
		position: static;
		justify-content: center;
		padding-top: 20px;
	}
	.banner-sections-two {
		text-align: -moz-center;
		text-align: -webkit-center;
		padding: 40px 0;
	}
	.banner-sections-two .section-heading {
		padding-top: 0;
	}
	.banner-sections-two .banner-imgs {
		display: none;
	}
	.banner-small-circles .small-circle-one {
		position: absolute;
		top: 50px;
		left: 100px;
	}
	.banner-small-circles .small-circle-two {
		position: absolute;
		top: 0;
		left: 900px;
	}
	.banner-small-circles .small-circle-four {
		position: absolute;
		left: -30px;
		bottom: 80px;
	}
	.trained-by-experts {
		padding: 40px 0 20px 0;
	}
	.banner-sections-two .section-heading p {
		font-size: 20px;
	}
	.online-courses {
		margin-bottom: 20px;
	}
	.best-course-section .best-course-heading {
		justify-content: space-evenly;
		gap:0 !important;
	}
	.best-course-slider .owl-nav .owl-prev i {
		position: absolute;
		top: 210px;
		left: -70px;
	}
	.section-headings::after {
		display: none;
	}
	.best-course-slider .owl-nav .owl-next i {
		position: absolute;
		right: -70px;
		top: 210px;
	}
	.best-course-slider .owl-nav {
		position: static;
	}
	.best-course-slider .owl-nav .owl-prev i, .best-course-slider .owl-nav .owl-next i {
		display: none;
	}
	.best-course-section {
		padding: 40px 0;
	}
	.best-course-heading {
		padding-bottom: 30px;
	}
	.master-skills-section {
		padding: 40px 0;
		text-align: -moz-center;
		text-align: -webkit-center;
	}
	.skills-main-info .section-headings {
		padding-top: 0;
		padding-bottom: 30px;
	}
	.section-headings h2 {
		padding-bottom: 10px;
	}
	.feature-instructor-two {
		padding: 40px 0;
	}
	.feature-instructor-two .section-headings {
		padding-bottom: 30px;
	}
	.accelerate-cloud-section {
		padding: 40px 0;
	}
	.cloud-technologies .technology-item {
		width: 300px;
	}
	.cloud-technology-img {
		display: none;
	}
	.students-enrolled .total-students span {
		font-size: 23px;
	}
	.students-enrolled .total-students h5 {
		font-size: 17px;
	}
	.student-section {
		padding-top: 40px;
	}
	.student-section .section-headings p {
		font-size: 16px;
		padding-bottom: 40px;
	}
	.multiple-students-informations {
		padding-bottom: 40px;
	}
	.student-section .section-headings h2 {
		font-size: 20px;
	}
	.user-registration {
		gap: 0;
	}
	.registration-info h2 {
		font-size: 30px;
		max-width: 235px;
	}
	.registration-info p {
		font-size: 14px;
	}
	.registration-info a {
		padding: 12px 40px;
	}
	.student-bottom-section {
		padding: 40px 0;
	}
	.impressive-section .section-headings {
		padding-bottom: 30px;
	}
	.impressive-section {
		padding: 40px 0;
	}
	.impressive-card p {
		padding: 20px 0;
	}
	.latest-news-events {
		padding: 40px 0;
	}
	.latest-news-events .section-headings {
		padding-bottom: 30px;
	}
	.news-card-img .date {
		width: 70px;
		height: 70px;
	}
	.news-card-img .rounded-img {
		top: 230px;
	}
	.news-card-body h2 {
		font-size: 18px;
	}
	.news-card-body p {
		font-size: 15px;
	}
	.unlimited-courses-labs-two {
		padding: 40px 0;
	}
	.unlimited-courses-labs-two .section-heading h2 {
		font-size: 20px;
	}
	.unlimited-courses-labs-two .multiple-labs {
		padding-bottom: 40px;
	}
	.trusted-customer {
		padding-top: 40px;
	}
	.trusted-customer .section-heading h2 {
		font-size: 25px;
	}
	.footer-two .footer-top-two h2 {
		font-size: 22px;
		padding-bottom: 20px;
	}
	.footer-two .footer-top-two h5 {
		font-size: 18px;
	}
	.footer-top-two .email-address-two {
		margin-bottom: 20px;
	}
	.goto-website {
		padding-bottom: 30px;
	}
	.footer-two-info h3 {
		padding-bottom: 20px;
	}
	.footer-top-two {
		padding-bottom: 30px;
	}
    .banner-content-five .sub-btn {
        padding: 10px 20px;
    }
    .banner-content-five .select2-container 
    .select2-selection--single {
        height: 50px;
        font-size: 14px;
    }
    .banner-content-five .select2-container .select2-selection--single 
    .select2-selection__rendered {
        display: flex;
        padding-left: 0;
        padding-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .banner-content-five .select2-container--default 
    .select2-selection--single .select2-selection__rendered {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
    }
    .banner-content-five .form-control::placeholder { 
        font-size: 12px;
    }      
    .banner-content-five .form-control:-ms-input-placeholder { 
        font-size: 12px;
    }      
    .banner-content-five .form-control::-ms-input-placeholder { 
        font-size: 12px;
    }
    .banner-content-five .select2-container--default 
    .select2-selection--single .select2-selection__rendered {
        font-size: 12px;
    }
    .slider-five-one {
        display: none;
    }
    .slider-five-two {
        display: none;
    }
	.slider-five-one img {
		width: 100%;
		max-width: 550px;
	}
	.slider-five-two img {
        width: 220px !important;
        max-width: 475px;
        float: right;
    }
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 70px;
	}
	.header-navbar-five > li .signup-five {
		font-size: 14px;
		padding: 8px 30px;
	}
	.header-navbar-five > li .login-five {
		font-size: 14px;
	}
    .master-section-five .section-five-sub {
        margin-bottom: 50px;
    }
    .vector-shapes-five {
       display: none;
    }
    .experienced-five-sub {
        max-width: 100%;
        margin: auto;
        padding-left: 0;
    }
    .instructor-vector-left {
        position: absolute;
        left: 10rem;
        top: 0.7rem;
    }
    .instructor-vector-right {
        position: absolute;
        right: 12rem;
        bottom: 1.7rem;
    }
    .master-section-five .header-five-title h2 {
        max-width: 100%;
        width: 100%;
    }
    .home-five .course-count h3 {
        margin-bottom: 0;
        font-size: 25px;
    }
    .home-five .course-count p {
        margin-bottom: 0;
        font-size: 16px;
    }
    .master-five-vector {
        display: none;
    }
    .goals-section-five .header-five-title h2 {
        text-align: center;
        margin-bottom: 40px;
    }
    .footer-five-left {
        display: none;
    }
    .footer-five-right {
        display: none;
    }
    .footer-six-info {
        display: block;
        justify-content: flex-start;
    }
    .footer-bottom-six .social-media-links {
        float: none;
    }
    .footer-bottom-six p {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        padding-bottom: 45px;
    }
    .footer-top-six .email-address-six {
        margin-bottom: 50px;
    }
    .footer-top-six {
        padding-bottom: 30px;
    }
    .footer-six .footer-top-six h2 {
        font-size: 18px;
    }
    .footer-instructor-info-six {
        margin-bottom: 30px;
    }
    .sign-btn-six {
        padding: 10px 15px;
        font-size: 14px !important;
    }
    .login-btn-six {
        padding: 10px 25px;
        font-size: 14px !important;
    }
    .home-six header input[type=search] {
        padding: 8px 8px 8px 25px;
    }
    .banner-sections-six .banner-imgs .banner-img-three {
        position: absolute;
        bottom: 28px;
        right: 0;
        width: 300px;
    }
    .header-navbar-rht > li {
        padding-right: 10px;
    }
    .right-header-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
        transition: all 0.3s ease;
    }
    .best-course-section .small-icons .hat-img {
        display: none;
    }
    .master-skills-section .small-circles .small-circle-two {
        display: none;
    }
    .master-skills-section .small-circles .small-circle-three {
        display: none;
    }
    .student-section .section-small-imgs .vector-img-two {
        display: none;
    }
    .footer-top-six .subscribe-btn-six button {
        padding: 10px 35px;
        font-size: 14px;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        position: absolute;
        bottom: 50px;
        left: 0;
    }
    .banner-sections-six .banner-imgs .banner-img-two {
        position: absolute;
        top: 20rem;
        right: 13rem;
        width: 300px;
    }
    .banner-sections-six .banner-imgs .banner-img-three {
        position: absolute;
        bottom: 5rem;
        right: 13rem;
        width: 300px;
    }
    .more-information li {
        position: relative;
        margin-left: 20px;
    }
    .home-two .join-title-one {
    	max-width: inherit;
    }
    .header-nav-three {
    	padding: 0;
    }
    .header-nav-three #mobile_btn {
    	top: 2px;
    }
    .header-three .main-nav > li .submenu li a {
    	padding: 10px 20px;
    }
    .header-three .menu-header {
    	height: 70px;
    }
    .header-three .main-nav li a:hover {
    	color: #fff;
    }
}

@media (max-width: 767.98px) {
	body {
		font-size: 15px;
	}
	.navbar-header {
		width: 100%;
	}
	.logo {
		margin-right: 0;
		width: 100%;
		text-align: center;
	}
	.header-nav {
		height: 60px;
	}
	.header-nav-three #mobile_btn {
    	top: 50%;
    	transform: translateY(-50%);
    }
	.search-box .form-group .form-control {
		font-size: 14px;
	}
	.profile-custom-list > li:nth-child(2n+1){
		clear: left;
	}
	.bg-pattern-style-register {
		height: auto;
	}
	.footer .footer-widget .footer-logo img {
		max-height:40px;
	}
	#mobile_btn {
		top: 18px;
		margin-right: 0;
		position: absolute;
	}
	.logo img,
	.menu-logo img {
		width: 120px;
	}
	h5 {
		font-size: 16px;
	}
	h6 {
	    font-size: 14px;
	}
	.testimonial-four .testimonial-all {
		background: none;
		min-height: 454px;
	}
	.footer-menu-three ul li a {
		font-size: 14px;
	}
	.testimonial-four .testimonial-two-head {
		max-width: 95%;
	}
	.testimonial-four .testimonial-two-head p {
		font-size: 14px;
	}
	.testimonial-four .slick-list {
		padding: 0px !important;
	}
	.testimonial-four .slick-list {
		max-width: 85%;
	}
	.testimonial-four .slick-prev:before,
	.testimonial-four .slick-next:before {
		font-size: 24px;
	}
	.testimonial-four .slick-prev {
		left: 65px;
		width: 30px;
		height: 30px;
	}
	.testimonial-four .slick-next{
		right:65px;
		width: 30px;
		height: 30px;
	}
	.testimonial-four .slick-prev, .testimonial-four .slick-next {
		top: 63%;
	}
	.testimonial-bg-top img {
		top: 18px;
		left: 38%;
		width: 160px;
	}
	.testimonial-bg-right img {
		top: 50%;
		right: -77px;
		width: 140px;
	}
	.testimonial-bg-left img {
		top: 50%;
		left: 59px;
		width: 140px;
	}
	.testimonial-two-head {
		max-width: 90%;
	}
	.testimonial-thumb img {
		width: 40px;
	}
	.testimonial-two .slider-nav {
		margin-top: 0;
	}
	.testimonial-two-head {
		padding: 15px;
	}
	.bar-icon span {
		background-color: #7B1FFE;
	}
	.header.header-navbar-rht {
		display: none;
	}
	.course-key {
		position: relative;
		top: 0;
	}
	.home-slide-text h1 {
		font-size: 28px;
		margin-bottom: 10px;
	}
	.home-slide {
		padding: 100px 0px 60px;
	}
	.home-slide-text a,
	.home-slide-text p,
	.home-slide-text h5,
	.trust-user p {
		font-size: 15px;
	}
	.banner-content .form-control {
		height: 46px;
	}
	.banner-content .sub-btn {
		width: 42px;
		height: 42px;
		font-size: 14px;
		margin-top: 10px;
	}
	.trending-three-item {
		margin-bottom: 20px;
	}
	.gallery-img {
		margin-bottom: 20px;
	}
	.rate-head h2,
	.rating h2	{
		font-size: 32px;
	}
	.rate-head h2 {
		margin-right: 20px;
	}
	.course-widget {
		padding: 70px 20px 0px;
	}
	.student-course,
	.how-it-works,
	.new-course,
	.trend-course,
	.share-knowledge {
		padding: 40px 0px;
	}
	.master-skill {
		padding: 40px 0px 0px;
	}
	.feature-instructors {
		padding-top: 40px;
	}
	.lead-companies {
		padding: 40px 0px 20px;
	}
	.lead-group {
		padding: 10px 0px 40px;
	}
	.course-radius {
		margin-bottom: 50px;
	}
	.section-header {
		position: relative;
		display: block;
	}
	.section-sub-head {
		margin-bottom: 20px;
	}
	.section-header h2 {
		font-size: 28px;
	}
	.section-text p,
	.join-mentor p,
	.section-sub-head span,
	.top-instructors p,
	.course-count p	{
		font-size: 15px;
	}
	.owl-theme .owl-dots, .owl-theme .owl-nav {
		margin-top: 0;
	}
	.owl-theme .owl-dots .owl-dot span {
		width: 10px;
		height: 10px;
	}
	.owl-theme .owl-dots .owl-dot.active span, 
	.owl-theme .owl-dots .owl-dot:hover span {
		width: 25px;
		height: 10px;
	}
	.join-mentor h2 {
		font-size: 28px;
	}
	.mentor-img img {
		position: relative;
		bottom: 0;
		right: 0;
	}
	.top-instructors p {
		margin-bottom: 0;
	}
	.student-mentor {
		margin-bottom: 20px;
	}
	.become-instructors {
		padding: 260px 0px 20px;
	}
	.latest-blog {
		padding: 40px 0px 13px;
	}
	.enroll-group {
		margin: 40px 0px;
	}
	.course-count h3 {
		font-size: 24px;
		margin-bottom: 1px;
	}
	.footer-bottom .copyright-text p {
		text-align: center;
	}
	.privacy-policy {
		text-align: center;
		margin-bottom: 20px;
	}
	.footer-bottom .copyright {
		padding: 0 0 20px;
	}
	.testi-quotes img {
		width: 50px;
	}
	.trending-course {
		background: transparent;
		margin-top: 25px;
	}
	.course-feature {
		margin-top: 25px;
	}
	.testi-right img {
		right: 0;
		right: -15px;
		width: 25px;
		top: -16px;
	}
	.cube-instuctor:before,
	.yellow-mentor:before {
		display: none;
	}
	.enroll-img img {
		width: 100%;
	}
	.review {
		top: -300px;
		bottom: inherit;
	}
	.scroll-sticky {
		margin-top: 0;
	}
	.fount-about-img img {
		width: 60px;
	}
	.four-testimonial-founder h3 {
		font-size: 20px;
	}
	.four-testimonial-founder span, .help-title p, .faq-title a {
		font-size: 14px;
	}
	.testimonial-four .testimonial-two-head p, .faq-card {
		margin-bottom: 15px;
	}
	.enroll-img {
		width: 100%;
		height: 100%;
	}
	.form-inner .form-control {
		width: 100%;
	}
	.banner-content .form-inner {
		height: auto;
	}
	.banner-content .input-group .search-icon {
		display: none;
	}
	.drop-detail {
		width: 100%;
	}
	.trust-rating .rating i {
		padding: 0px 5px;
		font-size: 12px;
	}
	.testi-quotes img {
		left: 60px;
		top: -10px;
	}
	.testimonial-four .testimonial-two-head p {
		margin: 0px 0px 43px
	}
	.icon-group .total-course {
		justify-content: center;
		width: 100%;
	}
	.enroll-group .total-course {
		margin-bottom: 5px;
	}
	.course-group-img {
		align-items: center;
	}
	.course-name h4 {
		font-size: 15px;
		margin-bottom: 0px;
	}
	.product-content h3 {
		font-size: 16px;
	}
	.course-info p {
		font-size: 14px;
	}
	.all-category .btn-primary {
		min-width: 130px;
		padding: 6px 15px;
		font-weight: 500;
		font-size: 14px;
	}
	.home-slide-text h5 {
		margin-bottom: 15px;
	}
	.home-slide-text p {
		font-size: 14px;
		margin-bottom: 25px;
	}
	.home-three-slide-face {
		padding: 100px 0 50px;
	}
	.trust-user p {
		margin: 21px 0px 10px;
	}
	.instructors-course {
		margin-top: 37px;
	}
	.instructors-content p {
		font-size: 15px;
	}
	.student-count span,
	.student-count i {
		font-size: 14px;
	}
	.course-list li {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.course-count p {
		font-size: 14px;
	}
    .home-three-goals .course-count p {
		text-align: center;
	}
    .home-three-goals .acheive-goals-content h4 {
		text-align: center;
	}
	.enroll-group {
		padding: 20px;
	}
	.enroll-img {
		width: 60px;
		height: 100%;
	}
	.student-mentor h4 {
		font-size: 20px;
	}
	.price {
		min-width: 130px;
		padding: 10px 15px;
		border-radius: 10px;
	}
	.price h3 {
		font-size: 16px;
	}
	.login-bg {
		display: none;
	}
	.login-wrapper .loginbox {
		padding: 50px;
	}
	.login-wrapper .loginbox h1 {
		font-size: 24px;
		margin-bottom: 15px;
	}
	.login-wrapper .loginbox label {
		font-size: 15px;
	}
	.google-bg {
		padding: 20px;
	}
	.sign-google ul li a img {
		margin-right: 10px;
		width: 16px;
	}
	.sign-google ul {
		display: block;
	}
	.sign-google ul li:first-child a {
		border-right: 0;
	}
	.sign-google ul li {
		margin-bottom: 10px;
	}
	.sign-google {
		margin: 20px 0px;
	}
	.login-wrapper .loginbox .img-logo {
		display: block;
		margin-bottom: 35px;
	}
	.back-home {
		margin-top: 10px;
	}
	.personal-form {
		margin-top: 20px;
	}
	.login-wrapper .register-box {
		padding: 35px;
	}
	.personal-detail span {
		width: 22px;
		height: 22px;
		font-size: 12px;
	}
	.title-sec h5, .title-sec p {
		font-size: 14px;
	}
	.title-sec h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.table-price td {
		font-size: 14px;
	}
	.wishlist-info h5, .wish-card .card-header h5 {
		font-size: 16px;
	}
	.wishlist-detail {
		display: block;
		flex-direction: column;
	}
	.wishlist-img {
		margin: 0 auto 20px;
	}
	.wishlist-info .course-info {
		justify-content: center;
	}
	.wishlist-info .course-info p {
	    margin-left: 7px;
	}
	.wish-card .card-body, .wish-card .card-header {
		padding: 20px;
	}
	.wishlist-info .course-view {
		margin-left: 10px;
	}
	.wishlist-item .remove-btn {
		margin-top: 25px;
		text-align: center;
	}
	.btn {
		font-size: 14px;
	}
	.wishlist-info {
		text-align: center;
	}	
	.wishlist-img img, .wishlist-img {
		max-width: 230px;
	}
	.course-content {
		padding: 50px 0;
	}
	.notify-item a.btn {
		margin: 20px 0 0 60px;
	}
	.notify-sec h5 {
		font-size: 15px;
		margin-bottom: 20px;
	}
	.course-card ul li {
		display: block;
	}
	.course-card ul li p {
		margin-bottom: 10px;
	}
	.inner-banner h2 {
		font-size: 22px;
	}
	.complete-sec h2 {
		font-size: 20px;
	}
	.web-badge, .cou-info {
		margin-bottom: 10px;
	}
	.instructor-badge .web-badge {
		margin-bottom: 0;
		font-size: 12px;
	}
	.instructor-wrap .rating {
		margin: 10px 0;
	}
	.instructor-detail h5 {
		font-size: 15px;
	}
	.terms-text h3, .help-title h1  {
		font-size: 20px;
	}
	.support-img img {
		position: relative;
	}
	.support-sec {
		padding: 40px 0 20px;
	}
	.support-card {
		margin-bottom: 20px;
	}
	.instructor-list {
		display: block;
		padding: 10px;
	}
	.instructor-list .instructor-content {
		padding: 0;
	}
	.instructor-list .instructor-img img {
		border-radius: 7px;
		margin-bottom: 15px;
	}
	.instructor-list .instructor-content .rating-count {
		right: 0;
		top: 0;
	}
	.check-on .form-check-input {
		min-height: 25px;
		width: 55px;
		flex: none;
	}
	.payment-list {
		flex-wrap: wrap;
	}
	.grp-action-style {
		padding: 0;
	}
	.payment-method-blk .payment-list:not(:last-child) {
		margin-bottom: 10px;
	}
	.profile-details .table th {
		font-size: 12px;
	}
	.profile-details .comman-space {
		padding: 15px;
	}
	.ticket-grp {
		flex-wrap: wrap;
	}
	.ticket-grp .ticket-btn-grp {
		display: inline-flex;
		margin-top: 10px;
	}
	.filter-grp h3 {
		font-size: 20px;
	}
	.filter-tickets ul li {
		width: 100%;
		margin-bottom: 10px;
	}
	.sell-course-head h3 {
		font-size: 20px;
	}
	.instruct-search-blk .select-form .form-select {
		margin-left: 0;
	}
	.instruct-search-blk .show-filter .form-control, 
	.instruct-search-blk .select-form .form-select {
		max-width: 100%;
	}
	.sell-group-img {
		width: 112px;
		height: 82px;
	}
	.sell-table-group .sell-tabel-info p {
		font-size: 14px;
	}
	.course-instruct-blk .table-nowrap tr th, 
    .course-instruct-blk .table-nowrap tr td {
		white-space: nowrap;
	}
	.instruct-orders-info p {
		max-width: 100%;
	}
	.ticket-btn-grp a {
		padding: 8px 28px;
	}
	.radio-with-img p.radio-deposit-item {
		display: inline-flex;
		width: 100%;
	}
	.error-box h4 {
		font-size: 18px;
	}
	.error-box h6 {
		font-size: 14px;
		margin: 15px 0px;
	}
	.time-counts li span {
		font-size: 18px;
	}
	.time-counts li {
		font-size: 14px;
		min-width: 70px;
	}
	.time-counts ul li:not(:last-child) {
		margin-right: 5px;
	}
	.come-soon-box h5 {
		font-size: 12px;
		margin: 20px 0px;
	}
	.social-icon-soon ul li i {
		font-size: 18px;
	}
	.error-box-img img {
		width: 200px;
	}
	.error-logo img {
		width: 100px;
	}
	.error-box {
		padding: 10px;
	}
	.lms-page {
		margin-bottom: 20px;
		margin-top: 0px;
	}
	.show-filter .search-group {
		margin: 12px 0px;
	}
	.lms-page li .page-link {
		margin-right: 10px;
		width: 30px;
		height: 30px;
		color: #000000;
		font-size: 12px;
	}
	.course-design .price h3 {
		font-size: 15px;
	}
	.price h3 span {
		font-size: 12px;
	}
	.clear-filter h4 {
		font-size: 20px;
	}
	.clear-filter {
		margin-bottom: 25px;
	}
	.clear-text p {
		font-size: 12px;
	}
	.course-content {
		padding: 40px 0px;
	}
	.list-course .product {
		display: block;
	}
	.list-course .product-img {
		margin-bottom: 12px;
		width: 100%;
	}
	.view-icons a {
		font-size: 15px;
		width: 30px;
		height: 30px;
	}
	.show-result h4 {
		font-size: 14px;
	}
	.breadcrumb-bar {
		background: #FFF;
		padding: 15px 0;
	}
	.page-breadcrumb ol {
		font-size: 14px;
	}
	.blog-title {
		font-size: 18px;
	}
	.post-left ul li {
		margin-bottom: 10px;
	}
	.blog-info a {
		font-size: 12px;
	}
	.blog-read .btn-primary {
		font-size: 14px;
		padding: 7px 26px;
	}
	.blog-modern-box .blog-title a {
		font-size: 16px;
	}
	.student-group .course-group {
		display: block;
	}
	.student-group .course-share  {
		display: block;
		margin-top: 15px;
	}
	.student-group .course-group-img img {
		max-width: 70px;
		height: 70px;
		margin-right: 12px;
	}
	.student-group .course-name h4 a {
		font-size: 20px;
	}
	.student-group .course-name p {
		font-size: 14px;
	}
	.student-group .course-name h4 span {
		font-size: 12px;
	}
	.student-group .course-share .btn-primary {
		padding: 8px 20px;
	}
	.my-student-list ul {
		display: block;
	}
	.my-student-list ul li .active {
		border-bottom: 0;
	}
	.my-student-list ul li a {
		padding-bottom: 0;
	}
	.my-student-list ul li {
		list-style: none;
		margin-right: 0;
		margin-bottom: 15px;
	}
	.mycourse-student {
		display: -webkit-box;
		display: -ms-flexbox;
		display: block;
	}
	.show-filter .mycourse-student .form-control {
		max-width: 100%;
	}
	.progress-stip {
		height: 10px;
	}
	.start-leason .btn-primary {
		padding: 10px 15px;
	}
	.my-student-list ul li a {
		font-size: 14px;
	}
	.purchase-widget .head-course-title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: block;
	}
	.chat-cont-left,
	.chat-cont-right {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
		font-size: 14px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		font-size: 12px;
	}
	.cart-head h4 {
		font-size: 18px;
	}
	.cart-remove .btn-primary {
		font-size: 14px;
	}
	.cart-subtotal p,
	.cart-subtotal span {
		font-size: 16px;
	}
	.check-outs .btn-primary,
	.condinue-shop .btn-primary {
		padding: 10px 15px;
		font-size: 16px;
	}
	.check-outs .btn-primary {
		margin-bottom: 10px;
	}
	.course-design .course-info p {
		font-size: 12px;
	}
	.payment-btn .btn-primary,
	.plan-change .btn-primary	{
		min-width: 100%;
		padding: 10px 15px;
		font-size: 16px;
	}
	.pay-method {
		margin: 30px 0px;
	}
	.checkout-form label,
	.checkout-form .form-control, 
	.checkout-form .form-select	{
		font-size: 14px;
	}
	.ship-check {
		font-size: 12px;
	}
	.plan-header h4 {
		font-size: 20px;
	}
	.basic-plan h3 {
		font-size: 18px;
	}
	.basic-plan h2 {
		font-size: 28px;
	}
	.profile-details .course-group-img,
	.profile-details .course-group	{
		flex-wrap: wrap;
	}
	.profile-details .course-group-img .course-name {
		margin: 15px 0px;
	}
	.profile-share {
		margin-left: 0;
	}
	.profile-details .course-group-img img {
		max-width: 60px;
		height: 60px;
	}
	.profile-img img {
		width: 90px;
		height: 90px;
		bottom: -50px;
	}
	.profile-name h4 {
		font-size: 16px;
	}
	.profile-name p {
		font-size: 12px;
	}
	.profile-group {
		padding-top: 62px;
	}
	.go-dashboard .btn-primary {
		padding: 8px 24px;
	}
	.profile-heading h3 {
		font-size: 20px;
	}
	.profile-heading p {
		font-size: 14px;
	}
	.update-profile .btn-primary {
		font-size: 16px;
		min-width: 180px;
	}
	.personal-address p {
		margin-bottom: 20px;
	}
	.checkout-form.personal-address,
	.profile-details .course-group,
	.profile-heading	{
		padding: 15px;
	}
	.un-subscribe .btn-danger {
		font-size: 12px;
	}
	.world-img img {
		margin-bottom: 15px;
	}
	.link-accounts.personal-form {
		margin-top: 0;
	}
	.widra-your-money,
	.user-referred	{
		flex-wrap: wrap;
	}
	.filter-grp.user-referred h3 {
		font-size: 20px;
		margin-bottom: 15px;
	}
	.link-box h3 {
		font-size: 20px;
	}
	.subscription-group {
		flex-wrap: wrap;
	}
	.subscription-group .subscription-name {
		margin-bottom: 15px;
	}
	.upgrade-now .btn-primary,
	.active-change-plan .btn-primary {
		font-size: 14px;
	}
	.upgrade-now {
		margin-left: 0;
	}
	.monthly-group {
		flex-wrap: wrap;
	}
	.monthly-group .subscribtion-active {
		margin-bottom: 15px;
	}
	.active-change-plan {
		margin-left: 0;
	}
	.instructor-card .card-body {
        padding: 15px;
    }
    .instructor-profile-content h4 {
        margin-bottom: 10px;
    }
    .instructor-profile-content h4 a {
        font-size: 20px;
    }
    .instructor-profile-menu {
        padding: 15px;
    }
    .instructor-profile-menu ul {
        flex-direction: column;
    }
    .instructor-profile-menu ul li {
        margin-bottom: 15px;
    }
    .instructor-profile-menu ul li:last-child {
        margin-bottom: 0;
    }
    .instructor-profile-menu-content p {
        font-size: 14px;
    }
    .instructor-profile-text p {
        font-size: 14px;
    }
    .instructor-grid {
        display: block;
    }
    .instructor-grid .product-img {
        width: 100%;
        margin-bottom: 15px;
    }
    .instructor-grid .instructor-product-content {
        margin-left: 0;
    }
    .instructor-grid .instructor-product-content h3 {
        font-size: 16px;
        max-width: 100%;
        line-height: normal;
    }
    .instructor-grid .course-info {
        margin: 15px 0px;
    }
    .instructor-grid .course-info p {
        font-size: 14px;
        margin-left: 6px;
    }
    .instructor-grid .course-view {
        margin-left: 8px;
    }
    .add-course-header {
        padding-bottom: 20px;
        flex-direction: column;
    }
    .add-course-header h2 {
        font-size: 18px;
    }
    .add-course-btns ul {
        flex-direction: column;
    }
    .add-course-btns ul li {
        margin-top: 10px;
        margin-right: 0;
    }
    .add-course-btns .btn {
        padding: 8px 15px;
        min-width: 160px;
    }
    .widget-setcount {
        padding: 15px;
        margin-bottom: 5px;
    }
    .widget-setcount ul {
        display: block;
    }
    .widget-setcount ul li {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .widget-setcount ul li:last-child {
        margin-bottom: 0;
    }
    .widget-setcount ul li p {
        border: 0 !important;
        padding-top: 0;
    }
    .add-course-inner-header {
        padding: 15px;
        padding-bottom: 0;
    }
    .add-course-inner-header h4 {
        font-size: 16px;
    }
    .add-course-form {
        padding: 15px;
    }
    .widget-btn {
        padding: 15px;
    }
    .widget-btn .btn {
        padding: 8px 15px;
        min-width: 120px;
    }
    .relative-file-upload {
        min-width: 120px;
    }
    .add-image-box {
        min-height: 150px;
    }
    .add-image-box a {
        font-size: 40px;
    }
    .add-course-section {
        padding: 15px;
        padding-bottom: 0;
    }
    .curriculum-grid {
        padding: 15px;
        margin-bottom: 20px;
    }
    .curriculum-head p {
        font-size: 14px;
    }
    .faq-grid {
        padding: 15px;
    }
    .faq-right {
        display: none;
    }
    .faq-body {
        padding-top: 10px;
        padding-left: 0;
    }
    .add-article-btns .btn {
        min-width: 100%;
        padding: 8px 10px;
        margin-top: 10px;
    }
    .add-course-msg {
        padding: 15px;
    }
    .add-course-msg i {
        font-size: 30px;
    }
    .add-course-msg h4 {
        font-size: 16px;
        margin-bottom: 6px;
    }
    .add-course-msg p {
        font-size: 14px;
    }
    .instructor-card {
        margin-bottom: 20px;
    }
    .instructor-inner h6 {
        margin-bottom: 10px;
    }
    .instructor-inner h4 {
        font-size: 24px;
        margin-bottom: 10px;
    }
	.submit-ticket .btn-primary,
	.submit-ticket .btn-dark {
		padding: 10px 12px;
		min-width: 161px;
		margin-bottom: 10px;
	}
	.error-box .big-text {
		font-size: 18px;
		    margin-bottom: 6px;
	}
	.countdown-el {
		min-width: 80px;
	}
	.countdown-el span {
		font-size: 14px;
	}
	.choose-search-blk .form-group {
		width: 100%;
	}
	.invoice-item .invoice-details {
		text-align: left;
		font-weight: 500;
		font-size: 14px;
	}
	.invoice-info.invoice-info2 {
		text-align: left;
	}
	.invoice-table tr td, .invoice-table-two tr td {
		font-weight: 500;
		font-size: 14px;
	}
	.post-left ul {
		display: block;
	}
	.post-left ul li {
		border: 0;
		padding: 0 0px;
	}
	.blog-widget .card-header {
		margin-bottom: 20px;
	}
	.student-list {
		padding: 15px;
	}
    .home-five p {
        font-size: 14px;
    }
	.home-two .home-slide-text h1 {
		font-weight: 700;
		font-size: 25px;
		color: #324FA2;
		margin-bottom: 20px;
		max-width: 100%;
	}
	.home-two .shapes-one {
		position: absolute;
		right: -4rem;
		top: 0rem;
		width: 200px;
	}
	.home-two .shapes-four {
		display: none;
	}
	.home-two .banner-content .sub-btn {
        width: 100%;
        height: 40px;
        font-size: 14px;
        margin-top: 8px;
	}
    .banner-content-five .input-group>.form-control, .input-group>.form-select {
        position: relative;
        width: auto;
        padding: 0;
        margin-left: 0px !important;
        border: 1px solid transparent !important;
    }
	.home-two .banner-content 
	.select2-container 
	.select2-selection--single {
		border-right: none;
	}
	.home-two .shapes-two {
		display: none;
	}
    .count-content-three h4 {
        font-size: 25px;
    }
    .count-content-three p {
        font-size: 18px;
    }
    .header-two-title .tagline {
        font-size: 18px;
    }
    .course-count-two h4 {
        font-size: 20px;
    }
    .home-two .course-content-column h3 a {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .header-two-title {
        margin-bottom: 20px;
    }
    .growup-skills-img {
        text-align: center;
        margin-bottom: 20px;
    }
    .discover-btn { 
        padding: 8px 35px;
    }
	.about-section .count-two {
		margin-top: 0;
	}
	.stylist-gallery .about-image {
		margin-bottom: 25px;
	}
	.about-section .count-four {
		margin-top: 0px;
	}
	.topcategory-sec {
		padding: 200px 0px 200px;
	}
	.featured-courses-sec {
		padding: 0 0 100px;
	}
	.margin-bottom {
		margin-bottom: 0;
	}
	.featured-courses-sec .featured-details {
		margin-bottom: 1rem;
	}
	.master-skills-info .winning-img img {
		width: 100%;
	}
	.master-skills-sec .pattern-icon-one {
		display: none;
	}
	.master-skills-sec .pattern-layer-three {
		display: none;
	}
	.master-skills-sec .pattern-icon-two {
		display: none;
	}
	.master-skills-sec .join-mentor {
		margin-top: 0;
		padding-bottom: 0;
	}
	.master-skills-sec .pattern-icon-three {
		display: none;
	}
	.feature-instructors-sec .instructors-img img {
		width: 100%;
	}
    .joing-icon-award .joing-icon-one {
        bottom: 5rem;
        left: 28rem;
    }
    .price-text {
        min-width: 100px;
        padding: 5px 10px;
    }
    .price-text h4 {
        font-size: 14px;
    }
    .course-count-two h5 {
        font-size: 16px;
    }
    .footer-two .footer-bottom {
        margin-bottom: 0;
    }
    .footer-two .footer-bottom .copyright {
        padding: 30px 0 0 !important;
    }
    .footer-two .footer-top {
        padding: 30px 0 30px !important;
    }
    .privacy-policy ul li {
        border-right: 0;
        margin-bottom: 10px;
    }
    .event-content-title h5 a {
        font-size: 16px;
    }
    .privacy-policy ul {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .home-two .master-skills-sec h2 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 15px;
    }
    .home-two .join-title-one {
        max-width: 100%;
    }
    .home-two .join-title-middle {
        margin-bottom: 25px;
        max-width: 100%;
    }
    .home-two .header-two-text {
        max-width: 700px;
        font-size: 16px;
        margin: 0;
    }
    .knowledge-sec .joing-group {
        padding: 10px;
    }
    .knowledge-sec .featured-img-1 {
        margin-bottom: 25px;
    }
	.real-reviews-grid {
		margin-top: 40px;
	}
	.real-reviews-sec {
		background: #FBFCFF;
		padding: 50px 0 0;
	}
	.latest-blog-sec {
		background: #ffffff;
		padding: 30px 0 30px;
	}
	.home-two-slide .drop-detail {
		width: 100%;
		display: flex;
		margin: 0;
		border-radius: 50px !important;
		border: 0;
		height: 44px;
	}
	.home-two .trust-user p {
		margin-top: 120px;
		font-weight: 500;
		font-size: 16px;
		color: #324FA2;
	}
	.home-two-slide .banner-content .input-group .form-control {
		margin-top: 25px;
		margin-bottom: 5px;
		border: 1px solid #7A9EDD;
		border-radius: 10px !important;
	}
	.home-two-slide .banner-content .input-group {
		border-radius: 130px;
		background: transparent;
		padding-left: 12px;
	}
	.home-two .trust-user p {
		margin-top: 20px;
		font-weight: 500;
		font-size: 16px;
		color: #324FA2;
	}
	.home-two-slide .banner-content .form-inner {
		height: auto;
	}
	.home-two-slide .banner-content .select2-container {
		border-radius: 50px;
		background-color: transparent;
		z-index: unset;
	}
	.home-two-slide .more-details {
		margin-top: 40px;
		display: block;
	}
	.growup-section .growup-right-img {
		position: absolute;
		bottom: 0;
		top: -5rem;
		right: 1rem;
	}
	.master-skills-sec {
		padding: 0 0 50px;
	}
	.master-skills-sec .join-mentor h2 {
		text-align: left;
	}
	.master-skills-sec .join-mentor p {
		text-align: left;
	}
	.master-skills-sec .join-mentor {
		padding: 30px;
	}
	.master-skills-info {
		margin-bottom: 20px;
	}
	.event-blog-main {
		margin-bottom: 40px;
	}
	.stylist-gallery {
		margin-bottom: 30px;
	}
	.about-section .count-two {
		margin-top: 0;
	}
	.home-two-slide .home-slide-face {
		padding: 60px 0 60px;
	}
	.home-two .course-column-img {
		-ms-flex: 30%;
		flex: 30%;
		max-width: 30%;
	}
	.home-two .course-content-column {
		-ms-flex: 70%;
		flex: 70%;
		max-width: 70%;
	}
	.home-two .about-section {
		padding: 50px 0 50px;
	}
	.home-two .topcategory-sec {
		padding: 50px 0px 50px;
		background: #FBFCFF;
	}
	.featured-courses-sec {
		padding: 50px 0 50px;
	}
	.growup-section {
		padding: 50px 0 50px;
	}
	.growup-skills-img img {
		max-width: 100%;
	}
	.home-two-shapes img {
		display: none;
	}
	.winning-two-one img {
		margin-bottom: 30px;
	}
	.winning-two-two img {
		margin-bottom: 30px;
	}
	.winning-two-three img {
		margin-bottom: 30px;
	}
	.home-two .testimonial-two-img {
		padding: 0px;
	}
	.course-count-two {
		margin-bottom: 20px;
	}
	.home-two .testimonial-sec {
		padding: 50px 0 50px;
	}
	.home-two .trending-course-sec {
		padding: 50px 0 50px;
	}
	.feature-instructors-sec {
		padding: 50px 0 50px;
	}
	.footer-two .footer-bottom .copyright-text p {
		color: #292929;
		font-weight: 400;
		text-align: center;
		font-size: 14px;
		margin-bottom: 20px !important;
	}
	.footer-two .privacy-policy {
		text-align: center;
	}
	.featured-details-two {
		width: 100%;
	}
    .footer-three-about p {
        font-size: 14px;
        margin-bottom: 30px;
    }
	.home-three .home-three-slide-text h1 {
		font-size: 25px;
        color: #1A1E21;
        margin-bottom: 15px;
	}
	.home-three-slide .girl-slide-img img {
		display: none;
	}
	.home-three .shapes-one {
		position: absolute;
		right: -4rem;
		top: 0rem;
		width: 200px;
	}
	.home-three .shapes-four {
		display: none;
	}
	.home-three .banner-content .sub-btn {
		width: 100%;
		height: 42px;
		font-size: 14px;
		margin-top: 10px;
	}
	.home-three .banner-content 
	.select2-container 
	.select2-selection--single {
		border-right: none;
	}
	.banner-three-content .input-group .search-icon {
		display: none;
	}
	.home-three .shapes-three {
		display: none;
	}
	.home-three-slide .banner-three-content .sub-btn {
		width: 100%;
		height: 42px;
		font-size: 14px;
		margin-top: 0px;
	}
	.banner-three-content .drop-detail {
		margin-bottom: 20px;
	}
	.home-three-slide .banner-three-content .form-inner {
		height: auto;
	}
	.home-three-slide .banner-three-content .input-group .form-control {
		margin-bottom: 10px;
		border: 1px solid #cccccc;
		border-radius: 10px !important;
		padding: 10px;
		width: 100%;
	}
	.home-three .drop-detail {
		width: 100%;
	}
	.course-details-three {
		margin-bottom: 15px;
	}
	.master-three-left img {
        max-width: 100%;
        text-align: center;
        margin: auto;
        display: block;
    }
	.master-skill-three {
		padding: 0px 0 20px;
		position: relative;
	}
	.skils-icon-item {
		margin-bottom: 20px;
	}
	.master-three-left {
		margin-bottom: 40px;
	}
	.section-header-title h1 {
		font-size: 20px;
		margin-bottom: 10px;
	}
    .home-three .home-three-head {
        margin-bottom: 20px;
    }
    .become-content-three h2 {
        font-size: 20px;
    }
    .become-content-three p {
        font-size: 16px;
    }
    .btn-become {
        padding: 8px 15px;
        border-radius: 5px;
    }
    .trending-three-text h3 {
        font-size: 18px;
    }
    .lead-companies-three .section-header-title {
        padding-bottom: 20px;
    }
	.home-three .see-all {
		float: right;
	}
	.home-three-transform .cta-content p {
		font-size: 16px;
		margin-bottom: 15px;
	}
    .home-three-head h1 {
        font-size: 18px;
        padding-bottom: 15px;
    }
    .home-three .see-all a {
        font-size: 18px;
    }
    .tablist-three .nav-tab {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .tablist-three .nav-tab.active {
        font-size: 16px;
         margin-bottom: 15px;
    }
    .tablist-three {
        margin-bottom: 40px;
        display: grid;
    }
	.home-three-transform {
		padding: 40px 0 40px;
	}
	.home-three-goals {
		padding: 40px 0 20px;
	}
	.home-three-courses {
    	padding: 40px 0 20px;
	}
    .acheive-goals-main h2 {
        font-size: 20px;
    }
    .acheive-goals span {
        font-size: 20px;
    }
    .acheive-goals-content h4 {
        margin-bottom: 10px;
    }
    .course-price-three h3 {
        font-size: 16px;
    }
    .content-three-image {
        width: 100%;
        margin-bottom: 24px;
    }
    .gallery-three .gallery-three-img-item {
        margin-bottom: 0;
    }
	.acheive-goals h2 {
		font-size: 25px;
		margin-bottom: 20px;
        text-align: center;
	}
	.acheive-goals {
		position: relative;
		margin-bottom: 20px;
        max-width: 100%;
	}
    .award-three-content-two {
        max-width: 100%;
        margin: auto;
    }
    .award-three-images-two {
        max-width: 100%;
        margin: auto;
    }
    .award-three-images-one {
        max-width: 100%;
        margin: auto;
    }
    .award-three-images-three {
        max-width: 100%;
        margin: auto;
    }
	.accelerate-cloud-three {
		padding: 40px 0 20px;
	}
	.award-one .award-three-images img {
		max-width: 100%;
		margin-bottom: 20px;
	}
	.award-three-content {
        margin-bottom: 40px;
        float: left;
    }
    .award-one {
        padding: 26px 0 26px;
    }
	.award-list-content h2 {
		font-size: 22px;
	}
    .award-three-content p {
        font-size: 14px;
    }
    .shapes-three-left .accelerate-three {
        display: none;
    }
    .home-three-transform .cta-content h2 {
        font-size: 20px;
    }
	.learn-anything .award-three-images img {
		max-width: 100%;
	}
    .swiper-container-3d {
        margin-top: 35px;
    }
    .testimonial-bottom-nav {
        margin-top: 20px;
    }
    .home-three .testimonial-item-five {
        width: 350px;
    }
	.development-carrer .award-three-images img {
		max-width: 100%;
		margin-bottom: 20px;
	}
    .shapes-three-left .accelerate-five {
        display: none;
    }
	.home-three-trending {
		padding: 40px 0 40px;
		background: #FBFBFF;
	}
	.home-three-trending .section-header-title {
		padding-bottom: 15px;
	}
	.home-three .owl-theme .owl-dots, .owl-theme .owl-nav {
		margin-top: 20px;
	}
	.gallery-three {
		padding: 20px 0 20px;
	}
	.home-three-become {
		padding: 40px 0 40px;
	}
	.transform-button-three {
		text-align: left;
	}
	.become-button-three {
		text-align: center;
	}
    .acheive-elips-one {
        right: 15.5rem;
    }
    .acheive-elips-two {
        right: 17rem;
    }
    .acheive-elips-three {
        right: 18.3rem;
    }
	.event-three-title h5 {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 15px;
	}
	.event-three-title p {
		line-height: 20px;
		font-size: 16px;
	}
	.blog-student-count {
		font-size: 14px;
	}
	.span-name-three {
		display: none;
	}
	.latest-blog-three {
		background: #ffffff;
		padding: 40px 0 20px;
	}
	.testimonial-three {
		padding: 40px 0 40px;
	}
	.home-three .become-content {
		width: auto;
		margin-bottom: 30px;
	}
	.btn-action {
	    padding: 8px 15px;
	}
	.box-form-newsletter {
		margin-bottom: 30px;
	}
	.lead-companies-three {
		padding: 40px 0 40px;
	}
	.become-content-three {
		padding-bottom: 20px;
	}
	.box-form-newsletter form .input-newsletter {
		width: 80%;
		font-size: 12px;
		border: 0;
		background-position: 5px;
		padding: 10px 10px 10px 50px;
	}
    .footer-three-top-content {
        padding-bottom: 15px;
    }
	.footer-menu-three {
		margin-bottom: 24px;
	}
    .footer-three-right {
        margin-left: 0;
    }
	.social-icon-three {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: 0;
    }
	.privacy-policy-three {
		margin-bottom: 30px;
	}
	.footer-three-bottom .copyright-three {
		padding-top: 30px;
	}
	.footer-three {
		padding: 40px 0 40px;
	}
	.social-icon-three h6 {
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		margin-bottom: 20px;
	}
	.footer-three-top {
		margin-bottom: 0;
	}		
	.home-three .banner-three-content .input-group {
		position: relative;
		max-width: 100%;
	}
	.banner-three-content .form-inner-three {
		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgb(216 216 216 / 25%);
		border-radius: 10px;
		padding: 10px;
		width: 100%;
		border: 0;
		max-width: 100%;
		height: auto;
	}
	.home-three .banner-three-content .input-group {
		display: block;
		flex-wrap: wrap;
		border: 0;
		background-image: none;
		background-repeat: no-repeat;
		background-position: left 0 center;
		padding-left: 0;
		align-items: stretch;
		width: 100%;
	}
	.home-three .drop-detail-three {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		border-radius: 50px !important;
		border: 0;
		height: auto;
	}
	.banner-three-content .select2-container .select2-selection--single {
		border: 0;
		align-items: center;
		display: flex;
		background: #f7f7f7f7;
		height: 45px;
	}
	.privacy-policy-three ul li {
		padding: 15px 0px;
		border-right: none;
		list-style: none;
		height: 10px;
		align-items: center;
		display: block;
	}
	.privacy-policy-three ul {
		display: block;
		list-style: none;
		margin: 0;
	}
	.banner-section-four {
        padding: 15px 0 25px 0;
    }
    .banner-heading-four h2 {
        font-size: 20px;
        margin: 0;
    }
    .banner-heading-four h3 {
        font-size: 18px;
    }
    .banner-img {
        display: none;
    }
    .search-box-four {
        padding: 10px;
        display: block;
    }
    .search-box-four input {
        width: 100%;
    }
    .search-box-four .select2-container {
        width: 100% !important;
        padding: 10px;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .search-box-four input {
        font-size: 12px;
        margin-bottom: 20px;
    }
    .search-box-four {
        border-radius: 10px;
    }
    .search-box-four .select2-container--default 
    .select2-selection--single .select2-selection__arrow {
        height: 26px;
        position: absolute;
        top: 10px;
        right: 5px;
        width: 20px;
    }
    .search-box-four .select2-container--default 
    .select2-selection--single .select2-selection__rendered {
        color: #392C7D;
        text-align: left;
    }
    .search-icon-four {
        width: 50px;
        height: 50px;
    }
    .course-categories {
        padding: 25px 0;
    }
    .section-heading h5 {
        font-size: 15px;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    .course-categories .section-heading {
        padding: 5px 0 25px;
    }
    .our-courses .section-heading {
        padding-bottom: 25px;
    }
    .course-name h4 {
        font-size: 18px;
    }
    .course-name span {
        font-size: 18px;
    }
    .multiple-courses-text span {
        font-size: 20px;
    }
    .circle {
        width: 80px;
        height: 80px;
    }
    .php-course a {
        font-size: 16px;
    }
    .skills-info {
        display: block;
        flex-direction: unset;
        align-items: flex-start;
    }
    .our-courses {
        padding: 25px 0;
        text-align: -moz-center;
        text-align: -webkit-center;
    }
    .card-button .buy-now-btn a {
        padding: 8px 20px;
    }
    .card-button .buy-now-btn a {
        font-size: 14px;
    }
    .our-courses .card .card-body {
        text-align: left;
    }
    .career-skills {
        padding: 25px 0;
    }
    .skills-info .section-heading h2 {
        padding-bottom: 15px;
        text-align: left;
    }
    .skills-info .section-heading p {
        padding-bottom: 15px ;
        text-align: left;
    }
    .skills-info ul {
        padding-bottom: 15px;
    }
    .explore-button .explore-btn a {
        padding: 10px 30px;
    }
    .multiple-informations {
        padding-bottom: 15px;
    }
    .skill-img, .skills-img {
        display: none;
    }
    .feature-instructor {
        padding: 25px 0;
        text-align: -moz-center;
        text-align: -webkit-center;
    }
    .feature-instructor .section-heading {
        padding-bottom: 25px;
    }
    .feature-instructor-two {
        padding: 30px 0;
    }
    .feature-instructor-two-slider .owl-dots {
        padding-top: 30px;
    }
    .feature-instructor-slider .owl-nav .owl-next {
        right: -60px;
    }
    .feature-instructor-two-slider .owl-dots {
        padding-top: 30px;
    }
    .feature-instructor-img {
        margin-bottom: 30px;
    }
    .best-course-slider .owl-nav .owl-prev i {
        position: absolute;
        top: 210px;
        left: -35px;
    }
    .best-course-slider .owl-nav .owl-next i {
        position: absolute;
        right: -35px;
        top: 210px;
    }
    .instructor-info a {
        font-size: 18px;
    }
    .instructor-info h4 {
        padding-bottom: 10px;
    }
    .instructor-info h5 {
        margin: 0;
    }
    .different-companies {
        padding: 25px 0;
    }
    .different-companies .section-heading {
        padding-bottom: 25px;
    }
    .testimonial {
        background-image: none;
        padding: 25px 0;
    }
    .testimonial-img img {
        padding: 25px 0;
    }
    .testimonial-name h2 {
        font-size: 25px;
    }
    .testimonial-name p {
        padding: 25px 0;
    }
    .latest-blog {
        padding: 25px 0;
        text-align: -moz-center;
        text-align: -webkit-center;
    }
    .latest-blog-slider .card .card-body {
        text-align: left;
    }
    .blog-info .course-name {
        padding-bottom: 15px;
    }
    .blog-info .course-date {
        padding: 0;
    }
    .unlimited-courses-labs {
        padding: 25px 0;
    }
    .section-small-imgs {
        display: none;
    }
    .best-course-section .small-icons {
        display: none;
    }
    .unlimited-courses-labs .section-heading {
        padding-bottom: 25px;
    }
    .education-info h2 {
        font-size: 26px;
    }
    .education-details {
        padding: 25px 0 0 0;
    }
    .education-details-box {
        padding-bottom: 25px;
    }
    .news-letter {
        padding: 25px 0;
    }
    .request-box .send-btn a {
        padding: 10px 37px;
    }
    .footer {
        padding: 25px 0;
    }
    .footer-logo p {
        padding: 25px 0 0 0;
    }
    .footer-main-info {
        padding-bottom: 25px;
    }
    .favourite-course .owl-nav {
        position: static;
        display: block;
    }
    .favourite-course .owl-nav .owl-prev {
        display: none;
    }
    .favourite-course .owl-nav .owl-next {
        display: none;
    }
    .education-details-box {
        padding-bottom: 25px;
    }
    .education-info h2 {
        font-size: 18px;
    }
    .education-info p {
        font-size: 14px;
    }
    .card-button {
        padding: 0 0 15px 0;
    }
    .news-letter-four .section-heading h2 {
        font-size: 20px;
    }
    .our-courses-slider .owl-nav {
        position: static;
    }
    .our-courses-slider .owl-nav .owl-prev {
        position: absolute;
        top:200px;
        left: -60px;
    }
    .our-courses-slider .owl-nav .owl-next{
        position: absolute;
        top:200px;
        right: -60px;
    }
    .feature-instructor-slider .owl-nav {
        position: static;
    }
    .feature-instructor-slider .owl-nav .owl-prev {
        display: none;
    }
    .feature-instructor-slider .owl-nav .owl-next {
        display: none;
    }
    .latest-blog-slider .owl-nav {
        position: static;
    }
    .latest-blog-slider .owl-nav .owl-prev {
        position: absolute;
        top: 220px;
        left: 0;
    }
    .latest-blog-slider .owl-nav .owl-next {
        position: absolute;
        top: 220px;
        right: 0;
    }
    .banner-section-fours-two {
        padding: 20px 0 30px 0;
    } 
    .section-heading h2 {
        font-size: 21px;
    } 
    .banner-section-fours-two .section-heading p {
        font-size: 16px;
    } 
    .banner-small-circles {
        display: none;
    }
    .banner-section-fours-two .section-heading .search-box-four input {
	    width: 300px !important;
	    min-width: 0;
    }
    .multiple-websites {
        flex-wrap: wrap;
    }
    .top-category {
        padding: 30px 0;
    }
    .top-category .section-headings {
        padding: 20px 0;
        text-align: center;
    }
    .trained-by-experts {
        padding: 30px 0;
    }
    .section-headings {
        text-align: center;
    }
    .section-headings h2 {
        font-size: 20px;
    }
    .section-headings p {
        font-size: 15px;
    }
    .best-course-section .best-course-heading {
        flex-direction: column;
        gap: 15px !important;
    }
    .master-skill-imgs {
        display: none;
    }
    .master-skills-section .small-circles {
        display: none;
    }
    .master-skills-section {
        padding: 30px 0;
    }
    .skills-main-info .section-headings p {
        padding-top: 5px;
        font-size: 15px;
    }
    .accelerate-cloud-section {
        padding: 30px 0;
    }
    .accelerate-cloud-section .section-headings p {
        font-size: 16px;
    }
    .cloud-technologies {
        justify-content: center;
    }
    .student-section .section-headings p {
        font-size: 16px;
        padding-bottom: 25px;
    }
    .student-section .section-headings h2 {
        font-size: 22px;
    }
    .student-section {
        padding-top: 30px;
    }
    .multiple-students-informations {
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }
    .student-bottom-section {
        background: linear-gradient(to top, #F58E06 0%, #F58E06 50%, #4C51CE 50%, #4C51CE 100%);
        padding: 30px 0;
    }
    .student-register-field {
        flex-direction: column;
        gap: 15px;
    }
    .impressive-section {
        padding: 30px 0;
    }
    .latest-news-events {
        padding: 30px 0;
    }
    .news-card-img .rounded-img {
        top: 372px;
    }
    .best-course-slider .owl-nav .owl-prev i, .best-course-slider .owl-nav .owl-next i {
        width: 50px;
        height: 50px;
    }
    .unlimited-courses-labs-two .section-heading h2 {
        font-size: 21px;
    }
    .unlimited-courses-labs-two {
        padding: 30px 0;
    }
    .unlimited-courses-labs-two .lab-items-one {
        padding-bottom: 20px;
        flex-wrap: wrap;
    }
    .unlimited-courses-labs-two .multiple-labs {
        padding-bottom: 25px;
    }
    .trusted-customer {
        padding-top: 20px;
    }
    .footer-two .footer-top-two h5 {
        font-size: 17px;
        padding: 15px 0;
    }
    .footer-two .footer-top-two h2 {
        font-size: 20px;
    }
    .goto-website {
        padding-bottom: 20px;
    }
    .footer-two-info h3 {
        padding-bottom: 15px;
    }
    .footer-top-two {
        padding-bottom: 20px;
    }
    .footer-bottom-two {
        padding-top: 20px;
    }
    .footer-bottom-two p {
        font-size: 15px;
    }
	.banner-content-five .form-inner-five {
		padding: 5px;
	}
	.home-slide-five-text h1 {
		font-size: 25px;
		margin-bottom: 25px;
	}
	.header-navbar-five {
		display: none;
	}
	.slider-five-one {
		position: absolute;
		top: 3.5rem;
	}
	.slider-five-one img {
		width: 100%;
		max-width: 400px;
	}
	.slider-five-two img {
		width: 100%;
		max-width: 400px;
		float: right;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 70px;
	}
    .vector-shapes-five {
        display: none;
    }
    .count-five {
        border-right: 0;
    }
	.slider-five-one {
        display: none;
	}
	.slider-five-two {
        display: none;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 50px;
	}
	.header-navbar-five > li .signup-five {
		font-size: 14px;
		padding: 8px 30px;
	}
	.header-navbar-five > li .login-five {
		font-size: 14px;
	}
    .banner-content-five .form-inner-five .input-group {
        position: relative;
        display: flex;
        flex-wrap: revert;
        align-items: stretch;
        width: 100%;
    }
    .home-slide-five-text h1 {
        font-weight: 700;
        font-size: 25px;
        color: #FFFFFF;
        margin-bottom: 25px;
    }
    .banner-content-five .form-inner-five {
        padding: 5px;
    }
    .leading-five-content {
        text-align: center;
        margin-bottom: 20px;
    }
    .leading-section-five {
        padding: 40px 0 40px;
    }
    .learn-more-five {
    	padding: 9px 20px;
     	font-size: 14px;
    }
    .share-knowledge-five {
        padding: 40px 0 40px;
    }
    .leading-five-content h2 {
        font-size: 25px;
    }
    .course-icon-five img {
        padding: 15px;
    }
    .course-icon-five {
        margin-bottom: 29px;
    }
    .course-section-five {
        padding: 40px 0 40px;
    }
    .course-info-five h3 {
        font-size: 18px;
    }
    .course-info-five p {
        font-size: 14px;
    }
    .counter-section-five {
        padding: 30px 0 20px;
    }
    .featured-section-five {
        padding: 40px 0 20px;
    }
    .tablist-five .nav-tab {
        font-size: 14px;
    }
    .tablist-five .nav-tab.active {
        color: #2D0160;
        border-bottom: 3px solid #2D0160;
        padding-bottom: 15px;
    }
    .tablist-five {
        padding-bottom: 14px;
    }
    .product-img-five img {
        width: 100%;
    }
    .product-content-five h3 a {
        font-size: 16px;
    }
    .joing-course-btn {
        padding: 8px 20px;
        font-size: 14px;
    }
    .product-content-five .price-five-group h3 {
        font-size: 16px;
    }
    .count-content-five h4 {
        font-size: 25px;
    }
    .count-content-five p {
        font-size: 14px;
    }
    .count-five-last {
        margin-bottom: 0;
    }
    .skill-five-content h3 {
        font-size: 18px;
    }
    .section-five-sub {
        margin-bottom: 40px;
    }
    .header-five-title h2 {
        font-size: 25px;
    }
    .header-five-title {
        margin-bottom: 20px;
    }
    .experienced-course-five {
        padding: 40px 0 40px;
    }
    .joing-count-five-one {
        position: absolute;
        left: 6.5rem;
        top: 5.5rem;
    }
    .joing-count-five-two {
        position: absolute;
        left: 2rem;
        bottom: 3rem;
    }
    .joing-count-five-three {
        position: absolute;
        right: 9.5rem;
        top: 2rem;
        z-index: 2;
    }
    .goals-section-five {
        padding: 40px 0 40px;
    }
    .goals-content-five h4 {
        font-size: 25px;
    }
    .goals-section-five .header-five-title h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    .transform-count-five-one {
        position: absolute;
        top: 0;
        left: 0;
    }
    .goals-five-one .goals-content-five {
        border-left: 0;
    }
    .goals-five-two .goals-content-five {
        border-left: 0;
    }
    .goals-five-three .goals-content-five {
        border-left: 0;
    }
    .goals-five-four .goals-content-five {
        border-left: 0;
    }
    .transform-section-five {
        padding: 50px 0 50px;
    }
    .transform-count-five-one {
        position: absolute;
        top: 0;
        left: 0;
    }
    .joing-count-number {
        font-size: 25px;
    }
    .joing-count-text {
        font-size: 16px;
    }
    .transform-count-five-three {
        position: absolute;
        bottom: 3.5rem;
        left: 18rem;
    }
    .experienced-five-sub {
        max-width: 100%;
        margin: auto;
    }
    .joing-count-five img {
        margin-bottom: 20px;
        max-width: 100%;
    }
    .testimonial-content-five {
        position: relative;
        background: #ffffff;
        border-radius: 10px;
        left: 0px;
        padding: 20px;
        max-width: 757px;
        height: auto;
        width: 100%;
        z-index: 2;
    }
    .testimonial-image img {
        display: none;
    }
    .testimonial-section-five .slick-prev {
        left: 0;
        top: -55px;
    }
    .testimonial-section-five .slick-next {
        right: 0;
        top: -55px;
    }
    .testimonial-text p {
        font-size: 14px;
    }
    .testimonial-section-five {
        padding: 40px 0 40px;
    }
    .testimonial-users h5 {
        font-size: 18px;
    }
    .leading-five-content p {
        font-size: 14px;
    }
    .blogs-section-five {
        padding: 40px 0 20px;
    }
    .footer-bottom-five {
    	margin-bottom: 0;
    }
    .footer .footer-menu-five ul li a {
    	margin-left: 30px;
    }
    .footer-menu-five ul li a:before {
    	left: -30px;
    }
    .social-icon-five ul li {
	    margin-right: 15px;
	}
	.social-icon-five a {
	    width: 38px;
	    height: 38px;
	    font-size: 14px;
	}
    .blog-five-footer h3 a {
        font-size: 18px;
    }
    .blog-box-content {
        padding: 20px;
    }
    .footer.footer-five {
        padding: 50px 0 50px;
    }
    .copyright-five {
        padding: 20px 0 0;
    }
    .footer-title-five {
        font-size: 18px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .footer-top-five {
        margin-bottom: 30px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .footer-news-five .btn {
        width: 115px;
        height: 50px;
    }
    .footer-news-five .form-control {
        min-height: 50px;
    }
    .footer-menu-five {
        margin-bottom: 30px;
    }
    .footer-title-five {
        font-size: 18px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .footer-news-five .btn {
        width: 115px;
        height: 40px;
    }
    .footer-news-five .form-control {
        min-height: 40px;
    }
    .footer-logo-five {
        text-align: center;
        margin-bottom: 15px;
    }
    .footer-bottom-five .copyright-text {
        text-align: center;
        margin-bottom: 15px;
    }
    .social-icon-three {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: 0;
    }
    .footer-menu ul li {
        margin-bottom: 15px;
        position: relative;
    }
    .footer.footer-five {
        padding: 30px 0 10px;
    }
    .footer-news-five .form-group ::placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group :-ms-input-placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group::-ms-input-placeholder { 
        font-size: 12px;
    }
    .footer-five-left {
        display: none;
    }
    .footer-five-right {
        display: none;
    }
    .master-five-vector {
        display: none;
    }
    .footer-six-info {
        display: block;
        justify-content: flex-start;
    }
    .banner-sections-six .banner-imgs .banner-img-three {
        position: absolute;
        bottom: 12rem;
        right: 0;
        max-width: 100%;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        position: absolute;
        bottom: 50px;
        left: 0;
    }
    .banner-sections-six .banner-imgs .react-icon {
        position: absolute;
        top: 200px;
        left: 0;
    }
    .banner-sections-six .banner-imgs .banner-img-two {
        position: absolute;
        top: 250px;
        right: 0;
        max-width: 300px;
    }
    .registration-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 30px;
    }
    .footer-top-six .email-address-six {
        max-width: 550px;
        padding: 10px;
        width: 100%;
        height: 80px;
        gap: 10px;
    }
    .footer-top-six .subscribe-btn-six button {
        padding: 20px 50px;
        font-size: 14px;
    }
    .students-enrolled .student-icon {
        width: 65px;
        height: 65px;
    }
    .best-course-text h3 {
        font-size: 20px;
    }
    .online-courses-name span {
        font-size: 22px;
    }
    .best-course-instructor-info .course-cost {
        font-size: 20px;
    }
    .banner-content-six .sub-btn {
        padding: 8px 40px;
    }
    .home-four .our-courses-slider .owl-nav {
        display: none;
    }
    .more-information {
        display: block;
    }
    .growup-section .growup-right-img {
		position: absolute;
		bottom: 0;
		top: -5rem;
		right: 1rem;
	}
	.acheive-elips-one {
		display: none;
	}
	.acheive-elips-two {
		display: none;
	}
    .award-three-content {
        max-width: 515px;
        float: left;
    }
    .shapes-three-left .accelerate-five {
        display: none;
    }
    .shapes-three-left .accelerate-three {
        display: none;
    }
	.acheive-elips-three {
		display: none;
	}
	.event-three-title h5 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.event-three-title p {
		font-size: 14px;
	}
    .course-three-text h3 {
        font-size: 16px;
    }
    .course-price-three h3 {
        font-size: 18px;
    }
	.span-name-three {
		padding: 2px 20px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-flex;
		margin-bottom: 10px;
		color: #ffffff;
	}
	.slider-five-one {
		position: absolute;
		top: 3.5rem;
	}
	.slider-five-one img {
		width: 100%;
		max-width: 400px;
	}
	.slider-five-two img {
		width: 100%;
		max-width: 400px;
		float: right;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 70px;
	}
    .home-two .course-content-column h3 a {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .topcategory-sec {
        padding: 50px 0px 50px;
    }
    .latest-blog-content {
        padding: 15px 20px;
    }
    .joing-icon-award .joing-icon-two {
        bottom: 6rem;
        right: 15rem;
    }
    .joing-icon-award .joing-icon-three {
        bottom: 6rem;
        left: 29rem;
    }
    .course-count-two h5 {
        font-size: 15px;
    }
    .event-content-title h5 a {
        font-size: 16px;
    }
    .home-three-slide-text h1 {
        font-size: 30px;
    }
    .home-three-slide .girl-slide-img img {
        display: none;
    }
	.banner-content-five .form-inner-five {
		padding: 5px;
	}
	.slider-five-one {
		position: absolute;
		top: 3.5rem;
	}
	.slider-five-one img {
		width: 100%;
		max-width: 400px;
	}
	.slider-five-two img {
		width: 100%;
		max-width: 400px;
		float: right;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 70px;
	}
    .vector-shapes-five {
        display: none;
    }
	.slider-five-one {
        display: none;
	}
	.slider-five-two {
        display: none;
	}
	.home-slide-five-text {
		width: 100%;
		max-width: 100%;
	}
	.banner-content-five {
		max-width: 580px;
		width: 100%;
		margin-bottom: 50px;
	}
	.header-navbar-five > li .signup-five {
		font-size: 14px;
		padding: 8px 25px;
	}
	.header-navbar-five > li .login-five {
		font-size: 14px;
	}
    .banner-content-five .form-inner-five .input-group {
        position: relative;
        display: flex;
        flex-wrap: revert;
        align-items: stretch;
        width: 100%;
    }
    .home-slide-five-text h1 {
        font-weight: 700;
        font-size: 25px;
        color: #FFFFFF;
        margin-bottom: 25px;
    }
    .banner-content-five .form-inner-five {
        padding: 5px;
    }
    .count-content-five {
        border-right: none;
        margin-bottom: 20px;
    }
    .count-five-last {
        margin-bottom: 0;
    }
    .leading-section-five {
        padding: 40px 0 40px;
    }
    .learn-more-five {
        padding: 10px 15px;
    }
    .leading-five-content h2 {
        font-size: 25px;
    }
    .course-icon-five img {
        padding: 15px;
    }
    .course-icon-five {
        margin-bottom: 29px;
    }
    .course-info-five h3 {
        font-size: 18px;
    }
    .course-info-five p {
        font-size: 14px;
    }
    .social-icon-five {
    	text-align: center;
    	float: unset;
    }
    .tablist-five .nav-tab {
        font-size: 14px;
    }
    .tablist-five .nav-tab.active {
        color: #2D0160;
        border-bottom: 3px solid #2D0160;
        padding-bottom: 15px;
    }
    .goals-five-one {
        border-left: 0;
    }
    .master-five-vector {
        display: none;
    }
    .product-img-five img {
        width: 100%;
    }
    .product-content-five h3 a {
        font-size: 16px;
    }
    .joing-course-btn {
        padding: 8px 20px;
        font-size: 14px;
    }
    .product-content-five .price-five-group h3 {
        font-size: 16px;
    }
    .count-content-five h4 {
        font-size: 25px;
    }
    .count-content-five p {
        font-size: 14px;
    }
    .skill-five-content h3 {
        font-size: 18px;
    }
    .section-five-sub {
        margin-bottom: 40px;
    }
    .header-five-title h2 {
        font-size: 25px;
    }
    .header-five-title {
        margin-bottom: 31px;
    }
    .master-section-five {
        padding: 50px 0 50px;
    }
    .joing-count-five-one {
        position: absolute;
        left: 2.5rem;
        top: 0.5rem;
    }
    .joing-count-five-two {
        position: absolute;
        left: -7rem;
        bottom: 3rem;
    }
    .joing-count-five-three {
        position: absolute;
        right: 1.5rem;
        top: 1rem;
        z-index: 2;
    }
    .goals-content-five h4 {
        font-size: 25px;
    }
    .goals-section-five .header-five-title h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    .transform-count-five-one {
        position: absolute;
        top: 0;
        left: 0;
    }
    .transform-count-five-two {
        position: absolute;
        top: 1rem;
        left: 13rem;
    }
    .goals-five-one .goals-content-five {
        border-left: 0;
    }
    .goals-five-two .goals-content-five {
        border-left: 0;
    }
    .goals-five-three .goals-content-five {
        border-left: 0;
    }
    .goals-five-four .goals-content-five {
        border-left: 0;
    }
    .transform-section-five {
        padding: 50px 0 50px;
    }
    .transform-count-five-one {
        position: absolute;
        top: 0;
        left: 0;
    }
    .joing-count-number {
        font-size: 20px;
        margin-bottom: 0;
    }
    .joing-count-text {
        font-size: 16px;
    }
    .transform-count-five-three {
        position: absolute;
        bottom: -2.5rem;
        left: 14rem;
    }
    .experienced-five-sub {
        max-width: 100%;
        margin: auto;
    }
    .joing-count-five img {
        margin-bottom: 20px;
        max-width: 100%;
    }
    .developer-five-list .column-img {
        display: flex;
        -ms-flex: 33.33%;
        flex: 33.33%;
        max-width: 33.33%;
        margin-bottom: 20px;
    }
    .instructor-vector-left {
        display: none;
    }
    .instructor-vector-right {
        display: none;
    }
    .testimonial-content-five {
        position: relative;
        background: #ffffff;
        border-radius: 10px;
        left: 0px;
        padding: 20px;
        max-width: 757px;
        height: auto;
        width: 100%;
        z-index: 2;
    }
    .testimonial-image img {
        display: block;
        width: 100%;
    }
    .testimonial-section-five .slick-prev {
        left: -10px;
    }
    .testimonial-section-five .slick-next {
        right: -10px;
    }
    .testimonial-text p {
        font-size: 14px;
    }
    .testimonial-users h5 {
        font-size: 18px;
    }
    .leading-five-content p {
        font-size: 14px;
    }
    .blog-five-footer h3 a {
        font-size: 18px;
    }
    .blog-box-content {
        padding: 20px;
    }
    .copyright-five {
        padding: 20px 0 0;
    }
    .footer-title-five {
        font-size: 18px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .footer-news-five .btn {
        width: 115px;
        height: 50px;
    }
    .footer-news-five .form-control {
        min-height: 50px;
    }
    .footer-menu-five {
        margin-bottom: 30px;
    }
    .footer-title-five {
        font-size: 18px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .footer-news-five .btn {
        width: 115px;
        height: 40px;
    }
    .footer-news-five .form-control {
        min-height: 40px;
    }
    .footer-logo-five {
        text-align: center;
        margin-bottom: 15px;
    }
    .footer-bottom-five .copyright-text {
        text-align: center;
        margin-bottom: 15px;
    }
    .social-icon-three {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: 0;
    }
    .footer.footer-five {
        padding: 30px 0 0px;
    }
    .footer-news-five .form-group ::placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group :-ms-input-placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group::-ms-input-placeholder { 
        font-size: 12px;
    }
    .footer-five-left {
        display: none;
    }
    .footer-five-right {
        display: none;
    }
    .master-section-five {
	    padding: 40px 0 20px;
	}
    .skill-five-item {
    	margin-bottom: 20px;
    }
    .banner-sections-six .banner-imgs .banner-img-two {
        position: absolute;
        top: 250px;
        right: 0;
        width: 300px;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        position: absolute;
        bottom: 50px;
        left: 0;
    }
    .banner-sections-six .banner-imgs .react-icon {
        position: absolute;
        top: 200px;
        left: 0;
    }
    .students-enrolled .student-icon {
        width: 60px;
        height: 60px;
    }
    .more-information li {
        position: relative;
        margin-left: 14px;
    }

    .header-navbar-rht{
        display: none !important;
    }
}

@media (max-width: 575.98px) {
	.video-thumbnail img {
		min-height: 150px;
		object-fit: cover;
	}
	.video-details .btn-enroll {
		margin-top: 10px;
	}
	.page-breadcrumb ol li {
		display: none;
	}
	.page-breadcrumb ol li.active {
		display: block;
		padding-left: 0;
	}
	.category-tab ul li {
		width: 100%;
		margin-right: 0;
	}
	.growup-section .growup-right-img {
		margin-top: 20px;
		filter: drop-shadow(0px 4px 14px rgba(202, 202, 202, 0.25));
		border-radius: 10px;
		-webkit-border-radius: 5px;
		-khtml-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}
    .home-three-head h2 {
        font-size: 20px;
    }
	.knowledge-sec .joing-list .joing-icon {
		flex-shrink: 0;
	}
	.home-two-slide .home-slide-face {
		padding: 100px 0 50px;
	}
	.home-two .about-section {
		padding: 50px 0 50px;
	}
	.home-two .topcategory-sec {
		padding: 50px 0px 50px;
		background: #FBFCFF;
	}
    .home-two .shapes-two {
		display: none;
	}
    .header-two-title h2 {
        font-size: 20px;
    }
    .header-two-title .tagline {
        font-size: 16px;
    }
    .header-two-title {
        margin-bottom: 20px;
    }
    .discover-btn { 
        padding: 8px 35px;
    }
	.featured-courses-sec {
		padding: 50px 0 50px;
	}
	.growup-section {
		padding: 50px 0 50px;
	}
	.growup-skills-img img {
		width: 100%;
	}
	.home-two-shapes img {
		display: none;
	}
	.winning-two-one img {
		margin-bottom: 30px;
        max-width: 100%;
	}
	.winning-two-two img {
		margin-bottom: 30px;
        max-width: 100%;
	}
	.winning-two-three img {
		margin-bottom: 30px;
        max-width: 100%;
	}
    .joing-icon-award .joing-icon-one {
        display: none;
    }
    .joing-icon-award .joing-icon-two {
        display: none;
    }
    .joing-icon-award .joing-icon-three {
        display: none;
    }
    .rating-price-two p {
        font-size: 12px;
    }
    .home-three-courses .tab-content {
        text-align: center;
    }
    .knowledge-sec .featured-img-1 {
        height: 500px;
        background-size: cover;
        background-position: 0%;
    }
	.home-two .testimonial-two-img {
		padding: 10px;
	}
    .home-three .testimonial-item-five {
        width: 300px;
    }
    .home-three .testimonial-quote {
        padding: 10px;
    }
    .testimonial-quote .quote {
        width: 40px;
    }
    .home-three .testimonial-content p {
        font-size: 14px;
    }
    .home-three .testimonial-ratings {
        padding: 15px;
    }
    .home-three .testimonial-item-five .testimonial-users {
        padding: 15px;
    }
    .home-three .testimonial-content {
        padding: 15px;
    }
    .home-three .testimonial-item-five .testimonial-users h6 {
        font-size: 14px;
    }
    .home-three .testimonial-item-five .testimonial-users p {
        font-size: 12px;
    }
	.home-two .course-column-img {
		-ms-flex: 35%;
		flex: 35%;
		max-width: 35%;
	}
	.home-two .course-content-column {
		-ms-flex: 65%;
		flex: 65%;
		max-width: 65%;
	}
	.featured-details-two {
		width: 100%;
	}
    .course-price-two h3 {
        font-size: 12px;
    }
    .course-content-column {
        padding-left: 20px;
    }
    .featured-details-two .name-text h3 a {
        font-size: 16px;
        display: flex;
    }
	.home-three .banner-three-content .input-group {
		position: relative;
		max-width: 100%;
	}
	.banner-three-content .form-inner-three {
		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgb(216 216 216 / 25%);
		border-radius: 10px;
		padding: 10px;
		width: 100%;
		border: 0;
		max-width: 100%;
		height: auto;
	}
	.home-three .banner-three-content .input-group {
		display: block;
		flex-wrap: wrap;
		border: 0;
		background-image: none;
		background-repeat: no-repeat;
		background-position: left 0 center;
		padding-left: 0;
		align-items: stretch;
		width: 100%;
	}
	.box-form-newsletter form .input-newsletter {
		width: 55%;
		font-size: 12px;
		border: 0;
		background-image: none;
		background-repeat: no-repeat;
		background-position: 5px;
		padding: 0;
	}
    .home-three .btn-default {
        padding: 10px;
        font-size: 10px;
    }
	.event-three-title p {
		line-height: 14px;
        font-size: 12px;
        margin-bottom: 5px;
	}
	.span-name-three {
		display: none;
	}
	.blog-student-count {
		font-size: 10px;
	}
	.event-three-title h5 {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.acheive-elips-one {
		display: none;
	}
    .latest-blog-content {
        position: absolute;
        padding: 10px 10px;
    }
	.acheive-elips-two {
		display: none;
	}
	.acheive-elips-three {
		display: none;
	}
	.home-three .become-content h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.home-three .become-content h4 {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 0;
		color: #FFFFFF;
	}
	.news-letter-four .request-box {
        flex-direction: column;
        gap: 20px;
    }
    .news-letter-four .request-box .name-field input {
        width: 100%;
    }
    .latest-blog-slider .owl-nav .owl-prev {
        display: none;
    }
    .latest-blog-slider .owl-nav .owl-next {
        display: none;
    }
    .banner-img-four {
        display: none;
    }
    .banner-heading-four {
        padding: 60px 0 60px;
    }
    .more-information li {
        position: relative;
        padding: 10px;
        margin-left: 0;
        display: block;
    }
    .social-media-icon {
        width: 40px;
        height: 40px;
        background: rgba(57, 44, 125, 0.1);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
	.home-slide-five-face {
		padding: 100px 0 40px;
	}
	.home-slide-five-text h1 {
		font-size: 25px;
		margin-bottom: 20px;
	}
	.slider-five-one {
		position: absolute;
		bottom: -2rem;
	}
	.slider-five-one img {
		width: 100%;
		max-width: 300px;
	}
	.slider-five-two img {
		width: 100%;
		max-width: 300px;
		float: right;
	}    
	.banner-content-five .sub-btn {
        border-radius: 10px;
        padding: 10px 20px;
        width: 100%;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }
    .home-slide-five .banner-slider-img {
    	display: none;
    }
    .drop-detail-five {
        background: transparent;
        border-radius: 10px;
        display: flex;
        border: 0;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        max-width: 100%;
        width: 100%;
    }
    .banner-content-five .select2-container 
    .select2-selection--single {
        border: 0;
        align-items: center;
        display: flex;
        background: transparent;
        justify-content: space-between;
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
    }
    .banner-content-five .form-group ::placeholder { 
        font-size: 14px;
    }      
    .banner-content-five .form-group :-ms-input-placeholder { 
        font-size: 14px;
    }      
    .banner-content-five .form-group::-ms-input-placeholder { 
        font-size: 14px;
    }
    .banner-content-five .select2-container--default 
    .select2-selection--single .select2-selection__rendered {
        font-size: 14px;
    }
    .banner-content-five .form-inner-five {
        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #FFD600;
        box-shadow: 0px 16px 106px rgb(196 196 196 / 16%);
        border-radius: 10px;
        width: 100%;
        max-width: 585px;
    }
    .transform-count-five-three {
        position: absolute;
        bottom: -1rem;
        left: -9rem;
    }
    .joing-count-five {
        position: relative;
        margin-bottom: 40px;
    }
    .vector-shapes-five {
        display: none;
    }
    .banner-content-five .form-inner-five .input-group {
        position: relative;
        display: block;
        flex-wrap: revert;
        align-items: stretch;
        width: 100%;
    }
    .leading-five-content {
        margin-bottom: 30px;
    }
    .developer-five-list .column-img {
        display: flex;
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .goals-count-five {
        border-left: 0;
    }
    .footer-logo-five {
        text-align: center;
        margin-bottom: 15px;
        font-size: 16px;
    }
    .footer-bottom-five .copyright-text {
        text-align: center;
        margin-bottom: 15px;
    }
    .social-icon-three {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: 0;
    }
    .footer.footer-five {
        padding: 30px 0 30px;
    }
    .footer-news-five .form-group ::placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group :-ms-input-placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group::-ms-input-placeholder { 
        font-size: 12px;
    }
    .goals-five-one {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .goals-five-two {
        margin-bottom: 30px;
    }
    .goals-five-three {
        margin-bottom: 30px;
    }
    .goals-five-four {
        margin-bottom: 0px;
    }
    .testimonial-image {
        display: none;
    }
    .testimonial-content-five {
        position: relative;
        background: #ffffff;
        border-radius: 10px;
        left: 0px;
        padding: 20px;
        max-width: 757px;
        height: auto;
        width: 100%;
        z-index: 2;
    }
    .testimonial-section-five .slick-prev {
        display: none !important;
    }
    .testimonial-section-five .slick-next {
        display: none !important;
    }
    .tablist-five {
        display: flex;
        margin-bottom: 0;
    }
    .tablist-five .nav-tab.active {
        color: #2D0160;
        border-bottom: 3px solid #2D0160;
        padding-bottom: 5px;
    }
	.slider-five-one {
		position: absolute;
		top: 10.5rem;
	}
	.slider-five-one img {
		width: 300px !important;
		max-width: 550px;
	}
	.slider-five-two img {
		width: 300px !important;
		max-width: 550px;
		float: right;
	}	
    .leading-five-content h2 {
        font-size: 25px;
        text-align: center;
    }
    .leading-five-content {
        margin-bottom: 30px;
    }
    .course-icon-five img {
        padding: 15px;
    }
    .course-icon-five {
        margin-bottom: 29px;
    }
    .course-info-five h3 {
        font-size: 18px;
    }
    .course-info-five p {
        font-size: 14px;
    }
    .tablist-five .nav-tab {
        font-size: 14px;
        display: block;
        text-align: center;
        margin: auto auto 20px;
    }
    .tablist-five .nav-tab.active {
        border-bottom: none;
        padding-bottom: 5px;
        font-size: 14px;
    }
    .tablist-five {
        display: block;
        text-align: center;
        border-bottom: none;
    }
    .product-img-five img {
        width: 100%;
    }
    .product-content-five h3 a {
        font-size: 16px;
    }
    .product-content-five .price-five-group h3 {
        font-size: 16px;
    }
    .count-content-five p {
        font-size: 18px;
    }
    .skill-five-content h3 {
        font-size: 18px;
    }
    .header-five-title {
        margin-bottom: 20px;
    }
    .instructor-vector-left {
        display: none;
    }
    .instructor-vector-right {
        display: none;
    }
    .joing-count-five-one {
        position: absolute;
        left: 0rem;
        top: 0.5rem;
    }
    .joing-count-five-two {
        position: absolute;
        left: 6rem;
        bottom: -2.5rem;
    }
    .joing-count-five-three {
        position: absolute;
        right: 0rem;
        top: 2rem;
        z-index: 2;
    }
    .goals-content-five h4 {
        font-size: 25px;
    }
    .transform-section-five {
        padding: 50px 0 50px;
    }
    .joing-count-number {
        font-size: 20px;
    }
    .joing-count-text {
        font-size: 16px;
    }
    .developer-five-list .column-img {
        display: flex;
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .developer-profile-five {
        width: 100%;
    }
    .experienced-five-sub {
        width: 100%;
        max-width: 100%;
        margin: 0;
    }
    .experienced-five-sub {
        padding-left: 0;
    }
    .testimonial-image {
        display: none;
    }
    .testimonial-users h5 {
        font-size: 18px;
    }
    .count-five-last {
        margin-bottom: 0;
    }
    .leading-five-content p {
        font-size: 14px;
        text-align: center;
    }
    .blog-five-footer h3 a {
        font-size: 18px;
    }
    .blog-box-content {
        padding: 20px;
    }
    .footer.footer-five {
        padding: 50px 0 50px;
    }
    .copyright-five {
        padding: 20px 0 0;
    }
    .footer-title-five {
        font-size: 16px;
    }
    .footer-logo-five img {
        width: 120px;
    }
    .social-icon-three ul li a {
        width: 32px;
        height: 32px;
    }    
    .copyright-text-three p {
        font-size: 14px;
    }
    .footer-news-five .btn {
        width: 115px;
        height: 40px;
    }
    .footer-news-five .form-control {
        min-height: 40px;
    }
    .footer-logo-five {
        text-align: center;
        margin-bottom: 15px;
    }
    .footer-bottom-five .copyright-text {
        text-align: center;
        margin-bottom: 15px;
    }
    .social-icon-three {
        display: block;
        float: none;
        text-align: center;
        margin-bottom: 0;
    }
    .footer.footer-five {
        padding: 30px 0 30px;
    }
    .footer-news-five .form-group ::placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group :-ms-input-placeholder { 
        font-size: 12px;
    }      
    .footer-news-five .form-group::-ms-input-placeholder { 
        font-size: 16px;
    }
    .footer-top-six .email-address-six {
        max-width: 550px;
        padding: 10px;
        width: 100%;
        height: 115px;
        gap: 10px;
        flex-direction: column;
    }
    .banner-sections-six .banner-imgs .banner-img-three {
        position: absolute;
        bottom: 14rem;
        right: 0;
        max-width: 100%;
    }
    .banner-sections-six .banner-imgs .react-icon {
        display: none;
    }
    .banner-sections-six .banner-imgs .banner-img-two {
        position: absolute;
        top: 13rem;
        right: 0;
        max-width: 300px;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        display: none;
    }
    .best-course-section .section-small-imgs .vector-img {
        display: none;
    }
    .best-course-section .small-icons .arrow-img {
        display: none;
    }
    .banner-sections-six .banner-imgs .react-icon {
        display: none;
    }
    .banner-sections-six .banner-imgs .figma-icon {
        display: none;
    }
    .registration-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 50px;
    }
}

/* Home two without home-two class */

.header-two-text {
    max-width: 700px;
    color: #685F78;
    font-size: 16px;
    font-weight: 500;
	margin: auto;
}
.about-image {
    background: #FFFFFF;
    border: 1px solid #E0EBFF;
    box-shadow: 0px 4px 34px #e0ebff;
    border-radius: 10px;
    height: 300px;
    -webkit-transition: all 1.5s;
    transition: all 1.5s;
    margin-bottom: 24px;
}
.about-image:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    background: #fefefe;
    box-shadow: none;
}
.add-header-bg {
	/* box-shadow: 0px 4px 15px hsl(220deg 23% 97%); */
    transition-duration: 0.4s;
    background: #fff;
    padding-top: 0;
    margin: 0;
}
.banner-content .input-group {
    background: #fff;
    padding-left: 0;
    align-items: center;
}
.slide {
    position: relative;
    background-image: url(../img/bg/home-main.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.slide .home-slide-face {
    padding: 230px 0 230px;
}
.slide .drop-detail {
    width: 170px;
    display: flex;
    margin: 0;
    border-radius: 50px !important;
    border: 0;
    height: 44px;
}
.slide .banner-content .form-inner {
    padding: 10px;
    background-color: #fff;
    border-radius: 35px;
    width: 100%;
    border: 0;
    max-width: 740px;
}
.banner-content .input-group>:not(:first-child) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #F4F4F4;
}
.trust-user-two p {
    color: #324FA2;
    font-weight: 500;
    font-size: 16px;
}
.trust-user-two .rating-two {
    color: #21B477;
	font-size: 15px;
	font-weight: 700;
}
.trust-user-two .rating-two i {
	font-size: 14px;
}
.banner-content .form-inner {
    max-width: 856px;
    width: 100%;
}
.banner-content .select2-container 
.select2-selection--single {
    border: 0;
    align-items: center;
    display: flex;
    background: #ffffff;
    height: 44px;
    border-right: 1px solid #f5f5f5;
    border-radius: 0;
}
.trust-user p {
    margin: 30px 0px 10px;
    font-weight: 500;
    font-size: 16px;
    color: #324FA2;
}
.shapes-one {
    position: absolute;
    right: -12rem;
    top: 0;
}
.shapes-two {
    position: absolute;
    right: 7rem;
    bottom: 0;
    top: 26rem;
    animation: up-down 3s ease-in-out infinite alternate-reverse both;
}
.shapes-four {
    position: absolute;
	width: 220px;
    left: -5rem;
    bottom: -1rem;
}
.shapes-middle {
    position: absolute;
    left: 50rem;
    top: 18rem;
    bottom: 0;
}
.about-section {
	padding: 60px 0 60px;
}
.trust-user .rating .filled {
    color: #21B477;
	font-size: 16px;
}
.trust-user .rating span {
    color: #21B477;
	font-size: 16px;
}
/* Home 2 category */
.category3{
    width: 54px;
    height: 54px;
}

/* Owl Carousel styles */
.owl-prev {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    margin-left: -20px;
    display: block !important;
    border:0px solid black;
}

.owl-next {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: -25px;
    display: block !important;
    border:0px solid black;
}
.owl-prev i, .owl-next i {transform : scale(1,6); color: #ccc;}

.stickysidebar {
    position: sticky;
    top: 0;
}

.css-b62m3t-container{
    cursor: pointer;
}

.personal-form .form-check{
    margin-bottom: 1.125rem !important;
    display: flex;
}

.header-navbar-rht li .show{
    visibility: visible;
    opacity: 1;
}

.modalPosition{
    top: 100%;
}

.submenuShow{
    display: block !important;
}

.main-nav.notifications .noti-content::-webkit-scrollbar {
    width: 6px;
    background: #fff;
    height: 10px;
}
.main-nav.notifications .noti-content::-webkit-scrollbar-track {
    border-radius: 4px;
}
.main-nav.notifications .noti-content::-webkit-scrollbar-thumb {
    background: #8A8A95; 
    border-radius: 4px;
}
.main-nav.notifications .noti-content::-webkit-scrollbar-thumb:hover {
    background: #1B2559; 
}

.listMob{
    float: right;
}
.faqFlex{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.faqicon{
    margin-right: 10px;
    cursor: pointer;
}
.faqChange{
    color: red;
}
.header-navbar-rht li .dropdown-menu {
    margin-left: auto;
}

svg.feather.feather-search{
    width: 16px;
}
svg.feather.feather-filter {
    width: 20px;
}
svg.feather.feather-grid {
    width: 20px;
}
svg.feather.feather-list {
    width: 20px;
}
svg.feather{
    width: 20px;
}
svg.feather.feather-corner-up-left {
    width: 14px;
    margin-right: 3px;
}
svg.feather.feather-heart {
    width: 14px;
    margin-right: 2px;
}
svg.feather.feather-share-2 {
    width: 14px;
    margin-right: 3px;
}
.addHeart{
    display: flex;
    align-items: center;
}
.rti--tag {
    align-items: center;
    /* background: var(--rti-tag); */
    border-radius: var(--rti-radius);
    display: inline-flex;
    justify-content: center;
    /* padding: var(--rti-tag-padding); */
    margin-right: 5px;
    margin-bottom: 5px;
    color: #263238;
    border: 1px solid #e9ecef;
    background: rgba(227, 227, 227, 0.41) !important;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 12px;
}


.slideIcon {
    font-size: 24px;
    color: #392c7d !important;
    cursor: pointer;
    width: 44px;
    height: 44px;
    -webkit-transform: none !important;
    transform: none !important;
  }
  .real-reviews-sec .owl-nav {
    display: block;
  }
  .real-reviews-sec .owl-nav button {
    background: none !important;
    color: #392c7d !important;
    transition: 2s;
  }
  .real-reviews-sec .owl-nav button :hover {
    color: #ff602e !important;
  }
  .real-reviews-sec .owl-prev {
    top: auto;
    bottom: -80px;
    left: 0;
  }
  .real-reviews-sec .owl-next {
    top: auto;
    bottom: -70px;
    left: 30px;
  }
  
  /* .topCat .owl-item{
      width:356px;
  margin-right: 24px;
  } */
  
  .review-rating-five .rating-star {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 3.5px;
  }
  
  .home-slide-five .drop-detail-five {
    display: flex;
    justify-content: center;
  }
  .featured-section-five .tab-content .nav {
    justify-content: center;
  }
  .home-five .course-section-five {
    z-index: 998 !important;
  }
  
  .nav.tablist-five {
    margin-bottom: 40px;
  }
  .blogs-section-five .mentoring-course .owl-stage {
    padding-top: 0;
  }
  
  .blogs-section-five h3 a {
    color: #22100d;
  }
  .featured-section-five .tablist-five {
    padding-bottom: 0;
  }
  
  .coverFlow {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .coverFlow::after {
    content: "\f054";
  }
  .coverItem {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 455px;
    height: 460px;
  }
  .coverPos {
    top: 60px;
    right: 0;
    position: absolute;
  }
  .coverFlow .swiper-slide {
    position: absolute;
  }
  
  .coverLeft {
    transform: translateX(-45%) scale(0.5);
    transition: 500ms;
    opacity: 0.8;
    position: absolute;
    top: 150px;
  }
  .coverRight {
    transform: translateX(45%) scale(0.5);
    transition: 500ms;
    opacity: 0.8;
    position: absolute;
    top: 150px;
  }
  .coverActive {
    z-index: 1;
  }
  .swipeSlide {
    transform: scale(0.5);
    transition: transform 300ms;
    opacity: 0.5;
  }
  .swipeActive {
    transform: scale(1);
    opacity: 1;
  }
  .arrow {
    cursor: pointer;
    position: absolute;
    z-index: 10;
  }
  .arrow svg {
    transition: color 300ms;
  }
  .arrow svg:hover {
    color: red;
  }
  .next {
    right: 0;
    top: 50%;
  }
  .prev {
    left: 0;
    top: 50%;
  }
  .swiper-slide {
    width: 80% !important;
    border-radius: 10px;
  }
  .mySwiper {
    width: 50vw;
    box-sizing: border-box;
  }
  
  .slick-slide {
    width: 200px;
  }
  .slick-active {
    width: 400px;
  }
  
  .notification-list .media-body h6 a {
    color: #22100d;
  }
  .notification-list .media-body h6 a:hover{
    color: #F66962;
  }
  
  .testimonial-three .prev {
    left: 20px;
  }
  .home-three .testimonial-three {
    /* padding: 150px 0; */
  }
  /* .slick-list {
      /* Show slides that would otherwise be hidden off-screen 
      /* overflow: visible;
    } */
  
  .slick-slide:not(.slick-current) {
    /* Make slides other than the current one translucent  */
    opacity: 0.4;
  }
  /* .varWidth .slick-center .testimonial-item-five{
      /* background-color: black; 
    } */
  .homeSearch {
    padding-left: 12px !important;
  }
  .home-two .rating-two {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .home-two .navbar.header-nav {
    padding-bottom: 0;
  }
  
  .settings-widget h5 a {
    color: #22100d;
  }
  .settings-widget h5 a:hover {
    color: #f66962;
  }
  .settings-widget .table-responsive a {
    color: #22100d;
  }
  .headerIcon {
    margin-right: 0.25rem;
  }
  .dropdown-item.text,
  .text-muted.text {
    font-size: 14px;
  }
  .dropdown-item.text:active {
    background-color: #f7f7f7;
  }
  /* .sideStickyBar {
      position: sticky;
      top: 0;
  } */
  .wish-header a {
    color: inherit;
    text-decoration: underline !important;
  }
  .wish-header a:hover {
    color: #f66962;
  }
  .btn.btn-reject {
    margin-left: 4px;
  }
  
  .addBg {
    background-color: transparent !important;
  }
  .btn.btn-primary:hover {
    background-color: #ff5364 ;
    border-color: transparent;
  }
  .account-link .btn-primary:hover {
    background-color: #777777 !important;
    border-color: transparent;
  }
  .df {
    display: flex;
    align-items: center;
  }
  .iconMar {
    margin-right: 14px !important;
  }
  .student-content h5 a:hover {
    color: #f66962;
  }
  .go-dashboard.text-center .btn.btn-primary:hover {
    background-color: #392c7d !important;
  }
  .go-dashboard.text-center .btn.btn-primary:focus {
    box-shadow: none;
    background-color: #392c7d !important;
  }
  .update-profile .btn.btn-primary:hover {
    background-color: transparent !important;
    border: 1px solid #ff875a;
  }
  .update-profile .btn.btn-primary:focus {
    box-shadow: none;
    background-color: #392c7d !important;
  }
  .google-link .btn.btn-primary:hover{
      background-color: #777777 !important;
  }
  .google-link .btn.btn-primary:focus {
      box-shadow: none;
      background-color: #392c7d !important;
    }
  .google-link .btn.btn-primary:active {
      background-color: #fc7f50;
      border-color: #fc7f50;
      color: #fff;
    }
    .refer-avatar-blk:hover{
      color: #F66962 !important;
    }
    .hvr-sweep-to-right .btn-primary{
      background-color: unset;
    }
    .subscribtion-active .btn-danger{
      background-color: #ff0100;
      border: 1px solid #ff0100;
    }
    .btn.btn-action-primary{
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      padding: 4px 8px;
      text-transform: uppercase;
      background-color: #159F46;
    }
    .btn.btn-action-dark{
      min-width: 35px;
      background-color: #455A64;
      color: #ffffff;
    }
    .btn.btn-action-method {
      min-width: 35px;
      display: block;
      background-color: #FF875A;
      color: #ffffff;
  }
  .btn.btn-action-delete {
      min-width: 35px;
      background-color: #EB4334;
      color: #ffffff;
  }
  
  /* Tooltip */
  .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
      width: auto;
      top: 0;
    /* margin-left: -60px; */
    }
    
    /* Tooltip text */
    .tooltip1 .tooltipEdit, .tooltipMake, .tooltipRemove {
      visibility: hidden;
      width: auto;
      background-color: black;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 5px 5px;
      border-radius: 6px;
     
      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip1:hover .tooltipEdit {
      visibility: visible;
    }
    .tooltip1:hover .tooltipMake {
      visibility: visible;
    }
    .tooltip1:hover .tooltipRemove {
      visibility: visible;
    }
  
    .btn-action-dark{
      color: white;
    }
    .payment-list .list-inline-item a:hover{
      color: white;
    }
    .payment-list .list-inline-item a:focus{
      box-shadow: unset;
    }
    .ticket-btn-grp a:hover{
      color: white;
    }
    .linkColor{
      color: #f66962 !important ;
  
    }
    .nav-item.user-nav .form-check .form-check-input{
  
    }
    .nav-item.user-nav .form-check .form-check-input:checked{
      
    }
    .comment-sec .btn.submit-btn{
        font-weight: 500;
        color: #392C7D;
        border-radius: 50px;
        padding: 9px 15px;
        min-width: 150px;
        border: 4px solid transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        background-image: linear-gradient(white, white), linear-gradient(to right, rgba(180, 167, 245, 1), rgba(57, 44, 125, 1));
    }
    .all-category .btn.btn-primary:hover {
      background-color: #917cf6 !important;
      border-color: #917cf6;
      color: #fff;
  }
  .edu-detail a{
    color: #22100D;
  }
  .edu-detail a:hover{
    color: #F66962;
  }
  .instruct-orders-info p a:hover{
    color: #F66962;
  }
  .red{
    color: #F66962 !important;
  
  }
  .hoverRed:hover{
    color: #F66962 !important;
  }
  .instruct-check-list .form-check-input:checked{
    background-color: #F66962;
  }
  .instruct-check-list .form-check-input:focus{
    color: #F66962;
    box-shadow: none;
    border-color: #F66962;  
  }
  .pl0{
    padding-left: 0 !important;
  }
  .green{
    color: #00cc52 !important;
  }
  
  .btn-dark-1{
    background: #392C7D !important;
      border: 1px solid #392C7D;
  }
  .btn-dark-1:hover{
    background: transparent !important;
    color: #392C7D !important;
      border: 1px solid #392C7D;
  }
  .searchFeather{
    position: absolute;
    left: 9px;
    color: #F66962;
  }
  .hoverBlue .btn:hover{
    background: #3b2999 !important;
      border: 1px solid #3b2999;
  }
  .hoverBlue .btn:focus{
    box-shadow: none !important;
  }
  .btn.btn-primary.hover0:hover{
    background-color: transparent !important;
  }
  .btn.btn-primary.hover0:focus{
    box-shadow: none;
  }
  .add-compose .btn.btn-primary:hover {
    background-color: #FF875A !important;
    border: 1px solid #FF875A;
    color: #fff;
  }
  .home3Slide{
  width: auto !important;}
  .home-three .swiper-slide{
    display: flex;justify-content: center;
  }
  svg.feather.feather-user.me-1 {
    width: 14px;
    color: #FF875A;
}
svg.feather.feather-star.me-1 {
    width: 14px;
    color: #FF875A;
}
svg.feather.feather-moon.me-1 {
    width: 14px;
    color: #685F78;
}
svg.feather.feather-log-out.me-1 {
    width: 14px;
    color: #FF875A;
}
.home-three .swiper-testimonial-three {
    height: auto !important;
  }
  
  @media (max-width:1200px) {
    .home-three .swiper-testimonial-three {
      margin-top: 20px !important;
    }  
  }

/* .home-two-slide.css-1dimb5e-singleValue {
    grid-area: 1/1/2/3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: hsl(0, 0%, 20%);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    margin-top: 3px;
} */
/* .home-two-slide.css-tj5bde-Svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    margin-top: 4px;
} */
.testi-right img{
    margin-right: 25px;
}
.feather-icon svg{
    color: #FF875A;
    margin-right: 10px;
    width: 20px;
}
.owl-nav slide-nav-8 nav-control{
    display: none;
}

/* .btn-primary {
    background-color: #FF875A !important;
    border: 1px solid #FF875A !important;
} */